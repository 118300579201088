export default [
  {
    TipoIdentificacion: "CC",
    NumeroIdentificacion: "456",
    Nombres: "Pepe Peperoni",
    Prioritario: false,
    ConCita: false,
    PrioridadMarcaValor: 1
  },
  {
    TipoIdentificacion: "CC",
    NumeroIdentificacion: "789",
    Nombres: "Natasha Cox",
    Prioritario: true,
    ConCita: true,
    PrioridadMarcaValor: 1
  }
];
