var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "IsOlderOverlay" }),
    _c(
      "div",
      {
        staticClass: "IsOlderModal p-4 text-center",
        attrs: { id: "IsOlderModal" }
      },
      [
        _c("p", { staticClass: "buttonText pb-3" }, [
          _vm._v("Este servicio es para mayores de 60 años")
        ]),
        _c(
          "b-button",
          {
            staticClass: "AuthsBtn text-center",
            attrs: {
              variant: "bluecolmedica",
              block: "",
              size: "lg",
              type: "button"
            },
            on: {
              click: function() {
                return _vm.goToView({ view: "Autenticate" })
              }
            }
          },
          [
            _c("p", { staticClass: "buttonText m-0" }, [
              _vm._v("Ingresar nuevos datos")
            ])
          ]
        ),
        _vm.view === "IsOlder" && !_vm.showAreYouThere
          ? _c(
              "div",
              { staticClass: "p-3" },
              [
                _c("BottomTimer", {
                  attrs: {
                    doThis: _vm.onShowAreYouThere,
                    $element: "#IsOlderModal"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.showAreYouThere
          ? _c(
              "div",
              [
                _c("AreYouThere", {
                  on: { restartAreYouThere: _vm.restartTimerWaiting }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }