var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Tutorial" }, [
    _c(
      "button",
      {
        staticClass:
          "button-without-styles d-flex justify-content-center align-items-center m-0 position-relative mt-0",
        class: _vm.isModeTutorial && "buttonHelp"
      },
      [
        _c("img", {
          staticClass: "helpIcon",
          attrs: {
            src: require("@/assets/older_adults/helpIcon.svg"),
            alt: "helpIcon"
          },
          on: { click: _vm.onToggleTutorialMode }
        }),
        _vm.showTooltip
          ? _c(
              "div",
              {
                staticClass: "tooltipTutorial",
                on: { click: _vm.onToggleTutorial }
              },
              [
                _c("p", { staticClass: "mb-0 text-center mx-auto p-2" }, [
                  _vm._v(
                    _vm._s(
                      _vm.isModeTutorial
                        ? "Desactivar"
                        : "Presiona este ícono para activar el tutorial"
                    )
                  )
                ])
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }