// Tool for custom events for Tag Manager
window.dataLayer = window.dataLayer || [];

class GTM {
  setFirstElementDatalayer({ session_id, version, user_type, user_uuid }) {
    let index = window.dataLayer.findIndex(elm => elm.session_id);
    if (index === -1) {
      window.dataLayer.push({
        session_id, // Id of session
        version, // BSIX version
        user_type, // agent or client
        user_uuid // unique user identificator
      });
    } else {
      window.dataLayer[index] = {
        ...window.dataLayer[index],
        ...{ session_id, version, user_type, user_uuid }
      };
    }
  }
  checkSession() {
    return window.dataLayer.find(elm => elm.session_id);
  }
  setVariable(variable) {
    if (
      variable &&
      typeof variable === "object" &&
      Object.keys(variable).length
    )
      window.dataLayer.push(variable);
  }
  replaceSession(session) {
    let index = window.dataLayer.findIndex(elm => elm.session_id);
    if (session && index > -1) window.dataLayer[index].session_id = session;
    else console.error("Not exist session in GTM", session, window.dataLayer);
  }
  boxOpen(mdata) {
    const data = JSON.parse(JSON.stringify(mdata));
    this.__traverse(data);
    this.__sendEvent("GTMBoxOpen", data);
  }

  boxClose(mdata) {
    const data = JSON.parse(JSON.stringify(mdata));
    this.__traverse(data);
    this.__sendEvent("GTMBoxClose", data);
    this.extinguishVisibility(data.view);
  }

  event({ name, eventCategory, eventAction, eventLabel, eventValue = 0 }) {
    this.__sendEvent(name, {
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    });
  }

  __sendEvent(name, data = {}) {
    data.timeOfEvent = Date.now();
    window.dataLayer.push({
      event: name,
      data
    });
  }

  __traverse(obj, parn, ky) {
    // Internal traverse
    traverse(obj, parn, ky);

    // Internal Fnc
    function traverse(o, parent, key) {
      const type = typeof o;
      if (o !== null && type === "object") {
        for (const keyIntro in o) {
          // eslint-disable-next-line no-prototype-builtins
          if (o.hasOwnProperty(keyIntro)) {
            traverse(o[keyIntro], o, keyIntro);
          }
        }
      } else {
        if (key === "html") {
          delete parent[key];
        }
      }
    }
  }
}

export let GTMService = new GTM();
