var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "CurrentUser d-flex justify-content-between alig-items-stretch h-100",
      style:
        _vm.isAtril &&
        "width: 100%; overflow: hidden; height: calc(100vh - 112.06px) !important;"
    },
    [
      _c("div", { staticClass: "Content w-100 flex-1" }, [
        _c("div", { staticClass: "banderaSvg" }),
        _vm._m(0),
        _c(
          "form",
          {
            style: _vm.isAtril ? "margin-top: 30px;" : "padding-bottom: 100px;",
            attrs: { id: "validateUser", autocomplete: "off" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.sendData.apply(null, arguments)
              }
            }
          },
          [
            _c("div", { staticClass: "containerForm" }, [
              _c("p", { staticClass: "label-text" }, [
                _vm._v("Tipo de documento")
              ]),
              _c(
                "div",
                {
                  staticClass: "form-select-container select--Input-text mb-2"
                },
                [
                  _c("b-form-select", {
                    staticClass: "Input-text",
                    attrs: {
                      options: _vm.options,
                      size: "sm",
                      autocomplete: "off",
                      required: "",
                      id: "formSelect"
                    },
                    model: {
                      value: _vm.models.TipoIdentificacion,
                      callback: function($$v) {
                        _vm.$set(_vm.models, "TipoIdentificacion", $$v)
                      },
                      expression: "models.TipoIdentificacion"
                    }
                  }),
                  _c(
                    "svg",
                    {
                      staticClass: "mr-3",
                      attrs: {
                        width: "30",
                        height: "29",
                        viewbox: "0 0 30 29",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      },
                      on: {
                        click: function() {
                          return _vm.document
                            .getElementById("formSelect")
                            .focus()
                        }
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M15.0001 1.16699C17.6371 1.16699 20.215 1.92946 22.4077 3.35791C24.6003 4.78637 26.3093 6.81667 27.3184 9.19211C28.3276 11.5675 28.5916 14.1814 28.0772 16.7031C27.5627 19.2249 26.2928 21.5413 24.4281 23.3594C22.5634 25.1774 20.1877 26.4156 17.6013 26.9172C15.0149 27.4188 12.334 27.1613 9.89762 26.1774C7.46128 25.1935 5.3789 23.5272 3.91382 21.3894C2.44873 19.2516 1.66675 16.7382 1.66675 14.167C1.66675 10.7192 3.0715 7.41259 5.57198 4.97462C8.07246 2.53665 11.4638 1.16699 15.0001 1.16699Z",
                          stroke: "#788591",
                          "stroke-width": "2.025",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M15.0002 16.7656L18.5624 13.2925",
                          stroke: "#788591",
                          "stroke-width": "2.025",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M11.4377 13.2925L14.9999 16.7656",
                          stroke: "#788591",
                          "stroke-width": "2.025",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  ),
                  _c("div", { staticClass: "svgBackground" })
                ],
                1
              ),
              _c("p", { staticClass: "label-text" }, [_vm._v("Número")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.models.NumeroIdentificacion,
                    expression: "models.NumeroIdentificacion"
                  }
                ],
                staticClass: "Input-text",
                staticStyle: { "margin-bottom": "15px !important" },
                attrs: {
                  type: "text",
                  min: "0",
                  placeholder: "Ej: 105456783",
                  required: "",
                  autocomplete: "off",
                  disabled: _vm.isAtril,
                  id: "cedula_number"
                },
                domProps: { value: _vm.models.NumeroIdentificacion },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.models,
                      "NumeroIdentificacion",
                      $event.target.value
                    )
                  }
                }
              })
            ])
          ]
        ),
        _vm.isAtril
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-center mt-5 mb-2" },
              [
                _vm.isAlpha
                  ? _c(
                      "PanelAlpha",
                      { staticClass: "mr-5" },
                      _vm._l(_vm.panelAlpha, function(panelAlphaItem, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "item-panel bigbutton",
                            on: {
                              click: function($event) {
                                _vm.printPanel(panelAlphaItem.toUpperCase())
                              }
                            }
                          },
                          [_vm._v(_vm._s(panelAlphaItem.toUpperCase()))]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _c("Panel", [
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(1)
                        }
                      }
                    },
                    [_vm._v("1")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(2)
                        }
                      }
                    },
                    [_vm._v("2")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(3)
                        }
                      }
                    },
                    [_vm._v("3")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(4)
                        }
                      }
                    },
                    [_vm._v("4")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(5)
                        }
                      }
                    },
                    [_vm._v("5")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(6)
                        }
                      }
                    },
                    [_vm._v("6")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(7)
                        }
                      }
                    },
                    [_vm._v("7")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(8)
                        }
                      }
                    },
                    [_vm._v("8")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(9)
                        }
                      }
                    },
                    [_vm._v("9")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton buttonAbc",
                      on: { click: _vm.onChangeAlpha }
                    },
                    [
                      _c("strong", [
                        _vm._v(_vm._s(!_vm.isAlpha ? "ABC" : "123"))
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(0)
                        }
                      }
                    },
                    [_vm._v("0")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-panel bigbutton item-panel-delete_button",
                      on: {
                        click: function($event) {
                          return _vm.printPanel("<")
                        }
                      }
                    },
                    [_c("span", [_vm._v("Borrar")])]
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "containerForm" }, [
          _c(
            "button",
            {
              staticClass: "text-center buttonService mt-3",
              class:
                !_vm.models.TipoIdentificacion ||
                !_vm.models.NumeroIdentificacion ||
                _vm.charging
                  ? "disabled"
                  : "active",
              attrs: { disabled: !_vm.models.NumeroIdentificacion },
              on: {
                click: function($event) {
                  return _vm.printPanel("Ok")
                }
              }
            },
            [
              !!!_vm.charging
                ? _c("span", [_vm._v("Continuar")])
                : _c("div", [_c("MiniSpinner")], 1)
            ]
          )
        ]),
        _vm.error
          ? _c("div", { staticClass: "error" }, [
              _c("span", [_vm._v(_vm._s(_vm.error.message))])
            ])
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "box box2 mx-0 w-100 h-100 position-relative" },
        [
          _vm.areaCustom.backgrounds
            ? _c("img", {
                staticClass: "containerImg-img w-100",
                attrs: {
                  src: _vm.areaCustom.backgrounds.background1,
                  alt: "saludImportantImage"
                }
              })
            : _vm._e(),
          _c("img", {
            staticClass: "logoFSFBTransparent",
            attrs: {
              src: require("@/assets/logoFSFBTransparent.png"),
              alt: "logoFSFBTransparent"
            }
          }),
          _c("div", { staticClass: "box-tape text-center d-flex pl-5" }, [
            _c("div", { staticClass: "boxText text-start d-block" }, [
              _c("div", { staticClass: "ml-4 pl-3 boxBorderer" }, [
                _c("h3", { staticClass: "welcomeText mb-0" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.areaCustom.text))])
                ]),
                _c(
                  "h4",
                  { staticClass: "welcomeText welcomeTextSecondary mb-2" },
                  [
                    _c("strong", [
                      _vm._v("(Zona " + _vm._s(_vm.areaCustom.label) + ")")
                    ])
                  ]
                )
              ]),
              _vm.areaCustom && _vm.areaCustom.labelEnglish
                ? _c("p", { staticClass: "descText mb-0 pl-5 ml-2 mt-4" }, [
                    _vm._v("Welcome to " + _vm._s(_vm.areaCustom.labelEnglish))
                  ])
                : _vm._e()
            ]),
            _vm.areaCustom && _vm.areaCustom.homeLabel
              ? _c(
                  "div",
                  { staticClass: "box-label text-center d-flex pl-5" },
                  [
                    _c("div", { staticClass: "ml-4 pl-3 boxBorderer" }, [
                      _c(
                        "h4",
                        {
                          staticClass: "welcomeText welcomeTextSecondary mb-2"
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.areaCustom.homeLabel))
                          ])
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-dk mt-4 mb-4 pt-4 text-center" }, [
      _c("h3", { staticClass: "fonterH3 mx-auto d-block" }, [
        _vm._v("Ingresa tus datos")
      ]),
      _c(
        "p",
        { staticClass: "fonterCurrentUser textHero m-0 mx-auto d-block" },
        [_vm._v("Digita tu número de documento")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }