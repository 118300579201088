/* eslint-disable no-unused-vars */
import {
  getToken,
  getQueues,
  getStyles,
  getEnvOnly,
  getMenus,
  sendRecords,
  tracking
} from "../../helpers/Meet";
import { autorizar, consultarCliente } from "../../helpers/colmedica_huella";
import _get from "lodash/get";
import LS from "../../helpers/localStorage";

/**
 * Data for demo porpouses
 */
import CentroDemo from "./CentroDemo";
import OficinaDemo from "./OficinaDemo";
import CitasDemo from "./CitasDemo";
import ValPagoDemo from "./ValidarpagocitaDemo";

export default {
  changeAuth({ commit }, value) {
    commit("setState", { key: "showAuth", value });
  },

  setCustomArea({ commit }, value) {
    commit("setState", { key: "customArea", value });
  },

  async getSites({ commit }) {
    const sites = await getQueues();
    if (sites && sites.length)
      commit("setState", { key: "sites", value: sites });
  },

  async getStyles({ commit }) {
    try {
      const styles = await getStyles();
      commit("setState", {
        key: "primaryColorBlue",
        value: styles?.bsix_client?.companyColor
      });
      commit("setState", {
        key: "secondColorBlue",
        value: styles?.bsix_client?.secondaryCompanyColor
      });
      commit("setState", {
        key: "threeColorBlueColor",
        value: styles?.bsix_client?.contrastTextColor
      });
    } catch (error) {
      console.error("Cant get Styles", error);
    }
  },

  setEnv({ commit }, value) {
    commit("setState", { key: "env", value });
  },

  async getMenus({ commit }, { code, area }) {
    const { menu, actions, customMenu } = await getMenus(code);
    commit("setState", { key: "menu", value: customMenu[area] || menu });
    commit("setState", { key: "actions", value: actions });
  },

  goToView({ commit, state, dispatch }, { view }) {
    if (state.isAtril) {
      dispatch("setTimer", true, { root: true });
    }
    commit("setState", { key: "view", value: view });
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.push(view);
    commit("setState", { key: "history", value: newArray });
  },

  setExplanation({ commit }, value) {
    commit("setState", { key: "explanation", value });
  },

  selecteService({ commit }, value) {
    commit("setState", { key: "servicioSelected", value });
  },

  goBack({ commit, state }) {
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.pop();
    commit("setState", { key: "history", value: newArray });
    commit("setState", {
      key: "view",
      value: state.history[state.history.length - 1]
    });
  },

  setAction({ commit, dispatch }, value) {
    commit("setState", { key: "selectedAccion", value });
    if (value?.evaluationComponent)
      return dispatch("goToView", { view: value.evaluationComponent });
    if (value?.ignorePriority) dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  confirmedAction({ state, commit, dispatch }) {
    // /  state.selectedaction tiene ignorePriority?
    if (state.selectedAccion?.ignorePriority)
      dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  turnCall({ commit, dispatch }, value) {
    commit("setState", { key: "priority", value });
    dispatch("goToView", { view: "Turn" });
  },

  captchaValidation({ dispatch, commit, state }) {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(getEnvOnly().VUE_APP_RECAPTCHA_CODE, {
              action: "submit_identification"
            })
            .then(token =>
              getToken(token, "g_recaptcha_v3", {
                bunitId: getEnvOnly().VUE_APP_BUNIT
              })
            )
            .then(() => res())
            .catch(err => {
              commit("setState", {
                key: "validationErrors",
                value: [...state.validationErrors, err]
              });
              dispatch("errorValidationTemporizer");
              console.error("error in recaptcha request.");
              rej(err);
            });
        }); //
        // throw new Error('X ERROR');
      }).catch(err => {
        commit("setState", { key: "charging", value: false });
        console.error("Error cached in Autenticate.vue -> demoValidation", err);
        dispatch("goToView", { view: "ErrorAutenticate" });
      });
    }
    return Promise.reject(new Error("Recaptcha is not ready"));
  },

  async authenticate({ dispatch, commit, state, root }, toSend) {
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "error", value: null });
    let nextComponent = {
      view: "NewUser"
    };
    try {
      if (!state.isAtril) await dispatch("captchaValidation");
      try {
        await dispatch(
          getEnvOnly().VUE_APP_DEMO_MODE === "true"
            ? "demoValidation"
            : "authAndQuery",
          toSend
        );
        // check age
        if (
          (state.client && !state.client.Edad) ||
          (state.client &&
            state.client.Edad &&
            state.client.Edad >= state.env.VUE_APP_OLD_AGE_VALIDATION)
        ) {
          nextComponent.view = "VerificationData";
        } else {
          nextComponent.view = "IsOlder";
        }
      } catch (error) {
        console.error("Error in authorization kiosko 46", error);
        commit("setState", {
          key: "error",
          value: {
            zone: "authorization",
            message: "El usuario no se encuentra registrado en el sistema"
          }
        });
        dispatch("errorValidationTemporizer");
        nextComponent.view = "NewUser";
      }
    } catch (error) {
      console.error("Error in Captcha", error);
      commit("setState", {
        key: "error",
        value: { zone: "recaptcha", message: "La validación del captcha falló" }
      });
      dispatch("errorValidationTemporizer");
    }
    dispatch("goToView", nextComponent);
    commit("setState", { key: "charging", value: false });
  },

  async demoValidation({ commit, state, dispatch }, number_id) {
    let client = state.test_users.find(
      u => u.NumeroIdentificacion == number_id
    );
    if (!client || (client && !client.NumeroIdentificacion)) {
      dispatch("goToView", { view: "NewUser" });
      commit("setState", {
        key: "client",
        value: {
          NumeroIdentificacion: number_id
        }
      });
      commit("setState", { key: "charging", value: false });
    } else {
      commit("setState", {
        key: "client",
        value: client
      });
      dispatch("goToView", { view: "VerificationData" });
      commit("setState", { key: "charging", value: false });
    }
  },

  async authAndQuery({ state, commit, dispatch }, pay) {
    try {
      await autorizar({
        TipoIdentificacion: pay.TipoIdentificacion,
        NumeroIdentificacion: pay.NumeroIdentificacion
      });

      const inSite = {};

      // const inSite = await (state.currentSite?.type === "cm"
      //   ? enCentroMedico
      //   : enOficina)();
      // commit("setState", { key: "citas", value: [] });
      // if (state.currentSite?.type === "cm") {
      //   const citasList = await citas();
      //   commit("setState", { key: "citas", value: citasList });
      // }
      commit("setState", {
        key: "currentUser",
        value: { ...pay, ...inSite }
      });

      await dispatch("consultaClienteColmedica", pay);
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }
  },

  consultaClienteColmedica({ commit, dispatch, state }, pay) {
    return consultarCliente(pay)
      .then(client => {
        commit("setState", {
          key: "client",
          value: { ...pay, ...client }
        });
      })
      .catch(err => {
        commit("setState", {
          key: "validationErrors",
          value: [...state.validationErrors, err]
        });
        dispatch("errorValidationTemporizer");
        console.error("error in autorizacion colmedica.");
        throw err;
      });
  },

  handleSendRecords({ commit }, info) {
    return sendRecords(info)
      .then(response => {
        // commit("saveRecord", response);
        commit("setState", { key: "saveRecord", value: response });
        return response;
      })
      .catch(error => {
        // commit("errorRecord", error);
        commit("setState", { key: "errorRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  handleTracking({ commit }, info) {
    return tracking(info)
      .then(response => {
        commit("setState", { key: "trackingRecord", value: response });
        return response;
      })
      .catch(error => {
        commit("setState", { key: "errorTrackingRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  errorValidationTemporizer({ commit, state }) {
    if (state.errorValidationTemporizer !== null)
      clearTimeout(state.errorValidationTemporizer);
    if (state.error) return;
    let timer = setTimeout(() => {
      commit("setState", { key: "error", value: null });
      commit("setState", { key: "errorValidationTemporizer", value: null });
    }, 5000);
    commit("setState", { key: "errorValidationTemporizer", value: timer });
  },

  cleanCurrentUser({ commit }) {
    commit("setState", { key: "currentUser", value: null });
    LS.removeItem("currentUser");
  },
  
  setService({ commit }, value) {
    commit("setState", { key: "service", value });
  }
};
