var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TikTokVideos" }, [
    _c("div", { staticClass: "ContainerHeader pb-5" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-between" },
        [
          _c("img", {
            staticClass: "icn_backicn_back ml-3 backButton",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            },
            on: {
              click: function() {
                return _vm.goToView({ view: "Turn" })
              }
            }
          }),
          _c("h3", { staticClass: "fontTitleHeader m-0" }, [
            _vm._v("Turno asignado")
          ]),
          _c("img", {
            staticClass: "icn_backicn_back opacity-0 mr-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "TikTokVideosContainer" },
      [
        _vm._l(_vm.videos, function(video, index) {
          return _c("video", {
            key: index,
            staticClass: "VideoColmedica",
            attrs: { src: video, loop: "", controls: "" }
          })
        }),
        _c(
          "div",
          {
            staticClass: "boxAttendee",
            on: { drop: _vm.handlerDrop, dragover: _vm.handlerDragover }
          },
          [
            _c("h4", { staticClass: "text-center AssignmentTextAttendee" }, [
              _vm._v("Turno asignado:")
            ]),
            _c(
              "h3",
              {
                staticClass: "text-center font-weight-bold ImportantAsignment"
              },
              [_vm._v(_vm._s(_vm.assignTurn))]
            ),
            _c("h5", { staticClass: "mt-1 text-center mb-1 ActualAttendee" }, [
              _vm._v("Espera:")
            ]),
            _c("h5", { staticClass: "text-middle font-larger" }, [
              _vm._v(_vm._s(_vm.waitingTime))
            ])
          ]
        ),
        _vm.showSignal
          ? _c("div", { staticClass: "containerDescTikTok" }, [
              _c("img", {
                staticClass: "containerDescTikTok-img",
                attrs: { src: require("@/assets/mobileFSFB/handIconFSFB.svg") }
              })
            ])
          : _vm._e(),
        _c(
          "b-button",
          {
            staticClass: "w-100 py-2 mx-1 text-center buttonTurnero2 mt-3",
            attrs: { variant: "bluecolmedica", size: "sm", type: "button" },
            on: {
              click: function() {
                return _vm.goToView({ view: "CancelTurn" })
              }
            }
          },
          [_c("span", { staticClass: "font-md" }, [_vm._v("Cancelar turno")])]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }