var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "StackItem px-4 d-flex justify-content-between w-full align-items-center text-secondary",
      class: _vm.fv
    },
    [
      _c("span", [_vm._t("default")], 2),
      _c(
        "svg",
        {
          attrs: {
            width: "11",
            height: "18",
            viewbox: "0 0 11 18",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d: "M2 2L8.99954 8.99954",
              stroke: "#ff0304",
              "stroke-width": "2.53762",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          }),
          _c("path", {
            attrs: {
              d: "M2 15.9688L8.99954 8.96921",
              stroke: "#ff0304",
              "stroke-width": "2.53762",
              "stroke-linecap": "round",
              "stroke-linejoin": "round"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }