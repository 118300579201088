var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FlexBox" },
    [
      _c("Header"),
      !_vm.errorMessage
        ? [
            _c("h2", { staticClass: "TitleSection pt-4 mt-3" }, [
              _vm._v("Autorizaciones médicas Colmédica")
            ]),
            _vm.enableButton
              ? _c(
                  "article",
                  {
                    staticClass:
                      "BranchValidation position-relative w-100 h-100 mt-3 p-3"
                  },
                  [
                    _vm._l(_vm.blocks_1, function(block) {
                      return _c(
                        "p",
                        {
                          key: block,
                          staticClass:
                            "TextSection text-start color-colmedica-gray text-sizing-2 mt-0"
                        },
                        [_vm._v(_vm._s(block))]
                      )
                    }),
                    _c(
                      "p",
                      {
                        staticClass:
                          "TextSection text-start color-colmedica-gray text-sizing-2"
                      },
                      [_vm._v(_vm._s(_vm.continuesText))]
                    ),
                    _vm._l(_vm.blocks_2, function(block) {
                      return _c(
                        "p",
                        {
                          key: block,
                          staticClass:
                            "TextSection text-start color-colmedica-gray mb-2 pb-1"
                        },
                        [_vm._v(_vm._s(block))]
                      )
                    }),
                    _c("div", { staticClass: "botton-actions-footer" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-block mt-5",
                              attrs: { variant: "bluecolmedica", size: "lg" },
                              on: { click: _vm.continueAutoService }
                            },
                            [_vm._v("Radicar solicitud")]
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  2
                )
              : _c("section", [
                  _c(
                    "h5",
                    {
                      staticClass:
                        "text-center text-middle color-colmedica-gray mt-5"
                    },
                    [_vm._v("Cargando...")]
                  ),
                  _c("div", { staticClass: "flexMiddle" }, [_c("Spinner")], 1)
                ])
          ]
        : _c(
            "div",
            {
              staticClass: "text-middle color-colmedica-gray text-sizing-2 mt-4"
            },
            [
              _vm._v(
                "Se ha presentado un error al obtener la información de la compañía y la sucursal."
              )
            ]
          )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }