export default {
    appointments: [],
    availables: [],
    availablesDaysMonth: [],
    schedule: [],
    responseAppointment: {},
    loading: false,
    error: null,
    selectedDay:{},
    appointmentConfirm: {},
    appointment: {}
};