var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Oldest" }, [
    _c("div", { staticClass: "ContainerHeader pb-5" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between backButton"
        },
        [
          _c("img", {
            staticClass: "icn_backicn_back ml-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            },
            on: { click: _vm.goBack }
          }),
          _c("h3", { staticClass: "fontTitleHeader m-0" }, [
            _vm._v("Prioridad en la atención")
          ]),
          _c("img", {
            staticClass: "icn_backicn_back opacity-0 mr-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      )
    ]),
    _c("div", { staticClass: "ContainerData pt-4 px-2" }, [
      _c("h3", { staticClass: "yourTurnLabelO" }, [
        _vm._v(_vm._s(_vm.selectedArea ? _vm.selectedArea.label : ""))
      ]),
      _c("p", { staticClass: "text-center textWhiteGray" }, [
        _vm._v("¿Qué tipo de atención deseas?")
      ]),
      _c("ul", [
        _c("li", { staticClass: "liCard mx-auto pb-4" }, [
          _vm._m(0),
          _c("img", {
            staticClass: "allCards mx-auto d-block pt-3 pb-4",
            attrs: {
              src: require("@/assets/mobileFSFB/allCards.svg"),
              alt: "allCards"
            }
          }),
          _c(
            "button",
            {
              staticClass: "outlinedBthal mx-auto d-block mb-2",
              on: {
                click: function($event) {
                  return _vm.turnCall(true)
                }
              }
            },
            [_c("span", [_vm._v("Tomar turno preferencial")])]
          )
        ]),
        _c("li", { staticClass: "liCard mx-auto mt-3 pb-4" }, [
          _vm._m(1),
          _c("img", {
            staticClass: "desc mx-auto d-block pt-3 pb-4",
            attrs: { src: require("@/assets/mobileFSFB/desc.svg"), alt: "desc" }
          }),
          _c(
            "button",
            {
              staticClass:
                "outlinedBthal buttonBthal mx-auto d-block mb-2 text-center",
              on: {
                click: function($event) {
                  return _vm.turnCall(false)
                }
              }
            },
            [
              _c("span", { staticClass: "d-block" }, [
                _vm._v("Sin turno preferencial")
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "subTitle mx-auto text-center py-2" }, [
      _c("strong", [_vm._v("Con turno preferencial")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "subTitle mx-auto text-center py-2" }, [
      _c("strong", [_vm._v("Sin turno preferencial")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }