var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-logo" }, [
    _c("img", {
      directives: [{ name: "openlog", rawName: "v-openlog" }],
      staticClass: "stylelogo d-block",
      attrs: { src: _vm.Logo }
    }),
    _vm.message
      ? _c(
          "h5",
          {
            staticStyle: {
              position: "absolute",
              "margin-top": "30vh",
              "text-align": "center",
              color: "firebrick"
            }
          },
          [
            _vm._v(
              "No tienes habilitado el uso de la cámara y del video. Habilítalo y vuelve a intentarlo."
            )
          ]
        )
      : _vm._e(),
    _vm.messageTurn
      ? _c(
          "div",
          { staticStyle: { position: "absolute", "margin-top": "30vh" } },
          [
            _c("span", { staticStyle: { "text-align": "center" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  staticStyle: { width: "100%", display: "inline-block" },
                  on: { click: _vm.startTurn }
                },
                [_vm._v("Reintentar")]
              )
            ]),
            _c(
              "h5",
              {
                staticClass: "mt-3",
                staticStyle: { "text-align": "center", color: "firebrick" }
              },
              [
                _vm._v(
                  "No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico."
                )
              ]
            )
          ]
        )
      : _vm._e(),
    !_vm.message && !_vm.messageTurn
      ? _c("div", { staticClass: "position-relative d-inline text-center" }, [
          _c("img", {
            staticClass: "size_icon mx-auto",
            attrs: { src: _vm.Conected }
          }),
          _c("h5", [_vm._v("Estamos solicitando tu turno. Por favor espera.")])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }