export default {
  put({ commit, state }, { name, checked = true, message }) {
    commit("setState", {
      key: "list",
      value: [...state.list, { name, checked, message }]
    });
  },

  showModal({ commit }, value) {
    commit("setState", { key: "showModal", value });
  },

  clear({ commit }) {
    commit("setState", { key: "list", value: [] });
  }
};
