var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _c(
        "form",
        {
          staticClass: "contain px-0",
          staticStyle: { "margin-top": "35px" },
          attrs: { autocomplete: "off" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.sendData.apply(null, arguments)
            }
          }
        },
        [
          _vm._m(0),
          _vm.showModalError
            ? _c("div", { staticClass: "ModalContainer" }, [
                _c("div", { staticClass: "Overlay" }),
                _c("div", { staticClass: "Modal" }, [
                  _c(
                    "header",
                    {
                      staticClass:
                        "Modal_header py-3 p-1 d-flex justify-content-center align-items-center position-relative"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "centeredInfo d-flex justify-content-center align-items-center mt-2 mb-1"
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "23",
                                height: "23",
                                viewbox: "0 0 23 23",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M0.85 11.5C0.85 5.61832 5.61792 0.850249 11.4995 0.85C17.3791 0.856526 22.1438 5.62136 22.15 11.5009C22.1495 17.3823 17.3815 22.15 11.5 22.15C5.61817 22.15 0.85 17.3818 0.85 11.5Z",
                                  stroke: "white",
                                  "stroke-width": "1.7"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d:
                                    "M11.5013 6.71094C10.9558 6.71094 10.543 6.99787 10.543 7.46132V12.6705C10.543 13.1344 10.9557 13.4204 11.5013 13.4204C12.0335 13.4204 12.4596 13.1224 12.4596 12.6705V7.46132C12.4596 7.00887 12.0335 6.71094 11.5013 6.71094ZM11.5013 14.378C10.9772 14.378 10.5506 14.8079 10.5506 15.3366C10.5506 15.8644 10.9772 16.2943 11.5013 16.2943C12.0254 16.2943 12.4515 15.8644 12.4515 15.3366C12.4515 14.8079 12.0254 14.378 11.5013 14.378Z",
                                  fill: "#F4F4F4"
                                }
                              })
                            ]
                          ),
                          _c("h4", { staticClass: "m-0 ml-2 centeredInfoH4" }, [
                            _vm._v("Usuario no activo")
                          ])
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "button-without-styles",
                          on: {
                            click: function() {
                              return _vm.onChangeShowErrorModal(false)
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              staticClass: "position-absolute svgCloseIcon",
                              attrs: {
                                width: "20",
                                height: "21",
                                viewbox: "0 0 20 21",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M2.49023 17.7656L17.2565 2.99936",
                                  stroke: "#F4F4F4",
                                  "stroke-width": "4.55622",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M2.49023 3.23438L17.2565 18.0006",
                                  stroke: "#F4F4F4",
                                  "stroke-width": "4.55622",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._m(1),
                  _c("footer", { staticClass: "Modal_footer px-4 pb-3 mx-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "Modal_footer_button py-2 px-1 w-100",
                        on: {
                          click: function() {
                            return _vm.onChangeShowErrorModal(false)
                          }
                        }
                      },
                      [
                        _c("p", { staticClass: "my-1" }, [
                          _vm._v("Ingresar nuevos datos")
                        ])
                      ]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "afiliateText mt-1",
                        on: {
                          click: function($event) {
                            return _vm.goToView("Afiliate")
                          }
                        }
                      },
                      [_vm._v("Afiliate a Colmédica")]
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _c("p", { staticClass: "TextSection weight-500 text-start mb-2" }, [
            _vm._v("Selecciona el tipo de identificación")
          ]),
          _c(
            "div",
            { staticClass: "position-relative" },
            [
              _c("b-form-select", {
                staticClass:
                  "mb-4 number-input py-2 pl-3 position-relative selectStyles",
                attrs: {
                  options: _vm.options,
                  size: "sm",
                  required: "",
                  autocomplete: "off"
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              }),
              _c(
                "div",
                { staticClass: "position-absolute arrowBottomStyles" },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "16",
                        height: "9",
                        viewbox: "0 0 16 9",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M8.4215 8.06455L8.42137 8.06467C8.30826 8.17102 8.15872 8.23002 8.00348 8.22956M8.4215 8.06455L15.0341 0.998593C14.9785 0.939614 14.9117 0.89231 14.8377 0.859444C14.7636 0.826576 14.6837 0.808804 14.6027 0.807168C14.5217 0.805532 14.4411 0.820065 14.3658 0.849916C14.2911 0.879507 14.223 0.923561 14.1655 0.979541L8.00364 6.78267L1.84316 0.98104C1.78648 0.923711 1.71899 0.878178 1.64457 0.847096C1.56868 0.815398 1.48716 0.799385 1.40492 0.800018C1.32268 0.800652 1.24142 0.81792 1.16603 0.850783C1.09063 0.883646 1.02267 0.931425 0.966226 0.991243C0.909783 1.05106 0.866027 1.12168 0.837593 1.19885C0.809159 1.27603 0.796634 1.35815 0.800772 1.44029C0.804911 1.52243 0.825627 1.60289 0.861674 1.67681C0.89705 1.74936 0.946474 1.81414 1.00706 1.86742L7.58526 8.06349C7.58528 8.0635 7.58529 8.06351 7.58531 8.06353C7.69833 8.17034 7.84799 8.22975 8.00348 8.22956M8.4215 8.06455L15.0015 1.86682L8.4215 8.06455ZM8.00348 8.22956C8.00327 8.22956 8.00306 8.22956 8.00285 8.22956L8.00365 8.02956L8.0041 8.22956C8.00389 8.22956 8.00368 8.22956 8.00348 8.22956Z",
                          fill: "#737373",
                          stroke: "#737373",
                          "stroke-width": "0.4"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "TextSection weight-500 text-start mb-2",
              class: _vm.number_id && "activeLabel"
            },
            [
              _vm._v(
                "Ingresa el número de identificación del usuario que requiere la autorización"
              )
            ]
          ),
          _c("div", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_id,
                  expression: "number_id"
                }
              ],
              ref: "id_number",
              staticClass: "p-2 number-input",
              attrs: {
                autocomplete: "off",
                disabled: !_vm.isCapcha,
                placeholder: _vm._number,
                required: "",
                id: "cedula_number"
              },
              domProps: { value: _vm.number_id },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.number_id = $event.target.value
                }
              }
            }),
            !_vm.isCapcha
              ? _c(
                  "div",
                  { staticClass: "key-board" },
                  _vm._l(_vm.listNumbers, function(uniqueList) {
                    return _c(
                      "div",
                      { staticClass: "row-numbers" },
                      _vm._l(uniqueList, function(number) {
                        return _c(
                          "button",
                          {
                            key: number,
                            class:
                              number != "null"
                                ? "number-button"
                                : "null-button",
                            attrs: {
                              type: "button",
                              disabled: number === "null"
                            },
                            on: {
                              click: function($event) {
                                number === "null"
                                  ? null
                                  : number == "X"
                                  ? _vm.removeNumber()
                                  : _vm.addNumero(number)
                              }
                            }
                          },
                          [_vm._v(_vm._s(number))]
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
          _vm.number_id !== ""
            ? _c("div", { staticClass: "botton-actions-footer" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-center w-100 py-2 mx-1",
                        attrs: {
                          variant: "bluecolmedica",
                          size: "sm",
                          type: "submit",
                          disabled: !!_vm.charging
                        }
                      },
                      [
                        !!!_vm.charging && !_vm.charging2
                          ? _c("div", [
                              _c("span", { staticClass: "font-md" }, [
                                _vm._v("Continuar")
                              ])
                            ])
                          : _c(
                              "div",
                              { staticClass: "py-1" },
                              [_c("MiniSpinner")],
                              1
                            )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "contain color-colmedica-gray pb-4 mt-0" },
      [
        _c("h2", { staticClass: "TitleSection text-container-title" }, [
          _vm._v("Te damos la Bienvenida")
        ]),
        _c(
          "h2",
          { staticClass: "TitleSection text-center text-container-title" },
          [_vm._v("a Oficina Virtual")]
        ),
        _c(
          "p",
          {
            staticClass:
              "TextSection text-center pt-4 mt-2 mb-0 text-section-container",
            staticStyle: { "font-size": "17px" }
          },
          [_vm._v("Aquí podrás realizar con facilidad la")]
        ),
        _c(
          "p",
          {
            staticClass: "TextSection text-center m-0 text-section-container",
            staticStyle: { "font-size": "17px" }
          },
          [_vm._v("solicitud de Autorizaciones Médicas sin")]
        ),
        _c(
          "p",
          {
            staticClass: "TextSection text-center text-section-container",
            staticStyle: { "font-size": "17px" }
          },
          [_vm._v("desplazamientos.")]
        ),
        _c(
          "p",
          {
            staticClass: "TextSection text-center mb-3 text-section-container",
            staticStyle: { "font-size": "17px", color: "#212529" }
          },
          [
            _c("strong", [
              _vm._v(
                "Tenemos la opción de agendar un turno virtual para recibir atención personalizada"
              )
            ])
          ]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Modal_content px-4 text-center" }, [
      _c("p", { staticClass: "mainModalText" }, [
        _vm._v(
          "Asegurate de ingresar los datos correctos del usuario que requiere autorización"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }