import axios from "axios";
import _get from "lodash/get";
import { getEnvOnly } from "./Meet";

class Recorder {
  checkRecorder() {
    if (!_get(getEnvOnly(), "VUE_APP_RECORDER")) return;
    let routeOfCheck = `${getEnvOnly().VUE_APP_RECORDER}/status_call`;
    return axios
      .get(routeOfCheck)
      .then(res => res.data)
      .catch(err => console.error("Invalid recorder request Status_call", err)); // TODO: LF... when recorders will be installed, remove catch
  }
  setTurn(turn_code) {
    if (!_get(getEnvOnly(), "VUE_APP_RECORDER")) return;
    let routeOfCheck = `${getEnvOnly().VUE_APP_RECORDER}/load_call`;
    return axios
      .post(routeOfCheck, { turn_id: turn_code, client_data: {} })
      .then(res => res.data)
      .catch(err => console.error("Invalid recorder request Load Call", err)); // TODO: LF... when recorders will be installed, remove catch
  }
}

export let recorder = new Recorder();
