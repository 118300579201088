import LS from "./../../helpers/localStorage";
import kuid from "kuid";

export default {
  meeting: LS.getItem("meeting"),
  connecting: false,
  audioId: `audio_${kuid()}`,
  audioInputDevices: [],
  audioOutputDevices: [],
  videoInputDevices: [],
  attendees: [],
  loggerMeeting: null,
  processors: [],
  isBlur: false,
  isWithBg: false,
  deviceController: null,
  videosIds: [],
  myname: "",
  // current devices
  videoDevice: null,
  speakerDevice: null,
  audioDevice: null,
  // tootles
  audioEnabled: true,
  camEnabled: false,
  speakerEnabled: true,

  // Status info
  bitrateKbps: 0,
  stopSuggestionFromActiveCamera: false,
  packetsPerSecond: 0,
  nackCountPerSecond: 0,
  videoStopSuggestion: false,
  connectionIsGood: true,
  estimatedBandwidth: 0,
  requiredBandwidth: 0,

  m_allow_video: false,

  sharingEnabled: false,
  meetingSession: null,
  messages: [],
  isAgent: false,

  configCameras: {}
};
