/* eslint-disable no-prototype-builtins */
export default {
  setState: (state, pay) =>
    pay &&
    typeof pay === "object" &&
    !Array.isArray(pay) &&
    pay.hasOwnProperty("key") &&
    pay.hasOwnProperty("value") &&
    state.hasOwnProperty(pay.key)
      ? (state[pay.key] = pay.value)
      : null
};
