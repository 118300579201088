var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "OutOperationsFS" },
    [
      _c("Header"),
      _c("div", { staticClass: "OutOperationsFSPage" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-end align-items-center pb-4 px-2 pt-2"
          },
          [
            _c("Timer", {
              attrs: { timer: _vm.timer, showProgressBar: _vm.showProgressBar }
            })
          ],
          1
        ),
        _vm._m(0),
        _c("div", { staticClass: "px-2 mt-3" }, [
          _vm._m(1),
          _c("br"),
          _c(
            "p",
            { staticClass: "TextSection buttonText parr mt-0 text-left" },
            [_vm._v(_vm._s(_vm.showSchedule))]
          ),
          _c("br"),
          _vm._m(2)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 contain color-colmedica-gray mt-4" },
      [
        _c("h4", { staticClass: "Title" }, [
          _vm._v("Tu solicitud ha sido radicada exitosamente")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "TextSection buttonText parr mb-0 text-left" },
      [_c("strong", [_vm._v("Horario de atención de videollamada es:")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "buttonText parr text-left" }, [
      _c("strong", { staticClass: "color-colmedica-light" }, [
        _vm._v(
          "Te responderemos tu solicitud dentro de las próximas 12 horas hábiles. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }