var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Dates" }, [
    _c("div", { staticClass: "ContainerHeader pb-5 position-relative" }, [
      !_vm.fromQR
        ? _c("img", {
            staticClass: "icn_backicn_back ml-3 backButton",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            },
            on: { click: _vm.goBack }
          })
        : _c("div", { staticClass: "icn_backicn_back ml-3 backButton" }),
      _c("div", { staticClass: "ContainerUser ml-4 d-flex mt-3" }, [
        _c("img", {
          attrs: { src: require("@/assets/mobileFSFB/user.svg"), alt: "user" }
        }),
        _c("div", { staticClass: "ml-3", staticStyle: { height: "50px" } }, [
          _vm._m(0),
          _c("p", { staticClass: "text-Principal" }, [
            _vm._v(_vm._s(_vm.fullname))
          ])
        ]),
        _c("img", {
          staticClass: "bg_dates",
          attrs: {
            src: require("@/assets/mobileFSFB/bg_dates.png"),
            alt: "bg_dates"
          }
        })
      ])
    ]),
    _c(
      "div",
      {
        staticClass: "ContainerData pt-4 px-2",
        attrs: { id: "ContainerData" }
      },
      [
        _c(
          "button",
          {
            staticClass: "ArrowDownBold",
            attrs: { id: "arrowBtm" },
            on: { click: _vm.onScrollBtm }
          },
          [_c("ArrowDownBold")],
          1
        ),
        _c("h6", { staticClass: "subTitle" }, [_vm._v("Tus citas")]),
        _c(
          "div",
          { staticClass: "glider mt-4" },
          _vm._l(_vm.citas, function(cita) {
            return _c("div", { staticClass: "w-100 card-glider pb-3" }, [
              _c("div", { staticClass: "cardCarrousel mx-auto d-block pb-4" }, [
                _c(
                  "h6",
                  { staticClass: "subTitle mx-auto text-start py-2 pl-3" },
                  [
                    _c("strong", [
                      _vm._v("Trámite: " + _vm._s(cita.service.detail))
                    ])
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "doctorName pl-3",
                    staticStyle: { "font-family": "var(--font-secondary)" }
                  },
                  [
                    _vm._v(
                      "Dr(a). " +
                        _vm._s(cita.staff.name) +
                        " " +
                        _vm._s(cita.staff.last_name) +
                        " "
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "dateCard d-flex align-items-center p-3 mx-3"
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "25",
                          viewbox: "0 0 24 25",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M9.46056 5.62695V7.31721",
                            stroke: "#5AC3F0",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d: "M14.8297 5.62695V7.31721",
                            stroke: "#5AC3F0",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d:
                              "M7.92462 6.33936H16.3411C16.7418 6.33936 17.1261 6.50672 17.4094 6.80462C17.6927 7.10251 17.8519 7.50655 17.8519 7.92784V16.4499C17.8519 16.8724 17.6923 17.2775 17.4081 17.5763C17.124 17.875 16.7387 18.0428 16.3369 18.0428H7.92041C7.51973 18.0428 7.13546 17.8755 6.85214 17.5776C6.56882 17.2797 6.40965 16.8756 6.40965 16.4543V7.92784C6.40965 7.71887 6.44887 7.51195 6.52506 7.31893C6.60124 7.12592 6.7129 6.95061 6.85363 6.80305C6.99436 6.65549 7.16141 6.53857 7.34519 6.459C7.52897 6.37944 7.72587 6.33878 7.92462 6.33936V6.33936Z",
                            stroke: "#5AC3F0",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d: "M6.41446 9.32617H17.8777",
                            stroke: "#5AC3F0",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    ),
                    _c("p", { staticClass: "parragraphCapitalize mb-0" }, [
                      _vm._v(_vm._s(_vm.citaFecha(cita.date_appoinment)))
                    ]),
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "24",
                          height: "25",
                          viewbox: "0 0 24 25",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M10.9219 18.043C14.0735 18.043 16.6283 15.3567 16.6283 12.043C16.6283 8.72926 14.0735 6.04297 10.9219 6.04297C7.77031 6.04297 5.21545 8.72926 5.21545 12.043C5.21545 15.3567 7.77031 18.043 10.9219 18.043Z",
                            stroke: "#89D4F4",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d: "M10.9219 12.0431V8.79297",
                            stroke: "#89D4F4",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        }),
                        _c("path", {
                          attrs: {
                            d: "M10.9219 12.043H12.871",
                            stroke: "#89D4F4",
                            "stroke-width": "1.5",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    ),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        _vm._s(
                          _vm.citaHora(
                            cita.date_appoinment + " " + cita.hour_appoinment
                          )
                        )
                      )
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "dateCard d-flex align-items-center p-3 mx-3 mt-3"
                  },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v("№ de confirmación: " + _vm._s(cita.number_form))
                    ])
                  ]
                ),
                _c("div", { staticClass: "px-3" }, [
                  _c(
                    "button",
                    {
                      staticClass: "buttonForm d-block w-100 mt-4",
                      class: _vm.availableTime(cita) ? "" : "disableSchedule",
                      on: {
                        click: function($event) {
                          return _vm.onSelectedThisProcedure(cita)
                        }
                      }
                    },
                    [
                      _vm.loading && _vm.loading === "loadingSelect"
                        ? _c("span", [_c("MiniSpinner")], 1)
                        : _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.availableTime(cita)
                                  ? "Seleccionar"
                                  : "Cita fuera de horario"
                              )
                            )
                          ])
                    ]
                  )
                ])
              ])
            ])
          }),
          0
        ),
        _vm._m(1),
        _c("div", { staticClass: "pb-4" }, [
          _c("div", { staticClass: "px-3 pt-0 pb-5" }, [
            _c(
              "button",
              {
                staticClass: "buttonForm d-block w-100 mt-4 buttonOutlined",
                attrs: { id: "anotherServices" },
                on: {
                  click: function() {
                    return _vm.goToView({ view: "Procedure" })
                  }
                }
              },
              [_c("span", [_vm._v("Ver otros servicios")])]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mb-0" }, [
      _c("small", { staticClass: "text-Small" }, [_vm._v("Bienvenido")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-0 pb-0" }, [
      _c("div", { attrs: { id: "dots" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }