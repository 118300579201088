var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showMeet,
            expression: "!showMeet"
          }
        ],
        staticClass: "mx-auto max-width-box-form"
      },
      [
        _vm.preload
          ? _c(
              "div",
              { staticStyle: { width: "100%", "text-align": "center" } },
              [_c("Spinner"), _c("h3", [_vm._v("Conectando...")])],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.preload,
                expression: "!preload"
              }
            ]
          },
          [
            _c(
              "audio",
              { attrs: { id: "attended" }, on: { ended: _vm.soundEmmited } },
              [
                _c("source", {
                  attrs: {
                    src: require("@/assets/sounds/colmedica_audio.mp3"),
                    type: "audio/mpeg"
                  }
                })
              ]
            ),
            _vm._m(0),
            !_vm.showNotHourToTurn &&
            !_vm.showExpired &&
            !_vm.showExpiredLink &&
            !_vm.showCanceled
              ? _c("Header")
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "Page",
                style: !_vm.showVideosTikTok ? "bottom: 101px" : ""
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "TurnPage",
                    style:
                      (_vm.showVideosTikTok || _vm.shwoEnded) &&
                      "bottom: 0; position: absolute;"
                  },
                  [
                    !_vm.showVideosTikTok &&
                    !_vm.showNotHourToTurn &&
                    !_vm.showExpired &&
                    !_vm.shwoEnded &&
                    !_vm.showExpiredLink &&
                    !_vm.showCanceled
                      ? _c("div", { staticClass: "main_box pt-4" }, [
                          _c("h2", { staticClass: "Title" }, [
                            _vm._v("¡Turno asignado! " + _vm._s(_vm.username))
                          ]),
                          _c("div", { staticClass: "px-4" }, [
                            _c(
                              "div",
                              {
                                staticClass: "boxInfo col-12 mt-4 p-4",
                                staticStyle: { "z-index": "1" }
                              },
                              [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "text-center AssignmentText Subtitle"
                                  },
                                  [_vm._v("Tu turno asignado es:")]
                                ),
                                _c(
                                  "h3",
                                  {
                                    staticClass:
                                      "text-center font-weight-bold ImportantAsignment Subtitle"
                                  },
                                  [_vm._v(_vm._s(_vm.assingTurn))]
                                ),
                                _c(
                                  "h5",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.initMeet,
                                        expression: "!initMeet"
                                      }
                                    ],
                                    staticClass:
                                      "mt-1 text-center mb-1 Subtitle"
                                  },
                                  [
                                    _vm._v(
                                      "Tú tienes prioridad, espera en la línea para ser atendido."
                                    )
                                  ]
                                ),
                                _c(
                                  "h5",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.initMeet,
                                        expression: "initMeet"
                                      }
                                    ],
                                    staticClass:
                                      "mt-5 text-middle color-colmedica-gray"
                                  },
                                  [_vm._v("Un momento por favor...")]
                                )
                              ]
                            ),
                            _c("p", { staticClass: "mt-4 buttonText" }, [
                              _vm._v("¡Esperemos juntos!")
                            ]),
                            _c("p", { staticClass: "mb-0 buttonText" }, [
                              _vm._v(
                                "A continuacion hemos preparado una serie de contenidos para ti"
                              )
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm.showVideosTikTok
                      ? _c(
                          "div",
                          [
                            _c("TikTokVideos", {
                              attrs: {
                                assignTurn: _vm.assingTurn,
                                actualTurn: _vm.actualTurn,
                                turnsInFront: _vm.turnsInFrontBSIX,
                                goBack: function() {
                                  return (_vm.showVideosTikTok = false)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.shwoEnded
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center"
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "28px", opacity: "0" },
                                attrs: {
                                  src: require("@/assets/kiosco46/arrow.svg")
                                }
                              }),
                              _c("TimerFinished", {
                                attrs: {
                                  goHomeReal: _vm.goHomeReal,
                                  containerImageDocs: _vm.containerImageDocs,
                                  timer: _vm.timer,
                                  showProgressBar: _vm.showProgressBar
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mx-auto max-width-box-form" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "text-center d-block mx-auto mt-5",
                                  attrs: {
                                    width: "32",
                                    height: "32",
                                    viewbox: "0 0 32 32",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z",
                                      fill: "#3290E0"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z",
                                      fill: "#F4F4F4"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "h2",
                                { staticClass: "mt-3 TitleSection px-4 pb-3" },
                                [
                                  _vm._v(
                                    "La solicitud de la videollamada ha finalizado"
                                  )
                                ]
                              ),
                              _vm._m(1)
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm.showNotHourToTurn && !_vm.showExpired && !_vm.shwoEnded
                      ? _c(
                          "div",
                          { staticClass: "px-2" },
                          [
                            _c("Header", { staticClass: "mt-0" }),
                            _c(
                              "h2",
                              { staticClass: "mt-5 TitleSection px-4 pb-3" },
                              [_vm._v("Aún no es tiempo para tu videollamada")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-data mb-3 mt-5 py-4 mx-1 px-4 mx-3"
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "font-16 mb-0 text-start" },
                                  [
                                    _vm._v("Nombre:"),
                                    _c("strong", { staticClass: "ml-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.codeInfo.customer.firstName)
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "font-16 mb-0 text-start" },
                                  [
                                    _vm._v("Fecha y hora:"),
                                    _c("strong", { staticClass: "ml-1" }, [
                                      _vm._v(_vm._s(_vm.dateWithoutHour))
                                    ]),
                                    _c("strong", { staticClass: "ml-2" }, [
                                      _vm._v(_vm._s(_vm.hour))
                                    ])
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "font-16 mb-0 text-start" },
                                  [
                                    _vm._v("Servicio:"),
                                    _c("strong", { staticClass: "ml-1" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.codeInfo.reason.servicioSelected
                                        )
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "p",
                                  { staticClass: "font-16 mb-0 text-start" },
                                  [
                                    _vm._v("Modalidad de atención:"),
                                    _c("strong", { staticClass: "ml-1" }, [
                                      _vm._v(
                                        _vm._s(_vm.codeInfo.reason.modalidad)
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-16 mt-4 text-center text-center"
                                  },
                                  [
                                    _vm._v("Tu videollamada inicia en:"),
                                    _vm.duration.asHours() < 24
                                      ? _c(
                                          "strong",
                                          { staticClass: "ml-1 d-block" },
                                          [
                                            parseInt(_vm.duration.asHours()) > 0
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseInt(
                                                        _vm.duration.asHours()
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        parseInt(
                                                          _vm.duration.asHours()
                                                        ) === 1
                                                          ? "hora"
                                                          : "horas"
                                                      )
                                                  )
                                                ])
                                              : _vm._e(),
                                            parseInt(_vm.duration.asHours()) < 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      parseInt(
                                                        _vm.duration.asMinutes()
                                                      )
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        parseInt(
                                                          _vm.duration.asMinutes()
                                                        ) === 1
                                                          ? "minuto"
                                                          : "minutos"
                                                      )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _c(
                                          "strong",
                                          { staticClass: "ml-1 d-block" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                parseInt(_vm.duration.asDays())
                                              ) +
                                                " " +
                                                _vm._s(
                                                  parseInt(
                                                    _vm.duration.asDays()
                                                  ) === 1
                                                    ? "día"
                                                    : "días"
                                                )
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "p",
                              { staticClass: "mt-4 text-center buttonText" },
                              [_vm._v("¡Esperemos juntos!")]
                            ),
                            _c(
                              "p",
                              { staticClass: "mb-0 text-center buttonText" },
                              [
                                _vm._v(
                                  "A continuación hemos preparado una serie de contenidos para ti"
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.showVideosTikTok && !_vm.showExpired && !_vm.shwoEnded
                      ? _c(
                          "div",
                          { staticClass: "main_bottom mx-3" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mb-0 text-center AuthsBtn mt-4",
                                staticStyle: { width: "fit-content" },
                                attrs: {
                                  variant: "bluecolmedica",
                                  block: "",
                                  size: "lg",
                                  type: "button"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.showVideosTikTok = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "p",
                                  { staticClass: "buttonText AuthMeds mb-0" },
                                  [_vm._v("Muéstrame los videos")]
                                )
                              ]
                            ),
                            !_vm.showVideosTikTok && !_vm.showNotHourToTurn
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.cancel-modal",
                                        modifiers: { "cancel-modal": true }
                                      }
                                    ],
                                    staticClass:
                                      "text-center AuthsBtn AuthsBtnDanger mt-4 mb-2",
                                    attrs: {
                                      block: "",
                                      size: "lg",
                                      type: "button"
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "color-danger buttonText m-0"
                                      },
                                      [_vm._v("Cancelar Solicitud")]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "msm-modal",
                  footerClass: "p-2 border-top-0",
                  headerClass: "p-2 border-bottom-0",
                  centered: "",
                  "hide-footer": ""
                }
              },
              [
                _c("div", { staticClass: "d-block text-center modal_inside" }, [
                  _c("p", { staticClass: "Title" }, [_vm._v("¡Listo!")]),
                  _c("p", { staticClass: "my-4" }, [
                    _vm._v(
                      "Te llegará un mensaje de texto en " +
                        _vm._s(_vm.waiting_time) +
                        " aproximadamente"
                    )
                  ]),
                  _c("p", { staticClass: "my-4" }, [
                    _vm._v("Ya puedes cerrar esta ventana")
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "div_center_button" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "div_center_button",
                        attrs: { variant: "outline-dark" },
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.hide("msm-modal")
                          }
                        }
                      },
                      [_vm._v("Ok")]
                    )
                  ],
                  1
                )
              ]
            ),
            !_vm.showNotHourToTurn &&
            !_vm.showExpired &&
            !_vm.shwoEnded &&
            !_vm.showExpiredLink &&
            !_vm.showCanceled &&
            !_vm.initMeet
              ? _c(
                  "div",
                  [
                    !_vm.initMeet
                      ? _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "small-modal-dk",
                              centered: "",
                              "hide-footer": "",
                              "hide-header": "",
                              "no-close-on-backdrop": ""
                            },
                            model: {
                              value: _vm.showUltimateModal2,
                              callback: function($$v) {
                                _vm.showUltimateModal2 = $$v
                              },
                              expression: "showUltimateModal2"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "p-5" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "timer-container",
                                    on: { click: _vm.onCancelTurn }
                                  },
                                  [
                                    _c("TimerComponent", {
                                      attrs: {
                                        timer: _vm.timerAun,
                                        showProgressBar: _vm.showProgressBarAun
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("h3", { staticClass: "Title text-center" }, [
                                  _c(
                                    "strong",
                                    { staticClass: "normalFont strong" },
                                    [_vm._v("¿Aún estás ahí?")]
                                  )
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "text-center AuthsBtn mb-4 mt-5",
                                    staticStyle: { width: "fit-content" },
                                    attrs: {
                                      variant: "bluecolmedica",
                                      block: "",
                                      size: "lg",
                                      type: "button"
                                    },
                                    on: { click: _vm.restartTimerWaiting }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "buttonText AuthMeds mb-0"
                                      },
                                      [_vm._v("Sí, continuar")]
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "pt-3" },
                                  [
                                    _c("BottomTimer", {
                                      attrs: { doThis: _vm.onCancelTurn }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "cancel-modal",
                  footerClass: "p-2 border-top-0",
                  headerClass: "p-2 border-bottom-0",
                  centered: "",
                  "hide-footer": "",
                  "hide-header": ""
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "p-2 position-relative w-auto h-auto" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-block text-center modal_inside" },
                      [
                        _c("p", { staticClass: "Title" }, [
                          _vm._v(
                            "Estás a punto de finalizar la solicitud de la videollamada"
                          )
                        ]),
                        _c("p", { staticClass: "my-4 buttonText" }, [
                          _vm._v(
                            "¿Deseas continuar esperando al asesor Colmédica?"
                          )
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "position-relative" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "text-center AuthsBtn my-4",
                            staticStyle: {
                              "border-radius": "40px",
                              width: "562px"
                            },
                            attrs: {
                              variant: "outline-danger",
                              block: "",
                              size: "lg",
                              type: "button"
                            },
                            on: {
                              click: function($event) {
                                return _vm.cancelTurnHandler()
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "buttonText AuthMeds mb-0" },
                              [_vm._v("Cancelar la solicitud")]
                            )
                          ]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "text-center AuthsBtn my-4",
                            staticStyle: { width: "562px" },
                            attrs: {
                              variant: "bluecolmedica",
                              block: "",
                              size: "lg",
                              type: "button"
                            },
                            on: {
                              click: function($event) {
                                return _vm.$bvModal.hide("cancel-modal")
                              }
                            }
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "buttonText AuthMeds mb-0" },
                              [_vm._v("Continuar esperando")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                !_vm.showUltimateModal2
                  ? _c(
                      "div",
                      { staticClass: "p-3" },
                      [
                        _c("BottomTimer", {
                          attrs: { doThis: _vm.onShowAunSiguesAlli }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  id: "new-turn-modal",
                  footerClass: "p-2 pt-1 border-top-0",
                  headerClass: "p-2 border-bottom-0",
                  centered: "",
                  "hide-footer": "",
                  "hide-header": "",
                  "no-close-on-backdrop": ""
                }
              },
              [
                _c("div", { staticClass: "d-block text-center modal_inside" }, [
                  _c("p", { staticClass: "mb-4 mt-2 Title" }, [
                    _vm._v("Te hemos asignado el turno")
                  ]),
                  _c(
                    "h3",
                    {
                      staticClass:
                        "text-middle color-colmedica-light font-weight-bold Subtitle"
                    },
                    [_vm._v(_vm._s(_vm.assingTurn))]
                  ),
                  _c("p"),
                  _c(
                    "div",
                    { staticClass: "position-relative" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-center AuthsBtn my-4",
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            variant: "bluecolmedica",
                            block: "",
                            size: "lg",
                            type: "button"
                          },
                          on: { click: _vm.continueTurn }
                        },
                        [
                          _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                            _vm._v("Continuar")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]),
                !_vm.showUltimateModal2
                  ? _c(
                      "div",
                      { staticClass: "p-3" },
                      [
                        _c("BottomTimer", {
                          attrs: { doThis: _vm.onShowAunSiguesAlli }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "b-modal",
              {
                attrs: {
                  footerClass: "p-2 border-top-0",
                  headerClass: "p-2 border-bottom-0",
                  centered: "",
                  "hide-footer": "",
                  "hide-header": "",
                  "no-close-on-backdrop": "",
                  id: "confirm-modal"
                }
              },
              [
                _c("div", { staticClass: "d-block text-center modal_inside" }, [
                  _c("p", { staticClass: "my-4 Title" }, [
                    _vm._v("Tu asesor esta listo para atenderte")
                  ]),
                  _c(
                    "div",
                    { staticClass: "position-relative" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          staticStyle: { height: "72px" },
                          attrs: { variant: "bluecolmedica" },
                          on: { click: _vm.resolveconfirm }
                        },
                        [_vm._v("Conectar con asesor")]
                      )
                    ],
                    1
                  )
                ])
              ]
            )
          ],
          1
        )
      ]
    ),
    _vm.initMeet
      ? _c(
          "div",
          { staticClass: "ContainerMeetingOf" },
          [
            _c("Meeting", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showMeet,
                  expression: "showMeet"
                }
              ],
              attrs: {
                meetType: "customer",
                meetId: _vm.meetingId,
                username: _vm.username,
                isComponent: true
              },
              on: { endMeet: _vm.endMeet, attendees: _vm.attendees }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("audio", { attrs: { id: "advice" } }, [
      _c("source", {
        attrs: {
          src: require("@/assets/sounds/turno_audio.mp3"),
          type: "audio/mpeg"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-data mb-3 mt-5 py-4 mx-1 px-4 mx-4" },
      [
        _c("p", { staticClass: "font-16 mb-5 text-center px-4" }, [
          _vm._v(
            "Gracias por utilizar nuestros servicios. Te esperamos en una próxima ocasión."
          )
        ]),
        _c("p", { staticClass: "font-18 mb-5 text-center px-4" }, [
          _vm._v(
            "Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }