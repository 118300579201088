var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 header", attrs: { id: "headerCalendarTitle" } },
    [
      _c("div", { staticStyle: { margin: "0px auto" } }, [
        _c("img", {
          directives: [{ name: "openlog", rawName: "v-openlog" }],
          staticClass: "client_logo",
          attrs: { src: _vm.Logo }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }