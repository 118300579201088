var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Progress", staticStyle: { position: "fixed" } }, [
      _c("div", { staticClass: "container p-5" }, [
        _c(
          "div",
          {
            staticClass: "progress3",
            staticStyle: { cursor: "pointer" },
            attrs: { "data-percentage": _vm.percent },
            on: { click: _vm.toHome }
          },
          [
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "progress-value" }, [
              _c("div", { staticClass: "mt--1" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      staticClass: "colorHouse",
                      attrs: {
                        width: "25",
                        height: "23",
                        viewbox: "0 0 25 23",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M23.931 11.0673L13.1887 0.305881C13.1114 0.228188 13.0195 0.16655 12.9184 0.124494C12.8172 0.0824384 12.7088 0.060791 12.5993 0.060791C12.4898 0.060791 12.3814 0.0824384 12.2803 0.124494C12.1791 0.16655 12.0873 0.228188 12.0099 0.305881L1.26764 11.0673C0.954686 11.381 0.777344 11.8072 0.777344 12.2516C0.777344 13.1746 1.52583 13.9249 2.44645 13.9249H3.57831V21.6038C3.57831 22.0666 3.95125 22.4405 4.41287 22.4405H10.9302V16.5839H13.8511V22.4405H20.7858C21.2474 22.4405 21.6203 22.0666 21.6203 21.6038V13.9249H22.7522C23.1955 13.9249 23.6206 13.7498 23.9336 13.4334C24.583 12.7798 24.583 11.7209 23.931 11.0673Z",
                          fill: "#F2F4F8"
                        }
                      })
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-left" }, [
      _c("span", { staticClass: "progress-bar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-right" }, [
      _c("span", { staticClass: "progress-bar" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }