var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Login" }, [
    _c("section", { staticClass: "App text-center position-relative" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between backButton position-relative",
          staticStyle: { "z-index": "3" },
          on: { click: _vm.goBack }
        },
        [
          _c("img", {
            staticClass: "icn_backicn_back ml-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      ),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "w-100 d-flex justify-content-center columnClass" },
        [
          _c("img", {
            staticClass: "logoAuthLogin mx-auto",
            attrs: {
              src: require("@/assets/mobileFSFB/logoFSFB.png"),
              alt: "logoFSFB"
            }
          }),
          _c("h3", { staticClass: "yourTurnLabel" }, [
            _vm._v(_vm._s(_vm.selectedArea ? _vm.selectedArea.label : ""))
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.modeWelcome,
              expression: "modeWelcome"
            }
          ],
          staticClass: "boxText"
        },
        [
          _c("div", { staticClass: "containerText containerTextFirst" }, [
            _c("h2", { staticClass: "containerText-title mb-0" }, [
              _vm._v("Bienvenido a")
            ]),
            _vm.areaCustom
              ? _c("p", { staticClass: "containerText-desc m-0" }, [
                  _vm._v(_vm._s(_vm.areaCustom.label))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "containerText" }, [
            _vm.areaCustom && _vm.areaCustom.labelEnglish
              ? _c(
                  "p",
                  {
                    staticClass:
                      "containerText-desc containerText-lg mt-0 text-start"
                  },
                  [
                    _vm._v("Welcome to"),
                    _vm.areaCustom
                      ? _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(_vm.areaCustom.labelEnglish))
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._m(1)
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.modeWelcome,
              expression: "!modeWelcome"
            }
          ],
          staticClass: "text-start boxText boxText2 mx-auto"
        },
        [
          _vm._m(2),
          _c(
            "form",
            {
              staticClass: "boxWhite mx-auto px-4 pt-2 pb-3 d-block w-100",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onContinue.apply(null, arguments)
                }
              }
            },
            [
              _c("p", { staticClass: "fonterBlue mb-1 pt-1" }, [
                _vm._v("Tipo de documento")
              ]),
              _c(
                "div",
                { staticClass: "form-select-container mb-3 position-relative" },
                [
                  _c("b-form-select", {
                    staticClass: "Input-text p-2 pr-5 position-relative",
                    attrs: {
                      options: _vm.optionsMapped,
                      size: "sm",
                      autocomplete: "off",
                      required: ""
                    },
                    model: {
                      value: _vm.models.TipoIdentificacion,
                      callback: function($$v) {
                        _vm.$set(_vm.models, "TipoIdentificacion", $$v)
                      },
                      expression: "models.TipoIdentificacion"
                    }
                  }),
                  _c(
                    "svg",
                    {
                      staticClass: "mr-2 z-negative-2",
                      attrs: {
                        width: "21",
                        height: "21",
                        viewbox: "0 0 21 21",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M10.6375 4.23521C11.9577 4.23521 13.2482 4.62851 14.3459 5.36536C15.4436 6.10222 16.2992 7.14952 16.8044 8.37486C17.3096 9.6002 17.4418 10.9485 17.1842 12.2493C16.9267 13.5501 16.291 14.745 15.3574 15.6829C14.4239 16.6207 13.2346 17.2594 11.9397 17.5181C10.6449 17.7769 9.3028 17.6441 8.0831 17.1365C6.86341 16.629 5.82092 15.7694 5.08746 14.6667C4.354 13.5639 3.96252 12.2674 3.96252 10.9411C3.96252 9.16259 4.66578 7.45692 5.91758 6.19932C7.16938 4.94173 8.86719 4.23521 10.6375 4.23521Z",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M10.6376 12.2815L12.4209 10.49",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M8.85413 10.49L10.6374 12.2815",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("p", { staticClass: "fonterBlue mb-1 pt-1" }, [
                _vm._v("Número de documento")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.models.NumeroIdentificacion,
                    expression: "models.NumeroIdentificacion"
                  }
                ],
                ref: "idNumberInput",
                staticClass: "Input-text w-100 p-2 inputDoc",
                attrs: { placeholder: "Ej: 105456783", required: "" },
                domProps: { value: _vm.models.NumeroIdentificacion },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.models,
                      "NumeroIdentificacion",
                      $event.target.value
                    )
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "buttonForm d-block w-100 mt-4",
                  attrs: {
                    type: "submit",
                    disabled: !_vm.models.NumeroIdentificacion
                  }
                },
                [
                  !_vm.charging
                    ? _c("span", [_vm._v("Continuar")])
                    : _c("span", [_c("MiniSpinner")], 1)
                ]
              )
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "containerImg" }, [
      _c("img", {
        staticClass: "h-100 containerImg-img w-100",
        attrs: {
          src: require("@/assets/mobileFSFB/helpingBoy.png"),
          alt: "helpingBoy"
        }
      }),
      _c("div", { staticClass: "containerImg-tape" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center containerText-reserved" }, [
      _c(
        "strong",
        { staticClass: "text-derechos d-block pt-4 mb-0 m-0 mt-5" },
        [_vm._v("Todos los derechos reservados")]
      ),
      _c("strong", { staticClass: "text-derechos d-block mt-0 pt-1 m-0" }, [
        _vm._v("© Fundación Santa Fe de Bogotá 2022")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxContent pb-3" }, [
      _c("h2", { staticClass: "titleFSFBM" }, [_vm._v("Ingreso de datos")]),
      _c("p", { staticClass: "desc2" }, [
        _vm._v("Digita los datos de la persona que"),
        _c("span", { staticClass: "ml-1" }, [
          _vm._v("va a acceder al servicio")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }