var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "RootOlderAdult" },
    [
      _c("Header"),
      _vm.view === "Home" ? _c("Home") : _vm._e(),
      _vm.view === "Autenticate" ? _c("Autenticate") : _vm._e(),
      _vm.view === "Atention" ? _c("Atention") : _vm._e(),
      _vm.view === "Popup" ? _c("Popup") : _vm._e(),
      _vm.view === "UploadFiles" ? _c("UploadFiles") : _vm._e(),
      _vm.view === "ConfirmUpload" ? _c("ConfirmUpload") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }