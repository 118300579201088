var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.withOutHeader ? _c("Header") : _vm._e(),
      _vm.branchInfo && _vm.branchInfo.branch_name
        ? _c(
            "div",
            {
              directives: [{ name: "openlog", rawName: "v-openlog" }],
              staticClass: "col-12 contain color-colmedica-gray mt-4"
            },
            [
              _c(
                "p",
                {
                  staticStyle: { "font-size": "1.2rem", "text-align": "center" }
                },
                [
                  _c("b", { staticClass: "ml-2 mr-2" }, [
                    _vm._v(_vm._s(_vm.branchInfo.branch_name))
                  ])
                ]
              ),
              _c(
                "p",
                {
                  staticStyle: { "font-size": "1.2rem", "text-align": "center" }
                },
                [
                  _vm._v(
                    "En este momento no podemos atenderte, contáctanos en el horario de atención de videollamada:"
                  )
                ]
              ),
              _c("p", {
                staticStyle: { "font-size": "1.2rem", "text-align": "center" }
              }),
              _vm.branchInfo.activeBranchMessage
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "1.2rem",
                        "text-align": "center"
                      }
                    },
                    [_vm._v(_vm._s(_vm.branchInfo.activeBranchMessage))]
                  )
                : _vm._e(),
              !_vm.withOutClose
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "2rem",
                        "text-align": "center",
                        "margin-top": "30vh"
                      },
                      on: {
                        click: function() {
                          return _vm.goToView("Home")
                        }
                      }
                    },
                    [_vm._v("Ya puedes cerrar esta ventana.")]
                  )
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              directives: [{ name: "openlog", rawName: "v-openlog" }],
              staticClass: "col-12 contain color-colmedica-gray mt-4"
            },
            [
              _c(
                "p",
                {
                  staticStyle: { "font-size": "1.2rem", "text-align": "center" }
                },
                [_vm._v("La sede solicitada es inválida.")]
              ),
              !_vm.withOutClose
                ? _c(
                    "p",
                    {
                      staticStyle: {
                        "font-size": "2rem",
                        "text-align": "center",
                        "margin-top": "30vh"
                      },
                      on: {
                        click: function() {
                          return _vm.goToView("Home")
                        }
                      }
                    },
                    [_vm._v("Ya puedes cerrar esta ventana.")]
                  )
                : _vm._e()
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }