const utf8_to_b64 = str => encodeUrlComponent(window.btoa(str));

const encodeUrlComponent = str => unescape(encodeURIComponent(str));

const decodeUrlComponent = str => decodeURIComponent(unescape(str));

const b64_to_utf8 = str => window.atob(decodeUrlComponent(str));

const try_json = str => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return str;
  }
};

export {
  utf8_to_b64,
  b64_to_utf8,
  try_json,
  encodeUrlComponent,
  decodeUrlComponent
};
