var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "TikTokVideos" },
    [
      _c("Header", { staticClass: "absoluteHeader" }),
      _c("div", { staticClass: "botton-actions ml-4 pl-2" }, [
        _c("div", { staticClass: "d-flex justify-content-start" }, [
          _c(
            "button",
            {
              staticClass: "button-without-styles",
              attrs: { type: "button" },
              on: { click: _vm.goBack }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "19",
                    viewbox: "0 0 20 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                      stroke: "#0058A2",
                      "stroke-width": "3.12307",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        ])
      ]),
      _vm._l(_vm.videos, function(video, index) {
        return _c("video", {
          key: index,
          staticClass: "VideoColmedica",
          attrs: { src: video, loop: "", controls: "" }
        })
      }),
      _vm.assignTurn
        ? _c(
            "div",
            {
              staticClass: "boxAttendee",
              on: { drop: _vm.handlerDrop, dragover: _vm.handlerDragover }
            },
            [
              _c("h4", { staticClass: "text-center AssignmentTextAttendee" }, [
                _vm._v("Turno asignado:")
              ]),
              _c(
                "h3",
                {
                  staticClass: "text-center font-weight-bold ImportantAsignment"
                },
                [_vm._v(_vm._s(_vm.assignTurn))]
              ),
              _c(
                "h5",
                { staticClass: "mt-1 text-center mb-1 ActualAttendee" },
                [_vm._v("Posición en la fila:")]
              ),
              _c("h5", { staticClass: "text-middle font-larger" }, [
                _vm._v(
                  _vm._s(
                    typeof _vm.turnsInFront === "number"
                      ? _vm.turnsInFront + 1
                      : "1"
                  )
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.showSignal
        ? _c("div", { staticClass: "containerDescTikTok" }, [
            _c("img", {
              staticClass: "containerDescTikTok-img",
              attrs: { src: require("@/assets/handIcon.svg") }
            }),
            _c("p", { staticClass: "containerDescTikTok-text px-4 pt-3" }, [
              _vm._v("Desliza hacia arriba para ver más")
            ])
          ])
        : _vm._e(),
      _vm.assignTurn
        ? _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal.cancel-modal",
                  modifiers: { "cancel-modal": true }
                }
              ],
              staticClass: "w-100 py-2 mx-1 text-center buttonTurnero2 mt-3",
              attrs: { variant: "bluecolmedica", size: "sm", type: "button" }
            },
            [_c("span", { staticClass: "font-md" }, [_vm._v("Cancelar turno")])]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }