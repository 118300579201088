var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Atention position-relative" }, [
    _c("div", { staticClass: "ImageContainerTap" }),
    _c("div", { staticClass: "mx-5" }, [
      _c("div", { staticClass: "position-relative Atention__Container" }, [
        _c("div", { staticClass: "d-flex align-items-center py-3" }, [
          _c(
            "a",
            { staticClass: "active-link", on: { click: _vm.onGoToHome } },
            [_vm._v("Home")]
          ),
          _vm._m(0),
          _vm._m(1)
        ]),
        _vm._m(2),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center align-items-stretch ContainerCards"
          },
          [
            _c(
              "div",
              { staticClass: "position-relative max-button w-100 mr-3" },
              [
                _c("div", {
                  staticClass: "tap",
                  attrs: { id: "authorizations-container" }
                }),
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles Card p-4",
                    attrs: { id: "authorizations" },
                    on: { click: _vm.goUpload }
                  },
                  [
                    _c("h4", { staticClass: "Card__Text mt-3" }, [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "70",
                            height: "70",
                            viewbox: "0 0 70 70",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M28.1138 5L28.1138 51",
                              stroke: "white",
                              "stroke-width": "10",
                              "stroke-linecap": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M5 28H51",
                              stroke: "white",
                              "stroke-width": "10",
                              "stroke-linecap": "round"
                            }
                          })
                        ]
                      ),
                      _c("p", { staticClass: "m-0 mt-3" }, [
                        _vm._v("Adjuntar")
                      ]),
                      _c("p", { staticClass: "m-0" }, [_vm._v("Documentos")])
                    ])
                  ]
                )
              ]
            ),
            _c(
              "div",
              { staticClass: "position-relative max-button w-100 mr-3" },
              [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isModeTutorial,
                      expression: "isModeTutorial"
                    }
                  ],
                  staticClass: "tap",
                  attrs: { id: "service_client-container" }
                }),
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles Card p-4",
                    attrs: { id: "service_client" },
                    on: {
                      click: function($event) {
                        return _vm.getTurnWaiting(
                          _vm.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH == 16
                            ? 779
                            : 22,
                          1
                        )
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "IconAuths",
                      attrs: {
                        src: require("@/assets/older_adults/IconAuths.svg"),
                        alt: "IconAuths"
                      }
                    }),
                    _c("h4", { staticClass: "Card__Text mt-3" }, [
                      _vm._v("Prefiero hacerlo con el asesor")
                    ])
                  ]
                )
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mx-2" }, [
      _c("img", {
        staticClass: "arrowRight",
        attrs: {
          src: require("@/assets/older_adults/arrowRight.svg"),
          alt: "arrowRight"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "active-link" }, [
      _c("strong", { staticClass: "TextAtention" }, [
        _vm._v("atención preferencial adulto mayor")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "Atention__MainText" }, [
      _c("span", { staticClass: "red" }, [_c("b", [_vm._v("¡")])]),
      _c("span", { staticClass: "blue" }, [
        _c("b", [
          _vm._v("Pensando en agilizar tu atención, te sugerimos adjuntar")
        ])
      ]),
      _c("br"),
      _c("span", { staticClass: "blue" }, [
        _c("b", [_vm._v("los documentos antes de iniciar tu videollamada")])
      ]),
      _c("span", { staticClass: "red" }, [_c("b", [_vm._v("!")])]),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }