var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Home p-5" }, [
    _c("div", { staticClass: "d-flex align-items-start py-5 Home__content" }, [
      _c("article", { staticClass: "Home__article" }, [
        _c("h2", { staticClass: "text-center TitleSection p-3" }, [
          _vm._v("¡Información!")
        ]),
        _vm._m(0),
        _c(
          "div",
          { staticClass: "d-flex items-center containerBtns justify-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "mt-2 ButtonContinue",
                attrs: { variant: "bluecolmedica" },
                on: { click: _vm.onContinue }
              },
              [
                _c("p", { staticClass: "mb-0 ButtonContinue__text px-4" }, [
                  _vm._v("Entendido")
                ])
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { "max-width": "750px", margin: "0 auto", width: "100%" }
      },
      [
        _c("p", { staticClass: "mb-4 Home__TextMain text-center" }, [
          _vm._v(
            "En este canal solo se autorizan servicios médicos cubiertos por tu plan de medicina prepagada, si tu solicitud es de medicamentos ambulatorios te invitamos a conocer el nuevo proceso a través de tu EPS"
          )
        ]),
        _c("p", { staticClass: "Home__TextMain mb-4 text-center" }),
        _c(
          "a",
          {
            staticClass: "d-block text-center mb-5 mt-4 mb-0 mx-3 color-dark",
            staticStyle: {
              "text-decoration": "underline",
              color: "#226DB1",
              "font-size": "1.5rem"
            },
            attrs: {
              target: "_blank",
              href: "https://www.aliansalud.com.co/Paginas/CVM.aspx"
            }
          },
          [_vm._v("Consulta aquí")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }