var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-11 header" }, [
        _c("div", { staticStyle: { margin: "0px auto", width: "50%" } }, [
          _c("img", {
            directives: [{ name: "openlog", rawName: "v-openlog" }],
            staticClass: "client_logo",
            attrs: { src: _vm.Logo }
          })
        ])
      ])
    ]),
    _c("div", { staticClass: "main_box" }, [
      _c("h3", { staticClass: "text-center" }, [
        _vm._v("Oficina Virtual " + _vm._s(_vm.version))
      ]),
      _c("h3", { staticClass: "text-center" }, [_vm._v("En construcción")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }