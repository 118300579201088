<template lang="pug">
main.MainView
  transition(name="fade" mode="out-in")
    router-view
  ModalLogs
  #dialogdk.dialogdk
    section#dialogdkbox
</template>
<script>
import { mapActions, mapState } from "vuex";
import ModalLogs from "./views/components/ModalLogs.vue";
import EmptyLogo from "@/assets/mobileFSFB/EmptyLogo.png";

export default {
  name: "App",

  components: {
    ModalLogs
  },

  async created() {
    this.startup();
    document.addEventListener("visibilitychange", () =>
      this.setViewState(document.visibilityState === "visible")
    );
  },

  computed: {
    ...mapState(["sessionMaster", "env"])
  },

  watch: {
    env(val) {
      if (val) {
        const $faviconPage = document.getElementById("faviconPage");

        if (val.VUE_APP_COMPANY === "fsfb") {
          $faviconPage.href = EmptyLogo;
        }
      }
    }
  },

  methods: {
    ...mapActions(["setViewState", "startup"])
  }
};
</script>
<style lang="scss">
:root {
  // COLORS
  --color-black-cm: #000000;
  --color-white: #f4f4f4;
  --color-primary: #0058a2;
  --color-secondary: #009fe3;
  --color-gray: #768191;
  --color-dark: #081e3d;
  --color-bad: #e2574c;
  --color-success: #4eb93c;
  --color-disabled-text: #b8b8b8;

  // FONTS
  --font-large: 34px;
  --font-md: 18px;
  --font-sm: 16px;
}

$gutterSm: 0.4rem;
$gutterMd: 0.8rem;
$gutterLg: 1rem;
$gutterXl: 2.4rem;
$gutterXx: 7.2rem;
$colorPrimary400: #c4e3fa;
$colorPrimary600: #94cffd;
$colorPrimary800: #7bc6ff;
$fontFamily: "Dosis", sans-serif;
$fontSizeSm: 1.2rem;
$fontSizeMd: 1.6rem;
$fontSizeLg: 2.1rem;
$fontSizeXl: 2.8rem;
$fontSizeXx: 3.6rem;
$lineHeightSm: 1.1;
$lineHeightMd: 1.8;
$transitionDuration: 300ms;
$transitionTF: cubic-bezier(0.645, 0.045, 0.355, 1);

$inputPaddingV: $gutterMd;
$inputPaddingH: $gutterLg;
$inputFontSize: $fontSizeLg;
$inputLineHeight: $lineHeightMd;
$labelScaleFactor: 0.8;
$labelDefaultPosY: 50%;
$labelTransformedPosY: calc(50% - (0.8rem * 0.8) - (2.1rem * 1.8));
$inputTransitionDuration: $transitionDuration;
$inputTransitionTF: $transitionTF;

$color-primary: #0058a2;
$color-secondary: #009fe3;
$color-gray: #768191;
$color-white: #f4f4f4;

$font-large: 26px;
$font-md: 20.3521px;
$font-sm: 16px;

@font-face {
  font-family: "Gilroy";
  src: url("./assets/mobileFSFB/fonts/Gilroy-ExtraBold.woff2") format("woff2"),
    url("./assets/mobileFSFB/fonts/Gilroy-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/mobileFSFB/fonts/Gilroy-Light.woff2") format("woff2"),
    url("./assets/mobileFSFB/fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: url("./assets/mobileFSFB/fonts/Gilroy-Semibold.woff2") format("woff2"),
    url("./assets/mobileFSFB/fonts/Gilroy-Semibold.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  height: stretch;
  background-color: $color-white !important;
}

.bolder {
  font-weight: bold !important;
}

.containerTimerFSFBVirtualrow {
  position: absolute;
  z-index: 9;
  top: 24.41px;
  right: 48px;
  border: none;
  outline: none;
  background: none;
  width: 62px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4.5px solid white;
}

.circle__item,
.circle__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerTimerC {
  position: relative;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 4.5px solid white;
}

.houseVirtualrowFSFB {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circles__container {
  // display: none !important;
  position: absolute;
  left: -9px;
  bottom: -9px;
}

.dialogdk {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  bottom: 0;
}

.text-sizing-lg {
  font-size: 3.5rem;
}

.dialogdk section {
  position: fixed;
  left: 50%;
  top: 30px;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  display: none;
  color: rgb(26, 26, 26);
  align-items: center;
  justify-content: center;
  z-index: 9999;
  padding: 10px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dialogdk p {
  margin: 0;
}

.button-without-styles {
  border: none;
  outline: none;
  background: none;
  padding: 0;
  height: auto;
}

.Input {
  position: relative;
}

.atril .Input-text {
  display: block !important;
  margin: 0 !important;
  padding: 0 $inputPaddingH !important;
  color: #788591 !important;
  width: 100% !important;
  font-weight: 500 !important;
  line-height: $inputLineHeight !important;
  transition: box-shadow $transitionDuration !important;
  box-shadow: 0rem 0rem 0.1rem rgb(218, 217, 217);
  min-height: 40px;
  font-size: 28px !important;
  line-height: 25px;
  background-color: rgba(0, 0, 255, 0) !important;
  border: 2.18367px solid #dedede !important;
  box-sizing: border-box;
  border-radius: 17.4694px !important;
  padding: 1rem 1.2rem !important;
  height: auto !important;
}

.margin-auto {
  margin: auto;
}

.text-success-local {
  width: 259.82px;
  height: 76px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  /* or 144% */

  text-align: center;

  /* PALETA/BUTTON 1 */

  color: #009dac;
}

.mblocal-5 {
  margin-bottom: 7rem;
}

.button-primary-box-card {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #001598;
  border-radius: 6.49541px;
  border: none;
  outline: none;
  padding: 0.5rem 1rem;
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.buttonGreen {
  width: 320px;
  height: 60.07px;

  background: #009dac;
  border-radius: 17.4744px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  /* identical to box height, or 144% */

  text-align: center;

  /* TEXT/Uso: texto en fondo oscuro y contraste con imagen. */

  color: #ffffff;
  border: none;
}

.button-primary-box-card p {
  font-style: normal;
  font-weight: 400;
  font-size: 15.156px;
  line-height: 19px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
  margin: 0;
}

.Input-text.select {
  background: url("./assets/arrow-up-icon-23.png") no-repeat;
}

.Input-text:focus {
  outline: none;
  box-shadow: 0rem 0rem 0.5rem $colorPrimary600;
}

.Input-text::placeholder {
  color: rgba(128, 128, 128, 0.582) !important;
}

.dk-overlay {
  background-color: rgb(255 255 255 / 82%);
  position: relative;
  padding: 1rem;
  border: 2px solid #c7c7c7;
  border-radius: 5px;
}

.cropper-container {
  width: 100% !important;
  height: 100% !important;
}

.Input-label {
  display: block;
  position: absolute;
  bottom: 50%;
  left: 1rem;
  color: #fff;
  font-family: inherit;
  font-size: $inputFontSize;
  font-weight: inherit;
  line-height: $inputLineHeight;
  opacity: 0;
  transform: translate3d(0, $labelDefaultPosY), 0 scale(1);
  transform-origin: 0 0;
  transition: opacity $inputTransitionDuration $inputTransitionTF,
    transform $inputTransitionDuration $inputTransitionTF,
    visibility 0ms $inputTransitionDuration $inputTransitionTF,
    z-index 0ms $inputTransitionDuration $inputTransitionTF;
}

.Input-text:placeholder-shown + .Input-label {
  visibility: hidden;
  z-index: -1;
}

.Input-text:not(:placeholder-shown) + .Input-label,
.Input-text:focus:not(:placeholder-shown) + .Input-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translate3d(0, $labelTransformedPosY, 0) scale($labelScaleFactor);
  transition: transform $inputTransitionDuration, visibility 0ms, z-index 0ms;
}

.TitleSection {
  /* font-size: 28px; */
  text-align: center;
  color: $color-primary;
  font-weight: bold;
  font-size: $font-large;
  line-height: 30px;
}

.cropper-point {
  height: 10px;
  width: 10px;
}

.TextSection {
  font-size: $font-sm;
  color: $color-gray;
  text-align: center;
  line-height: 21px;
}

.font-16 {
  font-size: 16px;
  line-height: 20px;
}

.custom-control-label::before {
  width: 21px !important;
  height: 21px !important;
  top: 0rem !important;
  left: -2rem !important;
  margin-right: 25px;
  border: 1px solid #768191 !important;
  box-sizing: border-box;
  border-radius: 4px;
}

.FontDesc {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* RESPUESTAS AND ACCENT */
  color: #081e3d;
  text-align: center;
}

// .selectedThing svg.svgIcon path {
//   stroke: #009dac;
// }

// svg.svgIcon path,
// svg.svgIcon rect {
//   fill: #dedede;
// }

// .selectedThing svg.svgIcon rect {
//   stroke: none;
// }

.cropper-point.point-se {
  width: 10px !important;
  height: 10px !important;
}

.custom-control-label::after {
  top: 0.2rem !important;
  left: -1.85rem !important;
}

.custom-control-label {
  font-size: 14px;
  line-height: 19px;
  max-width: 300px;
}

.MainView,
.container-fluid,
body,
html {
  height: 100%;
}

.Footer {
  display: none !important;
}

/* fonterH3 */

h3.fonterH3 {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  color: #788591;
}

h3.fonterH3Colmedica {
  font-weight: 700;
  font-size: 34px;
  line-height: 36px;

  text-align: center;

  color: #0058a2;
}

/* textHero */

.textHero {
  margin: auto;
  margin-top: 8px;
  margin-bottom: 54.94px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.textHeroColmedica {
  max-width: 404px;
  margin: auto;
  display: block;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #081e3d;
}

.with-mx {
  max-width: 70%;
}

.svgIcon {
  height: 86px;
}

.banderaSvg,
.banderaSvgMobile {
  width: 100%;
  height: 18px;
  position: fixed;
  bottom: -7px;
  left: 0;
  z-index: 3;
  background-image: url(./assets/svgBandera.svg);
  background-size: cover contain;
  background-repeat: no-repeat;
}

.banderaSvgMobile {
  background-image: url(./assets/mobileFSFB/svgBanderaMobile.svg);
  right: 0;
  background-position: center;
}

.without-mx {
  max-width: 100%;
}

.atril.atrilColmedica .Input-text {
  border: 1px solid #66707e !important;
  box-sizing: border-box;
  border-radius: 15px !important;
  margin-bottom: 15.6px !important;

  padding: 1.25rem 1rem !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  display: flex !important;
  align-items: center !important;

  color: #081e3d !important;
}

.MainView.atrilColmedica {
  background-color: #f4f4f4;
}

.opacity-0 {
  opacity: 0;
}

#qrCode canvas {
  width: 100%;
}

.tutorialBox {
  width: 385px;
  height: 168.58px;
  padding: 22.5px 24.87px;
  position: absolute;
  bottom: 0;
  z-index: 90000000;
  background: #081e3d;
  border-radius: 5.22727px;
  opacity: 0;
  transition: 0.5s opacity;
  animation: showMe forwards 0.5s ease-in-out;
  text-align: start;
}

.tutorialBox .svgIconClose {
  position: absolute;
  top: 22px;
  right: 26.14px;
}

.tutorialBox h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #ffffff;
}

.tutorialBox .text-desc {
  font-style: normal;
  font-weight: 100;
  font-size: 20px;
  line-height: 23px;
  padding-right: 27px;
  color: #ffffff;
}

.tutorialBox .text-steps {
  position: absolute;
  bottom: 0;
  left: 24.87px;
  font-style: normal;
  font-weight: 100;
  font-size: 18px;
  line-height: 23px;

  color: rgba(255, 255, 255, 0.6);
}

.tutorialBox .triangulo {
  position: absolute;
}

.text-danger-dk {
  color: #fb0101 !important;
}

/* FIRST PAGE */

.tutorialBox.Selection,
.tutorialBox.Identification,
.tutorialBox.PhoneNumber {
  right: -385px;
  top: 50%;
  transform: translateY(-50%);
}

.tutorialBox.AcceptTerms {
  left: -28px;
  top: -166px;
}

.tutorialBox.Selection .triangulo,
.tutorialBox.Identification .triangulo,
.tutorialBox.PhoneNumber .triangulo {
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.tutorialBox.AcceptTerms .triangulo {
  left: 40px;
  bottom: -33px;
  transform: translateY(-50%) rotate(-90deg);
}

/* SECOND PAGE */

.tutorialBox.Att1,
.tutorialBox.Att2 {
  left: 275px;
  top: 50%;
  transform: translateY(-50%);
}

.tutorialBox.Att1 {
  left: -403px;
}

.tutorialBox.Upload1 {
  bottom: -100%;
  // transform: translateY(-50%);
}

.tutorialBox.Upload1 .triangulo {
  left: 30px;
  top: -19px;
  transform: translateX(-50%) rotate(90deg);
}

.tutorialBox.Att1 .triangulo,
.tutorialBox.Att2 .triangulo {
  right: -11px;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%) rotate(180deg);
}

.tutorialBox.Att2 .triangulo {
  left: -11px;
  right: auto;
  transform: translateY(-50%) rotate(0deg);
}

/* THERTH PAGE */

.tutorialBox.Mic,
.tutorialBox.VideoCam,
.tutorialBox.EndVideoCall,
.tutorialBox.AddFiles,
.tutorialBox.WriteMessage {
  right: 50%;
  transform: translateX(50%);
  top: -192.58px;
}

.tutorialBox.SendMessage {
  right: 145px;
  transform: translateX(50%);
  top: -192.58px;
}

.tutorialBox.Mic .triangulo,
.tutorialBox.VideoCam .triangulo,
.tutorialBox.EndVideoCall .triangulo,
.tutorialBox.AddFiles .triangulo,
.tutorialBox.WriteMessage .triangulo {
  left: 50%;
  bottom: -19px;
  transform: rotate(-90deg);
}

.tutorialBox.SendMessage .triangulo {
  right: 38.14px;
  bottom: -19px;
  transform: rotate(-90deg);
}

#pays-modal-virtualrowFSFB___BV_modal_content_ {
  width: 640px;
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999;
}

@keyframes showMe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

button.slick-arrow::before {
  color: #00599d;
}

.slick-next {
  right: 0 !important;
  z-index: 10;
}

.slick-prev {
  left: 0px !important;
  z-index: 10;
}

@media screen and (max-width: 780px) {
  // FIRST PAGE

  .tutorialBox.Selection,
  .tutorialBox.Identification,
  .tutorialBox.PhoneNumber {
    left: 50%;
    top: auto;
    right: auto;
    bottom: -190px;
    transform: translateX(-50%);
  }

  .tutorialBox.PhoneNumber {
    bottom: 120px;
  }

  .tutorialBox.Selection .triangulo,
  .tutorialBox.Identification .triangulo,
  .tutorialBox.PhoneNumber .triangulo {
    left: 50%;
    bottom: auto;
    right: auto;
    top: -18px;
    transform: translateX(-50%) rotate(90deg);
  }

  .tutorialBox.PhoneNumber .triangulo {
    top: auto;
    bottom: -18px;
    transform: translateX(-50%) rotate(-90deg);
  }

  .tooltipTutorial {
    right: -15px;
  }

  // THERTH PAGE

  .tutorialBox.Mic,
  .tutorialBox.VideoCam,
  .tutorialBox.EndVideoCall {
    right: 50%;
    transform: translateX(50%);
    top: auto;
    bottom: -192.58px;
  }

  .tutorialBox.AddFiles {
    left: -20px;
    transform: none;
    top: -192.58px;
  }

  .tutorialBox.AddFiles .triangulo {
    left: 38.14px;
  }

  .tutorialBox.Mic .triangulo,
  .tutorialBox.VideoCam .triangulo,
  .tutorialBox.EndVideoCall .triangulo {
    left: 50%;
    top: -19px;
    bottom: auto;
    transform: rotate(90deg);
  }

  .tutorialBox.SendMessage {
    right: 160px;
  }
}

@media screen and (max-width: 850px) {
  // SECOND PAGE

  .tutorialBox.Att1,
  .tutorialBox.Att2 {
    top: auto;
    left: 69%;
    bottom: -270px;
    transform: translate(-50%, -50%);
  }

  .tutorialBox.Att1 {
    right: -69%;
    left: auto;
  }

  .tutorialBox.Att1 .triangulo,
  .tutorialBox.Att2 .triangulo {
    left: 37%;
    bottom: auto;
    right: auto;
    top: -18px;
    transform: translateX(-50%) rotate(90deg);
  }

  .tutorialBox.Att1 .triangulo {
    left: 62%;
  }
}
</style>
