import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=653754d9&scoped=true&lang=pug&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=653754d9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653754d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src142773959/src/bsix_meet_client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('653754d9')) {
      api.createRecord('653754d9', component.options)
    } else {
      api.reload('653754d9', component.options)
    }
    module.hot.accept("./Home.vue?vue&type=template&id=653754d9&scoped=true&lang=pug&", function () {
      api.rerender('653754d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/older_adult/Home.vue"
export default component.exports