var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100 maxContainer mx-auto" }, [
    _c(
      "div",
      { staticClass: "main_box" },
      [
        _c("Header", { staticClass: "mt-0" }),
        _c("div", { staticClass: "mx-auto max-width-box-form" }),
        _c(
          "svg",
          {
            staticClass: "text-center d-block mx-auto mt-5",
            attrs: {
              width: "32",
              height: "32",
              viewbox: "0 0 32 32",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z",
                fill: "#3290E0"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z",
                fill: "#F4F4F4"
              }
            })
          ]
        ),
        _c("h2", { staticClass: "mt-3 TitleSection px-4 pb-3" }, [
          _vm._v("La videollamada ha finalizado")
        ]),
        _vm._m(0),
        _c(
          "footer",
          { staticClass: "ButtonFixed position-fixed" },
          [
            _vm.redirect
              ? _c(
                  "b-button",
                  {
                    attrs: { variant: "bluecolmedica" },
                    on: { click: _vm.goHome }
                  },
                  [_vm._v("Volver al menú inicial")]
                )
              : _c(
                  "b-button",
                  {
                    attrs: { variant: "bluecolmedica" },
                    on: {
                      click: function() {
                        return _vm.goToView("Home")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-dm" }, [
                      _vm._v("Ya puedes cerrar esta ventana")
                    ])
                  ]
                )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "form-data mb-3 mt-2 py-4 mx-1 px-4 mx-4" },
      [
        _c("p", { staticClass: "font-20 mb-5 text-center px-4" }, [
          _vm._v(
            "Gracias por utilizar nuestros servicios. Te esperamos en una próxima ocasión."
          )
        ]),
        _c("p", { staticClass: "font-18 mb-5 text-center px-4" }, [
          _vm._v(
            "Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura."
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }