var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Header py-3 px-5" }, [
    _c(
      "div",
      {
        staticClass:
          "Header__content d-flex justify-content-between align-items-center"
      },
      [
        _vm.view !== "Home" && _vm.showArrow
          ? _c(
              "button",
              {
                staticClass:
                  "button-without-styles Header__back_button d-flex align-items-center",
                on: { click: _vm.onGoBack }
              },
              [
                _vm._m(0),
                _c("p", { staticClass: "mb-0 Header__text" }, [
                  _vm._v("Regresar")
                ])
              ]
            )
          : _c(
              "button",
              {
                staticClass:
                  "button-without-styles Header__back_button d-flex align-items-center opacity-0",
                on: { click: _vm.onGoBack }
              },
              [
                _vm._m(1),
                _c("p", { staticClass: "mb-0 Header__text" }, [
                  _vm._v("Regresar")
                ])
              ]
            ),
        _c("img", {
          staticClass: "Header__client_logo",
          attrs: { src: _vm.Logo },
          on: { click: _vm.onGoToHome }
        }),
        _vm.showTutorialTurn
          ? _c("Tutorial", {
              staticClass: "mb-0 pl-5",
              attrs: {
                firstCharge: _vm.firstCharge,
                showTutorialTurn: _vm.showTutorialTurn
              }
            })
          : _vm._e(),
        _vm.viewDisplayed && _vm.view !== "Home"
          ? _c("Tutorial", {
              staticClass: "mb-0 pl-5",
              attrs: { firstCharge: _vm.firstCharge }
            })
          : _vm.showTutorial && _vm.view !== "Home"
          ? _c("Tutorial", {
              staticClass: "mb-0 pl-5",
              attrs: { firstCharge: _vm.firstCharge }
            })
          : _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showTutorialTurn,
                  expression: "!showTutorialTurn"
                }
              ],
              staticClass: "Header__client_logo opacity-0",
              attrs: { src: _vm.Logo },
              on: { click: _vm.onGoToHome }
            })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mr-2 d-block" }, [
      _c("img", {
        staticClass: "d-block",
        attrs: {
          src: require("@/assets/older_adults/backArrow.svg"),
          alt: "backArrow"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "mr-2 d-block" }, [
      _c("img", {
        staticClass: "d-block",
        attrs: {
          src: require("@/assets/older_adults/backArrow.svg"),
          alt: "backArrow"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }