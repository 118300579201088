var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-logo" }, [
    _c("img", {
      directives: [{ name: "openlog", rawName: "v-openlog" }],
      staticClass: "stylelogo d-block",
      attrs: { src: _vm.Logo }
    }),
    _c(
      "div",
      { staticClass: "main_box", staticStyle: { display: "none" } },
      [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v("Version " + _vm._s(_vm.version))
        ]),
        _c(
          "b-form",
          {
            staticClass: "form-horizontal",
            on: {
              submit: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.saveUserData.apply(null, arguments)
              }
            }
          },
          [
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Nombre", readonly: "" },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Apellido", readonly: "" },
              model: {
                value: _vm.last_name,
                callback: function($$v) {
                  _vm.last_name = $$v
                },
                expression: "last_name"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Cédula", readonly: "" },
              model: {
                value: _vm.user_id,
                callback: function($$v) {
                  _vm.user_id = $$v
                },
                expression: "user_id"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Celular", readonly: "" },
              model: {
                value: _vm.phone_number,
                callback: function($$v) {
                  _vm.phone_number = $$v
                },
                expression: "phone_number"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Sucursal", readonly: "" },
              model: {
                value: _vm.branchId,
                callback: function($$v) {
                  _vm.branchId = $$v
                },
                expression: "branchId"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Tramite", readonly: "" },
              model: {
                value: _vm.queueId,
                callback: function($$v) {
                  _vm.queueId = $$v
                },
                expression: "queueId"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Fuente", readonly: "" },
              model: {
                value: _vm.fuente,
                callback: function($$v) {
                  _vm.fuente = $$v
                },
                expression: "fuente"
              }
            }),
            _c("b-form-input", {
              staticClass: "w-100",
              attrs: { type: "text", placeholder: "Marcacion", readonly: "" },
              model: {
                value: _vm.marcacion,
                callback: function($$v) {
                  _vm.marcacion = $$v
                },
                expression: "marcacion"
              }
            }),
            _c("b-form-textarea", {
              ref: "crude",
              attrs: {
                value: _vm.rawB64,
                rows: "4",
                "max-rows": "6",
                placeholder: "Base 64 Crudo"
              },
              on: { click: _vm.copyPaste }
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "position-relative d-inline text-center" }, [
      _c("img", {
        staticClass: "size_icon mx-auto",
        attrs: { src: _vm.Conected }
      }),
      _c("h5", [_vm._v("Estamos solicitando tu turno. Por favor espera.")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }