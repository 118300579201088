import LS from "../../helpers/localStorage";

export default {
  view: "Home",
  isAtril: false,
  previousView: "",
  previousViewTwo: "",
  showAuth: false,
  HorarioOutContinue: false,
  charging: false,
  error: null,
  tramite_info: {
    queueId: "idSASASASXX2C12",
    branchId: "5a46d454da6a6",
    params: {}
  },
  restartTimerModalState: () => {},
  tramiteInUserEvaluation: null,
  tramiteByDefault: null,
  validationErrors: [],
  errorValidationTemporizer: null,
  currentUser: LS.getItem("currentUser") || null,
  servicioSelected: null,
  selectedAccion: null,
  stopTimerCircle: false,
  turn: null,
  time_received_turn: undefined,
  catch_all: null,
  priority: false,
  history: [],
  menu: [],
  showModalError: false,
  actions: null,
  tipide: [
    // { value: null, text: "Seleccione una opción" },
    { value: "CC", text: "Cédula de ciudadanía" },
    { value: "PS", text: "Pasaporte" },
    { value: "TI", text: "Tarjeta de identidad" },
    { value: "RC", text: "Registro civil" },
    { value: "CE", text: "Cédula de extranjería" },
    { value: "CD", text: "Carné diplomático" },
    { value: "SC", text: "Salvoconducto" },
    { value: "NI", text: "NIT" },
    { value: "NU", text: "NUIP" }
  ],
  citas: [
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: false,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    }
  ],
  currentAppointment: null,
  currentValidation: [],
  currentSite: null,
  userDemo: [
    {
      NumeroIdentificacion: "456"
    },
    {
      NumeroIdentificacion: "789"
    }
  ],
  queueList: [],
  customArea: null,
  sites: [],
  sedeCode: "",
  primaryColorBlue: null,
  secondColorBlue: null,
  threeColorBlueColor: null,
  convertDocumentsFromString: {
    CC: "4",
    TI: "3",
    PS: "P",
    CE: "5",
    RC: "2",
    SI: "A",
    CD: "D",
    MI: "M",
    NI: "6",
    NU: "N",
    SC: "S",
    SN: "A"
  },
  qrLoading: false,
  userActionDate: Date.now(),

  // progress home on payments
  respPayments: '',
  dataPayment: null
};
