/* eslint-disable no-unused-vars */
import {
  getToken,
  getQueues,
  getStyles,
  getEnvOnly,
  getMenus,
  sendRecords,
  tracking
} from "../../helpers/Meet";
import {
  autorizar,
  citas,
  enCentroMedico,
  enOficina,
  validarPago
} from "../../helpers/colmedica_fila_virtual";
import _get from "lodash/get";
import LS from "../../helpers/localStorage";

/**
 * Data for demo porpouses
 */
import CentroDemo from "./CentroDemo";
import OficinaDemo from "./OficinaDemo";
import CitasDemo from "./CitasDemo";
import ValPagoDemo from "./ValidarpagocitaDemo";

export default {
  changeAuth({ commit }, value) {
    commit("setState", { key: "showAuth", value });
  },

  setCustomArea({ commit }, value) {
    commit("setState", { key: "customArea", value });
  },

  setUserActionDate({ commit },value){
    commit("setState", { key: "userActionDate", value });
  },

  async getSites({ commit }) {
    const sites = await getQueues();
    if (sites && sites.length)
      commit("setState", { key: "sites", value: sites });
  },

  async getStyles({ commit }) {
    try {
      const styles = await getStyles();
      commit("setState", {
        key: "primaryColorBlue",
        value: styles?.bsix_client?.companyColor
      });
      commit("setState", {
        key: "secondColorBlue",
        value: styles?.bsix_client?.secondaryCompanyColor
      });
      commit("setState", {
        key: "threeColorBlueColor",
        value: styles?.bsix_client?.contrastTextColor
      });
    } catch (error) {
      console.error("Cant get Styles", error);
    }
  },

  async getMenus({ commit }, { code, area }) {
    const { menu, actions, customMenu } = await getMenus(code);
    commit("setState", { key: "menu", value: customMenu[area] || menu });
    commit("setState", { key: "actions", value: actions });
  },

  goToView({ commit, state, dispatch }, { view }) {
    if (state.isAtril) {
      dispatch("setTimer", true, { root: true });
    }
    commit("setState", { key: "view", value: view });
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.push(view);
    commit("setState", { key: "history", value: newArray });
  },

  setExplanation({ commit }, value) {
    commit("setState", { key: "explanation", value });
  },

  selecteService({ commit }, value) {
    commit("setState", { key: "servicioSelected", value });
  },

  goBack({ commit, state }) {
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.pop();
    commit("setState", { key: "history", value: newArray });
    commit("setState", {
      key: "view",
      value: state.history[state.history.length - 1]
    });
  },

  async setCitaCM({ commit, dispatch, state }, value) {

    // Lf Tested 21-01-21
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "citaActual", value });
    commit("setState", { key: "currentValidation", value: [] });
    try {
      const validation = (await dispatch("getCitaValidation", value)).sort(
        (a, b) => a.Orden - b.Orden
      );
      dispatch("setTimer", true, { root: true });
      commit("setState", { key: "currentValidation", value: validation });
      if (validation.length) {
        if (validation[0]?.IdPaso > -1) {
          dispatch("goToView", { view: "Pay" });
          commit("setState", { key: "charging", value: false });
          value.r();
          return;
        } else if (validation[0]?.IdPaso === -1) {
          commit("setState", {
            key: "selectedAccion",
            value:
              state.menu[0] && state.menu[0].children // TODO: Only 2 levels. If require multilevel, fix it with a recursive function
                ? state.menu[0].children[0]
                : state.menu[0]
          });
        }
      }
      commit("setState", { key: "charging", value: false });
      dispatch("turnCall", true);
      value.r();
      return;
    } catch (error) {
      console.error("In setCitaCM action", error);
    }
    commit("setState", {
      key: "selectedAccion",
      value:
        state.menu[0] && state.menu[0].children // TODO: Only 2 levels. If require multilevel, fix it with a recursive function
          ? state.menu[0].children[0]
          : state.menu[0]
    });
    dispatch("goToView", { view: "Normativity" });
    commit("setState", { key: "charging", value: false });
    value.r();
    return;
  },

  setAction({ commit, dispatch }, value) {
    commit("setState", { key: "selectedAccion", value });
    if (value?.evaluationComponent)
      return dispatch("goToView", { view: value.evaluationComponent });
    if (value?.ignorePriority) dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  confirmedAction({ state, commit, dispatch }) {
    // /  state.selectedaction tiene ignorePriority?
    if (state.selectedAccion?.ignorePriority)
      dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  turnCall({ commit, dispatch }, value) {
    commit("setState", { key: "priority", value });
    dispatch("goToView", { view: "Turn" });
  },

  async getCitaValidation(ctx, { idCita }) {
    let toReturn = [];
    if (getEnvOnly().VUE_APP_DEMO_MODE === "true") {
      if (idCita === 123456) return ValPagoDemo.good;
      else ValPagoDemo.bad;
    } else {
      try {
        toReturn = await validarPago({ idCita });
      } catch (error) {
        console.error("In getCitaValidation", error);
      }
    }
    return toReturn;
  },

  captchaValidation({ dispatch, commit, state }) {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(getEnvOnly().VUE_APP_RECAPTCHA_CODE, {
              action: "submit_identification"
            })
            .then(token =>
              getToken(token, "g_recaptcha_v3", {
                bunitId: getEnvOnly().VUE_APP_BUNIT
              })
            )
            .then(() => res())
            .catch(err => {
              commit("setState", {
                key: "validationErrors",
                value: [...state.validationErrors, err]
              });
              dispatch("errorValidationTemporizer");
              console.error("error in recaptcha request.");
              rej(err);
            });
        }); //
        // throw new Error('X ERROR');
      }).catch(err => {
        commit("setState", { key: "charging", value: false });
        console.error("Error cached in Autenticate.vue -> demoValidation", err);
        dispatch("goToView", { view: "ErrorAutenticate" });
      });
    }
    return Promise.reject(new Error("Recaptcha is not ready"));
  },

  async authenticate({ dispatch, commit, state }, toSend) {
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "error", value: null });
    let nextComponent = {
      view: "ErrorAutenticate"
    };
    try {
      if (!state.isAtril) await dispatch("captchaValidation");
      try {
        await dispatch(
          getEnvOnly().VUE_APP_DEMO_MODE === "true"
            ? "demoValidation"
            : "authAndQuery",
          toSend
        );
        nextComponent.view =
          state.citas && state.citas.length ? "Quote" : "NoDate";
      } catch (error) {
        console.error("Error in authorization", error);
        commit("setState", {
          key: "error",
          value: {
            zone: "authorization",
            message: "El usuario no se encuentra registrado en el sistema"
          }
        });
        dispatch("errorValidationTemporizer");
        nextComponent.view = "Help";
      }
    } catch (error) {
      console.error("Error in Captcha", error);
      commit("setState", {
        key: "error",
        value: { zone: "recaptcha", message: "La validación del captcha falló" }
      });
      dispatch("errorValidationTemporizer");
    }
    dispatch("goToView", nextComponent);
    commit("setState", { key: "charging", value: false });
  },

  async demoValidation({ commit, state }, toSend) {
    let userfinded = state.userDemo.find(
      usr => usr.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    await new Promise(res => setTimeout(res, 2000));
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
    if (!userfinded) throw new Error("Invalid demo User");
    let originFinded = (state.currentSite?.type === "cm"
      ? CentroDemo
      : OficinaDemo
    ).find(
      cm =>
        cm.TipoIdentificacion == toSend?.TipoIdentificacion &&
        cm.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    if (!originFinded) throw new Error("User not have meetings");
    toSend = { ...toSend, ...originFinded };
    if (
      state.currentSite?.type === "cm" &&
      toSend.NumeroIdentificacion === "789"
    )
      commit("setState", { key: "citas", value: CitasDemo });
    else commit("setState", { key: "citas", value: [] });
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
  },

  async authAndQuery({ state, commit }, pay) {
    try {
      // Check Company
      await autorizar({
        TipoIdentificacion: pay.TipoIdentificacion,
        NumeroIdentificacion: pay.NumeroIdentificacion,
        CodigoOficina: pay.CodigoOficina
      });

      const inSite = await (state.currentSite?.type === "cm"
        ? enCentroMedico
        : enOficina)();
      commit("setState", { key: "citas", value: [] });
      if (state.currentSite?.type === "cm") {
        const citasList = await citas();
        console.log("citasList")
        console.log(citasList)
        commit("setState", { key: "citas", value: citasList });
      }
      commit("setState", {
        key: "currentUser",
        value: { ...pay, ...inSite }
      });
        
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }
  },

  handleSendRecords({ commit }, info) {
    return sendRecords(info)
      .then(response => {
        // commit("saveRecord", response);
        commit("setState", { key: "saveRecord", value: response });
        return response;
      })
      .catch(error => {
        // commit("errorRecord", error);
        commit("setState", { key: "errorRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  handleTracking({ commit }, info) {
    return tracking(info)
      .then(response => {
        commit("setState", { key: "trackingRecord", value: response });
        return response;
      })
      .catch(error => {
        commit("setState", { key: "errorTrackingRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  errorValidationTemporizer({ commit, state }) {
    if (state.errorValidationTemporizer !== null)
      clearTimeout(state.errorValidationTemporizer);
    if (state.error) return;
    let timer = setTimeout(() => {
      commit("setState", { key: "error", value: null });
      commit("setState", { key: "errorValidationTemporizer", value: null });
    }, 5000);
    commit("setState", { key: "errorValidationTemporizer", value: timer });
  },

  cleanCurrentUser({ commit }) {
    commit("setState", { key: "currentUser", value: null });
    LS.removeItem("currentUser");
  }
};
