var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-11 header" }, [
        _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("img", {
            directives: [{ name: "openlog", rawName: "v-openlog" }],
            staticClass: "client_logo",
            attrs: { src: _vm.Logo }
          })
        ])
      ])
    ]),
    _c("div", { staticClass: "main_box" }, [
      _c("div", { staticClass: "main_text" }, [_vm._v("Tu llamada")]),
      _c("div", { staticClass: "main_text" }, [_vm._v("ha finalizado")]),
      _c(
        "div",
        {
          staticStyle: { "font-size": "1.2rem", "margin-top": "3rem" },
          on: {
            click: function() {
              return _vm.goToView("Home")
            }
          }
        },
        [_vm._v("Ya puedes cerrar esta ventana")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }