var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agentbase" }, [
    _vm.preload
      ? _c(
          "div",
          {
            staticStyle: {
              "max-width": "25%",
              width: "100%",
              "text-align": "center"
            }
          },
          [_c("Loading"), _c("h3", [_vm._v("Conectando...")])],
          1
        )
      : _vm._e(),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.preload,
            expression: "!preload"
          }
        ]
      },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [
            !_vm.turnId
              ? _c("div", { staticClass: "container-logo" }, [
                  _c("img", {
                    directives: [{ name: "openlog", rawName: "v-openlog" }],
                    staticClass: "stylelogo d-block",
                    attrs: { src: _vm.Logo }
                  }),
                  _c(
                    "h5",
                    {
                      staticClass: "container-text",
                      staticStyle: {
                        position: "absolute",
                        "margin-top": "30vh",
                        "text-align": "center",
                        color: "firebrick"
                      }
                    },
                    [
                      _vm._v(
                        "La versión de aplicación utilizada por el cliente es menor a 4.0, por tanto, no se puede conectar a la videollamada. Por favor cierra esta ventana y finaliza el turno."
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm.turnId
              ? _c("Meeting", {
                  attrs: {
                    meetType: "agent",
                    meetId: _vm.meetingId,
                    username: _vm.username
                  },
                  on: { endMeet: _vm.endMeet, attendees: _vm.managePeople }
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "styleDivData" },
      [
        _c("DivInfoCall", { attrs: { meetType: "agent" } }),
        _c(
          "button",
          { staticClass: "buttonAgent", on: { click: _vm.onBlurVideo } },
          [_vm._v(_vm._s(!_vm.isBlur ? "Apply blur" : "Remove blur"))]
        ),
        _c("input", {
          attrs: { type: "file" },
          on: { change: _vm.onSelectedFile }
        }),
        _c(
          "button",
          { staticClass: "buttonAgent", on: { click: _vm.onBlurVideo } },
          [_vm._v(_vm._s(!_vm.isBlur ? "Apply bg image" : "Remove bg image"))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }