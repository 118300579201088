/* eslint-disable no-unused-vars */
import {
  getToken,
  getQueues,
  getStyles,
  getEnvOnly,
  getMenus,
  sendRecords,
  tracking
} from "../../helpers/Meet";
import {
  validarPago
} from "../../helpers/colmedica_fila_virtual";
import { autorizarFSFB, notificationsFSFB, citasFSFB, fetchCupsCode } from "../../helpers/santafe_fila_firtual";
import _get from "lodash/get";
import LS from "../../helpers/localStorage";
import moment from "moment";
import router from "../../router";
/**
 * Data for demo porpouses
 */
import CentroDemo from "./CentroDemo";
import OficinaDemo from "./OficinaDemo";
import CitasDemo from "./CitasDemo";
import ValPagoDemo from "./ValidarpagocitaDemo";

export default {
  changeAuth({ commit }, value) {
    commit("setState", { key: "showAuth", value });
  },

  setCustomArea({ commit }, value) {
    commit("setState", { key: "customArea", value });
  },

  async getSites({ commit }) {
    const sites = await getQueues();
    if (sites && sites.length)
      commit("setState", { key: "sites", value: sites });
  },

  async getStyles({ commit }) {
    try {
      const styles = await getStyles();
      commit("setState", {
        key: "primaryColorBlue",
        value: styles?.bsix_client?.companyColor
      });
      commit("setState", {
        key: "secondColorBlue",
        value: styles?.bsix_client?.secondaryCompanyColor
      });
      commit("setState", {
        key: "threeColorBlueColor",
        value: styles?.bsix_client?.contrastTextColor
      });
    } catch (error) {
      console.error("Cant get Styles", error);
    }
  },

  async getMenus({ commit }, { code, area }) {
    const { menu, actions, customMenu } = await getMenus(code);
    commit("setState", { key: "menu", value: area ? customMenu[area] : menu });
    commit("setState", { key: "actions", value: actions });
  },

  goToView({ commit, state, dispatch }, { view, actionError, fromError }) {
    if (state.isAtril) {
      dispatch("setTimer", true, { root: true });
    }
    if (actionError) {
      commit("setState", { key: "actionError", value: actionError });
    }

    if (fromError) {
      commit("setState", { key: "fromError", value: fromError });
    }

    commit("setState", { key: "view", value: view });
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.push(view);
    commit("setState", { key: "history", value: newArray });
  },

  setExplanation({ commit }, value) {
    commit("setState", { key: "explanation", value });
  },

  selecteService({ commit }, value) {
    commit("setState", { key: "servicioSelected", value });
  },

  goBack({ commit, state }) {
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.pop();
    commit("setState", { key: "history", value: newArray });
    commit("setState", {
      key: "view",
      value: state.history[state.history.length - 1]
    });
  },

  async setCitaCM({ commit, dispatch, state }, appointment) {
    // Lf Tested 21-01-21
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "citaActual", value: appointment });
    commit("setState", { key: "currentValidation", value: [] });
    try {

      let currentSite = state.sites.find(s=>s.code == state.sedeCode);

      // Check Payments enabled
      let paymentsEnabled = currentSite.customAreas[state.customArea];

      console.log("PAYMENTS MOBILE ENABLED: " + paymentsEnabled.enablePayments)

      // Check if appointment is pending for pay
      // if(appointment.authorized_appoinment && appointment.appoinment_paid == 'NO' && appointment.appoinment_url_paid && !appointment.goAsesor){
      if(
        appointment.authorized_appoinment == 'SI' && 
        appointment.appoinment_paid == 'NO' && 
        (appointment.appoinment_url_paid || appointment.cost_appoinment > 0) && 
        paymentsEnabled.enablePayments
      ){
        commit("setState", { key: "charging", value: false });
        dispatch("goToView", { view: "Payment" });
        return router.replace({ query: { area: state.customArea, id_cita_pago: appointment.number_form } })
      }
      
      // check actions
      // let allConditions = state.sites.find(s=>s.code == state.sedeCode);
      let conditions = currentSite.conditions[state.customArea];

      // check conditions
      let code = conditions.find(c=>{
        if(c.appoinment_paid == appointment.appoinment_paid && 
          c.authorized_appoinment == appointment.authorized_appoinment) 
          return c
      })

      // serch actions
      commit("setState", {
        key: "selectedAccion",
        value: code
      });
      commit("setState", { key: "charging", value: false });

      return dispatch("turnCall", false);

    } catch (error) {
      console.error("In setCitaCM action", error);
    }
    commit("setState", {
      key: "selectedAccion",
      value:
        state.menu[0] && state.menu[0].children // TODO: Only 2 levels. If require multilevel, fix it with a recursive function
          ? state.menu[0].children[0]
          : state.menu[0]
    });
    dispatch("goToView", { view: "Normativity" });
    commit("setState", { key: "charging", value: false });
  },

  setAction({ commit, dispatch }, value) {
    commit("setState", { key: "selectedAccion", value });
    // if (value?.evaluationComponent)
    //   return dispatch("goToView", { view: value.evaluationComponent });
    if (value?.ignorePriority) {
      commit("setState", { key: "priority", value: false });
      dispatch("goToView", { view: "Turn" });
    } 
    else {
      if (value.userAge && value.userAge >= 60) {
        commit("setState", { key: "priority", value: true });
        dispatch("goToView", { view: "Turn" });
      } else dispatch("goToView", { view: "Oldest" });
    } 

  },

  confirmedAction({ state, commit, dispatch }) {
    // /  state.selectedaction tiene ignorePriority?
    if (state.selectedAccion?.ignorePriority)
      dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  turnCall({ commit, dispatch }, value) {
    commit("setState", { key: "priority", value });
    dispatch("goToView", { view: "Turn" });
  },

  async getCitaValidation(ctx, { idCita }) {
    let toReturn = [];
    if (getEnvOnly().VUE_APP_DEMO_MODE === "true") {
      if (idCita === 123456) return ValPagoDemo.good;
      else ValPagoDemo.bad;
    } else {
      try {
        toReturn = await validarPago({ idCita });
      } catch (error) {
        console.error("In getCitaValidation", error);
      }
    }
    return toReturn;
  },

  captchaValidation({ dispatch, commit, state }) {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(getEnvOnly().VUE_APP_RECAPTCHA_CODE, {
              action: "submit_identification"
            })
            .then(token =>
              getToken(token, "g_recaptcha_v3", {
                bunitId: getEnvOnly().VUE_APP_BUNIT
              })
            )
            .then(() => res())
            .catch(err => {
              commit("setState", {
                key: "validationErrors",
                value: [...state.validationErrors, err]
              });
              dispatch("errorValidationTemporizer");
              console.error("error in recaptcha request.");
              rej(err);
            });
        }); //
        // throw new Error('X ERROR');
      }).catch(err => {
        commit("setState", { key: "charging", value: false });
        console.error("Error cached in Autenticate.vue -> demoValidation", err);
        dispatch("goToView", { view: "ErrorAutenticate" });
      });
    }
    return Promise.reject(new Error("Recaptcha is not ready"));
  },

  changeDocInfo({ commit }, value) {
    commit("setState", { key: "docInfo", value });
  },

  async authenticate({ dispatch, commit, state }, toSend) {
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "error", value: null });
    let nextComponent = {
      view: ""
    };
    try {
      if (!state.isAtril) await dispatch("captchaValidation");
      try {
        await dispatch(
          getEnvOnly().VUE_APP_DEMO_MODE === "true"
            ? "demoValidation"
            : "authAndQuery",
          toSend
        );

        // check user exist
        if(state.currentUser && !state.currentUser.id_patient){
          throw new Error("El usuario no se encuentra registrado en el sistema");
        }

        nextComponent.view =
          state.citas && state.citas.length ? "Dates" : "ConfirmDataAuth";
        // }
      } catch (error) {
        console.error("Error in authorization", error);
        commit("setState", {
          key: "error",
          value: {
            zone: "authorization",
            message: "El usuario no se encuentra registrado en el sistema"
          }
        });
        dispatch("errorValidationTemporizer");
        nextComponent.view = "Help";
      }
    } catch (error) {
      console.error("Error in Captcha", error);
      commit("setState", {
        key: "error",
        value: { zone: "recaptcha", message: "La validación del captcha falló" }
      });
      dispatch("errorValidationTemporizer");
    }
    dispatch("goToView", nextComponent);
    commit("setState", { key: "charging", value: false });
  },

  async demoValidation({ commit, state }, toSend) {
    let userfinded = state.userDemo.find(
      usr => usr.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    await new Promise(res => setTimeout(res, 2000));
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
    if (!userfinded) throw new Error("Invalid demo User");
    let originFinded = (state.currentSite?.type === "cm"
      ? CentroDemo
      : OficinaDemo
    ).find(
      cm =>
        cm.TipoIdentificacion == toSend?.TipoIdentificacion &&
        cm.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    if (!originFinded) throw new Error("User not have meetings");
    toSend = { ...toSend, ...originFinded };
    if (
      state.currentSite?.type === "cm" &&
      toSend.NumeroIdentificacion === "789"
    )
      commit("setState", { key: "citas", value: CitasDemo });
    else commit("setState", { key: "citas", value: [] });
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
  },

  async authAndQuery({ state, commit }, pay) {
    try {
      
      commit("setState", { key: "preloadPayments", value: true });
      commit("setState", { key: "charging", value: true });
      const userResponse = await autorizarFSFB({
        TipoIdentificacion: pay.TipoIdentificacion,
        NumeroIdentificacion: pay.NumeroIdentificacion
      });

      if(userResponse.message && userResponse.message.includes("Failed to fetch")){
        commit("setState", { key: "preloadPayments", value: false });
        throw new Error("Connection Error");
      }

      let citasList = [];
      if (userResponse && userResponse.id_patient) {

        if(state.customArea){

          // Check appointments
          citasList = await citasFSFB(userResponse);
  
          // check cups_code
          let cups_code = await fetchCupsCode(state.customArea.toUpperCase());
          cups_code = cups_code.map(c=>c.cups_code?.S)
  
          // console.log(state.sites)
          // console.log(state.sedeCode)
  
          // let customArea = state.sites.find(s=>s.code == state.sedeCode).customAreas;
          // citasList = citasList.filter(c=>c.service.code == customArea[state.customArea].fsfbCode);
          citasList = citasList.filter( c=> cups_code.includes(c.attention.code) );
          citasList = citasList.sort((itemA, itemB) => {
            const itemBHCS = moment().hour(itemB.hour_appoinment.split(":")[0]);
            const itemAHCS = moment().hour(itemA.hour_appoinment.split(":")[0]);
      
            if (moment().diff(itemAHCS.hour()) - moment().diff(itemBHCS.hour()) > 0) {
              return -1;
            }
            if (
              moment().diff(itemAHCS.hour()) - moment().diff(itemBHCS.hour()) <=
              0
            ) {
              return 1;
            }
            return 0;
            
          })
        }
      } else {
        commit("setState", { key: "preloadPayments", value: false });
        throw new Error("Invalid Authorization in Fundación Santafe");
      }

      commit("setState", { key: "citas", value: citasList });
      commit("setState", {
        key: "currentUser",
        value: { ...pay, ...userResponse }
      });

      commit("setState", { key: "charging", value: false });
      commit("setState", { key: "preloadPayments", value: false });
        
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      commit("setState", { key: "preloadPayments", value: false });
      throw error;
    }
  },

  async handleMobileAppointments({ commit }, info){
      // Check appointments
      commit("setState", { key: "preloadPayments", value: true });
      let citasList = await citasFSFB(info);
      commit("setState", { key: "citas", value: citasList });
      commit("setState", { key: "preloadPayments", value: false });
  },

  handleSendRecords({ commit }, info) {
    return sendRecords(info)
      .then(response => {
        // commit("saveRecord", response);
        commit("setState", { key: "saveRecord", value: response });
        return response;
      })
      .catch(error => {
        // commit("errorRecord", error);
        commit("setState", { key: "errorRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  handleTracking({ commit }, info) {
    return tracking(info)
      // .then(response => {
      //   commit("setState", { key: "trackingRecord", value: response });
      //   return response;
      // })
      // .catch(error => {
      //   commit("setState", { key: "errorTrackingRecord", value: error });
      //   return {
      //     response: false,
      //     message: error
      //   };
      // });
  },

  errorValidationTemporizer({ commit, state }) {
    if (state.errorValidationTemporizer !== null)
      clearTimeout(state.errorValidationTemporizer);
    if (state.error) return;
    let timer = setTimeout(() => {
      commit("setState", { key: "error", value: null });
      commit("setState", { key: "errorValidationTemporizer", value: null });
    }, 5000);
    commit("setState", { key: "errorValidationTemporizer", value: timer });
  },

  setSede({ commit }, value) {
    commit("setState", { key: "sedeCode", value });
  },

  cleanCurrentUser({ commit }) {
    commit("setState", { key: "currentUser", value: null });
    LS.removeItem("currentUser");
  },

  setEmbebedData({commit}, value) {
    commit("setState", { key: "embebedData", value: value });
  },

  async mobileNotification({ state, commit }, pay) {

    try {
      
      // check notification
      let notification = await notificationsFSFB({
        "tdoc": pay.type == 1 ? state.convertDocumentsFromString[state.currentUser.TipoIdentificacion] : null,
        "doc": pay.type == 1 ? state.currentUser.NumeroIdentificacion : null,
        "title": pay.title,
        "body": pay.body,
        "type": pay.type,
        "module": state.customArea //"derivaciones"
      });
      return notification.response;
      
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }

  },

  handlePreloadAppointments({ commit }, pay){
    commit("setState", { key: "preloadPayments", value: pay });
  },

  setFromQR({ commit }, pay){
    commit("setState", { key: "fromQR", value: pay });
  }
};
