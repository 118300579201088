var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _c("div", { staticClass: "contain" }, [
        _c("div", { staticClass: "text-center d-block" }, [
          _c("svg", {
            attrs: {
              width: "32",
              height: "32",
              viewbox: "0 0 32 32",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          })
        ]),
        _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
          _vm._v(
            "No te hemos encontrado. Ingresa por favor, estos datos para darte una mejor gestión"
          )
        ]),
        _c(
          "div",
          { staticClass: "form-data mb-3 mt-4 p-4" },
          [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Nombre",
                required: "",
                autocomplete: "off",
                disabled: _vm.isAtril,
                id: "name"
              },
              model: {
                value: _vm.models.Nombres,
                callback: function($$v) {
                  _vm.$set(_vm.models, "Nombres", _vm._n($$v))
                },
                expression: "models.Nombres"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Apellido",
                required: "",
                autocomplete: "off",
                disabled: _vm.isAtril,
                id: "last_name"
              },
              model: {
                value: _vm.models.PrimerApellido,
                callback: function($$v) {
                  _vm.$set(_vm.models, "PrimerApellido", _vm._n($$v))
                },
                expression: "models.PrimerApellido"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: {
                type: "number",
                min: "0",
                placeholder: "Celular",
                required: "",
                autocomplete: "off",
                id: "phone"
              },
              model: {
                value: _vm.models.Celular,
                callback: function($$v) {
                  _vm.$set(_vm.models, "Celular", _vm._n($$v))
                },
                expression: "models.Celular"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: {
                type: "text",
                placeholder: "Correo",
                autocomplete: "off",
                id: "email"
              },
              model: {
                value: _vm.models.Correo,
                callback: function($$v) {
                  _vm.$set(_vm.models, "Correo", _vm._n($$v))
                },
                expression: "models.Correo"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "botton-actions-footer" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "w-100 py-2 text-center mx-1",
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "submit",
                    disabled: !_vm.newUserReady
                  },
                  on: { click: _vm.continueWithExistent }
                },
                [_c("span", { staticClass: "font-md" }, [_vm._v("Continuar")])]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }