var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "IndexSchedules" },
    [
      _c("Header"),
      _c("div", { staticClass: "headerMenu" }, [
        _c(
          "button",
          {
            staticClass: "button-without-styles py-3 px-4 isPopup",
            attrs: { type: "button" },
            on: {
              click: function() {
                return _vm.goToView("VerificationData")
              }
            }
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  width: "20",
                  height: "19",
                  viewbox: "0 0 20 19",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                    stroke: "#0058A2",
                    "stroke-width": "3.12307",
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round"
                  }
                })
              ]
            )
          ]
        )
      ]),
      !_vm.isPopup && !_vm.loading
        ? _c(
            "div",
            { staticClass: "withoutShowFinishView" },
            [
              _vm.service != "Centro Médico"
                ? _c(
                    "h2",
                    {
                      staticClass: "TitleSection mt-2 pt-5",
                      staticStyle: { color: "#025190" }
                    },
                    [
                      _vm._v(
                        "Hemos recibido tu solicitud, en las próximas 12 horas hábiles puedes consultar el estado."
                      )
                    ]
                  )
                : _c(
                    "h2",
                    {
                      staticClass: "TitleSection mt-2 pt-5",
                      staticStyle: { color: "#025190" }
                    },
                    [_vm._v("Oficina Virtual")]
                  ),
              _vm.service != "Centro Médico"
                ? _c(
                    "p",
                    {
                      staticClass: "TextSection mt-3 mx-2",
                      staticStyle: { color: "#025190" }
                    },
                    [
                      _vm._v(
                        "Si prefieres, puedes agendar una videollamada con uno de nuestros asesores "
                      )
                    ]
                  )
                : _c(
                    "p",
                    {
                      staticClass: "TextSection mt-3 mx-2",
                      staticStyle: { color: "#025190" }
                    },
                    [
                      _vm._v(
                        "Ahora puedes ahorrar tiempo agendando tu videollamada con un asesor"
                      )
                    ]
                  ),
              _vm._m(0),
              _c("ul", { staticClass: "mt-2 text-center" }, [
                _c(
                  "li",
                  {
                    staticClass: "CardVicon grayTextDesc",
                    staticStyle: { "list-style": "none" }
                  },
                  [
                    _vm.branchInfo
                      ? _c("div", { staticClass: "Vicon flex" }, [
                          _c("p", { staticClass: "ml-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.splittedMessage(
                                  _vm.branchInfo.activeBranchMessage
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                )
              ]),
              _c(
                "b-button",
                {
                  staticClass: "w-100 my-2 mt-3 py-2",
                  attrs: { variant: "bluecolmedica" },
                  on: {
                    click: function() {
                      return _vm.goToView("ToSchedule")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-md" }, [
                    _vm._v("Agendar Videollamada")
                  ])
                ]
              ),
              _c(
                "b-button",
                {
                  staticClass: "text-center w-100 py-2 mx-1 mb-1",
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: {
                    click: function() {
                      return _vm.goToView("MeetingMeToGetTurn")
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-md" }, [
                    _vm._v("Toma un turno y espera un asesor")
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "botton-actions-footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-2 d-block text-center py-2 mb-4 w-100",
                      staticStyle: {
                        "background-color": "#ff0304",
                        border: "none"
                      },
                      attrs: { variant: "bluecolmedica" },
                      on: { click: _vm.onFinish }
                    },
                    [
                      _c("span", { staticClass: "font-md" }, [
                        _vm._v("Finalizar")
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : !_vm.isPopup
        ? _c(
            "h5",
            {
              staticClass: "text-center text-middle color-colmedica-gray mt-5"
            },
            [
              _vm._v("Cargando..."),
              _c("div", { staticClass: "flexMiddle" }, [_c("Spinner")], 1)
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "fakeElementToScroll" }),
      _vm.isPopup
        ? _c("div", { staticClass: "text-center" }, [
            _c(
              "h2",
              {
                staticClass: "TitleSection pl-0 pt-5 pb-4",
                attrs: { id: "headerCalendarTitle2" }
              },
              [_vm._v("¡Información!")]
            ),
            _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
              _vm._v(
                "Este canal es sólo para solicitudes de servicios médicos de medicina prepagada."
              )
            ]),
            _vm._m(1),
            _c(
              "div",
              {
                staticClass: "botton-actions-footer",
                staticStyle: { "margin-bottom": "500px !important" }
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between mb-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "w-100 py-2 text-center mx-1",
                        attrs: {
                          variant: "bluecolmedica",
                          size: "sm",
                          type: "button"
                        },
                        on: { click: _vm.handleUnderstand }
                      },
                      [
                        _c("span", { staticClass: "font-md" }, [
                          _vm._v("Entendido")
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _vm._m(2)
              ]
            ),
            _c("p")
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-4 text-center grayTextDesc" }, [
      _c("strong", [_vm._v("Nuestro horario de videollamada es de:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
      _vm._v(" Si requieres servicios de Aliansalud EPS, como"),
      _c("strong", { staticClass: "ml-1" }, [
        _vm._v("Medicamentos ambulatorios")
      ]),
      _vm._v(", conoce cómo en el botón 'Más información'")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "TextSection pt-4 mb-0 mx-3 color-dark",
        staticStyle: { color: "#226DB1", "text-decoration": "underline" },
        attrs: {
          href:
            "https://www.aliansalud.com.co/Paginas/Canales-de-atencion.aspx",
          target: "_blank"
        }
      },
      [_c("strong", [_vm._v("Más información")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }