var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _vm.isntIntersection && !_vm.isCorrect
        ? _c("div", { staticClass: "headerFixed" }, [
            !_vm.isCorrect
              ? _c(
                  "button",
                  {
                    staticClass: "button-without-styles py-3 px-4",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goToView({ view: "Autenticate" })
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#ffff",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "button-without-styles py-3 px-4",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.isCorrect = !_vm.isCorrect
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#ffff",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                ),
            _vm.isCorrect
              ? _c(
                  "h2",
                  { staticClass: "TitleSection text-start color-white pt-2" },
                  [_vm._v("¿De dónde es tu orden médica?")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.isPopup
        ? _c("div", { staticClass: "Page" }, [
            _c(
              "div",
              {
                staticClass: "contain VerificationDataPage",
                staticStyle: { "padding-bottom": "275px !important" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center"
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "28px" },
                      attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                      on: { click: _vm.onGoPopup }
                    }),
                    _c("Timer", {
                      attrs: {
                        containerImageDocs: _vm.containerImageDocs,
                        timer: _vm.timer,
                        showProgressBar: _vm.showProgressBar
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "h2",
                  {
                    staticClass: "Title text-center pt-1 pb-3",
                    attrs: { id: "headerCalendarTitle2" }
                  },
                  [_vm._v("¡Información!")]
                ),
                _c(
                  "p",
                  {
                    staticClass: "Subtitle mt-3 text-center specialText mb-0",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "En este canal sólo se autorizan servicios médicos cubiertos por tu plan de medicina prepagada."
                    )
                  ]
                ),
                _vm._m(0),
                _c(
                  "div",
                  {
                    staticClass: "botton-actions-footer",
                    staticStyle: { bottom: "-180px" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between mb-3" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "text-center buttonDK mt-0",
                            attrs: {
                              variant: "bluecolmedica",
                              size: "lg",
                              type: "button"
                            },
                            on: { click: _vm.handleUnderstand }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "buttonText m-0 p-0",
                                staticStyle: { "font-size": "34px" }
                              },
                              [_vm._v("Entendido")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ]
            )
          ])
        : !_vm.isCorrect
        ? _c("div", { staticClass: "Page", staticStyle: { bottom: "300px" } }, [
            _c("div", { staticClass: "contain VerificationDataPage" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between align-items-center"
                },
                [
                  _c("img", {
                    staticStyle: { width: "28px" },
                    attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                    on: {
                      click: function($event) {
                        return _vm.goToView({ view: "Autenticate" })
                      }
                    }
                  }),
                  _c("Timer", {
                    attrs: {
                      containerImageDocs: _vm.containerImageDocs,
                      timer: _vm.timer,
                      showProgressBar: _vm.showProgressBar
                    }
                  })
                ],
                1
              ),
              _vm._m(1),
              _c(
                "p",
                {
                  staticClass: "TextSection mt-2 mb-5 mx-3 color-dark Subtitle",
                  staticStyle: { "line-height": "35px" }
                },
                [
                  _vm._v(
                    "Confirma la información del usuario que requiere la autorización"
                  )
                ]
              ),
              _c("div", { staticClass: "form-data mb-3 mt-4 p-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c("p", { staticClass: "buttonText mb-0" }, [
                      _vm._v("Documento:")
                    ]),
                    _c("p", { staticClass: "mb-0 ml-1 buttonText" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.selectedOption))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-start boxOfText flex-wrap"
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "buttonText mb-0 text-break-white mr-1" },
                      [_vm._v("Número de documento:")]
                    ),
                    _c("p", { staticClass: "mb-0 buttonText" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.docNumber))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c("p", { staticClass: "buttonText mb-0" }, [
                      _vm._v("Nombre:")
                    ]),
                    _c("p", { staticClass: "mb-0 ml-1 buttonText" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.name))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c(
                      "p",
                      { staticClass: "buttonText mb-0 text-break-white" },
                      [_vm._v("Celular:")]
                    ),
                    _c("p", { staticClass: "mb-0 ml-1 buttonText" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.phone))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-start boxOfText",
                    staticStyle: { "flex-wrap": "wrap" }
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "buttonText mb-0 text-break-white" },
                      [_vm._v("Correo electrónico:")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "mb-0 d-flex flex-wrap ml-1 buttonText",
                        staticStyle: {
                          "justify-content": "center",
                          width: "100%"
                        }
                      },
                      [_c("strong", [_vm._v(_vm._s(_vm.email))])]
                    )
                  ]
                ),
                _vm._m(2)
              ]),
              _c(
                "div",
                { staticClass: "py-4" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center buttonDK mt-0",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "lg",
                        type: "button"
                      },
                      on: { click: _vm.handleContinue }
                    },
                    [
                      _c("span", { staticClass: "buttonText" }, [
                        _vm._v("Continuar con estos datos")
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : _c("div", { staticClass: "Page" }, [
            _c(
              "div",
              { staticClass: "contain containNew VerificationDataPage" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center"
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "28px" },
                      attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                      on: {
                        click: function($event) {
                          _vm.isPopup = true
                        }
                      }
                    }),
                    _c("Timer", {
                      attrs: {
                        containerImageDocs: _vm.containerImageDocs,
                        timer: _vm.timer,
                        showProgressBar: _vm.showProgressBar
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "h2",
                  {
                    staticClass: "Title text-start pt-1 ml-2",
                    attrs: { id: "headerCalendarTitle2" }
                  },
                  [_vm._v("¿De dónde es tu orden médica?")]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "Subtitle mt-3 text-start specialText mb-0 mr-3"
                  },
                  [
                    _vm._v(
                      "La orden médica a autorizar te la entregó un médico de:"
                    )
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "py-4 containerStacks" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "text-center buttonDK my-4 d-flex justify-content-between align-items-center",
                        staticStyle: { width: "500px", "max-width": "100%" },
                        attrs: {
                          variant: "bluecolmedica",
                          size: "lg",
                          type: "button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.loadData("Centro Médico")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "buttonText" }, [
                          _vm._v("Centro Médico Colmédica")
                        ]),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "11",
                              height: "18",
                              viewbox: "0 0 11 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M2 2L8.99954 8.99954",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2 15.9688L8.99954 8.96921",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "my-4 text-center buttonDK d-flex justify-content-between align-items-center",
                        staticStyle: { width: "500px", "max-width": "100%" },
                        attrs: {
                          variant: "bluecolmedica",
                          size: "lg",
                          type: "button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.loadData(
                              "Profesional de la red",
                              "files"
                            )
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "buttonText" }, [
                          _vm._v("Profesional de la red")
                        ]),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "11",
                              height: "18",
                              viewbox: "0 0 11 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M2 2L8.99954 8.99954",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2 15.9688L8.99954 8.96921",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "text-center buttonDK my-4 d-flex justify-content-between align-items-center",
                        staticStyle: { width: "500px", "max-width": "100%" },
                        attrs: {
                          variant: "bluecolmedica",
                          size: "lg",
                          type: "button"
                        },
                        on: {
                          click: function($event) {
                            return _vm.loadData("Clínica", "files")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "buttonText" }, [
                          _vm._v("Clínica")
                        ]),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "11",
                              height: "18",
                              viewbox: "0 0 11 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M2 2L8.99954 8.99954",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2 15.9688L8.99954 8.96921",
                                stroke: "#FFF",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass: "Subtitle my-4 text-center specialText mb-0",
        staticStyle: { "font-size": "32px" }
      },
      [
        _vm._v(
          "Para realizar la solicitud de medicamentos ambulatorios mediante Aliansalud EPS debes dirigirte a las"
        ),
        _c("strong", { staticClass: "ml-1" }, [_vm._v("oficinas de atención")]),
        _vm._v(", encuentra la más cercana en: www.aliansalud.com.co")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center d-block" }, [
      _c("img", {
        staticClass: "infoIcon",
        attrs: {
          src: require("@/assets/kiosco46/infoIcon.svg"),
          alt: "infoIcon"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-5 d-block" }, [
      _c("strong", { staticClass: "buttonText" }, [
        _vm._v("Enviaremos la autorización al correo señalado.")
      ]),
      _c("span", { staticClass: "ml-1 buttonText" }, [
        _vm._v(
          "Si requieres actualizarlo, no olvides informarselo a tu agente Colmédica antes de finalizar la videollamada."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }