var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("h4", [
    _vm._v(
      "No fue posible cargar sus variables de entorno. La aplicación se detiene."
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }