var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Payment" },
    [
      _c(
        "h3",
        { staticClass: "fonterH3 w-100 mt-5 pt-3 mx-auto d-block text-center" },
        [_vm._v("!Hola!")]
      ),
      _c("p", { staticClass: "textHero SelectText m-0 mx-auto d-block mt-4" }, [
        _vm._v("Selecciona la forma en que deseas pagar tu cita")
      ]),
      _c(
        "div",
        {
          staticClass: "cards d-flex align-item-center mx-auto mt-5",
          class:
            !_vm.isPaymentDatafono && !_vm.urlExist && !_vm.isPaymentAgentEnable
              ? ""
              : _vm.isOneElement
              ? "justify-content-center"
              : _vm.areTwoElement
              ? "justify-content-between isPaymentDatafono"
              : "justify-content-between"
        },
        [
          _vm.urlExist
            ? _c("div", { staticClass: "cardPayment" }, [
                _c("p", { staticClass: "text-card" }, [
                  _vm._v("Paga en línea escaneando este código QR")
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.qrLoading,
                        expression: "!qrLoading"
                      }
                    ],
                    staticClass: "position-relative"
                  },
                  [
                    _c("img", {
                      staticClass: "border-green mx-auto d-block mt-5 pt-2",
                      attrs: {
                        src: require("@/assets/atrilFSFB/border-green.png"),
                        alt: "border-green"
                      }
                    }),
                    _c("div", {
                      staticClass: "qrCode",
                      attrs: { id: "qrCode" }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.qrLoading,
                        expression: "qrLoading"
                      }
                    ],
                    staticClass: "position-relative"
                  },
                  [
                    _c("Loading", {
                      staticClass: "border-green mx-auto d-block mt-5 pt-2"
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm.isPaymentDatafono
            ? _c("div", { staticClass: "cardPayment" }, [
                _c("p", { staticClass: "text-card-others" }, [
                  _vm._v(
                    "Realiza tu pago por medio de nuestro módulo de datafono"
                  )
                ]),
                _c("img", {
                  staticClass: "payment_datafono",
                  attrs: {
                    src: require("@/assets/atrilFSFB/payment_datafono.png"),
                    alt: "payment_datafono"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "text-center buttonService",
                    on: { click: _vm.openTerminalPay }
                  },
                  [_c("span", [_vm._v("Continuar")])]
                )
              ])
            : _vm._e(),
          _vm.isPaymentAgentEnable
            ? _c("div", { staticClass: "cardPayment" }, [
                _c("p", { staticClass: "text-card-others" }, [
                  _vm._v("Realiza tu pago con uno de nuestros asesores")
                ]),
                _c("img", {
                  staticClass: "payment_datafono",
                  attrs: {
                    src: require("@/assets/atrilFSFB/user.png"),
                    alt: "user"
                  }
                }),
                _c(
                  "button",
                  {
                    staticClass: "text-center buttonService",
                    on: { click: _vm.setAppointment }
                  },
                  [_c("span", [_vm._v("Continuar")])]
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "pays-modal-virtualrowFSFB",
            "hide-footer": "",
            "hide-header": "",
            size: "xl",
            centered: "",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c("div", { staticClass: "ContainerModal" }, [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-end pr-3 w-full",
                staticStyle: { "min-height": "62px" }
              },
              [
                !_vm.cancelPay
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-center align-items-center containerTimerC w-full"
                      },
                      [
                        _vm.showCCT
                          ? _c(
                              "div",
                              [
                                _c("circular-count-down-timer", {
                                  attrs: {
                                    "main-circle-id": "circle-2",
                                    "show-value": false,
                                    circles: !_vm.loading
                                      ? _vm.circles
                                      : _vm.circlesLoading
                                  },
                                  on: { update: _vm.onUpdateTimer }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("img", {
                          staticClass: "houseVirtualrowFSFB",
                          attrs: {
                            src: require("../../assets/houseVirtualrowFSFBWhiteGray.svg"),
                            alt: "houseVirtualrowFSFBWhiteGray"
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _c("AreYouThere", {
                  attrs: { showAreYouThereProp: _vm.showAreYouThere },
                  on: { onShowAreYouThereClose: _vm.onShowAreYouThereClose }
                })
              ],
              1
            ),
            _c("div", { staticClass: "d-block text-center mx-auto" }, [
              _c("p", { staticClass: "mt-4 mb-0 fonterCurrentUser" }, [
                _vm._v(
                  _vm._s(
                    _vm.cancelPay
                      ? "Cancelando pago"
                      : _vm.failPay
                      ? "Realizando el pago"
                      : _vm.successPay
                      ? "Para la cita de CONSULTA PRIMERA"
                      : "Realizarás el pago"
                  ) +
                    " de " +
                    _vm._s(
                      _vm.toCurrency(_vm.currentAppointment.cost_appoinment)
                    ) +
                    " a través del datáfono"
                )
              ]),
              !_vm.loading
                ? _c("p", { staticClass: "my-3 fonterCurrentUser" }, [
                    _vm._v(
                      "Para la cita de " +
                        _vm._s(_vm.currentAppointment.attention.detail)
                    )
                  ])
                : _vm._e(),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.failPay,
                      expression: "failPay"
                    }
                  ],
                  staticClass: "mb-3 text-danger fonterCurrentUser"
                },
                [_vm._v("La transacción ha sido declinada o cancelada")]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.successPay,
                      expression: "successPay"
                    }
                  ],
                  staticClass: "mb-3 text-success fonterCurrentUser"
                },
                [
                  _vm._v(
                    "El pago de " +
                      _vm._s(
                        _vm.toCurrency(_vm.currentAppointment.cost_appoinment)
                      ) +
                      " se realizo con éxito"
                  )
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.cancelPay,
                      expression: "cancelPay"
                    }
                  ],
                  staticClass: "mb-3 text-danger fonterCurrentUser"
                },
                [_vm._v("El pago se ha cancelado.")]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.failPay &&
                        !_vm.successPay &&
                        !_vm.cancelPay &&
                        !_vm.inPayment,
                      expression:
                        "!failPay && !successPay && !cancelPay && !inPayment"
                    }
                  ],
                  staticClass: "pb-5 fonterCurrentUser"
                },
                [_vm._v("¿Qué deseas hacer?")]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.successPay,
                      expression: "successPay"
                    }
                  ],
                  staticClass: "pb-2 fonterCurrentUser"
                },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.failPay && !_vm.cancelPay,
                          expression: "!failPay && !cancelPay"
                        }
                      ],
                      staticClass: "buttonServiceModal",
                      staticStyle: {
                        "margin-left": "14%",
                        "margin-top": "20px"
                      },
                      attrs: { id: "download" },
                      on: { click: _vm.printInvice }
                    },
                    [_c("span", [_vm._v("Descargar Comprobante")])]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "buttonServiceModal",
                      staticStyle: {
                        "margin-left": "14%",
                        "margin-top": "20px"
                      },
                      on: { click: _vm.continueAppointment }
                    },
                    [_c("span", [_vm._v("Continuar")])]
                  )
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.failPay,
                      expression: "failPay"
                    }
                  ],
                  staticClass: "pb-5 fonterCurrentUser"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "buttonServiceModal ml-3",
                      staticStyle: {
                        padding: "0",
                        border: "none",
                        background: "none",
                        color: "#3fc9c3"
                      },
                      attrs: { id: "download" },
                      on: { click: _vm.printInvice }
                    },
                    [_vm._v("Descargar Comprobante")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "buttonServiceModal",
                      staticStyle: {
                        "margin-left": "14%",
                        "margin-top": "20px"
                      },
                      on: { click: _vm.onCloseModal }
                    },
                    [_c("span", [_vm._v("Continuar")])]
                  )
                ]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.failPay && _vm.pending,
                      expression: "!failPay && pending"
                    }
                  ],
                  staticClass: "pb-5 fonterCurrentUser"
                },
                [_vm._v("Tarjeta leída, esperando respuesta del datáfono...")]
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.inPayment &&
                        !_vm.failPay && !_vm.successPay && !_vm.cancelPay,
                      expression:
                        "inPayment && (!failPay && !successPay && !cancelPay)"
                    }
                  ],
                  staticClass: "pb-2 pt-5 fonterCurrentUser"
                },
                [
                  _vm._v("Realizando el pago..."),
                  _c("Loading", {
                    staticClass: "pb-5 border-green mx-auto d-block mt-5 pt-2"
                  })
                ],
                1
              ),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.inPayment &&
                        !_vm.failPay && !_vm.successPay && !_vm.cancelPay,
                      expression:
                        "inPayment && (!failPay && !successPay && !cancelPay)"
                    }
                  ],
                  staticClass: "pt-5 pb-5 fonterCurrentUser"
                },
                [
                  _vm._v(" Por favor, "),
                  _c("b", [
                    _vm._v(
                      "presiona la tecla verde del datáfono y sigue las instrucciones"
                    )
                  ]),
                  _vm._v(".")
                ]
              )
            ]),
            _c("div", { staticClass: "d-block containerBox" }, [
              !_vm.loading && !_vm.failPay
                ? _c("div", [
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.failPay && !_vm.successPay && !_vm.cancelPay,
                            expression: "!failPay && !successPay && !cancelPay"
                          }
                        ],
                        staticClass: "buttonServiceModal mb-3",
                        staticStyle: { "font-size": "23px" },
                        on: { click: _vm.makePay }
                      },
                      [_c("span", [_vm._v("Pagar con Datáfono")])]
                    )
                  ])
                : _vm._e(),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.failPay && !_vm.successPay && !_vm.cancelPay,
                      expression: "!failPay && !successPay && !cancelPay"
                    }
                  ],
                  staticClass: "buttonServiceModal canceledbtn",
                  on: { click: _vm.closeModal }
                },
                [_c("span", [_vm._v("Cancelar")])]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }