var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "small-modal-dk",
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": ""
      },
      model: {
        value: _vm.showMe,
        callback: function($$v) {
          _vm.showMe = $$v
        },
        expression: "showMe"
      }
    },
    [
      _c(
        "div",
        { staticClass: "p-5" },
        [
          _c(
            "div",
            { staticClass: "timer-container" },
            [
              _c("TimerComponent", {
                attrs: {
                  timer: _vm.timer,
                  showProgressBar: _vm.showProgressBarAun
                }
              })
            ],
            1
          ),
          _c("h3", { staticClass: "Title text-center" }, [
            _c("strong", { staticClass: "normalFont strong" }, [
              _vm._v("¿Aún estás ahí?")
            ])
          ]),
          _c(
            "b-button",
            {
              staticClass: "text-center AuthsBtn mb-4 mt-5",
              staticStyle: { width: "fit-content" },
              attrs: {
                variant: "bluecolmedica",
                block: "",
                size: "lg",
                type: "button"
              },
              on: { click: _vm.restartTimerWaiting }
            },
            [
              _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                _vm._v("Sí, continuar")
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "pt-3" },
            [_c("BottomTimer", { attrs: { doThis: _vm.goToHome } })],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }