var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "CurrentUser d-flex justify-content-center",
      style:
        _vm.isAtril &&
        "width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 96.38px);"
    },
    [
      _c("div", { staticClass: "Content" }, [
        _vm._m(0),
        !!!_vm.currentUser
          ? _c(
              "form",
              {
                style: _vm.isAtril
                  ? "margin-top: 30px;"
                  : "padding-bottom: 100px;",
                attrs: { id: "validateUser", autocomplete: "off" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.sendData.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "max-width": "347px",
                      margin: "auto",
                      display: "block"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-select-container" },
                      [
                        _c("b-form-select", {
                          staticClass: "Input-text mb-4",
                          attrs: {
                            options: _vm.options,
                            size: "sm",
                            autocomplete: "off",
                            required: ""
                          },
                          model: {
                            value: _vm.models.TipoIdentificacion,
                            callback: function($$v) {
                              _vm.$set(_vm.models, "TipoIdentificacion", $$v)
                            },
                            expression: "models.TipoIdentificacion"
                          }
                        }),
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "17",
                              height: "10",
                              viewbox: "0 0 17 10",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M15.4844 1.48438L8.48483 8.48392",
                                stroke: "#0058A2",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M1.51562 1.48438L8.51517 8.48392",
                                stroke: "#0058A2",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.models.NumeroIdentificacion,
                          expression: "models.NumeroIdentificacion",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "Input-text mt-4",
                      attrs: {
                        type: "number",
                        min: "0",
                        placeholder: "Número",
                        required: "",
                        autocomplete: "off",
                        disabled: _vm.isAtril,
                        id: "cedula_number"
                      },
                      domProps: { value: _vm.models.NumeroIdentificacion },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.models,
                            "NumeroIdentificacion",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        !!_vm.currentUser
          ? _c("div", { staticClass: "pb-5 mt-3" }, [
              _c("div", { staticClass: "pb-5" }, [
                _c("div", { staticClass: "big_box" }, [
                  _c("div", { staticClass: "dk-overlay" }, [
                    _vm.currentUser.Nombres
                      ? _c("p", [_vm._v(_vm._s(_vm.currentUser.Nombres))])
                      : _vm._e(),
                    _c("p", { staticClass: "pb-5" }, [
                      _vm._v(
                        "Documento: " +
                          _vm._s(_vm.currentUser.TipoIdentificacion) +
                          " " +
                          _vm._s(_vm.currentUser.NumeroIdentificacion)
                      )
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e(),
        _vm.isAtril
          ? _c(
              "div",
              { staticClass: "mt-4 mb-2 pt-1" },
              [
                _c("Panel", [
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(1)
                        }
                      }
                    },
                    [_vm._v("1")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(2)
                        }
                      }
                    },
                    [_vm._v("2")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(3)
                        }
                      }
                    },
                    [_vm._v("3")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(4)
                        }
                      }
                    },
                    [_vm._v("4")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(5)
                        }
                      }
                    },
                    [_vm._v("5")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(6)
                        }
                      }
                    },
                    [_vm._v("6")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(7)
                        }
                      }
                    },
                    [_vm._v("7")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(8)
                        }
                      }
                    },
                    [_vm._v("8")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(9)
                        }
                      }
                    },
                    [_vm._v("9")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "item-panel bigbutton item-panel-delete_button",
                      on: {
                        click: function($event) {
                          return _vm.printPanel("<")
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "IconBackDelete",
                        attrs: {
                          src: _vm.IconBackDelete,
                          alt: "IconBackDelete"
                        }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "item-panel bigbutton zeroButton",
                      on: {
                        click: function($event) {
                          return _vm.printPanel(0)
                        }
                      }
                    },
                    [_vm._v("0")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "button-without-styles item-panel bigbutton",
                      class:
                        (!!_vm.charging || !_vm.models.NumeroIdentificacion) &&
                        "disabled",
                      attrs: { disabled: !!_vm.charging },
                      on: { click: _vm.onGoToNextView }
                    },
                    [
                      !!!_vm.charging
                        ? _c("div", [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "19",
                                  height: "13",
                                  viewbox: "0 0 19 13",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M0.109375 5.67969H14.2794L10.6994 2.08969L12.1094 0.679688L18.1094 6.67969L12.1094 12.6797L10.6994 11.2697L14.2794 7.67969H0.109375V5.67969Z",
                                    fill: "#F4F4F4"
                                  }
                                })
                              ]
                            )
                          ])
                        : _c("div", [_c("MiniSpinner")], 1)
                    ]
                  )
                ])
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          !_vm.isAtril
            ? _c(
                "div",
                {
                  staticClass: "d-flex align-items-center",
                  class: !!_vm.currentUser
                    ? "justify-content-center"
                    : "justify-content-end",
                  attrs: { id: "d-flex" }
                },
                [
                  !!!_vm.currentUser
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-center",
                          style: _vm.colorComputed,
                          attrs: {
                            variant: "bluecolmedica",
                            form: "validateUser",
                            size: "sm",
                            type: "submit",
                            disabled: !this.newUserReady || !!_vm.charging
                          }
                        },
                        [
                          !!!_vm.charging
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-sizing-12" },
                                    [_vm._v("Continuar")]
                                  ),
                                  _c("Continue", { attrs: { size: "24px" } })
                                ],
                                1
                              )
                            : _c("div", [_c("MiniSpinner")], 1)
                        ]
                      )
                    : _c(
                        "b-button",
                        {
                          staticClass: "text-center",
                          style: _vm.colorComputed,
                          attrs: {
                            disabled: !!_vm.charging,
                            variant: "bluecolmedica",
                            size: "sm",
                            type: "button"
                          },
                          on: { click: _vm.cleanCurrentUser }
                        },
                        [
                          _c("span", { staticClass: "text-sizing-12" }, [
                            _vm._v("Continuar con otro usuario")
                          ]),
                          _c("Continue", { attrs: { size: "24px" } })
                        ],
                        1
                      )
                ],
                1
              )
            : _c(
                "div",
                {
                  staticClass: "d-flex align-items-start",
                  attrs: { id: "d-flex" }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center",
                      style: _vm.colorComputed,
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "submit"
                      },
                      on: { click: _vm.goBack }
                    },
                    [
                      _c("Atras", { attrs: { size: "24px" } }),
                      _c("span", { staticClass: "text-sizing-12" }, [
                        _vm._v("Atrás")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "box-dk mt-4 mb-5 pt-2 text-center with-mx" },
      [
        _c("h3", { staticClass: "fonterH3Colmedica mx-auto d-block" }, [
          _vm._v("Ingreso de datos")
        ]),
        _c(
          "p",
          { staticClass: "textHeroColmedica mb-2 m-0 mx-auto d-block mt-2" },
          [
            _vm._v(
              "Digita los datos de la persona que va a acceder al servicio"
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }