var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Home" },
    [
      _vm.preload || _vm.preloadPayments ? _c("LoadingFSFB") : _vm._e(),
      _vm.mode === "SelectZone"
        ? _c("SelectZone", { on: { changeMode: _vm.changeMode } })
        : _vm._e(),
      _vm.mode === "Services"
        ? _c("Services", { on: { changeMode: _vm.changeMode } })
        : _vm._e(),
      _c("Login", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.mode === "Login",
            expression: "mode === 'Login'"
          }
        ],
        ref: "loginComponent",
        on: { modeBack: _vm.modeBack, changeMode: _vm.changeMode }
      }),
      _vm.mode === "CurrentUser" ? _c("CurrentUser") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }