var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Alerted" },
    [
      _c("Header"),
      _c("div", { staticClass: "withoutShowFinishView" }, [
        _c("h2", { staticClass: "TitleSection mt-4 pt-3" }, [
          _vm._v(_vm._s(_vm.selectTitle))
        ]),
        _c("p", { staticClass: "TextSection mt-3 mx-2" }, [
          _vm._v(
            "No hay problema, puedes elegir entre las siguientes opciones:"
          )
        ]),
        _c(
          "button",
          {
            staticClass: "w-100 button-without-styles my-2 mt-5",
            on: {
              click: function() {
                return _vm.goToView("IndexSchedules")
              }
            }
          },
          [
            _c(
              "stack-item",
              { staticClass: "btn-block align-self-center mx-auto" },
              [_vm._v("Tomar un nuevo turno")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "fakeElementToScroll" })
      ]),
      _c(
        "div",
        { staticClass: "buttons-actions" },
        [
          _c(
            "b-button",
            {
              staticClass: "buttonBack mx-auto d-block mb-2",
              attrs: { variant: "whitecolmedica" },
              on: {
                click: function() {
                  return _vm.goToView("Home")
                }
              }
            },
            [_vm._v("Ya puedes cerrar esta ventana")]
          ),
          _c(
            "a",
            {
              staticClass: "mx-2 Link mt-2 pt-2 text-center d-block",
              on: {
                click: function() {
                  return _vm.goToView("ToSchedule")
                }
              }
            },
            [_vm._v("Ver videollamadas programadas")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }