import LS from "../../helpers/localStorage";

export default {
  view: "",
  isAtril: false,
  previousView: "",
  previousViewTwo: "",
  showAuth: false,
  HorarioOutContinue: false,
  charging: false,
  error: null,
  tramite_info: {
    queueId: "idSASASASXX2C12",
    branchId: "5a46d454da6a6",
    params: {}
  },
  tramiteInUserEvaluation: null,
  tramiteByDefault: null,
  validationErrors: [],
  errorValidationTemporizer: null,
  currentUser: LS.getItem("currentUser") || null,
  servicioSelected: null,
  selectedAccion: null,
  turn: null,
  time_received_turn: undefined,
  catch_all: null,
  priority: false,
  history: [],
  menu: [],
  showModalError: false,
  actions: null,
  tipide: [
    { value: "AS", text: "Adulto Sin Identificación" },
    { value: "CC", text: "Cédula de Ciudadanía" },
    { value: "CD", text: "Carné Diplomático" },
    { value: "CE", text: "Cedula de Extranjería" },
    { value: "CN", text: "Certificado de Nacido Vivo" },
    { value: "CO", text: "Código" },
    { value: "MS", text: "Menor Sin Identificación" },
    { value: "NI", text: "N.I.T." },
    { value: "PA", text: "Pasaporte" },
    { value: "PE", text: "Permanencia Especial" },
    { value: "PT", text: "Permiso Protección Temporal" },
    { value: "RC", text: "Registro Civil" },
    { value: "RU", text: "Reg. Único de Contribuyentes." },
    { value: "SC", text: "Salvoconducto De Permanencia" },
    { value: "TE", text: "Tarjeta de Extranjería" },
    { value: "TI", text: "Tarjeta Identidad" },
    { value: "PT", text: "Permiso temporal de permanencia" }
  ],
  citas: [
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: false,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    }
  ],
  citaActual: null,
  currentValidation: [],
  currentSite: null,
  userDemo: [
    {
      NumeroIdentificacion: "456"
    },
    {
      NumeroIdentificacion: "789"
    }
  ],
  queueList: [],
  customArea: null,
  sites: [],
  primaryColorBlue: null,
  secondColorBlue: null,
  threeColorBlueColor: null,
  userActionDate: Date.now(),
};
