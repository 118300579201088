import { store } from "../store";
let clickCounter = 0;

export default {
  bind(el) {
    el.addEventListener("click", () => {
      if (++clickCounter >= 3) {
        store.dispatch("log/showModal", true);
        clickCounter = 0;
      }
    });
  },
  unbind(el) {
    el.removeEventListener("click", () => {});
  }
};
