export default {
  bad: [
    {
      IdPaso: -1,
      DescripcionPaso:
        "Su cita no tiene un pago asociado. Por favor espere ser llamado en la recepción, para confirmar los datos.",
      Orden: 1,
      Identificacion: "CC789"
    },
    {
      IdPaso: -1,
      DescripcionPaso:
        "Ya ha pasado la hora de la cita, por favor agende una nueva cita",
      Orden: 2,
      Identificacion: "CC789"
    }
  ],
  good: [
    {
      IdPaso: 1,
      DescripcionPaso:
        "Su registro ha sido exitoso, por favor diríjase al PISO XXX SALA YYY CONSULTORIO N y espere el llamado del profesional para su cita de ESPECIALIDAD",
      Orden: 1,
      Identificacion: "CC789"
    },
    {
      IdPaso: 2,
      DescripcionPaso:
        "Su soporte de pago será enviado al correo electrónico registrado",
      Orden: 2,
      Identificacion: "CC789"
    }
  ]
};
