var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "turnAssignerArea" }, [
    _c(
      "div",
      { staticClass: "container-logo" },
      [
        _c("img", {
          directives: [{ name: "openlog", rawName: "v-openlog" }],
          staticClass: "stylelogo d-block",
          attrs: { src: _vm.Logo }
        }),
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          !_vm.turn
            ? _c(
                "h5",
                {
                  staticStyle: {
                    position: "absolute",
                    "margin-top": "30vh",
                    "text-align": "center",
                    color: "firebrick"
                  }
                },
                [_vm._v("Cargando...")]
              )
            : _c(
                "h5",
                {
                  staticStyle: {
                    position: "absolute",
                    "margin-top": "30vh",
                    "text-align": "center",
                    color: "firebrick"
                  }
                },
                [_vm._v("Turno cargado. Redireccionando.")]
              )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }