var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "CurrentUser Container",
      style: _vm.isAtril
        ? "color: #545454; max-width: 1012px; margin: auto; height: calc(100vh - 173px);"
        : "color: #545454; font-size: 1.3rem;"
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c("div", { staticClass: "text-center mb-5" }, [
          _c(
            "svg",
            {
              attrs: {
                width: "32",
                height: "32",
                viewbox: "0 0 32 32",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d:
                    "M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -1.57992e-06 16 -2.35244e-06C7.16345 -3.12495e-06 1.21761e-06 7.16344 4.4509e-07 16C0.00937469 24.8326 7.16733 31.9906 16 32Z",
                  fill: "#3290E0"
                }
              }),
              _c("path", {
                attrs: {
                  d:
                    "M15.9987 24.9844C17.1446 24.9844 18.0117 24.3863 18.0117 23.4204L18.0117 14.5604C18.0117 13.5934 17.1448 12.9974 15.9987 12.9974C14.8807 12.9974 13.9856 13.6184 13.9856 14.5604L13.9856 23.4204C13.9857 24.3634 14.8807 24.9844 15.9987 24.9844ZM15.9987 11.0013C17.0997 11.0013 17.9957 10.1053 17.9957 9.00339C17.9957 7.90341 17.0997 7.00735 15.9987 7.00735C14.8977 7.00735 14.0026 7.90341 14.0026 9.00339C14.0027 10.1054 14.8977 11.0013 15.9987 11.0013Z",
                  fill: "#F4F4F4"
                }
              })
            ]
          ),
          _c(
            "p",
            {
              staticClass:
                "text-success-local text-center d-block mx-auto mb-4 fonter"
            },
            [
              _c("strong", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.currentUser.Nombres || "El dolcumento"))
              ]),
              _vm._v(
                "no tiene un plan activo. Puedes continuar con estos datos, o reingresar nuevos datos"
              )
            ]
          )
        ]),
        _c("div", { staticClass: "boxInformative pb-5" }, [
          _c("p", [
            _c("span", [_vm._v("Nombre:")]),
            _c("strong", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.currentUser.Nombres || "No registra"))
            ])
          ]),
          _c("p", { staticClass: "pb-3" }, [
            _c("span", [_vm._v("Documento:")]),
            _c("strong", { staticClass: "ml-1" }, [
              _vm._v(
                _vm._s(_vm.currentUser.NumeroIdentificacion || "No registra")
              )
            ])
          ]),
          _c(
            "button",
            {
              staticClass: "text-center buttonServiceContinue mt-5",
              on: { click: _vm.goNoDate }
            },
            [_c("span", [_vm._v("Continuar")])]
          )
        ]),
        _c(
          "button",
          {
            staticClass: "text-center outlinedServiceButtonColmedica mt-5 py-2",
            on: { click: _vm.cleanAndBack }
          },
          [_c("span", { staticClass: "my-1" }, [_vm._v("Reingresar datos")])]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }