var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Home Container2 align-items-flex-start",
      staticStyle: {
        color: "#545454",
        "max-width": "1012px",
        margin: "auto",
        width: "100%"
      }
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c(
          "div",
          {
            staticClass: "d-flex pt-2",
            staticStyle: {
              "flex-direction": "column",
              "align-items": "center",
              height: "100%"
            }
          },
          [
            _c("article", { staticStyle: { width: "100%" } }, [
              _c(
                "div",
                {
                  staticClass: "boxTextHeader",
                  class: _vm.isCenter ? "without-mx" : "with-mx"
                },
                [
                  _c("h3", { staticClass: "fonterH3Colmedica" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isCenter
                          ? "Bienvenido a " + _vm.siteName + " "
                          : "Hola, bienvenido"
                      )
                    )
                  ]),
                  _c("p", { staticClass: "textHeroColmedica mt-1" }, [
                    _vm._v(
                      "Puedes tomar tu turno escaneando este código QR con tu celular"
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex containerBoxes align-items-center justify-content-center"
                },
                [
                  _c("div", { staticClass: "box" }, [
                    _c("div", { staticClass: "containerQr" }, [
                      _c("div", { staticClass: "canvas_bordered" }, [
                        _c(
                          "svg",
                          {
                            staticClass: "svg",
                            attrs: {
                              width: "220",
                              height: "203",
                              viewbox: "0 0 220 203",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M1 19.0213V1.54688H17.3823",
                                stroke: "#3290E0",
                                "stroke-width": "1.09215"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M218.885 184.479L218.885 201.953L202.503 201.953",
                                stroke: "#3290E0",
                                "stroke-width": "1.09215"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M201.41 0.999999L218.885 1L218.885 17.3823",
                                stroke: "#3290E0",
                                "stroke-width": "1.09215"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M18.4744 201.406L1 201.406L1 185.024",
                                stroke: "#3290E0",
                                "stroke-width": "1.09215"
                              }
                            })
                          ]
                        ),
                        _vm._m(0)
                      ])
                    ])
                  ])
                ]
              ),
              _c("p", { staticClass: "qrDescription mt-3 pt-1" }, [
                _vm._v("O presiona continuar para tomar un turno impreso")
              ]),
              _c(
                "button",
                {
                  staticClass: "button-without-styles buttonContinue mt-4",
                  staticStyle: { display: "block", margin: "auto" },
                  on: { click: _vm.goCurrent }
                },
                [_vm._v("Continuar")]
              )
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "Footer" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "canvas_container" }, [
      _c("div", { attrs: { id: "qr" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }