var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "NoDate Container",
      style: _vm.isAtril
        ? "color: #545454; max-width: 1800px; width: 100%; margin: auto; height: calc(100vh - 96.38px); min-height: 600px;"
        : "margin: auto; width: 100%; font-size: 1.3rem;"
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c(
          "h3",
          { staticClass: "fonterH3Colmedica fonterH3ColmedicaNoDate mb-4" },
          [_vm._v("¿Cuál es el motivo de tu asistencia?")]
        ),
        _c(
          "div",
          {
            staticClass: "buttons-dk",
            style: _vm.isAtril
              ? "margin-top: 80px; max-width: 400px; margin: auto;"
              : "margin: 30px auto;"
          },
          [
            _vm._l(_vm.actionToShow, function(act) {
              return [
                !!!act.hidden
                  ? _c(
                      "button",
                      {
                        key: act.actionCode,
                        staticClass:
                          "arrowButton mb-3 p-4 d-flex justify-content-between align-items-center",
                        attrs: { disabled: !!act.disabled, block: "" },
                        on: {
                          click: function($event) {
                            return _vm.calculateAction(act)
                          }
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "text-center",
                          domProps: { innerHTML: _vm._s(act.label) }
                        }),
                        _c(
                          "svg",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              width: "12",
                              height: "20",
                              viewbox: "0 0 12 20",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M2.26562 2.125L10.3343 9.99949",
                                stroke: "#C9C9C9",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M2.26562 17.8359L10.3343 9.96145",
                                stroke: "#C9C9C9",
                                "stroke-width": "2.53762",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  style: _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.BackCalculed }
                },
                [
                  _c("Back", { attrs: { size: "24px" } }),
                  _c("span", { staticClass: "text-sizing-12" }, [
                    _vm._v("Atrás")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }