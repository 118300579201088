var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.isFvVideo ? "lds-ring--fv" : "lds-ring" }, [
    _c("div"),
    _c("div"),
    _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }