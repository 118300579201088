var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recorder" }, [
    !_vm.recording
      ? _c(
          "button",
          { staticClass: "btn btn-info", on: { click: _vm.startHandler } },
          [_vm._v("Empezar a Grabar")]
        )
      : _c("button", { staticClass: "btn btn-info", on: { click: _vm.end } }, [
          _vm._v("Detener")
        ]),
    _c("video", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.recording,
          expression: "recording"
        }
      ],
      ref: "video",
      staticClass: "videoRecorder"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }