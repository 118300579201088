var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _c("div", {
        staticClass: "Overlay",
        staticStyle: { display: "none" },
        attrs: { id: "OverlayDocument" }
      }),
      _c("div", { staticClass: "ModalContent", attrs: { id: "AddDocument" } }, [
        _c(
          "div",
          {
            staticClass: "position-relative w-auto",
            staticStyle: { height: "94%" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "position-relative pt-4 d-flex justify-content-between pb-2"
              },
              [
                !_vm.modeCropper
                  ? _c(
                      "button",
                      {
                        staticClass: "button-without-styles ml-4",
                        on: { click: _vm.onDiscard }
                      },
                      [
                        _c("img", {
                          staticClass: "cancelIcon",
                          attrs: {
                            src: require("@/assets/kiosco46/cancel_white.svg"),
                            alt: "cancel"
                          },
                          on: {
                            click: function($event) {
                              _vm.showModalScan = false
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _c("span")
              ]
            ),
            _c("div", { staticClass: "d-block text-center Background" }, [
              _c("div", { staticClass: "tapeControl" }),
              _vm.holdIsImagen
                ? _c(
                    "figure",
                    {
                      staticClass: "m-0 containerImage mx-auto block pt-5 mt-4"
                    },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.cropper,
                            expression: "cropper"
                          }
                        ],
                        ref: "fileImage",
                        staticClass:
                          "object-fit-cover w-100 h-100 p-16 imageModal",
                        attrs: {
                          src: _vm.urlData,
                          alt: "imageUser",
                          id: "generalImage"
                        }
                      })
                    ]
                  )
                : _vm._e(),
              !_vm.holdIsImagen
                ? _c("div", { staticClass: "pt-4 mb-4 TextSection" }, [
                    _vm._v("Sin vista previa del documento")
                  ])
                : _vm._e(),
              !_vm.holdIsImagen
                ? _c(
                    "div",
                    [
                      !_vm.modeCropper
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mb-4",
                              attrs: { variant: "bluecolmedica", size: "sm" },
                              on: {
                                click: function($event) {
                                  return _vm.addDocument()
                                }
                              }
                            },
                            [_vm._v("Usar imagen")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.holdIsImagen
                ? _c("div", { staticClass: "zone-button" }, [
                    _c("div", { staticClass: "mx-5" }, [
                      _vm.compressImagen == false
                        ? _c(
                            "div",
                            [
                              !_vm.modeCropper || _vm.holdLoading
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "p-2 d-flex justify-content-around w-100 mt-3 mb-0"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circleCard mr-3 d-flex text-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function() {
                                                  return _vm.onRotation(-90)
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "iconCrop",
                                                attrs: {
                                                  src: require("@/assets/kiosco46/left.svg"),
                                                  alt: "left"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "textCard mt-1" },
                                            [_vm._v("Izquierda")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circleCard ml-2 d-flex text-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function() {
                                                  return _vm.onRotation(90)
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "iconCrop",
                                                attrs: {
                                                  src: require("@/assets/kiosco46/right.svg"),
                                                  alt: "right"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "textCard mt-1" },
                                            [_vm._v("Derecha")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circleCard ml-4 d-flex text-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function() {
                                                  return _vm.activateCropper(
                                                    true
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "iconCrop",
                                                attrs: {
                                                  src: require("@/assets/kiosco46/crop.svg"),
                                                  alt: "crop"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "textCard mt-1" },
                                            [_vm._v("Recortar")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.modeCropper
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass:
                                        "text-center AuthsBtn mt-4 mb-5",
                                      staticStyle: { width: "fit-content" },
                                      attrs: {
                                        variant: "bluecolmedica",
                                        block: "",
                                        size: "lg",
                                        type: "button"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.addDocument()
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "buttonText AuthMeds mb-0"
                                        },
                                        [_vm._v("Usar imagen")]
                                      )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "p-2 d-flex justify-content-around w-100 mt-3 mb-1"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circleCard mr-3 d-flex text-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onCancel()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "iconCrop",
                                                attrs: {
                                                  src: require("@/assets/kiosco46/cancel_2.svg"),
                                                  alt: "cancel_2"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "textCard mt-1" },
                                            [_vm._v("Cancelar")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "circleCard ml-2 d-flex text-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.onAccept()
                                                }
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "iconCrop",
                                                attrs: {
                                                  src: require("@/assets/kiosco46/accept.svg"),
                                                  alt: "accept"
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "p",
                                            { staticClass: "textCard mt-1" },
                                            [_vm._v("Aceptar")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm.addDocumentShow
          ? _c(
              "div",
              { staticClass: "pb-4 mx-5" },
              [
                _c("BottomTimer", {
                  attrs: {
                    doThis: _vm.onShowAreYouThere,
                    $element: "#AddDocument",
                    whiteC: true,
                    limit: 40
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.showAreYouThere
        ? _c(
            "div",
            [
              _c("AreYouThere", {
                on: { restartAreYouThere: _vm.restartTimerWaiting }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "Page" },
        [
          _c(
            "div",
            {
              staticClass: "contain UploadFilesPage",
              class: _vm.documents && _vm.documents.length && "down"
            },
            [
              _c("div", { staticClass: "containerPage" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center"
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "28px" },
                      attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                      on: { click: _vm.backView }
                    }),
                    _c("Timer", {
                      attrs: {
                        containerImageDocs: _vm.containerImageDocs,
                        timer_anim: 40,
                        timer: _vm.timer,
                        showProgressBar: _vm.showProgressBar
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "h2",
                  {
                    staticClass: "TitleSection text-start pl-0 ml-0 pt-1",
                    attrs: { id: "headerCalendarTitle" }
                  },
                  [_vm._v("Adjunta documentos")]
                ),
                _vm._m(0)
              ]),
              _vm.documents.length
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "glider-contain documents-zone" },
                      [
                        _c(
                          "vue-slick-carousel",
                          {
                            attrs: {
                              list: _vm.convertDataMapped,
                              key2: _vm.key2
                            }
                          },
                          _vm._l(_vm.convertDataMapped, function(snap, key2) {
                            return _c(
                              "div",
                              {
                                key: "col_" + key2,
                                staticClass: "slick-item",
                                class:
                                  _vm.convertDataMapped.length === 1 &&
                                  "centeredUniqElement",
                                attrs: { id: key2 + 1 }
                              },
                              [
                                _c("div", {
                                  staticClass: "tapDisabled",
                                  attrs: { id: "tapDisabled" }
                                }),
                                _c("i", {
                                  staticClass: "icon fa fa-times-circle",
                                  on: {
                                    click: function() {
                                      return _vm.handlerDeleteDoc(key2)
                                    }
                                  }
                                }),
                                snap.isImg
                                  ? _c("img", {
                                      staticClass: "snapshot",
                                      attrs: { src: snap.file },
                                      on: {
                                        click: function($event) {
                                          return _vm.showImageInModal(snap)
                                        }
                                      }
                                    })
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "snapshot",
                                        on: {
                                          click: function($event) {
                                            return _vm.showImageInModal(snap)
                                          }
                                        }
                                      },
                                      [
                                        _c("pdf", { attrs: { src: snap.file } })
                                      ],
                                      1
                                    )
                              ]
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center buttonDK mt-0",
                      attrs: {
                        disabled: _vm.documents.length >= 20,
                        variant: "bluecolmedica",
                        size: "lg",
                        type: "button"
                      },
                      on: { click: _vm.onShowModal }
                    },
                    [
                      _c("img", {
                        staticClass: "plus mr-3",
                        staticStyle: { "margin-top": "-5px !important" },
                        attrs: {
                          src: require("@/assets/kiosco46/plus.svg"),
                          alt: "plus"
                        }
                      }),
                      _c("span", { staticClass: "buttonText" }, [
                        _vm._v("Adjuntar documento")
                      ])
                    ]
                  ),
                  _vm.documents.length >= 20
                    ? _c("p", { staticClass: "buttonText mt-4 text-center" }, [
                        _vm._v("llegaste al máximo de documentos")
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm.documents.length
                ? _c(
                    "div",
                    { staticClass: "pb-1 pt-3" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "SendTwo text-center AuthsBtn my-2",
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            variant: "bluecolmedica",
                            block: "",
                            size: "lg",
                            type: "button"
                          },
                          on: { click: _vm.onShowContinue }
                        },
                        [
                          _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                            _vm._v("Continuar")
                          ]),
                          _c(
                            "svg",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                width: "11",
                                height: "18",
                                viewbox: "0 0 11 18",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M2 2L8.99954 8.99954",
                                  stroke: "#F4F4F4",
                                  "stroke-width": "2.53762",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M2 15.9688L8.99954 8.96921",
                                  stroke: "#F4F4F4",
                                  "stroke-width": "2.53762",
                                  "stroke-linecap": "round",
                                  "stroke-linejoin": "round"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ]
                },
                [
                  _c("input", {
                    ref: "image",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: "image/*" },
                    on: { change: _vm.fileHandlerImage }
                  }),
                  _c("input", {
                    ref: "file",
                    staticStyle: { display: "none" },
                    attrs: { type: "file", accept: "*/*" },
                    on: { change: _vm.fileHandlerGeneral }
                  })
                ]
              )
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                centered: "",
                "hide-footer": "",
                "hide-header": "",
                "no-close-on-backdrop": true,
                id: "timer-init"
              },
              model: {
                value: _vm.showModalContinue,
                callback: function($$v) {
                  _vm.showModalContinue = $$v
                },
                expression: "showModalContinue"
              }
            },
            [
              _c(
                "div",
                { staticClass: "p-5" },
                [
                  _c(
                    "p",
                    { staticClass: "Subtitle text-start listItem mb-4" },
                    [
                      _vm._v(
                        "Tienes " +
                          _vm._s(_vm.documents.length) +
                          " documento" +
                          _vm._s(_vm.documents.length === 1 ? "" : "s") +
                          " cargado" +
                          _vm._s(_vm.documents.length === 1 ? "" : "s") +
                          "."
                      )
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "m-0 Subtitle text-start listItem mb-4" },
                    [
                      _vm._v(
                        "Ya puedes continuar para que te atendamos por videollamada."
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center AuthsBtn my-4",
                      staticStyle: {
                        width: "513px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center"
                      },
                      attrs: {
                        variant: "bluecolmedica",
                        block: "",
                        size: "lg",
                        type: "button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.goToView({ view: "ConfirmUpload" })
                        }
                      }
                    },
                    [
                      _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                        _vm._v("Continuar con videollamada")
                      ]),
                      _c(
                        "svg",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            width: "11",
                            height: "18",
                            viewbox: "0 0 11 18",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2 2L8.99954 8.99954",
                              stroke: "#F4F4F4",
                              "stroke-width": "2.53762",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M2 15.9688L8.99954 8.96921",
                              stroke: "#F4F4F4",
                              "stroke-width": "2.53762",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ]
                  ),
                  _c(
                    "p",
                    { staticClass: "Subtitle text-start listItem m-0 mb-4" },
                    [
                      _vm._v(
                        "Si necesitas adjuntar más documentos presiona el siguiente botón:"
                      )
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center AuthsBtn my-4",
                      staticStyle: {
                        width: "513px",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center"
                      },
                      attrs: {
                        variant: "bluecolmedica",
                        block: "",
                        size: "lg",
                        type: "button"
                      },
                      on: { click: _vm.onCloseModalContinue }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "svgLeft mr-4",
                          attrs: {
                            width: "11",
                            height: "18",
                            viewbox: "0 0 11 18",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M2 2L8.99954 8.99954",
                              stroke: "#F4F4F4",
                              "stroke-width": "2.53762",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M2 15.9688L8.99954 8.96921",
                              stroke: "#F4F4F4",
                              "stroke-width": "2.53762",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      ),
                      _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                        _vm._v("Adjuntar más documentos")
                      ])
                    ]
                  )
                ],
                1
              ),
              !_vm.addDocumentShow
                ? _c(
                    "div",
                    [
                      _c("BottomTimer", {
                        attrs: { doThis: _vm.onShowAreYouThere }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: { centered: "", "hide-footer": "", "hide-header": "" },
              model: {
                value: _vm.showModalScan,
                callback: function($$v) {
                  _vm.showModalScan = $$v
                },
                expression: "showModalScan"
              }
            },
            [
              _vm.showModalScan
                ? _c("div", { staticClass: "px-5 pt-5 pb-0" }, [
                    _c("img", {
                      staticClass: "cancelIcon",
                      attrs: {
                        src: require("@/assets/kiosco46/cancel.svg"),
                        alt: "cancel"
                      },
                      on: {
                        click: function($event) {
                          _vm.showModalScan = false
                        }
                      }
                    }),
                    _c("h3", { staticClass: "Title text-center" }, [
                      _c("strong", { staticClass: "normalFont strong" }, [
                        _vm._v("Introduce el documento en la ranura")
                      ])
                    ]),
                    _c(
                      "h4",
                      { staticClass: "text-center normalFont Subtitle" },
                      [_vm._v("con las letras hacia arriba.")]
                    ),
                    _c("img", {
                      staticClass: "imgCropperGuide",
                      attrs: {
                        src: require("@/assets/kiosco46/cropperGuide.png"),
                        alt: "imgCropperGuide"
                      }
                    }),
                    _c("div", { staticClass: "video-container" }, [
                      _c("video", {
                        attrs: { autoplay: "", preload: "", id: "videoStream" }
                      })
                    ]),
                    _c("div", { staticClass: "fakeHeight" }, [
                      _c("canvas", {
                        staticStyle: { transform: "rotate(-90deg)" },
                        attrs: { id: "videoWebcam" }
                      })
                    ]),
                    _c(
                      "div",
                      { staticClass: "pt-0" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "text-center AuthsBtn mb-0 mt-3",
                            attrs: {
                              variant: "bluecolmedica",
                              block: "",
                              size: "lg",
                              type: "button",
                              disabled: !!!_vm.videoWebcam
                            },
                            on: { click: _vm.onScanDoc }
                          },
                          [
                            _c(
                              "p",
                              { staticClass: "buttonText AuthMeds mb-0" },
                              [_vm._v("Escanear documento")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    !_vm.addDocumentShow
                      ? _c(
                          "div",
                          [
                            _c("BottomTimer", {
                              attrs: {
                                doThis: _vm.onShowAreYouThere,
                                limit: 40
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "ShowImage",
                footerClass: "p-2 border-top-0",
                headerClass: "p-2 border-bottom-0",
                centered: "",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c("div", { staticClass: "position-relative w-auto h-auto" }, [
                _c("div", { staticClass: "pt-5 d-block text-center" }, [
                  _vm.holdDocument
                    ? _c("img", {
                        staticClass: "img-fluid max-image-hold",
                        attrs: { src: _vm.holdDocument }
                      })
                    : _c("div", [_vm._v("Sin vista previa del documento")])
                ])
              ]),
              _c("img", {
                staticClass: "cancelIcon",
                attrs: {
                  src: require("@/assets/kiosco46/cancel.svg"),
                  alt: "cancel"
                },
                on: { click: _vm.hiddenImageInModal }
              }),
              _c("BottomTimer", { attrs: { doThis: _vm.onShowAreYouThere } })
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "ConfirmDelete",
                footerClass: "p-2 border-top-0",
                headerClass: "p-2 border-bottom-0",
                centered: "",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c("div", { staticClass: "position-relative w-auto h-auto" }, [
                _c("div", { staticClass: "d-block text-center" }, [
                  _c("div", { staticClass: "text-sizing-108" }, [
                    _vm._v(
                      "Al ir Atrás, perderas las fotos cargadas. Estas seguro?"
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "position-relative mt-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "d-block mx-auto my-1 text-sizing-12",
                        attrs: { variant: "bluecolmedica", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.backView()
                          }
                        }
                      },
                      [_vm._v("Si, ir Atrás")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "d-block mx-auto my-1 text-sizing-12",
                        attrs: { variant: "bluecolmedica", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.hide("ConfirmDelete")
                          }
                        }
                      },
                      [_vm._v("No cancelar")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "invalid-mime",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          }
        },
        [
          _c("h6", [
            _vm._v("Puedes cargar documentos de maximo 10Mb de peso.")
          ]),
          _c("h6", { staticClass: "text-error" }, [
            _vm._v(
              "Error: " +
                _vm._s(
                  _vm.errorModalType
                    ? "Su documento no tiene el formato pdf, png, jpg, jpeg o tiff"
                    : "Su documento pesa más de 10MB"
                )
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons mt-4 mimeErrorCloser" },
            [
              _c(
                "b-button",
                { staticClass: "ml-2", on: { click: _vm.toogleInvalidModal } },
                [_vm._v("Cerrar")]
              )
            ],
            1
          ),
          _c("BottomTimer", { attrs: { doThis: _vm.onShowAreYouThere } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-1" }, [
      _c(
        "p",
        {
          staticClass:
            "text-start font-16 mr-5 color-descartDocumentBack mt-3 Subtitle",
          staticStyle: { "line-height": "32px" }
        },
        [_vm._v("Por favor adjunta los siguientes documentos:")]
      ),
      _c("ol", { staticClass: "olContainer pl-5" }, [
        _c(
          "li",
          {
            staticClass: "Subtitle text-start listItem",
            staticStyle: { "line-height": "32px" }
          },
          [
            _vm._v(
              "Foto frente y respaldo solicitud de servicios médicos y/o foto orden médica una cara."
            )
          ]
        ),
        _c(
          "li",
          {
            staticClass: "Subtitle text-start listItem",
            staticStyle: { "line-height": "32px" }
          },
          [_vm._v("Resultados de exámenes relacionados (en caso de tenerlos).")]
        ),
        _c(
          "li",
          {
            staticClass: "Subtitle text-start listItem",
            staticStyle: { "line-height": "32px" }
          },
          [_vm._v("Copia de la historia clínica (en caso de tenerla a mano).")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }