var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Autenticate pt-0" }, [
    _c(
      "div",
      { staticClass: "Autenticate__content" },
      [
        _vm.showErrorExced
          ? _c("Modal", {
              attrs: { hideHeader: false },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "23",
                              height: "24",
                              viewbox: "0 0 23 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z",
                                stroke: "#F4F4F4",
                                "stroke-width": "1.7"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z",
                                fill: "#F4F4F4"
                              }
                            })
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "main",
                    fn: function() {
                      return [
                        _c("p", { staticClass: "m-0 text-center" }, [
                          _vm._v(
                            "El número de celular no puede exceder los diez caracteres"
                          )
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "mt-3 mb-4 ButtonContinue mx-auto d-block",
                            attrs: { variant: "bluecolmedica" },
                            on: {
                              click: function($event) {
                                _vm.showErrorExced = false
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mb-0 ButtonContinue__text px-4" },
                              [_vm._v("Ingresa nuevos datos")]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1878236620
              )
            })
          : _vm._e(),
        _vm.showModalErrorNumber
          ? _c("Modal", {
              attrs: { hideHeader: false },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "23",
                              height: "24",
                              viewbox: "0 0 23 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z",
                                stroke: "#F4F4F4",
                                "stroke-width": "1.7"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z",
                                fill: "#F4F4F4"
                              }
                            })
                          ]
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "main",
                    fn: function() {
                      return [
                        _c("p", { staticClass: "m-0 text-center" }, [
                          _vm._v("El primer número debe ser un tres")
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass:
                              "mt-3 mb-4 ButtonContinue mx-auto d-block",
                            attrs: { variant: "bluecolmedica" },
                            on: { click: _vm.cleanModalNumber }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "mb-0 ButtonContinue__text px-4" },
                              [_vm._v("Ingresa nuevos datos")]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3310719900
              )
            })
          : _vm._e(),
        _vm.showUserInactive || _vm.showUserOlder || _vm.showConfirmLink
          ? _c("Modal", {
              attrs: { hideHeader: true },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "23",
                              height: "24",
                              viewbox: "0 0 23 24",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M0.85 12.2422C0.85 6.36051 5.61792 1.59244 11.4995 1.59219C17.3791 1.59871 22.1438 6.36355 22.15 12.2431C22.1495 18.1245 17.3815 22.8922 11.5 22.8922C5.61817 22.8922 0.85 18.124 0.85 12.2422Z",
                                stroke: "#F4F4F4",
                                "stroke-width": "1.7"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d:
                                  "M11.5013 7.45312C10.9558 7.45312 10.543 7.74005 10.543 8.20351V13.4127C10.543 13.8766 10.9557 14.1626 11.5013 14.1626C12.0335 14.1626 12.4596 13.8646 12.4596 13.4127V8.20351C12.4596 7.75106 12.0335 7.45312 11.5013 7.45312ZM11.5013 15.1202C10.9772 15.1202 10.5506 15.5501 10.5506 16.0788C10.5506 16.6065 10.9772 17.0365 11.5013 17.0365C12.0254 17.0365 12.4515 16.6065 12.4515 16.0788C12.4515 15.5501 12.0254 15.1202 11.5013 15.1202Z",
                                fill: "#F4F4F4"
                              }
                            })
                          ]
                        ),
                        _vm.showUserInactive
                          ? _c("p", { staticClass: "mb-0 ml-2" }, [
                              _vm._v("Usuario no activo")
                            ])
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "main",
                    fn: function() {
                      return [
                        _vm.showConfirmLink
                          ? _c(
                              "div",
                              {
                                staticClass: "containerCloseLink",
                                on: { click: _vm.cleanModal }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "14",
                                      height: "14",
                                      viewbox: "0 0 14 14",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M1.41406 12.4297L11.8694 2.1381",
                                        stroke: "#333333",
                                        "stroke-width": "2.61364",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M1.41406 2.30078L11.8694 12.5924",
                                        stroke: "#333333",
                                        "stroke-width": "2.61364",
                                        "stroke-linecap": "round",
                                        "stroke-linejoin": "round"
                                      }
                                    })
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.showUserInactive
                          ? _c("p", { staticClass: "m-0 text-center" }, [
                              _vm._v(
                                "Asegurate de ingresar los datos correctos del usuario que requiere autorización"
                              )
                            ])
                          : _vm.showConfirmLink
                          ? _c(
                              "div",
                              [
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "Haz clic para ver la política de tratamiento de datos personales"
                                  )
                                ]),
                                _c(
                                  "b-button",
                                  {
                                    staticClass:
                                      "mt-3 mb-4 ButtonContinue mx-auto d-block",
                                    attrs: { variant: "bluecolmedica" },
                                    on: { click: _vm.goToNewWindow }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "mb-0 ButtonContinue__text px-4"
                                      },
                                      [_vm._v("Ver política")]
                                    )
                                  ]
                                ),
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v(
                                    "Vuelve a esta ventana cuando termines de ver la política de tratamiento de datos personales"
                                  )
                                ])
                              ],
                              1
                            )
                          : _c("p", { staticClass: "m-0 text-center" }, [
                              _vm._v(
                                "Este servicio es para mayores de " +
                                  _vm._s(_vm.env.VUE_APP_OLD_AGE) +
                                  " años"
                              )
                            ]),
                        !_vm.showConfirmLink && _vm.counterShowedUserOlder !== 2
                          ? _c(
                              "b-button",
                              {
                                staticClass:
                                  "mt-3 mb-4 ButtonContinue mx-auto d-block",
                                attrs: { variant: "bluecolmedica" },
                                on: { click: _vm.cleanModal }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mb-0 ButtonContinue__text px-4"
                                  },
                                  [_vm._v("Ingresa nuevos datos")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm.counterShowedUserOlder === 2
                          ? _c(
                              "b-button",
                              {
                                staticClass:
                                  "mt-3 mb-4 ButtonContinue mx-auto d-block",
                                attrs: { variant: "bluecolmedica" },
                                on: { click: _vm.goToPage }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "mb-0 ButtonContinue__text px-4"
                                  },
                                  [_vm._v("Ir a página web")]
                                )
                              ]
                            )
                          : _vm._e(),
                        _c("button", { staticClass: "button-without-styles" })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3549262793
              )
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "ContainerForm d-flex justify-content-center pr-5" },
          [
            _c(
              "form",
              {
                staticClass: "contentForm pt-3 mr-5",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.onValidate.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "Autenticate_TextSection weight-500 text-start mb-2",
                    class: _vm.selected && "activeLabel"
                  },
                  [_vm._v("Selecciona tipo de identificación")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "position-relative mb-4 custom-select-oa",
                    attrs: { id: "cc_number_container" }
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected,
                            expression: "selected"
                          }
                        ],
                        staticClass:
                          "number-input py-3 pl-4 position-relative position-relative selectStyles",
                        attrs: {
                          size: "sm",
                          required: "",
                          autocomplete: "off"
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.options, function(option) {
                        return _c(
                          "option",
                          { domProps: { value: option.value } },
                          [_vm._v(_vm._s(option.text))]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "position-absolute arrowBottomStyles" },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16",
                              height: "9",
                              viewbox: "0 0 16 9",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z",
                                fill: "#66707E",
                                stroke: "#66707E",
                                "stroke-width": "0.4"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "Autenticate_TextSection weight-500 text-start mb-2 mr-5",
                    class: _vm.number_id && "activeLabel"
                  },
                  [
                    _vm._v(
                      "Ingrese el número de identificación del usuario que requiere atención"
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "position-relative",
                    attrs: { id: "id_number_container" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.number_id,
                          expression: "number_id"
                        }
                      ],
                      ref: "id_number",
                      staticClass: "py-3 pl-4 number-input mb-4",
                      attrs: {
                        id: "id_number",
                        autocomplete: "off",
                        disabled: !_vm.isCapcha,
                        placeholder: _vm._number,
                        required: ""
                      },
                      domProps: { value: _vm.number_id },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.number_id = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass:
                      "Autenticate_TextSection weight-500 text-start mb-2",
                    class: _vm.phone_number && "activeLabel"
                  },
                  [_vm._v("Ingresa tu número de celular")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "position-relative",
                    attrs: { id: "phone_number_container" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone_number,
                          expression: "phone_number"
                        }
                      ],
                      ref: "phone_number",
                      staticClass: "py-3 pl-4 number-input mb-4",
                      attrs: {
                        id: "phone_number",
                        autocomplete: "off",
                        type: "text",
                        max: "1",
                        required: ""
                      },
                      domProps: { value: _vm.phone_number },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone_number = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "pt-3 mt-3 position-relative",
                    attrs: { id: "checkbox-1_container" }
                  },
                  [
                    _c("p", { staticClass: "AcceptTerms" }, [
                      _vm._v(
                        "Al dar clic en Continuar, acepto que mis datos serán tratados bajo la política de"
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "ml-1",
                          on: {
                            click: function() {
                              return _vm.changeConfirmLink(true)
                            }
                          }
                        },
                        [_vm._v("tratamiento de datos personales")]
                      )
                    ])
                  ]
                ),
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center pb-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2 ButtonContinue",
                        attrs: {
                          variant: "bluecolmedica",
                          type: "submit",
                          disabled:
                            !_vm.number_id ||
                            !_vm.selected ||
                            _vm.phone_number.length < 10 ||
                            _vm.charging ||
                            !_vm.captchaEnable
                        }
                      },
                      [
                        !_vm.charging
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "mb-0 ButtonContinue__text px-4"
                                },
                                [_vm._v("Continuar")]
                              )
                            ])
                          : _c(
                              "div",
                              { staticClass: "py-1 px-4" },
                              [_c("MiniSpinner")],
                              1
                            )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ]
        ),
        _vm._m(1)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-center pb-3" }, [
      _c("div", { staticClass: "captcha text-center" }, [
        _c("div", { attrs: { id: "captchaElement" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Container_Figure" }, [
      _c("figure", { staticClass: "ContainerImage" }, [
        _c("div", { staticClass: "ContainerImage__tap" }),
        _c("img", {
          staticClass: "oldPeople",
          attrs: {
            src: require("@/assets/older_adults/oldPeople.png"),
            alt: "oldPeople"
          }
        }),
        _c("img", {
          staticClass: "oldPeopleResponsive",
          attrs: {
            src: require("@/assets/older_adults/oldPeopleResponsive.png"),
            alt: "oldPeopleResponsive"
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }