/* eslint-disable no-unused-vars */
import axios from "axios";
import rand from "randomstring";
import {
  encryptAES,
  decryptAES,
  encryptAESWorker,
  signRSA
} from "./encryption";
import { getEnvOnly } from "./Meet";

let publicKey;
let LlaveAES;
let Token;
const rsa = new window.System.Security.Cryptography.RSACryptoServiceProvider(
  4096
);

function getLlavePublica() {
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  if (publicKey) return publicKey;
  return axios
    .get(`${server}/REST/LlavePublica`)
    .then(data => data.data)
    .then(pub => {
      publicKey = pub;
      return pub;
    });
}

async function autorizar({ TipoIdentificacion, NumeroIdentificacion }) {
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  const keyString = rand.generate(32);
  const { data } = await axios.post(
    `${server}/REST/Autorizar`,
    await signRSA(await getLlavePublica(), {
      TipoIdentificacion,
      NumeroIdentificacion,
      LlaveAES: keyString
    })
  );
  Token = data.token;
  LlaveAES = await decryptAES(keyString, data.iv, data.llaveAES);
}

async function colmedicaDecode(encoded) {
  let ivString = encoded.substr(0, 16);
  let encodedString = encoded.substr(16);
  const decoded = await decryptAES(LlaveAES, ivString, encodedString);
  return decoded;
}

async function consultarCliente({ TipoIdentificacion, NumeroIdentificacion }) {
  // LF Tested OK
  const ivString = rand.generate(16);
  const encoded = await encryptAES(
    LlaveAES,
    ivString,
    JSON.stringify({
      TipoIdentificacion,
      NumeroIdentificacion
    })
  );
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  return axios
    .post(
      `${server}/REST/ConsultarCliente`,
      {
        Data: encoded,
        Iv: ivString
      },
      {
        headers: {
          Authorization: `Bearer ${Token}`
        }
      }
    )
    .then(data => {
      console.log(data)
      return data.data
    } )
    .then(enc => colmedicaDecode(enc))
    .then(enc => {
      console.log(enc)
      let c = JSON.parse(enc);
      // c.ControlMedicamentos = true;
      console.log(c)
      console.log("----------------------------")
      return c
    } );
}

async function tiposAutorizacion() {
  // LF Tested OK
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  return axios
    .get(`${server}/REST/TiposAutorizacion`, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc));
}

async function guardarSolicitud({ ParametroSolicitudCanal, ArchivoSolicitud }) {
  // LF Tested OK
  const ivString = rand.generate(16);
  let dataToSend = JSON.stringify({
    ParametroSolicitudCanal,
    ArchivoSolicitud
  });
  console.log("dataToSend")
  console.log(dataToSend)
  const encoded = await encryptAESWorker(LlaveAES, ivString, dataToSend);
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  return axios
    .post(
      `${server}/REST/GuardarSolicitud`,
      {
        Data: encoded,
        Iv: ivString
      },
      {
        headers: {
          Authorization: `Bearer ${Token}`
        }
      }
    )
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc));
}

async function anexarDocumentosSolicitud({ IdSolicitud, ArchivoSolicitud }) {
  const encoded = encryptAES({ IdSolicitud, ArchivoSolicitud });
  const server = getEnvOnly()?.VUE_APP_HUELLA_SERVER;
  return axios
    .post(`${server}/REST/AnexarDocumentosSolicitud`, encoded, {
      headers: {
        Token
      }
    })
    .then(data => data.data); // Continue here. How to decode received?
}

export {
  autorizar,
  consultarCliente,
  tiposAutorizacion,
  guardarSolicitud,
  anexarDocumentosSolicitud
};
