import LS from "../helpers/localStorage";
import kuid from "kuid";

export default {
  env: JSON.parse(JSON.stringify(process.env)),
  pageForeground: true,
  environment: process.env.VUE_APP_ENV || "develop",
  version: process.env.PACKAGE_VERSION || "0",
  sessionMaster: LS.getItem("sessionMaster") || kuid(),
  started: false,
  company: LS.getItem("company") || null,
  client_data: LS.getItem("client_data") || {},
  tramite_info: LS.getItem("tramite_info") || null,
  turn: LS.getItem("turn") || null,
  turnUUID: LS.getItem("turnUUID") || null,
  turn_timer: null,
  catch_error: null,
  max_waiting_time: 20,
  time_received_turn: LS.getItem("time_received_turn") || null,
  error_turn_request_counter: 0,
  branchInfo: LS.getItem("branchInfo") || {},

  // b64 encrypted and decrypted customer data
  eclient_data: null,
  dclient_data: LS.getItem("dclient_data"),
  position: {},
  eventTime: 0,
  timer: 15,
  timer3: 30,
  generalTimeoutCounter: 15,
  generalTimeout: null,
  generalTimeout3: null,
  generalTimeoutModal: null,
  generalTimeoutAreYouThere: null,
  removeTimeout: false,
  restart: false,
  goTime: false,
  turnCoorelation: null,
  fromKiosco46: false
};
