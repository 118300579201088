import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";
import state from "./states";

// modules
import meeting from "./meeting";
import recording from "./recording";
import autoservice from "./autoservice";
import virtualrow from "./virtualrow";
import virtualrowFSFB from "./virtualrowFSFB";
import virtualrowFSFBAU from "./virtualrowFSFBAU";
import virtualrowAtrilVideo from "./virtualrow_atril_video";
import appointments from "./appointments";
import olderAdult from "./older_adult";
import mobileFSFB from "./mobileFSFB";

import turns from "./turn";
import log from "./log";

Vue.use(Vuex);

export let store = new Vuex.Store({
  namespaced: true,
  state,
  mutations,
  actions,
  getters,

  modules: {
    meeting,
    recording,
    autoservice,
    virtualrow,
    virtualrowFSFB,
    virtualrowFSFBAU,
    virtualrow_atril_video: virtualrowAtrilVideo,
    turns,
    log,
    appointments,
    olderAdult,
    mobileFSFB
  }
});
