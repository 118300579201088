var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Services" }, [
    _c("div", { staticClass: "headerFake" }),
    _c(
      "div",
      {
        staticClass: "d-flex align-items-center justify-content-between goBack",
        on: { click: _vm.goBack }
      },
      [
        _c("img", {
          staticClass: "icn_backicn_back ml-3",
          attrs: {
            src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
            alt: "icn_backicn_back"
          }
        })
      ]
    ),
    _c("div", { staticClass: "backgroundBuild" }),
    _vm.sede
      ? _c("div", { staticClass: "containerBox" }, [
          _c("div", { staticClass: "caveFake" }),
          _c(
            "div",
            {
              staticClass: "px-4 pt-4",
              staticStyle: { "margin-top": "-16px" }
            },
            [
              _vm._m(0),
              _c("h2", { staticClass: "SedeTitle" }, [
                _c("strong", [_vm._v(_vm._s(_vm.sede.name))])
              ]),
              _c("p", { staticClass: "descP mb-3" }, [
                _vm._v(
                  "¡A tu disposición! Conoce a cuáles servicios puedes acceder en esta sede."
                )
              ]),
              _c(
                "div",
                { staticClass: "pb-5" },
                _vm._l(Object.keys(_vm.areaData).sort(), function(item) {
                  return _c(
                    "button",
                    {
                      staticClass:
                        "button-without-styles arrowButtonServices d-flex justify-content-between align-items-center mb-4",
                      on: {
                        click: function($event) {
                          return _vm.continueToLogin(_vm.areaData[item])
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("img", { attrs: { src: _vm.areaData[item].icon } }),
                        _c("span", { staticClass: "buttonText ml-3" }, [
                          _vm._v(_vm._s(_vm.areaData[item].label))
                        ])
                      ]),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "24",
                            height: "24",
                            viewbox: "0 0 24 24",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d:
                                "M4 12C4 10.4178 4.46921 8.87105 5.34826 7.55546C6.22731 6.23987 7.47673 5.21449 8.93853 4.60899C10.4003 4.00349 12.0089 3.84506 13.5607 4.15374C15.1125 4.46242 16.538 5.22435 17.6568 6.34317C18.7757 7.46199 19.5376 8.88744 19.8463 10.4393C20.155 11.9911 19.9965 13.5997 19.391 15.0615C18.7855 16.5233 17.7601 17.7727 16.4446 18.6518C15.129 19.5308 13.5823 20 12 20C9.87828 20 7.84344 19.1571 6.34316 17.6569C4.84287 16.1566 4 14.1217 4 12Z",
                              stroke: "#788591",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M13.5991 11.9999L11.4618 9.86266",
                              stroke: "#788591",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          }),
                          _c("path", {
                            attrs: {
                              d: "M11.4618 14.1373L13.5991 12.0001",
                              stroke: "#788591",
                              "stroke-width": "1.5",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round"
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("small", { staticClass: "SedeTitle smallest" }, [
      _c("strong", [_vm._v("Servicios")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }