var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ConfirmSchedule" },
    [
      _c("Header"),
      _c("div", { staticStyle: { "margin-top": "50px" } }),
      !_vm.modeScheduled
        ? _c(
            "h2",
            {
              staticClass: "TitleSection text-center pt-3 pb-3",
              attrs: { id: "headerCalendarTitle" }
            },
            [_vm._v("Confirma tu videollamada")]
          )
        : _vm._e(),
      _vm.isntIntersection && !_vm.modeScheduled
        ? _c("div", { staticClass: "headerFixed" }, [
            _c(
              "button",
              {
                staticClass: "button-without-styles py-3 px-4",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return _vm.goToView("Calendar")
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "19",
                      viewbox: "0 0 20 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                        stroke: "#ffff",
                        "stroke-width": "3.12307",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            ),
            _c(
              "h2",
              { staticClass: "TitleSection text-title color-white pt-2" },
              [_vm._v("Confirma tu videollamada")]
            )
          ])
        : _vm._e(),
      !_vm.modeScheduled
        ? _c("div", { staticClass: "botton-actions ml-5" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "button-without-styles",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.goToView("Calendar")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "19",
                        viewbox: "0 0 20 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                          stroke: "#0058A2",
                          "stroke-width": "3.12307",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm.modeScheduled && _vm.responseAppointment.status != "CONFIRMED"
        ? _c("h2", { staticClass: "TitleSection pt-5 mb-4 pb-1 warning" }, [
            _vm._v("Error agendando la cita "),
            _c("br"),
            _c("br"),
            _c("p", { staticClass: "font-16 mb-0 text-error" }, [
              _vm._v(_vm._s(_vm.responseAppointment.message))
            ])
          ])
        : _c(
            "div",
            {
              staticClass: "text-center d-flex flex-wrap mt-4",
              staticStyle: {
                "justify-content": "center",
                "align-items": "baseline"
              }
            },
            [_vm._m(0), _vm._m(1), _vm._m(2)]
          ),
      !_vm.modeScheduled
        ? _c("div", { staticClass: "form-data mb-3 mt-0 py-4 mx-1 px-4" }, [
            _c("p", { staticClass: "FontDesc mb-2" }, [
              _vm._v(
                "Confirma los datos registrados para finalizar tu agendamiento:"
              )
            ]),
            _c("br"),
            _c("p", { staticClass: "mb-0 ml-1" }, [
              _vm._v("Nombre:"),
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.client.Nombres))
              ]),
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(
                  _vm._s(_vm.client.PrimerApellido) +
                    " " +
                    _vm._s(_vm.client.SegundoApellido)
                )
              ])
            ]),
            _c("p", { staticClass: "font-16 mb-0" }, [
              _vm._v("Fecha y hora:"),
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.confirmDay))
              ])
            ]),
            _vm.servicioSelected || _vm.service
              ? _c("p", { staticClass: "font-16 mb-0" }, [
                  _vm._v("Servicio:"),
                  _c("strong", { staticClass: "ml-1" }, [
                    _vm._v("Autorizaciones médicas")
                  ])
                ])
              : _vm._e(),
            _vm._m(3),
            _c(
              "a",
              {
                staticClass:
                  "mx-2 LinkColored mt-4 pt-2 pb-1 text-center d-block"
              },
              [
                _vm._v("Te enviaremos la confirmación a tu correo electrónico"),
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-0 ml-1 d-flex flex-wrap justify-content-center"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.email1))]),
                    _c("span", [_vm._v(_vm._s(_vm.email2))])
                  ]
                ),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v("y a tu número celular")
                ]),
                _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(_vm.phone))])
              ]
            )
          ])
        : _c("div", { staticClass: "text-center pb-5" }, [
            _c("p", { staticClass: "grayTextDesc pt-4" }, [
              _vm._v("Enviaremos a tu correo electrónico y número de celular:")
            ]),
            _vm._m(4)
          ]),
      _c(
        "div",
        { staticClass: "botton-actions-footer" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-center" },
            [
              !_vm.modeScheduled
                ? _c(
                    "b-button",
                    {
                      staticClass: "text-center w-100 py-2 mx-1",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button",
                        loading: _vm.loading
                      },
                      on: { click: _vm.confirmAppointment }
                    },
                    [
                      !_vm.loading
                        ? _c("span", { staticClass: "font-md" }, [
                            _vm._v("Confirmar")
                          ])
                        : _c(
                            "div",
                            { staticClass: "py-1" },
                            [_c("MiniSpinner")],
                            1
                          )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.modeScheduled
            ? _c(
                "b-button",
                {
                  staticClass: "mt-2 d-block text-center py-2 mb-4 w-100",
                  staticStyle: {
                    "background-color": "#ff0304",
                    border: "none"
                  },
                  attrs: { variant: "bluecolmedica" },
                  on: { click: _vm.onFinish }
                },
                [_c("span", { staticClass: "font-md" }, [_vm._v("Finalizar")])]
              )
            : _vm._e(),
          _vm.modeScheduled
            ? _c(
                "button",
                {
                  staticClass:
                    "mt-2 d-block text-center py-1 mb-4 link-btn-fake",
                  on: {
                    click: function() {
                      return _vm.goToView("ToSchedule")
                    }
                  }
                },
                [_vm._v("Ver videollamadas programadas")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "TitleSection pt-5 ProgramTitle m-0" }, [
      _c("span", { staticClass: "red", staticStyle: { "font-size": "30px" } }, [
        _vm._v("¡")
      ]),
      _c(
        "span",
        { staticClass: "blue", staticStyle: { "font-size": "30px" } },
        [_vm._v("Tu videollamada se")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "TitleSection ProgramTitle m-0" }, [
      _c(
        "span",
        { staticClass: "blue", staticStyle: { "font-size": "30px" } },
        [_vm._v("agendó con éxito")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "TitleSection mb-4 pb-1 ProgramTitle" }, [
      _c("span", { staticClass: "red" }, [_vm._v("!")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "font-16 mb-0" }, [
      _vm._v("Modalidad de atención:"),
      _c("strong", { staticClass: "ml-1" }, [_vm._v("Videollamada")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "grayTextDesc pb-5", staticStyle: { color: "black" } },
      [
        _c("b", [
          _c("span", [
            _vm._v(
              "El Link que te permitirá ingresar y activar tu videollamada"
            )
          ]),
          _c(
            "strong",
            { staticClass: "mx-1", staticStyle: { color: "#0BB257" } },
            [_vm._v("5 minutos")]
          ),
          _c("span", [_vm._v("antes de la hora programada.")]),
          _c("br"),
          _c("span", { staticClass: "red" }, [_vm._v("¡ ")]),
          _c("span", { staticClass: "blue" }, [_vm._v("Recuerda Revisarlo")]),
          _c("span", { staticClass: "red" }, [_vm._v("!")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }