var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "VueSlickCarouselContainer text-center" }, [
    _c(
      "div",
      {
        ref: "carousel",
        staticClass: "VueSlickCarousel",
        attrs: { id: "VueSlickCarousel" }
      },
      [_vm._t("default")],
      2
    ),
    _c(
      "div",
      { staticClass: "d-block mb-3 mt-4" },
      _vm._l(_vm.list, function(dot, index) {
        return _c(
          "button",
          {
            key: index + 1,
            staticClass: "dots mx-1",
            class: index + 1 === _vm.slide && "selected_dot",
            attrs: { id: index + 1 },
            on: {
              click: function($event) {
                return _vm.changeSelectedDot(index)
              }
            }
          },
          [_vm._v(_vm._s(index + 1 !== _vm.list.length ? index + 1 : "-"))]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }