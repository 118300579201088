var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "SelectZone" }, [
    _c("section", { staticClass: "App text-center" }, [
      _vm._m(0),
      _vm._m(1),
      _c("div", { staticClass: "text-start boxText boxText2 mx-auto" }, [
        _c("div", { staticClass: "boxContent pb-3" }, [
          _c("h2", { staticClass: "titleFSFBM" }, [_vm._v("Bienvenido")]),
          _c("p", { staticClass: "desc2" }, [
            _vm._v("Elige la sede en la que te encuentras")
          ]),
          _c("div", { staticClass: "pt-4 d-block" }, [
            _c("div", { staticClass: "boxContent1 px-3 pt-4" }, [
              _c(
                "div",
                {
                  staticClass:
                    "form-select-container mb-3 position-relative mt-2"
                },
                [
                  _c("b-form-select", {
                    staticClass:
                      "Input-text Input-textSede p-2 pr-5 position-relative",
                    style: _vm.selectedSede
                      ? "background: var(--color-white);"
                      : "",
                    attrs: {
                      options: _vm.sedes,
                      size: "sm",
                      autocomplete: "off",
                      placeholder: "Sede",
                      required: ""
                    },
                    model: {
                      value: _vm.selectedSede,
                      callback: function($$v) {
                        _vm.selectedSede = $$v
                      },
                      expression: "selectedSede"
                    }
                  }),
                  _c("p", { staticClass: "textSede" }, [_vm._v("Sede")]),
                  _c(
                    "svg",
                    {
                      staticClass: "mr-2 z-negative-2",
                      attrs: {
                        width: "21",
                        height: "21",
                        viewbox: "0 0 21 21",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M10.6375 4.23521C11.9577 4.23521 13.2482 4.62851 14.3459 5.36536C15.4436 6.10222 16.2992 7.14952 16.8044 8.37486C17.3096 9.6002 17.4418 10.9485 17.1842 12.2493C16.9267 13.5501 16.291 14.745 15.3574 15.6829C14.4239 16.6207 13.2346 17.2594 11.9397 17.5181C10.6449 17.7769 9.3028 17.6441 8.0831 17.1365C6.86341 16.629 5.82092 15.7694 5.08746 14.6667C4.354 13.5639 3.96252 12.2674 3.96252 10.9411C3.96252 9.16259 4.66578 7.45692 5.91758 6.19932C7.16938 4.94173 8.86719 4.23521 10.6375 4.23521Z",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M10.6376 12.2815L12.4209 10.49",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M8.85413 10.49L10.6374 12.2815",
                          stroke: "#788591",
                          "stroke-width": "1.5",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass: "buttonForm d-block w-100 mt-4",
                  attrs: {
                    disabled: !_vm.selectedSede || _vm.selectedSede === "Sede"
                  },
                  on: {
                    click: function($event) {
                      return _vm.continueToServices(_vm.selectedSede)
                    }
                  }
                },
                [
                  !_vm.charging
                    ? _c("span", [_vm._v("Continuar")])
                    : _c("span", [_c("MiniSpinner")], 1)
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("figure", { staticClass: "containerImgZone" }, [
      _c("img", {
        staticClass: "h-100 containerImgZone-img w-100",
        attrs: {
          src: require("@/assets/mobileFSFB/helpingBoy.png"),
          alt: "helpingBoy"
        }
      }),
      _c("div", { staticClass: "containerImgZone-tape" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100 d-flex justify-content-center" }, [
      _c("img", {
        staticClass: "logoAuthZone mx-auto",
        attrs: {
          src: require("@/assets/mobileFSFB/logoFSFB.png"),
          alt: "logoFSFB"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }