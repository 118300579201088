var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Content pt-5" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "px-2 mt-3" },
      [
        _vm._m(1),
        _c("br"),
        _c("p", { staticClass: "TextSection mt-0" }, [
          _vm._v(_vm._s(_vm.showSchedule))
        ]),
        _c("br"),
        _vm._m(2),
        _c("p", { staticClass: "text-left mt-0" }, [
          _vm._v(
            "Si deseas puedes agendar una videollamada para revisar tu solicitud con uno de nuestros asesores."
          )
        ]),
        _c(
          "b-button",
          {
            staticClass: "w-100 py-2 text-center mx-1",
            attrs: { variant: "bluecolmedica" },
            on: {
              click: function() {
                return _vm.goToView("ToSchedule")
              }
            }
          },
          [
            _c("div", { staticClass: "mx-auto align-items-center d-block" }, [
              _c("span", [_vm._v("Agendar Videollamada")])
            ])
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-auto d-block buttonBack" },
          [
            _c("b-button", { attrs: { variant: "whitecolmedica" } }, [
              _c("span", [_vm._v("Ya puedes cerrar esta ventana")])
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 contain color-colmedica-gray mt-4" },
      [
        _c("h2", { staticClass: "TitleSection" }, [
          _vm._v("Tu solicitud ha sido radicada exitosamente")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "TextSection mb-0" }, [
      _c("strong", [_vm._v("Nuestro horario de videollamada es de:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", { staticClass: "color-colmedica-light" }, [
        _vm._v(
          "Te responderemos tu solicitud dentro de las próximas 12 horas hábiles. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }