var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobileFSFB" },
    [
      _vm.view === "Payment" ? _c("Payment") : _vm._e(),
      _vm.view === "Home" || !_vm.view ? _c("Home") : _vm._e(),
      _vm.view === "Error"
        ? _c("Error", {
            attrs: { isLoadingAgain: _vm.isLoadingAgain },
            on: { again: _vm.callApiAgain }
          })
        : _vm.view === "Help"
        ? _c("Help")
        : _vm.view === "Procedure"
        ? _c("Procedure")
        : _vm.view === "TikTokVideos"
        ? _c("TikTokVideos", {
            attrs: {
              assignTurn: _vm.turnInf.jsonDetails.turn,
              waitingTime:
                _vm.calculatedMinutes(
                  _vm.turnInf.jsonDetails.averageWaitingTime
                ) + " minutos"
            }
          })
        : _vm.view === "Dates"
        ? _c("Dates")
        : _vm.view === "CancelTurn"
        ? _c("CancelTurn")
        : _vm.view === "ConfirmDataAuth"
        ? _c("ConfirmDataAuth")
        : _vm.view === "Oldest"
        ? _c("Oldest")
        : _vm._e(),
      _vm.view == "Canceled"
        ? _c("Finalize", { attrs: { isFinalized: false } })
        : _vm._e(),
      _vm.view == "Finalize"
        ? _c("Finalize", { attrs: { isFinalized: true } })
        : _vm.view == "Turn"
        ? _c("Turn", {
            attrs: {
              tramiteName: _vm.tramiteName,
              userInfo: _vm.turnObject,
              branchId: _vm.branch,
              queueId: _vm.tramite,
              source: _vm.source,
              onlyGenerate: _vm.isAtril,
              withAnalitycExtraFields: true,
              waitVideoSource: _vm.waitVideoSource,
              waitVideoPoster: _vm.waitVideoPoster,
              hideVideo: _vm.hideVideo
            },
            on: {
              generatedTurn: _vm.printTicket,
              endTurn: _vm.endTurn,
              errorRequestTurn: _vm.toHome,
              goBack: _vm.goBack
            },
            scopedSlots: _vm._u(
              [
                _vm.isAtril
                  ? {
                      key: "waitingActions",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              style: _vm.isAtril
                                ? "font-size: 1.5rem;" + " " + _vm.colorComputed
                                : _vm.colorComputed,
                              attrs: { variant: "bluecolmedica" },
                              on: { click: _vm.toHome }
                            },
                            [_c("span", [_vm._v("Volver al inicio")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "waiting",
                  fn: function(ref) {
                    var turn = ref.turn
                    return [
                      _vm.currentValidation.length
                        ? _vm._l(_vm.currentValidation, function(valid, i) {
                            return _c(
                              "h4",
                              { key: i, staticClass: "mt-4 text-middle" },
                              [_vm._v(_vm._s(valid.DescripcionPaso))]
                            )
                          })
                        : _vm._e(),
                      !_vm.currentValidation.length
                        ? _c("h4", { staticClass: "mt-2 text-middle" }, [
                            !_vm.siteIsOffice
                              ? _c("div", [
                                  _c(
                                    "p",
                                    { staticClass: "m-0 fontAssignedText" },
                                    [_vm._v("Tu turno asignado es")]
                                  )
                                ])
                              : _c(
                                  "p",
                                  { staticClass: "m-0 fontAssignedTextValue" },
                                  [_vm._v("Tu turno asignado es")]
                                )
                          ])
                        : _vm._e(),
                      _vm.currentValidation.length
                        ? _c(
                            "h4",
                            {
                              staticClass: "mt-4 text-middle fontAssignedText"
                            },
                            [_vm._v("tu turno asignado es")]
                          )
                        : _vm._e(),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-center color-colmedica-light mt-0 fontAssignedTextValue"
                        },
                        [_vm._v(_vm._s(turn.myTurn))]
                      ),
                      _c("p", { staticClass: "m-0 mt-5" }, [
                        _c("small", { staticClass: "estimadoP" }, [
                          _vm._v("Tiempo estimado de espera")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "mb-3 circleEstimado mt-3 mx-auto" },
                        [
                          _c("h2", [
                            _vm._v(
                              _vm._s(
                                _vm.calculatedMinutes(
                                  _vm.turnInf.jsonDetails.averageWaitingTime
                                )
                              )
                            )
                          ]),
                          _c("h4", [_vm._v("Minutos")])
                        ]
                      ),
                      _c("div", { staticClass: "impresoTicket pt-4" }, [
                        _c("p", { staticClass: "mb-0" }, [
                          _vm._v("Toma el ticket impreso")
                        ]),
                        _c(
                          "svg",
                          {
                            staticClass: "mb-0",
                            attrs: {
                              width: "24",
                              height: "11",
                              viewbox: "0 0 24 11",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M12 11L0.74167 0.5L23.2583 0.500002L12 11Z",
                                fill: "#283645"
                              }
                            })
                          ]
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }