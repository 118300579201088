var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ToSchedule" },
    [
      _c("Header"),
      _vm.isntIntersection
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading"
                }
              ],
              staticClass: "headerFixed"
            },
            [
              _c(
                "button",
                {
                  staticClass: "button-without-styles py-3 px-4",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.goToView("IndexSchedules")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "19",
                        viewbox: "0 0 20 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                          stroke: "#ffff",
                          "stroke-width": "3.12307",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              ),
              _c(
                "h2",
                { staticClass: "TitleSection text-title color-white pt-2" },
                [_vm._v("Gestionar videollamada")]
              )
            ]
          )
        : _vm._e(),
      _c("div", { staticStyle: { "margin-top": "50px" } }),
      _c(
        "h2",
        {
          staticClass: "TitleSection pt-3 pb-3",
          style: (_vm.loading && "opacity: 0;") + " margin-top: 50px",
          attrs: { id: "headerCalendarTitle" }
        },
        [_vm._v("Gestionar videollamada")]
      ),
      _c("div", { staticClass: "botton-actions ml-5" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c(
            "button",
            {
              staticClass: "button-without-styles",
              attrs: { type: "button" },
              on: {
                click: function() {
                  return _vm.goToView("IndexSchedules")
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "19",
                    viewbox: "0 0 20 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                      stroke: "#0058A2",
                      "stroke-width": "3.12307",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        ])
      ]),
      !_vm.appointments.length && !_vm.loading
        ? _c(
            "div",
            [
              _c("p", { staticClass: "FontDesc ml-2 mb-1" }, [
                _vm._v("Aún no tienes videollamadas programadas.")
              ]),
              _c("p", { staticClass: "FontDesc ml-2" }, [
                _vm._v("Presiona el botón para programar una videollamada.")
              ]),
              _c(
                "b-button",
                {
                  staticClass: "text-center w-100 py-2 mx-1",
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: {
                    click: function() {
                      return _vm.goToView("Calendar")
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c("span", { staticClass: "font-md" }, [
                        _vm._v("Programar videollamada")
                      ])
                    : _c("div", { staticClass: "py-1" }, [_c("MiniSpinner")], 1)
                ]
              )
            ],
            1
          )
        : _vm.appointments.length
        ? _c("p", { staticClass: "FontDesc ml-2" }, [
            _vm._v("Estas son las videollamadas que tienes programadas:")
          ])
        : _vm._e(),
      _c(
        "ul",
        { staticClass: "list_schedules" },
        [
          _vm._l(_vm.appointments, function(item, k) {
            return _c("li", { key: k, staticClass: "Card mb-3 p-4" }, [
              _c("p", { staticClass: "font-16 mb-2" }, [
                _c("span", { staticClass: "TextSection" }, [_vm._v("Fecha:")]),
                _c("span", { staticClass: "text-dark-cm ml-1" }, [
                  _vm._v(_vm._s(_vm.day(item.startAt)))
                ])
              ]),
              _c("p", { staticClass: "font-16 mb-2" }, [
                _c("span", { staticClass: "TextSection" }, [_vm._v("Hora:")]),
                _c("span", { staticClass: "text-dark-cm ml-1" }, [
                  _vm._v(_vm._s(_vm.time(item.startAt)))
                ])
              ]),
              item.reason.servicioSelected &&
              typeof item.reason.servicioSelected == "object" &&
              Object.keys(item.reason.servicioSelected).length
                ? _c("p", { staticClass: "font-16 mb-2" }, [
                    _c("span", { staticClass: "TextSection" }, [
                      _vm._v("Trámite:")
                    ]),
                    _c("span", { staticClass: "text-dark-cm ml-1" }, [
                      _vm._v(
                        _vm._s(item.reason.servicioSelected) +
                          " " +
                          _vm._s(typeof item.reason.servicioSelected)
                      )
                    ])
                  ])
                : _vm._e(),
              _c("p", { staticClass: "font-16 mb-2" }, [
                _c("span", { staticClass: "TextSection" }, [
                  _vm._v("Modalidad de atención:")
                ]),
                _c("span", { staticClass: "text-dark-cm ml-1" }, [
                  _vm._v(_vm._s(item.reason.modalidad))
                ])
              ]),
              _c("p", { staticClass: "font-16 mb-2" }, [
                _c("span", { staticClass: "TextSection" }, [
                  _vm._v("Ingresar: ")
                ]),
                _c(
                  "a",
                  {
                    staticClass: "link-colmedica",
                    attrs: {
                      href: "/#/turno?code=" + item.code + "&type=1",
                      target: "_blank"
                    }
                  },
                  [_vm._v("aquí")]
                )
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-end mt-3 ContainerFlexButtons"
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center mx-1 px-3",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: {
                        click: function() {
                          return _vm.handleCancel(item)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "font-16" }, [
                        _vm._v("Cancelar")
                      ])
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center mx-1 px-3",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: {
                        click: function() {
                          return _vm.handleUpdate(item)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "font-16" }, [
                        _vm._v("Reprogramar")
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          }),
          _vm.appointments.length
            ? _c("div", { staticClass: "botton-actions-footer" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-center" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-center w-100 py-2 mx-1",
                        attrs: {
                          variant: "bluecolmedica",
                          size: "sm",
                          type: "button"
                        },
                        on: {
                          click: function() {
                            return _vm.goToView("Calendar")
                          }
                        }
                      },
                      [
                        !_vm.loading
                          ? _c("span", { staticClass: "font-md" }, [
                              _vm._v("Programar otra videollamada")
                            ])
                          : _c(
                              "div",
                              { staticClass: "py-1" },
                              [_c("MiniSpinner")],
                              1
                            )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }