var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Container d-flex align-items-center justify-content-start",
      class: !!!_vm.turn ? "container-logo pb-1" : "dk-padd pb-1",
      style: _vm.isAtril
        ? "color: #545454; height: calc(100vh - 176px); display: flex; flex-direction: column; justify-content: center;"
        : "display: flex; flex-direction: column; justify-content: center; font-size: 1.3rem; font-weight: bold; height: calc(100vh - 102.9px);"
    },
    [
      _vm.priority
        ? _c("div", { staticClass: "mt-3" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "21",
                  height: "21",
                  viewbox: "0 0 21 21",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 1.49637e-06 10.5 9.8941e-07C4.70102 4.82446e-07 3.33225e-06 4.70101 2.82529e-06 10.5C0.00615352 16.2964 4.70356 20.9938 10.5 21Z",
                    fill: "#3290E0"
                  }
                }),
                _c("path", {
                  attrs: {
                    d:
                      "M10.4993 16.3906C11.2513 16.3906 11.8203 15.9982 11.8203 15.3642L11.8203 9.54988C11.8203 8.9153 11.2514 8.52416 10.4993 8.52416C9.76557 8.52416 9.17819 8.93167 9.17819 9.54988L9.17819 15.3642C9.17826 15.9831 9.76556 16.3906 10.4993 16.3906ZM10.4993 7.21426C11.2218 7.21426 11.8098 6.62622 11.8098 5.90311C11.8098 5.18124 11.2218 4.5932 10.4993 4.5932C9.77672 4.5932 9.18935 5.18124 9.18935 5.90311C9.18942 6.62629 9.77672 7.21426 10.4993 7.21426Z",
                    fill: "#F4F4F4"
                  }
                })
              ]
            ),
            _c("p", { staticClass: "fonterPref mb-3" }, [
              _vm._v("Tienes un turno preferencial")
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "card-box-white mb-4 littleBox d-flex align-items-center justify-content-center",
          class: !_vm.priority && "mt-5 pt-3"
        },
        [_vm._m(0)]
      ),
      _c("div", { staticClass: "Content card-box-white pt-3 px-5 pb-2" }, [
        _c(
          "audio",
          { attrs: { id: "attention" }, on: { ended: _vm.soundEmmited } },
          [
            _c("source", {
              attrs: {
                src: require("@/assets/sounds/campanitas.mp3"),
                type: "audio/mpeg"
              }
            })
          ]
        ),
        _vm._m(1),
        _vm.stage === -1
          ? _c(
              "div",
              { staticStyle: { position: "absolute", "margin-top": "30vh" } },
              [
                _c("span", { staticStyle: { "text-align": "center" } }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-info",
                      staticStyle: {
                        "font-style": "normal",
                        "font-weight": "600",
                        "font-size": "25.9817px",
                        "line-height": "34px",
                        "text-align": "center",
                        color: "#283645"
                      },
                      on: { click: _vm.startTurn }
                    },
                    [_vm._v("Reintentar")]
                  )
                ]),
                _c(
                  "h5",
                  {
                    staticClass: "mt-3",
                    staticStyle: {
                      "font-style": "normal",
                      "font-weight": "600",
                      "font-size": "25.9817px",
                      "line-height": "34px",
                      "text-align": "center",
                      color: "#283645"
                    }
                  },
                  [
                    _vm._v(
                      "No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico."
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm.stage === 0
          ? _c(
              "div",
              { staticClass: "position-relative d-inline text-center" },
              [
                _c("img", {
                  staticClass: "size_icon mx-auto",
                  attrs: { src: _vm.Conected }
                }),
                _vm._t("enqueue"),
                _c(
                  "h5",
                  {
                    staticStyle: {
                      "font-style": "normal",
                      "font-weight": "600",
                      "font-size": "25.9817px",
                      "line-height": "34px",
                      "text-align": "center",
                      color: "#283645"
                    }
                  },
                  [_vm._v("Estamos solicitando tu turno. Por favor espera.")]
                )
              ],
              2
            )
          : _vm._e(),
        _vm.stage === 1
          ? _c(
              "div",
              [
                _vm._t("waiting", null, { turn: _vm.turnCommon }),
                !_vm.onlyGenerate
                  ? [
                      _c(
                        "div",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "audioNotification",
                              class: {
                                isDisabled: !_vm.audioNotification,
                                isEnabled: _vm.audioNotification
                              },
                              attrs: { id: "checkbox-1", name: "checkbox-1" },
                              model: {
                                value: _vm.audioNotification,
                                callback: function($$v) {
                                  _vm.audioNotification = $$v
                                },
                                expression: "audioNotification"
                              }
                            },
                            [
                              _vm._v(
                                "Notificarme con audio cuando sea atendido"
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      !_vm.hideVideo
                        ? _c("p", { staticClass: "mt-2" }, [
                            _vm._v("Mientras esperas:")
                          ])
                        : _vm._e(),
                      !_vm.hideVideo
                        ? _c("div", [
                            _c("video", {
                              staticStyle: {
                                "max-width": "350px",
                                margin: "auto",
                                display: "block"
                              },
                              attrs: {
                                id: "video",
                                src: _vm.videoSource,
                                poster: _vm.videoPoster,
                                playsinline: "",
                                controls: ""
                              }
                            }),
                            _c("p", { staticClass: "mt-2" }, [
                              _vm._v("Conoce nuestro Autoservicio Digital")
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "div_center_button",
                          style: _vm.hideVideo ? "margin-top: 2rem;" : ""
                        },
                        [
                          _vm.status === "ABSENT"
                            ? _c(
                                "div",
                                { staticClass: "mb-4 color-colmedica-light" },
                                [
                                  _vm._v(
                                    "El turno fue marcado como 'Ausente'. Debe dirigirse a un asesor para que puedan volver a llamarlo."
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "u",
                            {
                              staticClass:
                                "modal_button cancelTurnDialog color-colmedica-light pb-5",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function($event) {
                                  _vm.showModal = true
                                }
                              }
                            },
                            [_vm._v("Cancelar Solicitud")]
                          )
                        ]
                      )
                    ]
                  : _vm._e(),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      footerClass: "p-2 border-top-0",
                      headerClass: "p-2 border-bottom-0",
                      centered: "",
                      "hide-footer": "",
                      "hide-header": ""
                    },
                    model: {
                      value: _vm.showModal,
                      callback: function($$v) {
                        _vm.showModal = $$v
                      },
                      expression: "showModal"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-2 position-relative w-auto h-auto" },
                      [
                        _c(
                          "div",
                          { staticClass: "d-block text-center modal_inside" },
                          [
                            _c(
                              "p",
                              { staticClass: "my-4 modal_highlight_text" },
                              [_vm._v("Estás a punto de cancelar la solicitud")]
                            ),
                            _c("p", { staticClass: "my-4" }, [
                              _vm._v(
                                "Esto cancelará el proceso de atención con el asesor " +
                                  _vm._s(_vm.comp)
                              )
                            ]),
                            _c("p", { staticClass: "my-4" }, [
                              _vm._v("¿Qué deseas hacer?")
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "position-relative" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-block cancelTurnConfirmation",
                                attrs: { variant: "outline-danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.cancelTurnAction()
                                  }
                                }
                              },
                              [_vm._v("Cancelar solicitud")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-block",
                                style: _vm.colorComputed,
                                attrs: { variant: "bluecolmedica" },
                                on: {
                                  click: function($event) {
                                    _vm.showModal = false
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "Continuar con el asesor " + _vm._s(_vm.comp)
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ],
              2
            )
          : _vm._e(),
        _vm.stage === 2
          ? _c(
              "div",
              [
                !_vm.siteIsOffice
                  ? _c("img", {
                      staticStyle: { "max-width": "66px", margin: "25px 0" },
                      attrs: { src: _vm.Altavoz, alt: "altavoz" }
                    })
                  : _c("img", {
                      attrs: {
                        src: require("../../../assets/bell.png"),
                        alt: "bell"
                      }
                    }),
                _c(
                  "h5",
                  {
                    staticClass: "mt-4 text-middle color-colmedica-gray",
                    style: _vm.isAtril
                      ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                      : "font-weight: bold !important; color: #545454;"
                  },
                  [_vm._v("Tu número")]
                ),
                _c(
                  "h4",
                  {
                    staticClass:
                      "text-middle color-colmedica-light font-weight-bold",
                    style: _vm.isAtril
                      ? "font-size: 2rem; !important; font-weight: bold;"
                      : " font-weight: bold !important; margin-bottom: 0;"
                  },
                  [_vm._v(_vm._s(_vm.myTurn))]
                ),
                _c(
                  "h5",
                  {
                    staticClass: "mt-4 text-middle color-colmedica-gray",
                    style: _vm.isAtril
                      ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                      : " font-weight: bold !important; color: #545454;"
                  },
                  [_vm._v("Ha sido llamado")]
                ),
                _vm.attentionModule
                  ? [
                      _c(
                        "h5",
                        {
                          staticClass:
                            "mt-4 text-middle color-colmedica-gray font-weight-bold",
                          style: _vm.isAtril
                            ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                            : "font-weight: bold !important; color: rgb(84, 84, 84); max-width: 187px; margin: 15px auto; font-size: 1.3rem; line-height: 25px;"
                        },
                        [_vm._v("Por favor acércate al módulo de atención")]
                      ),
                      _c(
                        "h4",
                        {
                          staticClass:
                            "text-middle color-colmedica-light font-weight-bold"
                        },
                        [_vm._v(_vm._s(_vm.attentionModule))]
                      ),
                      _c(
                        "h5",
                        {
                          staticClass: "mt-4 text-middle color-colmedica-light",
                          staticStyle: {
                            "font-weight": "bold !important",
                            color: "#545454"
                          }
                        },
                        [
                          _vm._v("Serás atendido por"),
                          _c(
                            "h4",
                            { staticClass: "ml-2 color-colmedica-light mt-1" },
                            [_c("strong", [_vm._v(_vm._s(_vm.attentionAgent))])]
                          )
                        ]
                      )
                    ]
                  : [
                      _c(
                        "h5",
                        {
                          staticClass: "mt-4 text-middle",
                          style: _vm.isAtril
                            ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                            : " font-weight: bold !important; color: #545454;"
                        },
                        [
                          _vm._v(
                            "Por favor procede al módulo que se indica en la pantalla de TV"
                          )
                        ]
                      )
                    ]
              ],
              2
            )
          : _vm._e(),
        _vm.stage === 3
          ? _c(
              "div",
              [
                _c(
                  "h5",
                  {
                    staticClass: "mt-4 text-middle color-colmedica-gray",
                    style: _vm.isAtril
                      ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                      : " font-weight: bold !important; color: #545454;"
                  },
                  [_vm._v("Tu turno ha finalizado.")]
                ),
                _c(
                  "h5",
                  {
                    staticClass: "mt-4 text-middle color-colmedica-gray",
                    style: _vm.isAtril
                      ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                      : " font-weight: bold !important; color: #545454;"
                  },
                  [_vm._v("Te esperamos en una próxima ocasión.")]
                ),
                _vm._t("endInfo", function() {
                  return [
                    _c(
                      "h6",
                      {
                        staticClass: "mt-4 text-middle color-colmedica-gray",
                        style: _vm.isAtril
                          ? "font-size: 2rem; color: #545454 !important; font-weight: bold;"
                          : " font-weight: bold !important; color: #545454;"
                      },
                      [_vm._v("Ya puedes cerrar esta ventana")]
                    )
                  ]
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm.stage === 1
        ? _c(
            "div",
            {
              staticClass: "buttons Footer",
              style: _vm.colorSecondaryComputed
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-end" },
                [_vm._t("waitingActions")],
                2
              )
            ]
          )
        : _vm._e(),
      _vm.stage !== 1 || _vm.isAtril
        ? _c("div", { staticClass: "Footer" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass:
          "specialRad d-flex justify-content-center align-items-center m-0"
      },
      [
        _c("span", [_vm._v("Servicio:")]),
        _c("strong", { staticClass: "ml-1 radiologyText" }, [
          _vm._v("Exámenes diagnosticos")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("audio", { attrs: { id: "aproximate" } }, [
      _c("source", {
        attrs: {
          src: require("@/assets/sounds/turno_audio.mp3"),
          type: "audio/mpeg"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }