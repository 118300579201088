var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Help" },
    [
      _c("div", { staticClass: "banderaSvgMobile" }),
      _c("Modal", {
        attrs: { header: true, goBack: _vm.goBack },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-start"
                  },
                  [
                    _c("img", {
                      staticClass: "image-confirm_data pl-3",
                      attrs: {
                        src: require("@/assets/mobileFSFB/cancel_data.png"),
                        alt: "confirm_data"
                      }
                    }),
                    _c("h2", { staticClass: "text-confirm_data ml-3" }, [
                      _c("strong", [
                        _vm._v("Bienvenido, eres un usuario nuevo")
                      ])
                    ])
                  ]
                ),
                _c(
                  "article",
                  { staticClass: "pl-2 pt-3 containerData mb-4 pb-1" },
                  [
                    _c("p", { staticClass: "mb-1 containerFontModal" }, [
                      _c("strong", { staticClass: "strongFont" }, [
                        _vm._v("Documento:")
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(_vm._s(_vm.number))
                      ])
                    ]),
                    _c("p", { staticClass: "mb-1 containerFontModal" }, [
                      _c("strong", { staticClass: "strongFont" }, [
                        _vm._v("Número:")
                      ]),
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v(
                          "******" +
                            _vm._s(
                              typeof _vm.document == "number"
                                ? String(_vm.document).substring(4)
                                : _vm.document.substring(4)
                            )
                        )
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between pt-3 pb-4" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBack px-4 py-2",
                        on: { click: _vm.goBack }
                      },
                      [_vm._v("Reingresar")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "buttonConfirm px-4 py-2",
                        on: { click: _vm.onContinue }
                      },
                      [_c("span", [_vm._v("Confirmar")])]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }