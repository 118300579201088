var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _vm.isPopup || _vm.isCorrect
        ? _c("div", { staticClass: "headerMenu" }, [
            _c(
              "button",
              {
                staticClass: "button-without-styles py-3 px-4 isPopu",
                attrs: { type: "button" },
                on: { click: _vm.onGoBackSim }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "19",
                      viewbox: "0 0 20 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                        stroke: "#0058A2",
                        "stroke-width": "3.12307",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isntIntersection && !_vm.isCorrect && !_vm.isPopup
        ? _c("div", { staticClass: "headerFixed" }, [
            !_vm.isCorrect
              ? _c(
                  "button",
                  {
                    staticClass: "button-without-styles py-3 px-4 isBad",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goToView("Autenticate")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#ffff",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "button-without-styles py-3 px-4 isSoft",
                    attrs: { type: "button" },
                    on: { click: _vm.handleContinue }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#ffff",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                ),
            _vm.isCorrect
              ? _c(
                  "h2",
                  {
                    staticClass: "TitleSection text-start color-white",
                    staticStyle: { "margin-top": "50px" }
                  },
                  [_vm._v("¿Dónde te atendimos?")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.isPopup
        ? _c("div", { staticClass: "text-center" }, [
            _vm._m(0),
            _c("div", { staticClass: "botton-actions-footer" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between mb-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-100 py-2 text-center mx-1",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: { click: _vm.handleUnderstand }
                    },
                    [
                      _c("span", { staticClass: "font-md" }, [
                        _vm._v("Entendido")
                      ])
                    ]
                  )
                ],
                1
              ),
              _vm._m(1)
            ])
          ])
        : _vm._e(),
      !_vm.isCorrect && !_vm.isPopup
        ? _c("div", { staticClass: "contain" }, [
            _c("div", { staticClass: "text-center d-block" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "32",
                    height: "32",
                    viewbox: "0 0 32 32",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z",
                      fill: "#3290E0"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z",
                      fill: "#F4F4F4"
                    }
                  })
                ]
              )
            ]),
            _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
              _vm._v(
                "Confirma la información del usuario que requiere la autorización"
              )
            ]),
            _c("div", { staticClass: "form-data mb-3 mt-4" }, [
              _c("div", { staticClass: "p-4" }, [
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c("p", { staticClass: "font-16 mb-0" }, [
                      _vm._v("Documento:")
                    ]),
                    _c("p", { staticClass: "mb-0 ml-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.selectedOption))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex align-items-start boxOfText flex-wrap"
                  },
                  [
                    _c(
                      "p",
                      { staticClass: "font-16 mb-0 text-break-white mr-1" },
                      [_vm._v("Número de documento:")]
                    ),
                    _c("p", { staticClass: "mb-0" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.docNumber))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c("p", { staticClass: "font-16 mb-0" }, [
                      _vm._v("Nombre:")
                    ]),
                    _c("p", { staticClass: "mb-0 ml-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.name))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-items-start boxOfText" },
                  [
                    _c("p", { staticClass: "font-16 mb-0 text-break-white" }, [
                      _vm._v("Celular:")
                    ]),
                    _c("p", { staticClass: "mb-0 ml-1" }, [
                      _c("strong", [_vm._v(_vm._s(_vm.phone))])
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-start boxOfText flex-wrap justify-content-center"
                  },
                  [
                    _vm._m(2),
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 d-flex flex-wrap ml-0 justify-content-center"
                      },
                      [
                        _c(
                          "strong",
                          {
                            staticClass: "text-truncate",
                            staticStyle: { "max-width": "80%" }
                          },
                          [_vm._v(_vm._s(_vm.email1))]
                        ),
                        _c(
                          "strong",
                          {
                            staticClass: "text-truncate",
                            staticStyle: { "max-width": "80%" }
                          },
                          [_vm._v(_vm._s(_vm.email2))]
                        )
                      ]
                    )
                  ]
                )
              ]),
              _vm._m(3)
            ]),
            _c("div", { staticClass: "botton-actions-footer" }, [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-100 py-2 text-center mx-1",
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: { click: _vm.handleContinue }
                    },
                    [
                      _c("span", { staticClass: "font-md" }, [
                        _vm._v("Continuar con estos datos")
                      ])
                    ]
                  )
                ],
                1
              )
            ])
          ])
        : !_vm.servicioSelected && _vm.isCorrect && !_vm.isPopup
        ? _c("div", { staticClass: "contain color-colmedica-gray" }, [
            _c("h2", { staticClass: "TitleSection text-center m-0" }, [
              _vm._v("Selecciona el servicio que")
            ]),
            _c("h2", { staticClass: "TitleSection text-center mb-4" }, [
              _vm._v("requieres autorizar:")
            ]),
            _c(
              "div",
              _vm._l(_vm.services, function(service) {
                return _c(
                  "button",
                  {
                    key: service.code_name,
                    staticClass: "w-100 button-without-styles my-2 mt-3",
                    on: {
                      click: function($event) {
                        return _vm.selecteService(service)
                      }
                    }
                  },
                  [
                    _c(
                      "stack-item",
                      {
                        staticClass: "btn-block align-self-center mx-auto pl-2"
                      },
                      [_vm._v(_vm._s(service.label))]
                    )
                  ],
                  1
                )
              }),
              0
            )
          ])
        : _vm.servicioSelected && _vm.isCorrect && !_vm.isPopup
        ? _c("div", { staticClass: "contain" }, [
            _c(
              "h2",
              {
                staticClass: "TitleSection text-start pl-4 pt-1 ml-2",
                attrs: { id: "headerCalendarTitle2" }
              },
              [_vm._v("¿Dónde te atendimos?")]
            ),
            _c(
              "p",
              {
                staticClass: "TextSection mt-3 text-start specialText mb-0 mr-3"
              },
              [
                _vm._v(
                  "La orden médica a autorizar te la entregó un médico de:"
                )
              ]
            ),
            _c("div", { staticClass: "mt-2" }, [
              _c(
                "button",
                {
                  staticClass:
                    "w-100 button-without-styles my-2 mt-3 position-relative",
                  on: {
                    click: function($event) {
                      return _vm.loadData("Centro Médico")
                    }
                  }
                },
                [
                  _c(
                    "stack-item",
                    { staticClass: "btn-block align-self-center mx-auto" },
                    [_vm._v("Centro Médico Colmédica")]
                  )
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "w-100 button-without-styles my-2 mt-3 position-relative",
                  on: {
                    click: function($event) {
                      return _vm.loadData("Profesional de la red", "files")
                    }
                  }
                },
                [
                  _c(
                    "stack-item",
                    { staticClass: "btn-block align-self-center mx-auto" },
                    [_vm._v("Profesional de la red")]
                  )
                ],
                1
              ),
              _c(
                "button",
                {
                  staticClass:
                    "w-100 button-without-styles my-2 mt-3 position-relative",
                  on: {
                    click: function($event) {
                      return _vm.loadData("Clínica", "files")
                    }
                  }
                },
                [
                  _c(
                    "stack-item",
                    { staticClass: "btn-block align-self-center mx-auto" },
                    [_vm._v("Clínica")]
                  )
                ],
                1
              )
            ]),
            _c("div", { staticClass: "botton-actions-2 ml-4 pl-2 pt-1" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.setIsCorrect(!_vm.isCorrect)
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#0058A2",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm._e(),
      !_vm.isCorrect
        ? _c("div", { staticClass: "botton-actions ml-4 pl-2" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "button-without-styles",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goToView("Autenticate")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "19",
                        viewbox: "0 0 20 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                          stroke: "#0058A2",
                          "stroke-width": "3.12307",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-flex",
        staticStyle: {
          "align-items": "center",
          "justify-content": "center",
          height: "calc(100vh - 112.531px)",
          "flex-direction": "column"
        }
      },
      [
        _c(
          "h2",
          {
            staticClass: "TitleSection pl-0 pt-5 pb-5",
            attrs: { id: "headerCalendarTitle2" }
          },
          [_vm._v("¡Información!")]
        ),
        _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
          _vm._v(
            "Este canal es sólo para solicitudes de servicios médicos de medicina prepagada."
          )
        ]),
        _c("p", { staticClass: "TextSection mt-2 mb-0 mx-3 color-dark" }, [
          _vm._v(" Si requieres servicios de Aliansalud EPS, como"),
          _c("strong", { staticClass: "ml-1" }, [
            _vm._v("Medicamentos ambulatorios")
          ]),
          _vm._v(", conoce cómo en el botón 'Más información'")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "TextSection pt-4 mb-0 mx-3 color-dark",
        staticStyle: {
          color: "#226DB1",
          "text-decoration": "underline",
          "font-size": "18px"
        },
        attrs: {
          href:
            "https://www.aliansalud.com.co/Paginas/Canales-de-atencion.aspx",
          target: "_blank"
        }
      },
      [_c("strong", [_vm._v("Más información")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100" }, [
      _c("p", { staticClass: "font-16 mb-0 text-break-white mr-1" }, [
        _vm._v("Correo electrónico:")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "text-center pb-4 px-4",
        staticStyle: {
          position: "relative",
          "border-radius": "0 0 21.4881px 21.4881px"
        }
      },
      [
        _c("p", [
          _c("strong", [
            _vm._v(
              "Enviaremos la respuesta de la autorización al correo registrado."
            )
          ])
        ]),
        _c("span", { staticClass: "ml-1" }, [
          _vm._v(
            "Si requieres actualizar datos, puedes hacerlo en la página web, línea de asistencia o en la videollamada con el asesor que realice la atención"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }