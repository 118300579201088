const rsa = new window.System.Security.Cryptography.RSACryptoServiceProvider(
  4096
);

async function configureRSA(key) {
  rsa.FromXmlString(key);
  const processedKey = rsa.ExportParameters(false);
  rsa.ImportParameters(processedKey);
  return rsa;
}

async function encryptPublicRSA(key, data) {
  const rsa = await configureRSA(key);
  const bytes = window.System.Text.Encoding.UTF8.GetBytes(data);
  const doOaepPadding = false;
  var encryptedBytes = rsa.Encrypt(bytes, doOaepPadding);
  return window.System.Convert.ToBase64String(encryptedBytes);
}

// eslint-disable-next-line no-unused-vars
async function signRSA(key, data) {
  const Data = await encryptPublicRSA(key, JSON.stringify(data));
  return { Data };
}

async function aesKey(keyAscii, ivAscii) {
  const encoder = new TextEncoder("ascii");
  const keyBytes = encoder.encode(keyAscii);
  const iv = encoder.encode(ivAscii);
  const key = await window.crypto.subtle.importKey(
    "raw",
    keyBytes,
    {
      name: "AES-CBC"
    },
    false,
    ["encrypt", "decrypt"]
  );
  return { key, iv };
}

async function encryptAES(keyAscii, ivAscii, data) {
  const { key, iv } = await aesKey(keyAscii, ivAscii);
  const utf8 = new TextEncoder("utf8");
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "AES-CBC",
      iv
    },
    key,
    utf8.encode(data)
  );
  const buff = new Uint8Array(encrypted);
  const str = String.fromCharCode.apply(null, buff);
  const result = btoa(str);
  return result;
}

async function decryptAES(keyAscii, ivAscii, encrypted) {
  const utf8 = new TextDecoder("utf8");
  const { key, iv } = await aesKey(keyAscii, ivAscii);
  const ascii = atob(encrypted);
  const asciiBytes = new Uint8Array(ascii.split("").map(c => c.charCodeAt(0)));
  const decrypted = await window.crypto.subtle.decrypt(
    {
      name: "AES-CBC",
      iv
    },
    key,
    asciiBytes
  );
  const buff = new Uint8Array(decrypted);
  const decoded = utf8.decode(buff);
  return decoded;
}

function encryptAESWorker(keyAscii, ivAscii, data) {
  return new Promise((res, rej) => {
    const aesWk = new Worker("./aeswk.js");
    aesWk.onmessage = res;
    aesWk.onerror = rej;
    aesWk.postMessage({ type: "encrypt", keyAscii, ivAscii, data });
  }).then(data => data.data);
}

function decryptAESWorker(keyAscii, ivAscii, data) {
  return new Promise((res, rej) => {
    const aesWk = new Worker("./aeswk.js");
    aesWk.onmessage = res;
    aesWk.onerror = rej;
    aesWk.postMessage({ type: "decrypt", keyAscii, ivAscii, data });
  }).then(data => data.data);
}

export { signRSA, encryptAES, decryptAES, encryptAESWorker, decryptAESWorker };
