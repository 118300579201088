import LS from "../../helpers/localStorage";

export default {
  view: "Home",
  isAtril: false,
  previousView: "",
  previousViewTwo: "",
  showAuth: false,
  HorarioOutContinue: false,
  charging: false,
  error: null,
  tramite_info: {
    queueId: "idSASASASXX2C12",
    branchId: "5a46d454da6a6",
    params: {}
  },
  env: {},
  showProgressBarAun: true,
  timerAun: null,
  explanation: false,
  tramiteInUserEvaluation: null,
  tramiteByDefault: null,
  validationErrors: [],
  errorValidationTemporizer: null,
  currentUser: LS.getItem("currentUser") || null,
  servicioSelected: null,
  selectedAccion: null,
  turn: null,
  time_received_turn: undefined,
  catch_all: null,
  priority: false,
  history: [],
  menu: [],
  containerImageDocs: null,
  showProgressBar: true,
  timer: null,
  showModalError: false,
  actions: null,
  tipide: [
    { value: "CC", text: "Cédula de Ciudadanía" },
    { value: "CE", text: "Cedula de Extranjería" },
    { value: "PA", text: "Pasaporte" },
    { value: "CD", text: "Carné Diplomático" },
    { value: "PE", text: "Permanencia Especial" },
    { value: "PT", text: "Permiso Protección Temporal" },
    { value: "SC", text: "Salvoconducto de Permanencia" },
    { value: "TE", text: "Tarjeta de Extranjería" },
    { value: "AS", text: "Adulto Sin Identificación" }
  ],
  citas: [
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: true,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    },
    {
      EnCentroMedico: false,
      Tramite: "Consulta programada",
      NumeroDeConfirmacion: "1546454654",
      Especialidad: "Cardiologia",
      Fecha: "13/02/2022,9:00a.m."
    }
  ],
  citaActual: null,
  currentValidation: [],
  currentSite: null,
  userDemo: [
    {
      NumeroIdentificacion: "456"
    },
    {
      NumeroIdentificacion: "789"
    }
  ],
  queueList: [],
  customArea: null,
  sites: [],
  primaryColorBlue: null,
  secondColorBlue: null,
  threeColorBlueColor: null,
  test_users: [
    {
      NumeroIdentificacion: 1033724553,
      Celular: "3017076719",
      Correo: "oscar.jimenez@brilliant360.net",
      Nombres: "Oscar",
      PrimerApellido: "Jimenez",
      Resultado: 1,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1000778041,
      Celular: "3154494547",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Derek",
      PrimerApellido: "Paul",
      Resultado: 1,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 80412679,
      Celular: "+17706853325",
      Correo: "marcelo.salazar@gobrilliant.com",
      Nombres: "Marcelo",
      PrimerApellido: "Salazar",
      Resultado: 1,
      SegundoApellido: "DEMO"
    } 
  ],
  client: {},
  service: null 
};
