var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Container" },
    [
      _vm.view === "TurnChecker" ? _c("TurnChecker") : _vm._e(),
      _vm.view === "Autenticate" ? _c("Autenticate") : _vm._e(),
      _vm.view === "UploadFiles" ? _c("UploadFiles") : _vm._e(),
      _vm.view === "VerificationData" ? _c("VerificationData") : _vm._e(),
      _vm.view === "ConfirmUpload" ? _c("ConfirmUpload") : _vm._e(),
      _vm.view === "ErrorAutenticate" ? _c("ErrorAutenticate") : _vm._e(),
      _vm.view === "ErrorAutenticateVal" ? _c("ErrorAutenticateVal") : _vm._e(),
      _vm.view === "BranchValidation" ? _c("BranchValidation") : _vm._e(),
      _vm.view === "OutOperationsFinish" ? _c("OutOperationsFinish") : _vm._e(),
      _vm.view === "Afiliate" ? _c("Afiliate") : _vm._e(),
      _vm.view == "NewUser" ? _c("NewUser") : _vm._e(),
      _vm.view === "IndexSchedules" ? _c("IndexSchedules") : _vm._e(),
      _vm.view === "CancelSchedule" ? _c("CancelSchedule") : _vm._e(),
      _vm.view === "ToSchedule" ? _c("ToSchedule") : _vm._e(),
      _vm.view === "Calendar"
        ? _c("Calendar", { attrs: { title: "Agenda tu videollamada" } })
        : _vm._e(),
      _vm.view === "CalendarRepro"
        ? _c("Calendar", { attrs: { title: "Reprogramar" } })
        : _vm._e(),
      _vm.view === "ConfirmSchedule" ? _c("ConfirmSchedule") : _vm._e(),
      _vm.view === "Alerted" ? _c("Alerted") : _vm._e(),
      _vm.view === "Popup" ? _c("Popup") : _vm._e(),
      _vm.view === "MeetingMeToGetTurn" ? _c("MeetingMeToGetTurn") : _vm._e(),
      _c("div", { staticClass: "fakeElementToScroll" }),
      _c("p", { staticClass: "Version" }, [
        _vm._v("versión: " + _vm._s(_vm.version))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }