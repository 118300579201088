var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showPresentation && _vm.localFiles.length > 0,
          expression: "showPresentation && localFiles.length>0"
        }
      ],
      staticClass: "modal_full"
    },
    [
      _vm.showMessage
        ? _c("div", { staticClass: "message_important" }, [_vm._m(0)])
        : _vm._e(),
      _vm.item && _vm.item.name && _vm.item.name.split(".")[1] != "mp4"
        ? _c("img", {
            staticStyle: {
              width: "-webkit-fill-available",
              height: "-webkit-fill-available",
              "object-fit": "contain"
            },
            attrs: { src: _vm.item.src }
          })
        : _vm._e(),
      _vm.item && _vm.item.name && _vm.item.name.split(".")[1] == "mp4"
        ? _c("video", {
            staticStyle: {
              width: "-webkit-fill-available",
              height: "-webkit-fill-available"
            },
            attrs: {
              id: _vm.item.id,
              src: _vm.item.src,
              autoplay: "",
              muted: ""
            },
            domProps: { muted: true }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative" }, [
      _c("img", {
        staticClass: "touchScreen",
        attrs: {
          src: require("@/assets/kiosco46/touchScreen.png"),
          alt: "touchScreen"
        }
      }),
      _c("img", {
        staticClass: "clicGif",
        attrs: { src: require("@/assets/kiosco46/clic.gif"), alt: "clic" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }