// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/whiteTriangule.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.Tutorial {\r\n  position: relative;\n}\n.buttonHelp {\r\n  width: 42px;\r\n  height: 42px;\r\n  border-radius: 6px;\r\n  background: #009fe3;\n}\n.helpIcon {\r\n  margin-top: 7px;\n}\n.tooltipTutorial {\r\n  width: 191px;\r\n  /* height: 56px; */\r\n  position: absolute;\r\n  padding: 0 16px;\r\n  border-radius: 4.84848px;\r\n  z-index: 23;\r\n  top: 58px;\r\n  right: -10px;\r\n  left: auto;\r\n  display: flex;\r\n  align-items: center;\r\n  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);\r\n  background: #f4f4f4;\n}\n.tooltipTutorial p {\r\n  font-style: normal;\r\n  font-weight: 700;\r\n  font-size: 18px;\r\n  line-height: 24px;\r\n  color: #0058a2;\n}\n.tooltipTutorial::after {\r\n  content: \"\";\r\n  width: 24.5px;\r\n  height: 10.91px;\r\n  position: absolute;\r\n  top: -8px;\r\n  right: 20px;\r\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\r\n\r\n/* @media screen and (max-width: 780px) {\r\n  .tooltipTutorial::after {\r\n    right: 13px;\r\n    \r\n  }\r\n} */\r\n", ""]);
// Exports
module.exports = exports;
