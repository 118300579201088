var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "NewUser" }, [
    _c("div", { staticClass: "NewUserOverlay" }),
    _c("div", { staticClass: "Modal NewUserModalContent" }, [
      _c(
        "header",
        {
          staticClass:
            "Modal_header py-3 p-1 d-flex justify-content-center align-items-center position-relative"
        },
        [
          _c(
            "div",
            {
              staticClass:
                "centeredInfo d-flex justify-content-center align-items-center mt-2 mb-1"
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "23",
                    height: "23",
                    viewbox: "0 0 23 23",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M0.85 11.5C0.85 5.61832 5.61792 0.850249 11.4995 0.85C17.3791 0.856526 22.1438 5.62136 22.15 11.5009C22.1495 17.3823 17.3815 22.15 11.5 22.15C5.61817 22.15 0.85 17.3818 0.85 11.5Z",
                      stroke: "white",
                      "stroke-width": "1.7"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M11.5013 6.71094C10.9558 6.71094 10.543 6.99787 10.543 7.46132V12.6705C10.543 13.1344 10.9557 13.4204 11.5013 13.4204C12.0335 13.4204 12.4596 13.1224 12.4596 12.6705V7.46132C12.4596 7.00887 12.0335 6.71094 11.5013 6.71094ZM11.5013 14.378C10.9772 14.378 10.5506 14.8079 10.5506 15.3366C10.5506 15.8644 10.9772 16.2943 11.5013 16.2943C12.0254 16.2943 12.4515 15.8644 12.4515 15.3366C12.4515 14.8079 12.0254 14.378 11.5013 14.378Z",
                      fill: "#F4F4F4"
                    }
                  })
                ]
              ),
              _c(
                "h4",
                {
                  staticClass: "m-0 ml-2 Title ml-2",
                  staticStyle: { color: "white" }
                },
                [_vm._v("Usuario no activo")]
              ),
              _c(
                "button",
                {
                  staticClass: "button-without-styles",
                  on: {
                    click: function() {
                      return _vm.goToView({ view: "Autenticate" })
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "position-absolute svgCloseIcon",
                      attrs: {
                        width: "20",
                        height: "21",
                        viewbox: "0 0 20 21",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M2.49023 17.7656L17.2565 2.99936",
                          stroke: "#F4F4F4",
                          "stroke-width": "4.55622",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d: "M2.49023 3.23438L17.2565 18.0006",
                          stroke: "#F4F4F4",
                          "stroke-width": "4.55622",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm._m(0),
      _c(
        "footer",
        { staticClass: "Modal_footer px-4 pb-3 mx-2" },
        [
          _c(
            "b-button",
            {
              staticClass: "text-center AuthsBtn",
              attrs: {
                variant: "bluecolmedica",
                block: "",
                size: "lg",
                type: "button"
              },
              on: {
                click: function() {
                  return _vm.goToView({ view: "Autenticate" })
                }
              }
            },
            [
              _c("p", { staticClass: "buttonText m-0" }, [
                _vm._v("Ingresar nuevos datos")
              ])
            ]
          ),
          _c(
            "a",
            {
              staticClass: "buttonText my-4",
              on: {
                click: function() {
                  return _vm.goToView({ view: "Afiliate" })
                }
              }
            },
            [_vm._v("Afiliate a Colmédica")]
          )
        ],
        1
      ),
      _vm.view === "NewUser" && !_vm.showAreYouThere
        ? _c(
            "p",
            { staticClass: "p-3" },
            [_c("BottomTimer", { attrs: { doThis: _vm.onShowAreYouThere } })],
            1
          )
        : _vm._e()
    ]),
    _vm.showAreYouThere
      ? _c(
          "div",
          [
            _c("AreYouThere", {
              on: { restartAreYouThere: _vm.restartTimerWaiting }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "Modal_content px-4 text-center" }, [
      _c("p", { staticClass: "Subtitle py-3" }, [
        _vm._v(
          "Asegurate de ingresar los datos correctos del usuario que requiere autorización"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }