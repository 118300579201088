var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-relative w-100 h-100",
      staticStyle: { "max-width": "100%" }
    },
    [
      _c("Header"),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-footer": "",
            "hide-header": "",
            size: "xl",
            centered: "",
            "no-close-on-backdrop": "",
            id: "autenticate"
          },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "ContainerModal" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center pb-4 px-2 pt-2"
              },
              [
                _c("img", {
                  staticStyle: { width: "28px" },
                  attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                  on: {
                    click: function($event) {
                      return _vm.goToView({ view: "Home" })
                    }
                  }
                }),
                _c("Timer", {
                  attrs: {
                    containerImageDocs: _vm.containerImageDocs,
                    timer: _vm.timer,
                    showProgressBar: _vm.showProgressBar
                  }
                })
              ],
              1
            ),
            _vm.loading || _vm.error
              ? _c("div", { staticClass: "OverlayStatus" }, [
                  _vm.loading
                    ? _c(
                        "div",
                        { staticClass: "ContainerInfo" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "Subtitle color-colmedica text-center"
                            },
                            [_vm._v("Validando datos, por favor espere")]
                          ),
                          _c("LargeSpinner", { attrs: { isFvVideo: true } })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.error
                    ? _c(
                        "div",
                        { staticClass: "ContainerInfo" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "Subtitle color-colmedica text-center"
                            },
                            [
                              _vm._v(
                                "Lo sentimos, pero hubo un error en la validación de sus datos"
                              )
                            ]
                          ),
                          _c("Error"),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center mt-4 mb-0"
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "fontBack mb-0",
                                  on: {
                                    click: function($event) {
                                      _vm.error = false
                                    }
                                  }
                                },
                                [_vm._v("< Atrás")]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "Panel pb-4 pt-0 mt-2" },
              [
                _c(
                  "p",
                  {
                    staticClass: "Subtitle weight-500 text-start mb-4",
                    staticStyle: { "line-height": "32px" }
                  },
                  [_vm._v("Selecciona tipo de identificación")]
                ),
                _c(
                  "div",
                  { staticClass: "position-relative mb-3" },
                  [
                    _c("b-form-select", {
                      staticClass:
                        "mb-4 py-2 pl-3 panelInput position-relative selectStyles",
                      attrs: {
                        options: _vm.options,
                        size: "sm",
                        required: "",
                        autocomplete: "off"
                      },
                      model: {
                        value: _vm.selected,
                        callback: function($$v) {
                          _vm.selected = $$v
                        },
                        expression: "selected"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "position-absolute arrowBottomStyles" },
                      [
                        _c("img", {
                          staticClass: "svgArrowBottom",
                          attrs: {
                            src: require("@/assets/kiosco46/svgArrowBottom.svg"),
                            alt: "svgArrowBottom"
                          }
                        })
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "p",
                  {
                    staticClass: "Subtitle weight-500 text-start mb-4",
                    staticStyle: { "line-height": "32px" }
                  },
                  [
                    _vm._v(
                      "Ingresa el número de identificación del usuario que requiere atención"
                    )
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.identificationNumber,
                      expression: "identificationNumber"
                    }
                  ],
                  ref: "idNumber",
                  staticClass: "panelInput Subtitle numberPanel",
                  attrs: { readonly: "" },
                  domProps: { value: _vm.identificationNumber },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.identificationNumber = $event.target.value
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    class: _vm.alphaNumeric ? "grid-container--alpha" : "ones"
                  },
                  [
                    _vm.alphaNumeric
                      ? _c(
                          "div",
                          { staticClass: "button_Grid--alpha" },
                          [
                            _vm._l(_vm.panelAlpha, function(item) {
                              return _c(
                                "button",
                                {
                                  key: item,
                                  staticClass: "button_Panel",
                                  on: {
                                    click: function() {
                                      return _vm.writeOnPanel(
                                        item.toUpperCase()
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.toUpperCase()))]
                              )
                            }),
                            _c(
                              "button",
                              {
                                staticClass: "button_Panel button123Borrar",
                                staticStyle: { "font-size": "20px" },
                                on: {
                                  click: function() {
                                    return _vm.writeOnPanel("<")
                                  }
                                }
                              },
                              [_vm._v("Borrar")]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "button_Panel button123",
                                on: {
                                  click: function() {
                                    return (_vm.alphaNumeric = false)
                                  }
                                }
                              },
                              [_c("strong", [_vm._v("123")])]
                            )
                          ],
                          2
                        )
                      : _c("div", { staticClass: "button_Grid" }, [
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(1)
                                }
                              }
                            },
                            [_vm._v("1")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(2)
                                }
                              }
                            },
                            [_vm._v("2")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(3)
                                }
                              }
                            },
                            [_vm._v("3")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(4)
                                }
                              }
                            },
                            [_vm._v("4")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(5)
                                }
                              }
                            },
                            [_vm._v("5")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(6)
                                }
                              }
                            },
                            [_vm._v("6")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(7)
                                }
                              }
                            },
                            [_vm._v("7")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(8)
                                }
                              }
                            },
                            [_vm._v("8")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(9)
                                }
                              }
                            },
                            [_vm._v("9")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return (_vm.alphaNumeric = true)
                                }
                              }
                            },
                            [_c("strong", [_vm._v("ABC")])]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel(0)
                                }
                              }
                            },
                            [_vm._v("0")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "button_Panel",
                              staticStyle: { "font-size": "20px" },
                              on: {
                                click: function() {
                                  return _vm.writeOnPanel("<")
                                }
                              }
                            },
                            [_vm._v("Borrar")]
                          )
                        ])
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "text-center AuthsBtn",
                    attrs: {
                      variant: "bluecolmedica",
                      block: "",
                      size: "lg",
                      type: "button",
                      disabled: _vm.identificationNumberValidation
                    },
                    on: { click: _vm.sendData }
                  },
                  [
                    _c("p", { staticClass: "buttonText m-0" }, [
                      _vm._v("Continuar")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }