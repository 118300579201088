<template lang="pug">
  .ModalLogs
    b-modal(
      hide-footer
      no-close-on-backdrop
      centered
      class="modal-content-dk"
      v-model="show")
      template(#modal-header="{ close }")
        h4 Registro de eventos
        .DropDown__Header__title
          i.fas.fa-times(style="cursor: pointer" @click="showModal(false)")
      .container
        .DropDown(v-for="(log, index) in logListMap" :key="index")
          .DropDown__Header(@click="() => toggleDropDown(index)")
            .DropDown__Header__title
              i(:class="log.checked ? 'fas fa-check CHECK' : 'fas fa-times TIMES'")
              h6 {{ log.name }}
            .DropDown__Header__icon
              i.fas.fa-sort-down(:ref="'iconItem__' + index").icon
          .DropDown__Body(:ref="'logItem__' + index" style="display: none")
            p {{ log.message }}
      hr
      .container
        .DropDown
          .DropDown__Header(@click="() => toggleDropDownMore()")
            .DropDown__Header__title
              //- i(:class="$refs.logItem__more.checked ? 'fas fa-check CHECK' : 'fas fa-times TIMES'")
              h6 Datos Vídeo Llamada
            .DropDown__Header__icon
              i.fas.fa-sort-down(ref="iconItem__more").icon
          .DropDown__Body(ref="logItem__more" style="display: none")
            p 
              b meeting: 
              pre {{meeting}}
            p 
              b connecting: 
              | {{connecting}}
            p 
              b audioId: 
              | {{audioId}}
            p 
              b audioInputDevices: 
              pre {{audioInputDevices}}
            p 
              b audioOutputDevices: 
              pre {{audioOutputDevices}}
            p 
              b videoInputDevices: 
              pre {{videoInputDevices}}
            p 
              b attendees: 
              | {{attendees}}
            p 
              b videosIds: 
              pre {{videosIds}}
            p 
              b myname: 
              | {{myname}}
            p 
              b videoDevice: 
              pre {{JSON.stringify(videoDevice) }}
            p 
              b speakerDevice: 
              pre {{JSON.stringify(speakerDevice) }}
            p 
              b audioDevice: 
              pre {{JSON.stringify(audioDevice) }}
            p 
              b audioEnabled: 
              | {{audioEnabled}}
            p 
              b camEnabled: 
              | {{camEnabled}}
            p 
              b speakerEnabled: 
              | {{speakerEnabled}}
            p 
              b bitrateKbps: 
              | {{bitrateKbps}}
            p 
              b stopSuggestionFromActiveCamera: 
              | {{stopSuggestionFromActiveCamera}}
            p 
              b packetsPerSecond: 
              | {{packetsPerSecond}}
            p 
              b nackCountPerSecond: 
              | {{nackCountPerSecond}}
            p 
              b videoStopSuggestion: 
              | {{videoStopSuggestion}}
            p 
              b connectionIsGood: 
              | {{connectionIsGood}}
            p 
              b estimatedBandwidth: 
              | {{estimatedBandwidth}}
            p 
              b requiredBandwidth: 
              | {{requiredBandwidth}}
            p 
              b m_allow_video: 
              | {{m_allow_video}}
            p 
              b sharingEnabled: 
              | {{sharingEnabled}}
            p 
              b meetingSession: 
              | {{meetingSession}}
            p 
              b isAgent: 
              | {{isAgent}}
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ModalLogs",

  computed: {
    ...mapState({
      show: state => state.log.showModal,
      logList: state => state.log.list,

      // test states
      meeting: state => state.meeting.meeting,
      connecting: state => state.meeting.connecting,
      audioId: state => state.meeting.audioId,
      audioInputDevices: state => state.meeting.audioInputDevices,
      audioOutputDevices: state => state.meeting.audioOutputDevices,
      videoInputDevices: state => state.meeting.videoInputDevices,
      attendees: state => state.meeting.attendees,
      videosIds: state => state.meeting.videosIds,
      myname: state => state.meeting.myname,
      videoDevice: state => state.meeting.videoDevice,
      speakerDevice: state => state.meeting.speakerDevice,
      audioDevice: state => state.meeting.audioDevice,
      audioEnabled: state => state.meeting.audioEnabled,
      camEnabled: state => state.meeting.camEnabled,
      speakerEnabled: state => state.meeting.speakerEnabled,
      bitrateKbps: state => state.meeting.bitrateKbps,
      stopSuggestionFromActiveCamera: state => state.meeting.stopSuggestionFromActiveCamera,
      packetsPerSecond: state => state.meeting.packetsPerSecond,
      nackCountPerSecond: state => state.meeting.nackCountPerSecond,
      videoStopSuggestion: state => state.meeting.videoStopSuggestion,
      connectionIsGood: state => state.meeting.connectionIsGood,
      estimatedBandwidth: state => state.meeting.estimatedBandwidth,
      requiredBandwidth: state => state.meeting.requiredBandwidth,
      m_allow_video: state => state.meeting.m_allow_video,
      sharingEnabled: state => state.meeting.sharingEnabled,
      meetingSession: state => state.meeting.meetingSession,
      isAgent: state => state.meeting.isAgent,
    }),

    logListMap() {
      return this.logList.map(log => ({
        ...log,
        showDropdown: false
      }));
    }
  },

  methods: {
    ...mapActions({
      showModal: "log/showModal"
    }),

    toggleDropDown(index) {
      this.$refs["logItem__" + index][0].style.display === "block"
        ? (() => {
            this.$refs["iconItem__" + index][0].style =
              "transform: rotate(0deg);";
            this.$refs["logItem__" + index][0].style = "display: none;";
          })()
        : (() => {
            this.$refs["iconItem__" + index][0].style =
              "transform: rotate(180deg);";
            this.$refs["logItem__" + index][0].style = "display: block;";
          })();
    },

    toggleDropDownMore() {
      this.$refs.logItem__more.style.display === "block"
        ? (() => {
            this.$refs.iconItem__more.style =
              "transform: rotate(0deg);";
            this.$refs.logItem__more.style = "display: none;";
          })()
        : (() => {
            this.$refs.iconItem__more.style =
              "transform: rotate(180deg);";
            this.$refs.logItem__more.style = "display: block;";
          })();
    }
  }
};
</script>

<style lang="scss" scoped>
.DropDown {
  border: 2px solid rgb(189, 189, 189);
  border-radius: 10px;
  transition: 0.5s all;
  /* min-height: 500px; */
}

.DropDown__Header {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 10px;
}

.DropDown__Header:hover {
  background-color: rgb(235, 235, 235);
}

.modal-content-dk {
  max-width: 400px !important;
}

.DropDown__Header__title {
  display: flex;
  align-items: center;
  margin: 0;
}

.DropDown__Header__title h6 {
  margin: 0;
  margin-left: 1rem;
}

.TIMES {
  color: rgb(224, 41, 41);
}

.container {
  max-height: 400px;
  overflow: auto;
}

.CHECK {
  color: rgb(28, 175, 28);
}

.DropDown__Header__title p {
  margin-left: 1rem;
}

.DropDown__Header__icon {
  margin: 0;
  margin-left: 1rem;
}

.DropDown {
  margin-top: 1rem;
}

.DropDown:nth-child(1) {
  margin-top: 0;
}

.DropDown__Body {
  opacity: 0;
  animation: fade 1s ease-in-out forwards;
  padding: 0.5rem;
}

.icon {
  transition: 1s all;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
