/* eslint-disable no-prototype-builtins */
import LS from "./../../helpers/localStorage";

export default {
  setState: (state, pay) =>
    pay &&
    typeof pay === "object" &&
    !Array.isArray(pay) &&
    pay.hasOwnProperty("key") &&
    pay.hasOwnProperty("value") &&
    state.hasOwnProperty(pay.key)
      ? (state[pay.key] = pay.value)
      : null,

  setMeeting(state, pay) {
    LS.setItem("meeting", pay);
    state.meeting = pay;
  },

  addMessage: (state, message) => {
    if (Array.isArray(state.messages) && state.messages.length)
      state.messages = [...state.messages, message];
    else state.messages = [message];
  },

  toogleCamModal(state) {
    state.m_allow_video = !state.m_allow_video;
  }
};
