import { getEnvOnly } from "./Meet";

let possibleNames = [
  "appointment_code",
  "branchInfo",
  "client_data",
  "coor_turnId",
  "data_b64",
  "e_mail",
  "last_name_user",
  "meeting",
  "name_user",
  "naturaleza",
  "person",
  "phone_number",
  "time",
  "tipide",
  "token",
  "user_id",
  "user_type"
];

export default {
  setItem(name, data) {
    const lsPrefix = getEnvOnly()?.VUE_APP_LS_PREFIX;
    return localStorage.setItem(`${lsPrefix}${name}`, JSON.stringify(data));
  },
  removeItem(name) {
    const lsPrefix = getEnvOnly()?.VUE_APP_LS_PREFIX;
    return localStorage.removeItem(`${lsPrefix}${name}`);
  },
  getItem(name) {
    const lsPrefix = getEnvOnly()?.VUE_APP_LS_PREFIX;
    let value = localStorage.getItem(`${lsPrefix}${name}`);
    try {
      value = JSON.parse(value);
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return value;
  },
  clear(){
    const lsPrefix = getEnvOnly()?.VUE_APP_LS_PREFIX;
    for (let index = 0; index < possibleNames.length; index++) {
      localStorage.removeItem(`${lsPrefix}${possibleNames[index]}`);
    }
  }
};
