var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Pay Container" }, [
    _c("div", { staticClass: "Content" }, [
      _c(
        "div",
        [
          _c(
            "article",
            { staticClass: "text-center" },
            _vm._l(_vm.$store.state.virtualrow.currentValidation, function(
              paso,
              i
            ) {
              return _c(
                "p",
                {
                  staticClass: "text-center",
                  class: {
                    ".mt-4": i === 0,
                    ".mt-2": i > 0,
                    ".mb-3":
                      i + 1 >=
                      _vm.$store.state.virtualrow.currentValidation.length
                  }
                },
                [_vm._v(_vm._s(paso.DescripcionPaso))]
              )
            }),
            0
          ),
          _vm._t("default")
        ],
        2
      )
    ]),
    _c("div", { staticClass: "Footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }