var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "AreYouThere",
      attrs: {
        id: "are-you-there-virtualrowFSFB",
        "hide-footer": "",
        "hide-header": "",
        size: "sm",
        centered: "",
        "no-close-on-backdrop": ""
      },
      model: {
        value: _vm.showAreYouThere,
        callback: function($$v) {
          _vm.showAreYouThere = $$v
        },
        expression: "showAreYouThere"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end pb-1 w-full",
          staticStyle: { "min-height": "62px" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-center align-items-center containerTimerC w-full"
            },
            [
              _vm.showCCT
                ? _c(
                    "div",
                    [
                      _c("circular-count-down-timer", {
                        attrs: {
                          "main-circle-id": "circle-3",
                          "show-value": false,
                          circles: _vm.circles
                        },
                        on: { update: _vm.onUpdateTimer }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c("img", {
                staticClass: "houseVirtualrowFSFB",
                attrs: {
                  src: require("../../../assets/houseVirtualrowFSFBWhite.svg"),
                  alt: "houseVirtualrowFSFBWhite"
                },
                on: { click: _vm.onGoHome }
              })
            ]
          )
        ]
      ),
      _c(
        "p",
        { staticClass: "fonterCurrentUser textHero mb-5 mx-auto d-block" },
        [_c("strong", [_vm._v("¿Aún estás ahí?")])]
      ),
      _c(
        "b-button",
        { staticClass: "buttonService", on: { click: _vm.onCloseModal } },
        [_vm._v("Sí, continuar")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }