var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simulator" }, [
    _c(
      "article",
      [
        _c("h5", [_vm._v("Probador de estado de branch")]),
        _c("b-form-input", {
          attrs: { placeholder: "Ingrese el nombre de la compañáa" },
          model: {
            value: _vm.text,
            callback: function($$v) {
              _vm.text = $$v
            },
            expression: "text"
          }
        }),
        !_vm.inTesting
          ? _c("b-button", [_vm._v("Iniciar")])
          : _c("b-button", { attrs: { variant: "danger" } }, [_vm._v("Parar")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }