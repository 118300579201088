var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ConfirmDataAuth" },
    [
      _c("div", { staticClass: "banderaSvgMobile" }),
      _c("Modal", {
        attrs: { header: true, goBack: _vm.goBack, disabled: _vm.charging },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c("img", {
                    staticClass: "image-confirm_data",
                    attrs: {
                      src: require("@/assets/mobileFSFB/confirm_data.png"),
                      alt: "confirm_data"
                    }
                  }),
                  _c("h2", { staticClass: "text-confirm_data ml-3" }, [
                    _vm._v("Confírmanos tus datos")
                  ])
                ]),
                _vm.docNumber
                  ? _c(
                      "article",
                      { staticClass: "pl-2 pt-3 containerData mb-4 pb-1" },
                      [
                        _c("p", { staticClass: "mb-1 containerFontModal" }, [
                          _c("strong", { staticClass: "strongFont" }, [
                            _vm._v("Nombre:")
                          ]),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.fullname))
                          ])
                        ]),
                        _c("p", { staticClass: "mb-1 containerFontModal" }, [
                          _c("strong", { staticClass: "strongFont" }, [
                            _vm._v("Documento:")
                          ]),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(_vm._s(_vm.docType))
                          ])
                        ]),
                        _c("p", { staticClass: "mb-1 containerFontModal" }, [
                          _c("strong", { staticClass: "strongFont" }, [
                            _vm._v("Número:")
                          ]),
                          _c("span", { staticClass: "ml-1" }, [
                            _vm._v(
                              "******" + _vm._s(_vm.docNumber.substring(4))
                            )
                          ])
                        ])
                      ]
                    )
                  : _c(
                      "article",
                      { staticClass: "pl-2 pt-3 containerData mb-4 pb-1" },
                      [
                        _c("div", { staticClass: "box-error" }, [
                          _c(
                            "div",
                            { staticClass: "pt-5 m-5" },
                            [
                              _c("AlertOutline", {
                                staticClass: "alert",
                                attrs: { size: 50 }
                              })
                            ],
                            1
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "text-center mb-3",
                              staticStyle: { color: "gray" }
                            },
                            [_vm._v("No encontramos información del usuario")]
                          )
                        ])
                      ]
                    ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between pt-3 pb-4" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBack px-4 py-2",
                        attrs: { disabled: _vm.charging },
                        on: { click: _vm.goBack }
                      },
                      [_vm._v("Reingresar")]
                    ),
                    _vm.docNumber
                      ? _c(
                          "button",
                          {
                            staticClass: "buttonConfirm px-4 py-2",
                            attrs: { disabled: _vm.charging },
                            on: { click: _vm.onContinue }
                          },
                          [
                            !_vm.charging
                              ? _c("span", [_vm._v("Confirmar")])
                              : _c("span", [_c("MiniSpinner")], 1)
                          ]
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }