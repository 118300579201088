var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TutorialVideos text-center" }, [
    _c("h2", { staticClass: "TitleSection mt-4 pt-2 mb-0" }, [
      _vm._v("Conoce más sobre Colmédica")
    ]),
    _c("div", { staticClass: "containerVideos" }, [
      _c(
        "div",
        { staticClass: "position-relative glider mt-4" },
        _vm._l(_vm.videos, function(video, index) {
          return _c("div", { staticClass: "position-relative" }, [
            _c("video", {
              staticClass: "VideoBordered mt-3 pt-1",
              attrs: {
                src: video,
                playsinline: "",
                controls: "",
                loop: "",
                muted: "false",
                id: index
              },
              domProps: { muted: true }
            }),
            _c(
              "button",
              {
                staticClass: "button-without-styles fullScreenButton",
                on: {
                  click: function() {
                    return _vm.goToFullScreen(index)
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/older_adults/fullScreen.svg"),
                    alt: "fullScreen"
                  }
                })
              ]
            ),
            _c("video", {
              staticClass: "mt-3 pt-1 VideoBorderedFullScreen",
              attrs: {
                src: video,
                playsinline: "",
                controls: "",
                loop: "",
                muted: "false",
                id: index + "videoToDisplay"
              },
              domProps: { muted: true }
            })
          ])
        }),
        0
      ),
      _c("div", { staticClass: "glider-prev" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "16",
              height: "9",
              viewbox: "0 0 16 9",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z",
                fill: "#d93535",
                stroke: "#d93535",
                "stroke-width": "0.4"
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "glider-next" }, [
        _c(
          "svg",
          {
            attrs: {
              width: "16",
              height: "9",
              viewbox: "0 0 16 9",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M8.5126 8.06871L8.51246 8.06883C8.39388 8.17361 8.23941 8.23001 8.08116 8.22955M8.5126 8.06871L15.5486 0.994141C15.49 0.935725 15.4203 0.889527 15.3439 0.857661C15.2675 0.8258 15.1855 0.808735 15.1027 0.807163C15.0199 0.805592 14.9373 0.819535 14.8597 0.848425C14.7828 0.877076 14.7119 0.920017 14.6513 0.975338L8.08132 6.79031L1.51263 0.97678C1.45297 0.920037 1.38258 0.875597 1.30584 0.845474C1.22763 0.814776 1.14403 0.799409 1.05997 0.800017C0.975898 0.800626 0.892535 0.817202 0.814792 0.849049C0.737035 0.880902 0.666104 0.927549 0.606618 0.986795C0.547104 1.04607 0.500251 1.11678 0.469584 1.195C0.438902 1.27326 0.425244 1.35697 0.429745 1.44094C0.434246 1.52489 0.456771 1.60667 0.495476 1.68126C0.533464 1.75447 0.586096 1.81899 0.649669 1.8715L7.64949 8.06766C7.64957 8.06773 7.64964 8.06779 7.64971 8.06786C7.76819 8.173 7.92273 8.22975 8.08116 8.22955M8.5126 8.06871L15.5142 1.87096C15.5765 1.81752 15.6277 1.75238 15.6642 1.67887C15.701 1.60471 15.722 1.52384 15.7255 1.44107L15.5256 1.43273L8.5126 8.06871ZM8.08116 8.22955C8.08096 8.22955 8.08076 8.22955 8.08057 8.22955L8.08132 8.02955L8.08175 8.22955C8.08155 8.22955 8.08136 8.22955 8.08116 8.22955Z",
                fill: "#d93535",
                stroke: "#d93535",
                "stroke-width": "0.4"
              }
            })
          ]
        )
      ]),
      _vm._m(0)
    ]),
    _c("div", { staticClass: "boxAttendee" }, [
      _c(
        "h4",
        { staticClass: "text-center AssignmentTextAttendee mb-0 mt-1" },
        [_vm._v("Tu turno asignado es")]
      ),
      _c(
        "h3",
        { staticClass: "text-center font-weight-bold ImportantAsignment mb-1" },
        [_vm._v(_vm._s(_vm.assignTurn))]
      ),
      _vm.typeofCond
        ? _c(
            "h5",
            { staticClass: "mt-1 text-center mb-1 AssignmentTextAttendee" },
            [_vm._v("Posición en la fila: ")]
          )
        : _vm._e(),
      _vm.typeofCond
        ? _c(
            "h5",
            { staticClass: "text-middle font-larger ImportantAsignment" },
            [
              _vm._v(
                _vm._s(
                  typeof _vm.turnsInFront == "number"
                    ? _vm.turnsInFront + 1
                    : "1"
                )
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-3 pb-3" }, [
      _c("div", { attrs: { id: "dots" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }