var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Home px-5 pb-5 pt-3" }, [
    _vm._m(0),
    _c("div", { staticClass: "d-flex align-items-start py-5 Home__content" }, [
      _c("img", {
        staticClass: "viewingPhone",
        attrs: {
          src: require("@/assets/older_adults/viewingPhone.png"),
          alt: "viewingPhone"
        }
      }),
      _c("article", { staticClass: "pl-4 Home__article" }, [
        _c("p", { staticClass: "mb-4 Home__TextMain" }, [
          _vm._v(
            "Si eres mayor de 60 años te brindamos asesoría preferencial para tus autorizaciones médicas por videollamada."
          )
        ]),
        _c("p", { staticClass: "Home__TextMain mb-4" }, [
          _vm._v(
            "Verifica que el audio, el micrófono y la cámara de tu dispositivo funcionen correctamente."
          )
        ]),
        _vm._m(1),
        _c(
          "div",
          { staticStyle: { width: "100%", "text-align": "center" } },
          [
            _c(
              "b-button",
              {
                staticClass: "mt-2 ButtonContinue",
                attrs: { variant: "bluecolmedica" },
                on: { click: _vm.onContinue }
              },
              [
                _c("p", { staticClass: "mb-0 ButtonContinue__text px-4" }, [
                  _vm._v("Continuar")
                ])
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "TitleHome" }, [
      _c("span", { staticClass: "blue" }, [
        _c("b", [_vm._v("Te damos la Bienvenida al")])
      ]),
      _c("br"),
      _c("span", { staticClass: "red" }, [_c("b", [_vm._v("¡")])]),
      _c("span", { staticClass: "blue" }, [
        _c("b", [_vm._v("Canal Preferencial Adulto Mayor")])
      ]),
      _c("span", { staticClass: "red" }, [_c("b", [_vm._v("!")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "Home__TextMain mb-4" }, [
      _c("b", [_vm._v("Horario de atención de videollamada es:")]),
      _c("br"),
      _c("span", [_vm._v("Lunes a Viernes de 7:00 am a 6:30 pm")]),
      _c("br"),
      _c("span", [_vm._v("Sábados de 7:00 am a 1:00 pm")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }