var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-alice",
      style: _vm.whiteC && "background-color: darkgray;"
    },
    [
      _c("div", {
        staticClass: "BottomTimer",
        style: _vm.whiteC && "background-color: white;",
        attrs: { id: "bottomTimer" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }