var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Header", class: [_vm.topClass], style: _vm.topStyle },
    [
      _vm.view !== "QR" && _vm.view !== "Turn"
        ? _c(
            "button",
            {
              staticClass: "button-without-styles d-inline-block align-middle",
              on: { click: _vm.goBack }
            },
            [
              _c("p", { staticClass: "goback m-0 mb-2" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "19",
                        height: "16",
                        viewbox: "0 0 19 16",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d:
                            "M19 8C19 8.30305 18.8749 8.59369 18.6522 8.80798C18.4296 9.02226 18.1275 9.14265 17.8126 9.14265L4.05579 9.14265L9.15435 14.0469C9.37731 14.2615 9.50256 14.5525 9.50256 14.8559C9.50256 15.1593 9.37731 15.4503 9.15435 15.6649C8.93139 15.8795 8.629 16 8.31369 16C7.99839 16 7.69599 15.8795 7.47304 15.6649L0.348832 8.809C0.238257 8.70285 0.150529 8.57676 0.0906703 8.43794C0.030812 8.29912 -1.22111e-06 8.1503 -1.20797e-06 8C-1.19483e-06 7.8497 0.030812 7.70088 0.0906704 7.56206C0.150529 7.42324 0.238257 7.29714 0.348832 7.191L7.47304 0.335097C7.69599 0.120538 7.99839 9.45557e-07 8.31369 9.73122e-07C8.629 1.00069e-06 8.9314 0.120538 9.15435 0.335097C9.37731 0.549657 9.50256 0.840662 9.50256 1.14409C9.50256 1.44753 9.37731 1.73853 9.15435 1.95309L4.05579 6.85735L17.8126 6.85735C18.1275 6.85735 18.4296 6.97773 18.6522 7.19202C18.8749 7.40631 19 7.69695 19 8Z",
                          fill: "#FAFAFB"
                        }
                      })
                    ]
                  )
                ]),
                _c(
                  "span",
                  { staticClass: "ml-2 d-inline-block align-middle" },
                  [_vm._v("Regresar")]
                )
              ])
            ]
          )
        : _c("span"),
      _vm.view !== "QR"
        ? _c(
            "div",
            {
              staticClass: "imageContainerHeader",
              staticStyle: { margin: "0px auto" }
            },
            [
              _c("img", {
                directives: [{ name: "openlog", rawName: "v-openlog" }],
                class: [_vm.logoClass],
                style: _vm.logoStyle,
                attrs: { src: _vm.Logo }
              })
            ]
          )
        : _c(
            "div",
            {
              staticClass: "imageContainerHeader",
              staticStyle: { margin: "0px auto" }
            },
            [
              _c("img", {
                directives: [{ name: "openlog", rawName: "v-openlog" }],
                staticClass: "p-0",
                class: [_vm.logoClass],
                style: _vm.logoStyle,
                attrs: { src: _vm.Logo }
              })
            ]
          ),
      _c("span"),
      _c("p", { staticClass: "sucursalName" }, [
        _vm._v("Sucursal: " + _vm._s(_vm.sucursalName))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }