// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/mobileFSFB/oldSenora.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Procedure {\n  height: 100vh;\n  overflow: auto;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: center;\n  background-size: cover;\n}\n.desc2 {\n  font-style: normal;\n  font-weight: 100;\n  font-size: 18px;\n  line-height: 25px;\n  color: var(--color-white);\n}\n.backButton {\n  padding-top: 21px;\n}\n.arrowButton {\n  width: 148px !important;\n  height: 142.75px;\n  background: #ffffff;\n  box-shadow: 0px 0px 5.77305px rgba(0, 0, 0, 0.25);\n  border-radius: 20px;\n}\n.arrowButton .svgIcon {\n  width: 50px;\n}\n.arrowButton span {\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 16px;\n  text-align: center;\n  color: #788591;\n}\n.yourTurnLabelProcedure {\n  font-family: var(--font-secondary);\n  font-size: 26px;\n  line-height: 30px;\n  text-align: center;\n  color: #ffffff;\n  padding-top: 26.12px;\n  margin-bottom: 12.12px;\n}\n.d-inline-block {\n  display: inline-block;\n}\n.columnClass {\n  flex-direction: column;\n  z-index: 9;\n  margin-bottom: 5%;\n}\n.columnClass h3 {\n  z-index: 9;\n}\n.logoAuth {\n  top: 0;\n  margin-top: 0;\n  margin-bottom: 0;\n  position: relative;\n}\n.childList h3 {\n  color: var(--color-white);\n  font-family: var(--font-secondary);\n  font-style: normal;\n  font-weight: 400;\n  font-size: 26px;\n  line-height: 30px;\n}\n.childList p {\n  text-align: start;\n  color: var(--color-white);\n  font-style: normal;\n  font-size: 16px;\n  line-height: 20px;\n  margin: 0 !important;\n}", ""]);
// Exports
module.exports = exports;
