var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box_size" }, [
    _c(
      "h2",
      { staticClass: "center-text", staticStyle: { "font-size": "2rem" } },
      [_vm._v(_vm._s(_vm.turn))]
    ),
    _c(
      "h4",
      {
        staticClass: "center-text mt-4",
        staticStyle: { "font-size": "1.5rem" }
      },
      [_vm._v(_vm._s(_vm.tramite))]
    ),
    _c(
      "h5",
      { staticClass: "center-text", staticStyle: { "font-size": "1rem" } },
      [_vm._v(_vm._s(_vm.branch))]
    ),
    _c(
      "h5",
      { staticClass: "center-text", staticStyle: { "font-size": "1rem" } },
      [_vm._v(_vm._s(_vm.queue))]
    ),
    _c(
      "h5",
      { staticClass: "center-text mt-4", staticStyle: { "font-size": "1rem" } },
      [_vm._v(_vm._s(_vm.date))]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }