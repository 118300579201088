import LS from "../helpers/localStorage";
import _get from "lodash/get";

export default {
  setPosition(state, pay) {
    state.position = pay;
  },
  setStarted: (state, pay) => (state.started = pay),
  setCoorelation: (state, pay) => (state.turnCoorelation = pay),
  setEnvironments: (state, pay) => {
    state.env = pay;
    if (pay.VUE_APP_ENV) state.environment = pay.VUE_APP_ENV;
  },
  setState: (state, pay) => {
    Object.prototype.hasOwnProperty.call(state, _get(pay, "key", "NA"))
      ? (() => {
          let key = _get(pay, "key", "NA"),
            value = _get(pay, "value", null);
          state[key] = value;
          if (key && key !== "NA") {
            if (value) LS.setItem(key, value);
            else LS.removeItem(key);
          }
        })()
      : null;
  }
};
