var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "list-group" }, [
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v(
        "Ancho de banda estimado: " + _vm._s(_vm.estimatedBandwidth) + " Kbps."
      )
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v(
        "Ancho de banda requerido: " + _vm._s(_vm.requiredBandwidth) + " Kbps."
      )
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v(
        "Estado de la conexión: " +
          _vm._s(_vm.connectionIsGood ? "Buena" : "Mala") +
          "."
      )
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v("Tasa de bits: " + _vm._s(_vm.bitrateKbps) + " Kbps.")
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v("Paquetes por segundo: " + _vm._s(_vm.packetsPerSecond) + ".")
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v("Fallos por segundo: " + _vm._s(_vm.nackCountPerSecond) + ".")
    ]),
    _c("li", { staticClass: "list-group-item" }, [
      _vm._v(
        "Sugerencia de detener video: " +
          _vm._s(_vm.videoStopSuggestion ? "Si" : "No") +
          "."
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }