var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "Progress", staticStyle: { position: "fixed" } }, [
      _c("div", { staticClass: "container p-5" }, [
        _c(
          "div",
          {
            staticClass: "progress",
            staticStyle: { cursor: "pointer" },
            attrs: { "data-percentage": _vm.percent },
            on: { click: _vm.toHome }
          },
          [
            _vm._m(0),
            _vm._m(1),
            _c("div", { staticClass: "progress-value" }, [
              _c("div", { staticClass: "h-100" }, [
                _c("span", { staticClass: "d-flex align-items-center h-100" }, [
                  _c(
                    "svg",
                    {
                      staticClass: "colorHouse",
                      attrs: {
                        width: "17",
                        height: "17",
                        viewbox: "0 0 17 17",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M16.5627 8.37355L8.92001 0.736435C8.86498 0.681298 8.79962 0.637555 8.72767 0.607709C8.65571 0.577863 8.57858 0.5625 8.50068 0.5625C8.42278 0.5625 8.34564 0.577863 8.27369 0.607709C8.20173 0.637555 8.13637 0.681298 8.08134 0.736435L0.438672 8.37355C0.216016 8.5962 0.0898438 8.89864 0.0898438 9.21407C0.0898438 9.86905 0.622363 10.4016 1.27734 10.4016H2.08262V15.8511C2.08262 16.1795 2.34795 16.4448 2.67637 16.4448H7.31318V12.2886H9.3913V16.4448H14.325C14.6534 16.4448 14.9187 16.1795 14.9187 15.8511V10.4016H15.724C16.0394 10.4016 16.3419 10.2773 16.5645 10.0527C17.0266 9.58888 17.0266 8.83741 16.5627 8.37355Z",
                          fill: "#F4F4F4"
                        }
                      })
                    ]
                  )
                ])
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-left" }, [
      _c("span", { staticClass: "progress-bar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progress-right" }, [
      _c("span", { staticClass: "progress-bar" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }