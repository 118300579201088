var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Content" }, [
    _c(
      "div",
      { staticClass: "Afiliate" },
      [
        _c("Header"),
        _c("div", { staticClass: "Page" }, [
          _c("div", { staticClass: "AfiliatePage text-center" }, [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between align-items-center"
              },
              [
                _c("img", {
                  staticStyle: { width: "28px" },
                  attrs: { src: require("@/assets/kiosco46/arrow.svg") },
                  on: {
                    click: function($event) {
                      return _vm.goToView({ view: "Autenticate" })
                    }
                  }
                }),
                _c("Timer", {
                  attrs: {
                    containerImageDocs: _vm.containerImageDocs,
                    timer: _vm.timer,
                    showProgressBar: _vm.showProgressBar
                  }
                })
              ],
              1
            ),
            _c("h2", { staticClass: "Title color-colmedica text-center" }, [
              _vm._v("Afiliate a Colmédica")
            ]),
            _c("p", { staticClass: "Subtitle mb-0 mt-3" }, [
              _vm._v(
                "Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:"
              )
            ]),
            _c("p", { staticClass: "Subtitle" }, [_vm._v("301 394 45 50")]),
            _c("p", { staticClass: "Subtitle mb-0" }, [
              _vm._v("El horario de atención para afiliaciones a Colmédica es:")
            ]),
            _c("p", { staticClass: "Subtitle mb-0" }, [
              _vm._v("Lunes a viernes: 7:00 a.m. a 7:00 p.m")
            ]),
            _c("p", { staticClass: "Subtitle" }, [
              _vm._v("Sábados: 9:00 a.m. a 1:00 p.m.")
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }