var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rootAu" },
    [
      _vm.view == "Home"
        ? _c("Home", { attrs: { inPresentation: _vm.inPresentation } })
        : _vm._e(),
      _vm.view == "TurnChecker" ? _c("TurnChecker") : _vm._e(),
      _vm.view == "Autenticate" ||
      _vm.view == "NewUser" ||
      _vm.view == "IsOlder" ||
      _vm.view == "IsOlderSecond"
        ? _c("Autenticate", { attrs: { newUser: _vm.view == "NewUser" } })
        : _vm._e(),
      _vm.view == "UploadFiles" ? _c("UploadFiles") : _vm._e(),
      _vm.view == "IsOlder" ? _c("IsOlder") : _vm._e(),
      _vm.view == "IsOlderSecond" ? _c("IsOlderSecond") : _vm._e(),
      _vm.view == "VerificationData" ? _c("VerificationData") : _vm._e(),
      _vm.view == "ConfirmUpload" ? _c("ConfirmUpload") : _vm._e(),
      _vm.view == "ErrorAutenticate" ? _c("ErrorAutenticate") : _vm._e(),
      _vm.view == "ErrorAutenticateVal" ? _c("ErrorAutenticateVal") : _vm._e(),
      _vm.view == "BranchValidation" ? _c("BranchValidation") : _vm._e(),
      _vm.view == "OutOperationsFinish" ? _c("OutOperationsFinish") : _vm._e(),
      _vm.view == "Afiliate" ? _c("Afiliate") : _vm._e(),
      _vm.view == "NewUser" ? _c("NewUser") : _vm._e(),
      _vm.view == "Home"
        ? _c("Presentation", {
            ref: "Presentation",
            on: { inPresentation: _vm.setPresentation }
          })
        : _vm._e(),
      _vm.showAreYouThere && _vm.view === "Turno"
        ? _c("AreYouThere", {
            on: { restartAreYouThere: _vm.restartAreYouThere }
          })
        : _vm._e(),
      _vm.showAreYouThere && _vm.view === "UploadFiles"
        ? _c("AreYouThere", {
            on: { restartAreYouThere: _vm.restartAreYouThere }
          })
        : _vm._e(),
      !_vm.HorarioOutContinue && _vm.view == "Home"
        ? _c("ModalOutHourContinue")
        : _vm._e(),
      _c("p", { staticClass: "Version" }, [
        _vm._v("versión: " + _vm._s(_vm.version))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }