var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-logo" },
    [
      _c("img", {
        directives: [{ name: "openlog", rawName: "v-openlog" }],
        staticClass: "stylelogo d-block",
        attrs: { src: _vm.Logo }
      }),
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        !_vm.paramsFail
          ? _c("div", [
              _c("h6", { staticStyle: { "margin-top": "3vh" } }, [
                _vm._v("Cargando parámetros...")
              ]),
              _c(
                "div",
                { staticClass: "mt-3 maxSizingProgress" },
                [_c("Spinner")],
                1
              )
            ])
          : _c(
              "h6",
              {
                staticStyle: {
                  "margin-top": "3vh",
                  "text-align": "center",
                  color: "firebrick"
                }
              },
              [
                _vm._v(
                  "La solicitud tiene parámetros inválidos y no puedes avanzar. Contacta con soporte técnico."
                )
              ]
            )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }