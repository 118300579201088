var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stage === 0,
              expression: "stage === 0"
            }
          ],
          staticClass: "row container_meet",
          style: {
            height: _vm.UsableHeight,
            width: _vm.meetType === "agent" ? "500px" : "100vw",
            margin: _vm.meetType === "agent" ? "0px auto" : ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "col-12 p-0 header p-1 position-relative" },
            [
              _c("img", {
                directives: [{ name: "openlog", rawName: "v-openlog" }],
                staticClass: "d-block h-100 mx-auto",
                attrs: { src: _vm.Logo },
                on: { click: _vm.debugModeCounter }
              }),
              _c("div", { staticClass: "version-position" }, [
                _vm._v(_vm._s(_vm.version) + " R")
              ])
            ]
          ),
          _vm._m(0)
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stage === 1,
              expression: "stage === 1"
            }
          ],
          staticClass: "row container_meet",
          style: {
            height: _vm.UsableHeight,
            width: _vm.meetType === "agent" ? "500px" : "100vw",
            margin: _vm.meetType === "agent" ? "0px auto" : ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "col-12 p-0 header p-1 position-relative" },
            [
              _c("img", {
                directives: [{ name: "openlog", rawName: "v-openlog" }],
                staticClass: "d-block h-100 mx-auto",
                attrs: { src: _vm.Logo },
                on: { click: _vm.debugModeCounter }
              }),
              _c("div", { staticClass: "version-position" }, [
                _vm._v(_vm._s(_vm.version))
              ]),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.videoStopSuggestion
                  ? _c(
                      "div",
                      { staticClass: "videoStopSuggestionMessage text-danger" },
                      [
                        _vm._v(
                          "Por problemas de red, hemos desactivado tu cámara. "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-12 p-0 videozone" },
            [
              _c("VideoCall", {
                attrs: { audioId: _vm.audioId, videosIds: _vm.videosIds },
                on: {
                  CamChange: function($event) {
                    return _vm.selectedDeviceInput("VideoInput")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "max-width": "940px",
                width: "100%",
                "justify-content": "center",
                margin: "auto",
                color: "#6C6B74",
                display: "flex",
                "align-items": "center",
                "margin-top": "5px"
              }
            },
            [
              _c("div", {
                class: _vm.attendees.length >= 2 ? "green-circle" : "red-circle"
              }),
              _c("small", [
                _vm._v(
                  _vm._s(
                    _vm.attendees.length >= 2
                      ? "Activo ahora"
                      : "Inactivo ahora"
                  )
                )
              ])
            ]
          ),
          _c("div", { staticClass: "col-12 p-0 chatzone" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.allowChat,
                    expression: "allowChat"
                  }
                ],
                staticClass: "chatWrapper"
              },
              [
                _c("div", { staticClass: "containerMsg" }, [
                  _c("div", { staticClass: "position-relative fullChat" }, [
                    _c("div", { staticClass: "dateTittle" }, [
                      _c("span", [_vm._v(_vm._s(_vm.timeDay))])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "messagesZone p-2",
                        attrs: { id: "full_messages" }
                      },
                      [
                        _vm._l(_vm.messages, function(msg, i) {
                          return _c(
                            "div",
                            {
                              key: "message_" + i,
                              staticClass: "d-flex mb-2 position-relative",
                              class: {
                                "justify-content-start": !msg.isSelf,
                                "justify-content-end": msg.isSelf
                              },
                              staticStyle: {
                                "max-width": "55%",
                                "min-width": "162px"
                              },
                              attrs: { id: "message_" + i }
                            },
                            [
                              _c("div", { staticClass: "msg_cotainer" }, [
                                _c("div", {
                                  class: {
                                    triangulo: msg.isSelf,
                                    triangulo_noself: !msg.isSelf
                                  }
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "msg_owner",
                                    class: {
                                      msg_owner_client: msg.isSelf,
                                      msg_owner_agent: !msg.isSelf
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "pharragraph_message" },
                                      [_vm._v(_vm._s(msg.attendeeName))]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          msg_time: !msg.isSelf,
                                          msg_time_send: msg.isSelf
                                        },
                                        staticStyle: { "margin-left": "5px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.timeFormat(msg.timestamp))
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "msg_text",
                                  style: {
                                    backgroundColor: msg.isSelf
                                      ? "#212624"
                                      : "#54678F",
                                    color: "#E9EBF2"
                                  },
                                  domProps: { innerHTML: _vm._s(msg.text) }
                                })
                              ])
                            ]
                          )
                        }),
                        _vm.typing
                          ? _c("div", { staticClass: "typing" }, [
                              _c("div", { staticClass: "triangulo-chat" }),
                              _vm._m(1)
                            ])
                          : _vm._e(),
                        _vm.typing
                          ? _c("div", { staticClass: "typing-2" }, [
                              _c("div", {
                                staticClass: "triangulo_noself-chat"
                              }),
                              _vm._m(2)
                            ])
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.allowChat,
                            expression: "allowChat"
                          }
                        ],
                        staticClass: "input-group p-2"
                      },
                      [
                        _c("input", {
                          ref: "file",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "*/*" },
                          on: { change: _vm.fileHandlerGeneral }
                        }),
                        _c("input", {
                          ref: "image",
                          staticStyle: { display: "none" },
                          attrs: { type: "file", accept: "image/*" },
                          on: { change: _vm.fileHandlerImage }
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-group-text attach_btn py-0 px-2 d-flex justify-content-center align-items-center attachFileToChat",
                              style: { borderRadius: "5px 0px 0px 5px" },
                              on: { click: _vm.simulateAttach }
                            },
                            [
                              _c("Attach", {
                                staticClass: "d-block",
                                attrs: { size: "25" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              staticClass:
                                "input-group-text attach_btn py-0 px-2 d-flex justify-content-center align-items-center attachImageToChat",
                              on: { click: _vm.simulateImage }
                            },
                            [
                              _c("Camera", {
                                staticClass: "d-block",
                                attrs: { size: "25" }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.message,
                              expression: "message"
                            }
                          ],
                          staticClass: "form-control type_msg",
                          attrs: { placeholder: "Ingrese su mensaje..." },
                          domProps: { value: _vm.message },
                          on: {
                            change: _vm.handleChangeMessage,
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.messageHandler.apply(null, arguments)
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.message = $event.target.value
                            }
                          }
                        }),
                        _c(
                          "div",
                          {
                            staticClass: "input-group-append",
                            on: { click: _vm.messageHandler }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "input-group-text send_btn py-0 px-2 d-flex justify-content-center align-items-center"
                              },
                              [
                                !_vm.showSendIcon
                                  ? _c("SendOutline", {
                                      staticClass: "d-block",
                                      attrs: { size: "20" }
                                    })
                                  : _vm._e(),
                                _vm.showSendIcon
                                  ? _c("Send", {
                                      staticClass: "d-block",
                                      attrs: { size: "20" }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ]
            ),
            _c("div", { staticClass: "bottomzone" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", { staticClass: "row" }, [
                  _vm.meetType === "agent"
                    ? _c(
                        "div",
                        {
                          staticClass: "col text-center",
                          class: {
                            "desktopSharingArea isShared": _vm.isSharedVideo,
                            "desktopSharingArea isNotShared": !_vm.isSharedVideo
                          },
                          staticStyle: { height: "50px" },
                          on: { click: _vm.toogleSharingEnabled }
                        },
                        [
                          _c(
                            "span",
                            {
                              style: {
                                color: _vm.isSharedVideo ? "#20a464" : "#DC5759"
                              }
                            },
                            [
                              _c("MonitorShare", {
                                staticClass: "size-buttons",
                                attrs: { size: "100%" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.debugButton
                    ? _c(
                        "div",
                        {
                          staticClass: "col text-center listStatusDialogShower",
                          staticStyle: { height: "50px" },
                          on: {
                            click: function($event) {
                              return _vm.$bvModal.show("List-status")
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticStyle: { color: "#DC5759" } },
                            [
                              _c("ListStatus", {
                                staticClass: "size-buttons",
                                attrs: { size: "100%" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  false
                    ? _c(
                        "div",
                        {
                          staticClass: "col text-center",
                          class: {
                            "speakerArea isShowed": _vm.speakerEnabled,
                            "speakerArea isHiden": !_vm.speakerEnabled
                          },
                          staticStyle: { height: "50px" },
                          on: {
                            click: function($event) {
                              return _vm.toogleSpeakerEnabled()
                            }
                          }
                        },
                        [
                          _c("span", { staticStyle: { color: "#DC5759" } }, [
                            _c("img", {
                              staticClass: "size-buttons",
                              attrs: {
                                src: _vm.speakerEnabled
                                  ? _vm.VolumeHigh
                                  : _vm.VolumeHighOff,
                                size: "100%"
                              }
                            })
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "col text-center",
                      class: {
                        "micArea isShowed": _vm.audioEnabled,
                        "micArea isHiden": !_vm.audioEnabled
                      },
                      staticStyle: { height: "50px" },
                      on: {
                        click: function($event) {
                          return _vm.toogleAudioEnabled()
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { color: "#DC5759" } }, [
                        _c("img", {
                          staticClass: "size-buttons",
                          attrs: {
                            src: _vm.audioEnabled ? _vm.Mic : _vm.MicOff,
                            size: "100%"
                          }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col text-center",
                      class: {
                        "camArea isShowed": _vm.camEnabled,
                        "camArea isHiden": !_vm.camEnabled
                      },
                      staticStyle: { height: "50px" },
                      on: { click: _vm.startCamFromBasis }
                    },
                    [
                      _c("span", { staticStyle: { color: "#DC5759" } }, [
                        _c("img", {
                          staticClass: "size-buttons",
                          attrs: {
                            src: _vm.camEnabled
                              ? _vm.VideoCam
                              : _vm.VideoCamOff,
                            size: "100%"
                          }
                        })
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col text-center cancelMeetDialog",
                      staticStyle: { height: "50px" },
                      on: {
                        click: function($event) {
                          return _vm.$bvModal.show("Finalize-call")
                        }
                      }
                    },
                    [
                      _c("span", { staticStyle: { color: "#DC5759" } }, [
                        _c("img", {
                          staticClass: "size-buttons",
                          attrs: { src: _vm.PhoneOff, size: "100%" }
                        })
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.stage !== 0 && _vm.stage !== 1,
              expression: "stage !== 0 && stage !== 1"
            }
          ],
          staticClass: "row container_meet",
          style: {
            height: _vm.UsableHeight,
            width: _vm.meetType === "agent" ? "500px" : "100vw",
            margin: _vm.meetType === "agent" ? "0px auto" : ""
          }
        },
        [
          _c(
            "div",
            { staticClass: "col-12 p-0 header p-1 position-relative" },
            [
              _c("img", {
                directives: [{ name: "openlog", rawName: "v-openlog" }],
                staticClass: "d-block h-100 mx-auto",
                attrs: { src: _vm.Logo },
                on: { click: _vm.debugModeCounter }
              }),
              _c("div", { staticClass: "version-position" }, [
                _vm._v(_vm._s(_vm.version))
              ])
            ]
          ),
          _vm._m(3)
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "Agent-not-connect",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("div", { staticClass: "p-2 position-relative w-auto h-auto" }, [
            _c("div", { staticClass: "d-block text-center" }, [
              _c("p", { staticClass: "my-4 font-weight-bold text-danger" }, [
                _vm._v("Lo sentimos")
              ]),
              _c("p", { staticClass: "my-4 text-danger" }, [
                _vm._v(
                  "No fue posible conectarte con " +
                    _vm._s(_vm.meetType != "agent" ? "un" : "cliente")
                )
              ]),
              _c("p", { staticClass: "my-4 text-danger" }, [
                _vm._v(
                  _vm._s(_vm.meetType != "agent" ? "cliente" : "asesor") +
                    " en este momento"
                )
              ]),
              _c("p", { staticClass: "my-4" }, [_vm._v("¿Qué deseas hacer?")])
            ]),
            _c(
              "div",
              { staticClass: "position-relative" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-block ",
                    attrs: { variant: "outline-danger" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push({ path: "/turno" })
                      }
                    }
                  },
                  [_vm._v("Reintentar conectar")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-block",
                    attrs: { variant: "danger" },
                    on: { click: _vm.end }
                  },
                  [_vm._v("Cancelar solicitud")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "Finalize-call",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": ""
          }
        },
        [
          _c("div", { staticClass: "p-2 position-relative w-auto h-auto" }, [
            _c("div", { staticClass: "d-block text-center" }, [
              _c("div", { staticClass: "my-4" }, [
                _c("p", { staticClass: "m-0 font-weight-bold text-danger" }, [
                  _vm._v("Estás a punto de finalizar la")
                ]),
                _c("p", { staticClass: "m-0 font-weight-bold text-danger" }, [
                  _vm._v("videollamada")
                ])
              ]),
              _c("p", { staticClass: "my-5 color-colmedica" }, [
                _vm._v("¿Qué deseas hacer?")
              ])
            ]),
            _c(
              "div",
              { staticClass: "position-relative" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-block",
                    attrs: { variant: "outline-bluecolmedica" },
                    on: {
                      click: function($event) {
                        return _vm.$bvModal.hide("Finalize-call")
                      }
                    }
                  },
                  [_vm._v("Volver a la videollamada")]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-block cancelMeetConfirmation",
                    attrs: { variant: "danger" },
                    on: { click: _vm.end }
                  },
                  [_vm._v("Finalizar la videollamada")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "Input-selector",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "hide-header": ""
          }
        },
        [
          _c("div", { staticClass: "p-2 position-relative w-auto h-auto" }, [
            _c(
              "div",
              { staticClass: "d-block text-center" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.deviceSelector != "VideoInput",
                        expression: "deviceSelector != 'VideoInput' "
                      }
                    ],
                    staticClass: "toggle my-2"
                  },
                  [
                    _c("img", {
                      staticClass: "icon-toogle",
                      attrs: {
                        id: "deviceOff",
                        src:
                          _vm.deviceSelector == "AudioInput"
                            ? _vm.MicOff
                            : _vm.VolumeHighOff
                      }
                    }),
                    _c("toggle-button", {
                      staticClass: "my-0 mx-2",
                      attrs: {
                        value: _vm.toogleDevice,
                        width: 140,
                        height: 30,
                        color: "#82C7EB",
                        labels: false
                      },
                      on: {
                        change: function($event) {
                          return _vm.disableDevice(true)
                        }
                      }
                    }),
                    _c("img", {
                      staticClass: "icon-toogle",
                      attrs: {
                        id: "deviceOn",
                        src:
                          _vm.deviceSelector == "AudioInput"
                            ? _vm.Mic
                            : _vm.VolumeHigh
                      }
                    })
                  ],
                  1
                ),
                _vm._l(_vm.selectedDeviceAllowed, function(device) {
                  return _vm.toogleDevice
                    ? [
                        device && device.deviceId
                          ? _c(
                              "b-button",
                              {
                                key: device.deviceId,
                                staticClass: "my-2 fontSizeButton",
                                attrs: {
                                  block: "",
                                  variant:
                                    _vm.toogleDevice &&
                                    _vm.deviceCurrent &&
                                    _vm.deviceCurrent.deviceId ==
                                      device.deviceId
                                      ? "bluecolmedica"
                                      : "outline-bluecolmedica"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.changeDeviceSelected(device)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(device.label))]
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                })
              ],
              2
            )
          ])
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "List-status",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            static: true
          }
        },
        [
          _c("ModalListStatus", {
            staticClass: "p-2 position-relative w-auto h-auto"
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "Allowed-video",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": ""
          }
        },
        [
          _c("h5", [_vm._v("Por favor activa tu cámara.")]),
          _c(
            "div",
            { staticClass: "buttons mt-4" },
            [
              _c(
                "b-button",
                {
                  staticClass: "camDialogActivate",
                  on: { click: _vm.startCameraHandler }
                },
                [_vm._v("Activar")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2 camDialogDeactivate",
                  on: { click: _vm.toogleCamModal }
                },
                [_vm._v("Desactivar")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "invalid-mime",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          }
        },
        [
          _c("h6", [
            _vm._v(
              "Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf."
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons mt-4 mimeErrorCloser" },
            [
              _c(
                "b-button",
                { staticClass: "ml-2", on: { click: _vm.toogleInvalidModal } },
                [_vm._v("Cerrar")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "invalid-size",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          }
        },
        [
          _c("h6", [
            _vm._v(
              "Soportamos archivos que pesen menos de 10Mb y sean de tipo imagen (jpeg, tiff, png) y pdf."
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons mt-4 sizeErrorCloser" },
            [
              _c(
                "b-button",
                { staticClass: "ml-2", on: { click: _vm.toogleInvalidModal } },
                [_vm._v("Cerrar")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 p-0" }, [
      _c("div", { staticClass: "imageConnection" }, [
        _c("img", {
          staticClass: "size_icon mx-auto",
          attrs: { src: require("@/assets/conected.png") }
        }),
        _c("h5", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Estamos conectandote. Por favor espera.")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "lds-ellipsis" }, [
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" }),
      _c("div", { staticClass: "into-ellipsis" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 p-0" }, [
      _c(
        "h5",
        { staticStyle: { "text-align": "center", color: "firebrick" } },
        [
          _vm._v(
            "No fue posible establecer la videollamada. La solicitud fue rechazada."
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }