var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100 maxContainer mx-auto" }, [
    _c(
      "div",
      { staticClass: "main_box" },
      [
        _c("Header", { staticClass: "m-0" }),
        _c("div", { staticClass: "TitleSection mt-4 pt-3 pb-3" }, [
          _vm._v("Tu videollamada ha finalizado")
        ]),
        _c("p", { staticClass: "TextSection mx-5 mt-5 pb-4" }, [
          _vm._v(
            "Gracias por utilizar nuestros servicios. Te esperamos en una proxima ocasión."
          )
        ]),
        _c(
          "p",
          { staticClass: "FontDesc text-start textLargeDes px-4 mt-5 pt-5" },
          [
            _vm._v(
              "Recuerda que también puedes solicitar tus autorizaciones médicas mediante nuestros canales virtuales App y Web Colmédica de manera rápida y segura."
            )
          ]
        ),
        _c(
          "footer",
          { staticClass: "ButtonFixed position-fixed" },
          [
            _vm.naturaleza
              ? _c(
                  "b-button",
                  {
                    staticClass: "buttonBack",
                    attrs: { variant: "whitecolmedica", disabled: "" },
                    on: {
                      click: function() {
                        return _vm.goToView("Home")
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "font-md" }, [
                      _vm._v("Ya puedes cerrar esta ventana")
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "mt-2 d-block text-center LinkUnderlined",
                on: { click: _vm.goToSchedule }
              },
              [
                _c("small", { staticClass: "TextSection" }, [
                  _vm._v("Ver videollamadas programadas")
                ])
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    !_vm.naturaleza
      ? _c(
          "div",
          { staticClass: "div_center_button", staticStyle: { bottom: "14vh" } },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "bluecolmedica" },
                on: { click: _vm.finishTurnClose }
              },
              [_vm._v("Volver a Profile_dev")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }