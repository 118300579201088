var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Help" },
    [
      _c("div", { staticClass: "banderaSvgMobile" }),
      _c("Modal", {
        attrs: { header: true, goBack: _vm.onGoBack },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center"
                  },
                  [
                    _c("img", {
                      staticClass: "image-confirm_data pl-3",
                      attrs: {
                        src: require("@/assets/mobileFSFB/error.png"),
                        alt: "confirm_data"
                      }
                    }),
                    _c("h2", { staticClass: "text-confirm_data ml-3" }, [
                      _c("strong", [_vm._v("Error")])
                    ])
                  ]
                ),
                _c(
                  "article",
                  { staticClass: "pl-2 pt-3 containerData mb-4 pb-1" },
                  [
                    _c("p", { staticClass: "mb-1 containerFontModal" }, [
                      _c("span", { staticClass: "ml-1" }, [
                        _vm._v("Error de servicio")
                      ])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between pt-3 pb-4" },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "buttonConfirm px-4 py-2 w-100 btnConfirmData",
                        on: { click: _vm.onAgain }
                      },
                      [
                        _vm.isLoadingAgain && _vm.isLoadingAgain
                          ? _c("span", [_c("MiniSpinner")], 1)
                          : _c("span", [_vm._v("Reintentar")])
                      ]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }