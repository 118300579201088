import axios from "axios";
import moment from "moment";
import rand from "randomstring";
import { encryptAES, decryptAES, signRSA } from "./encryption";
import { getEnvOnly } from "./Meet";

let publicKey;
let LlaveAES;
let Token;

function getLlavePublica() {
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  if (publicKey) return publicKey;
  return axios
    .get(`${server}/login/llavepublica`)
    .then(data => data.data)
    .then(pub => {
      publicKey = pub;
      return pub;
    });
}

async function colmedicaDecode(encoded) {
  let ivString = encoded.substr(0, 16);
  let encodedString = encoded.substr(16);
  const decoded = await decryptAES(LlaveAES, ivString, encodedString);
  console.log(decoded)
  return decoded;
}

async function autorizar({
  // LF Tested OK 21-01-20
  TipoIdentificacion,
  NumeroIdentificacion,
  CodigoOficina
}) {
  const keyString = rand.generate(32);
  const enc = await signRSA(await getLlavePublica(), {
    TipoIdentificacion,
    NumeroIdentificacion,
    CodigoOficina,
    Key: keyString
  });
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  const { data } = await axios.post(`${server}/login/autorizar`, {
    mensaje: enc.Data
  });

  LlaveAES = keyString;

  let results = await colmedicaDecode(data);

  console.log(results)

  results = JSON.parse(results);
  if (results.Resultado === 1) {
    Token = results.Token;
    LlaveAES = results.LlaveAes;
  } else {
    console.error("Invalid site auth", results);
    throw new Error("Invalid Authorization in Huella FV");
  }
}

async function enOficina() {
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  return axios
    .post(`${server}/cliente/oficina`, undefined, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc));
}

async function enCentroMedico() {
  // LF Tested 21-01-21
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  return axios
    .post(`${server}/cliente/centromedico`, undefined, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc));
}

async function citas() {
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  return axios
    .post(`${server}/cliente/citas`, undefined, {
      headers: {
        Authorization: `Bearer ${Token}`
      }
    })
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc))
    .then(citas => {
      console.log('citas')
      console.log(citas)
      return citas.map(cita => ({
        ...cita,
        idCita: cita.IdCita,
        Fecha: moment(cita.Fecha).format("YYYY-MM-DD h:mm a")
      }))
    }

    );
}

async function validarPago({ idCita }) {
  const server = getEnvOnly()?.VUE_APP_HUELLA_FV_SERVER;
  const ivString = rand.generate(16);
  const encoded = await encryptAES(
    LlaveAES,
    ivString,
    JSON.stringify({
      idCita
    })
  );
  return axios
    .post(
      `${server}/cliente/validarpagocita`,
      {
        mensaje: encoded,
        iv: ivString
      },
      {
        headers: {
          Authorization: `Bearer ${Token}`
        }
      }
    )
    .then(data => data.data)
    .then(enc => colmedicaDecode(enc))
    .then(enc => JSON.parse(enc));
}

export { autorizar, enCentroMedico, enOficina, citas, validarPago };
