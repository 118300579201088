var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-relative w-100 h-100",
      staticStyle: { "max-width": "100%" }
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "0 12%" } },
        [
          _c("Header"),
          _c(
            "b-modal",
            {
              attrs: {
                id: "small-modal-dk-over",
                "hide-footer": "",
                "hide-header": "",
                size: "xl",
                centered: "",
                "no-close-on-backdrop": ""
              },
              model: {
                value: _vm.showPrincipalModal,
                callback: function($$v) {
                  _vm.showPrincipalModal = $$v
                },
                expression: "showPrincipalModal"
              }
            },
            [
              _vm.isSending
                ? _c(
                    "div",
                    { staticClass: "contain" },
                    [
                      _c(
                        "StaticTimer",
                        _vm._l(_vm.filesIndexes, function(fi) {
                          return _c(
                            "p",
                            {
                              staticClass: "PAnimated",
                              style:
                                "transform: translateY(-" +
                                fi * 45 +
                                "px) translateX(-50%); opacity: 0.5;"
                            },
                            [_vm._v(_vm._s(fi))]
                          )
                        }),
                        0
                      ),
                      _c("h3", { staticClass: "Subtitle d-block" }, [
                        _c("strong", [
                          _vm._v(
                            "Estamos enviando " +
                              _vm._s(
                                _vm.files.length > 1
                                  ? "tus documentos"
                                  : "tu documento"
                              )
                          )
                        ])
                      ]),
                      _c("h3", { staticClass: "Subtitle d-block" }, [
                        _vm._v("y alistando tu videollamada")
                      ]),
                      _c("div", { staticClass: "pb-5" }),
                      _c("div", { staticClass: "pb-5" }),
                      _c("div", { staticClass: "pb-5" }),
                      _c(
                        "div",
                        { staticClass: "my-5 maxSizingProgress" },
                        [_c("Spinner")],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }