var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "IsOlderSecondOverlay" }),
    _c(
      "div",
      {
        staticClass: "IsOlderSecondModal p-4 text-center",
        attrs: { id: "IsOlderSecondModal" }
      },
      [
        _c("img", {
          staticClass: "redalert py-4",
          attrs: {
            src: require("@/assets/kiosco46/redalert.svg"),
            alt: "redalert"
          }
        }),
        _c("p", { staticClass: "buttonText mb-0" }, [
          _vm._v("Canal exclusivo para mayores de")
        ]),
        _c("p", { staticClass: "buttonText pb-3" }, [_vm._v("60 años")]),
        _c("p", { staticClass: "buttonText" }, [
          _vm._v(
            "Te invitamos a realizar tu solicitud a través de los diferentes canales:"
          )
        ]),
        _vm._m(0),
        _c(
          "b-button",
          {
            staticClass: "AuthsBtn text-center",
            attrs: {
              variant: "bluecolmedica",
              block: "",
              size: "lg",
              type: "button"
            },
            on: {
              click: function() {
                return _vm.goToView({ view: "Autenticate" })
              }
            }
          },
          [
            _c("p", { staticClass: "buttonText m-0" }, [
              _vm._v("Ingresar nuevos datos")
            ])
          ]
        ),
        _vm.view === "IsOlderSecond" && !_vm.showAreYouThere
          ? _c(
              "div",
              { staticClass: "p-3" },
              [
                _c("BottomTimer", {
                  attrs: {
                    doThis: _vm.onShowAreYouThere,
                    $element: "#IsOlderSecondModal"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.showAreYouThere
          ? _c(
              "div",
              [
                _c("AreYouThere", {
                  on: { restartAreYouThere: _vm.restartTimerWaiting }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-2 pb-4" }, [
      _c("p", { staticClass: "buttonText m-0" }, [_vm._v("Código QR")]),
      _c("div", { staticClass: "qrcode_container mt-3 mb-4" }, [
        _c("div", { attrs: { id: "qrcode" } })
      ]),
      _c("p", { staticClass: "buttonText m-0" }, [_vm._v("App")]),
      _c("p", { staticClass: "buttonText m-0" }, [_vm._v("Web")]),
      _c("p", { staticClass: "buttonText m-0" }, [
        _vm._v("WhatsApp 3175031111")
      ]),
      _c("p", { staticClass: "buttonText m-0" }, [
        _vm._v("Línea de asistencia")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }