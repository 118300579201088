import Bowser from "bowser";
import { getEnvOnly } from "./Meet";

export default function() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const env = getEnvOnly();
  return {
    sm: null,
    bsix: {
      v: env.PACKAGE_VERSION || "0",
      e: env.VUE_APP_ENV || "develop"
    },
    br: {
      n: browser.getBrowser().name,
      v: browser.getBrowser().version
    },
    os: {
      n: browser.getOS().name,
      v: browser.getOS().version,
      vn: browser.getOS().versionName
    },
    pl: browser.getPlatformType(),
    en: {
      n: browser.getEngine().name,
      v: browser.getEngine().version
    }
  };
}
