import _omit from "lodash/omit";
import moment from "moment";
import kuid from "kuid";
import LS from "./localStorage";

let envGlobal = JSON.parse(JSON.stringify(process.env));
let tries = 0;
let promiseEnv = null;
let requestedEnvironments = false;

let passphrase = "bsiTokenGenerator";
var token = LS.getItem(token);
var refresh = LS.getItem(refresh);
var time = LS.getItem(time);

async function requester(ctx) {
  const { headers = {}, url, body } = ctx;
  if (!url) return Promise.reject(new Error("URL is required"));
  let env = getEnvOnly();
  return await fetch(`${env.VUE_APP_AWS_API_URL}${url}`, {
    ..._omit(ctx, ["headers", "url", "body"]),
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      ...{
        "x-api-key": env.VUE_APP_AWS_API_KEY,
        "Content-Type": "application/json"
      },
      ...headers
    },
    mode: "cors"
  })
    .then(response => {
      if (
        typeof response.status === "number" &&
        response.status >= 200 &&
        response.status < 400
      )
        return response;

      if (url == "/meet/meet-coorelation" && response.status == 410)
        return response;

      throw new Error(
        `Invalid request status ${response.status}. ${JSON.stringify(
          response.json()
        )}`
      );
    })
    .then(response => response.json());
}

function windowDispatchEvent(name, detail) {
  let event = new CustomEvent(name, { detail });
  window.dispatchEvent(event);
}

function join(info) {
  if (typeof info !== "object" || !info.title || !info.name)
    return Promise.reject(
      new Error("Invalid paramethers for meeting creation")
    );
  return getToken().then(key => {
    return requester({
      url: "/meet/join",
      body: info,
      method: "POST",
      headers: {
        Authorization: key
      }
    });
  });
}

function getCompany(company) {
  if (!company)
    return Promise.reject(new Error("Invalid paramethers for Company request"));
  return getToken().then(key => {
    return requester({
      url: `/company/${company}`,
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function getBranch(company, branch) {
  if (!company || !branch)
    return Promise.reject(new Error("Invalid paramethers for Branch request"));
  return getToken().then(key => {
    return requester({
      url: `/company/${company}/branch/${branch}`,
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function getEnqueueTurn(tramiteId, branchId, info) {
  if (!tramiteId || !branchId || !info) {
    console.log("Invalid paramethers for Enqueue turn request");
    return Promise.reject(
      new Error("Invalid paramethers for Enqueue turn request", {
        tramiteId,
        branchId,
        info
      })
    );
  }

  return getToken().then(key => {
    return requester({
      url: `/queue/${tramiteId}/branch/${branchId}/enqueue`,
      method: "POST",
      body: info,
      headers: {
        Authorization: key
      },
      timeout: 10000
    });
  });
}

function getTurnInfo(turnCode) {
  if (!turnCode)
    return Promise.reject(new Error("Invalid turn code for request"));
  return getToken().then(key => {
    return requester({
      url: `/turn/${turnCode}`,
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function cancelTurn(turnCode) {
  if (!turnCode)
    return Promise.reject(new Error("Invalid turn code for request"));
  return getToken().then(key => {
    return requester({
      url: `/turn/${turnCode}/cancel`,
      method: "POST",
      headers: {
        Authorization: key
      }
    });
  });
}

function sendFile(element) {
  // eslint-disable-next-line no-constant-condition
  if (
    element.call_id &&
    element.turn_code &&
    element.name &&
    element.type &&
    element.b64
  ) {
    return getEnvironments().then(env => {
      return new Promise((res, rej) => {
        let nameSplitted = element.name.split(".");
        let mom = moment().utc();
        let nameOfUuidfile = `${kuid()}.${
          nameSplitted[nameSplitted.length - 1]
        }`;
        let hour = mom.format("HH");
        var timedate = mom.format("DDMMYYYY");
        var data = element.b64;
        var xhr = new XMLHttpRequest();
        const destiny = `${env.VUE_APP_FILE_SERVER}/${timedate}/${hour}/${element.call_id}/chat_files/${nameOfUuidfile}`;

        xhr.addEventListener("readystatechange", function() {
          if (this.readyState === 4) {
            if (xhr.status < 400 && xhr.status !== 0)
              res({ url: destiny, response: this.responseText });
            else rej(new Error("File upload failed with status " + xhr.status));
          }
        });

        xhr.open(
          "PUT",
          destiny
          // "https://l7hnmujmad.execute-api.us-east-1.amazonaws.com/prod/album1/anotherText.txt"
        );
        xhr.setRequestHeader("x-api-key", env.VUE_APP_BSI_KEY);
        xhr.setRequestHeader(
          "x-amz-meta-turn-code",
          encodeURIComponent(element.turn_code)
        );
        xhr.setRequestHeader(
          "x-amz-meta-original-name",
          encodeURIComponent(element.name)
        );
        xhr.setRequestHeader("Content-Type", element.type);
        xhr.onerror = err => rej(err);
        xhr.send(data);
      });
    });
  }
}

function getCorrelation(kuidId) {
  if (!kuidId)
    return Promise.reject(new Error("Invalid paramethers for coorelation"));
  /* eslint-disable */
  return new Promise( resolve => {
    return getToken().then( async key => {
      const response = await requester({
        url: `/meet/meet-coorelation`,
        body: {
          kuid: kuidId
        },
        method: "PUT",
        headers: {
          Authorization: key
        }
      });
      resolve(response)
    });
  });
  /* eslint-enable */
}

function getQueues() {
  return getToken().then(key => {
    return requester({
      url: "/queues",
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function getMenus(code_id) {
  return getToken().then(key => {
    return requester({
      url: `/menu${code_id ? "/" + code_id : ""}`,
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function getStyles() {
  return getToken().then(key => {
    return requester({
      url: "/styles",
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

function recursiveRequest(config, errorEventFunction) {
  return new Promise(resolve => {
    executor();
    // eslint-disable-next-line no-unused-vars
    async function executor() {
      let time = Date.now();
      try {
        const response = await requester(config);
        resolve(response);
      } catch (error) {
        console.error("Error in recursiveRequest", error);
        let newTime = Date.now() - time;
        if (typeof errorEventFunction === "function")
          errorEventFunction(newTime);
        if (newTime < 2000) setTimeout(() => executor(), 2000 - newTime);
        else executor();
      }
    }
  });
}

function getEnvironments() {
  let code = location.hash.split("fv_atril/");
  if (code.length > 1) {
    code = code.length > 1 ? code[1] : null;
  } else {
    code = location.hash.split("fv/");
    code = code.length > 1 ? code[1] : null;
  }

  if (requestedEnvironments) return Promise.resolve(envGlobal);
  promiseEnv = recursiveRequest(
    {
      url: "/environment",
      method: "POST",
      body: { url: location.origin, code: code }
    },
    () => {
      let adviceArea = document.querySelector("#app #messageInternal");
      if (adviceArea) {
        adviceArea.innerHTML = `Fallo en la conexión. Reintento # ${++tries}.`;
      }
    }
  ).then(newEnv => {
    promiseEnv = null;
    requestedEnvironments = true;
    envGlobal = { ...envGlobal, ...(newEnv?.env || {}), ...(newEnv?.silver || {}) };
    return envGlobal;
  });
  return promiseEnv;
}

async function setCorrelation({ kuidId, turnId, ...others }) {
  if (!kuidId && !turnId)
    return Promise.reject(new Error("Invalid paramethers for coorelation"));

  // check if existe correlation
  let coo = await getCorrelation(kuidId);
  if (coo && coo.message != "Can't read title(kuid) in table.") {
    return coo;
  }

  return getToken().then(key => {
    return requester({
      url: "/meet/meet-coorelation",
      body: {
        kuid: kuidId,
        title: turnId,
        ...others
      },
      method: "POST",
      headers: {
        Authorization: key
      }
    });
  });
}

function end(info) {
  if (typeof info !== "object" || !info.title || !info.meetingId)
    return Promise.reject(new Error("Invalid paramethers for meeting ending"));
  return getToken().then(key => {
    return requester({
      url: "/meet/end",
      body: info,
      method: "POST",
      headers: {
        Authorization: key
      }
    });
  });
}

function XMLtoPEM(xml) {
  return requester({
    url: "/key/xml-to-pem",
    body: {
      xml
    },
    method: "POST"
  }).then(key => key.pem);
}

function getOneTimeToken() {
  return getEnvironments(env =>
    requester({
      url: "/key/one-use-token",
      body: {
        token: env.VUE_APP_BSI_KEY,
        authentication_engine: "bsi",
        passphrase
      },
      method: "POST"
    })
  ).then(key => key.token);
}

function clearTokens() {
  token = null;
  refresh = null;
  time = null;
  LS.removeItem("token");
  LS.removeItem("refresh");
  LS.removeItem("time");
}

function setValues({ tokenValue, refreshValue, timeValue }) {
  if (tokenValue) {
    token = tokenValue;
    LS.setItem("token", tokenValue);
  }
  if (refreshValue) {
    refresh = refreshValue;
    LS.setItem("token", refreshValue);
  }
  if (timeValue) {
    time = timeValue;
    LS.setItem("time", timeValue);
  }
}

function getToken(manualKey, keysource, body = {}) {
  let noTimeRefresh = (() => {
    if (token) {
      try {
        let info = JSON.parse(atob(token.split(".")[1]));
        let now = Math.ceil(Date.now() / 1000);
        return info.exp > now + 20;
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  })();
  if (token && noTimeRefresh) return Promise.resolve(token);
  if (token && refresh) {
    return recursiveRequest(
      {
        url: "/key/refresh",
        body: {
          refresh,
          authentication_engine: "bsi",
          passphrase
        },
        method: "POST"
      },
      () =>
        windowDispatchEvent("refreshError", {
          message: "Cant resolve refresh in BSIX"
        })
    ).then(key => {
      setValues({ tokenValue: key.token, timeValue: Date.now() });
      return key.token;
    });
  } else {
    return getEnvironments()
      .then(env =>
        recursiveRequest(
          {
            url: "/key/token",
            body: {
              token: manualKey || env.VUE_APP_BSI_KEY,
              authentication_engine: keysource || (manualKey ? "bsix" : "bsi"),
              passphrase,
              ...body
            },
            method: "POST"
          },
          () =>
            windowDispatchEvent("tokenError", {
              message: "Cant resolve token in BSIX"
            })
        )
      )
      .then(key => {
        setValues({
          tokenValue: key.token,
          refreshValue: key.refresh,
          timeValue: Date.now()
        });
        return key.token;
      });
  }
}

function getOneUseToken() {
  return getEnvironments()
    .then(env =>
      recursiveRequest(
        {
          url: "/key/one-use-token",
          body: {
            token: env.VUE_APP_BSI_KEY,
            authentication_engine: "bsi"
          },
          method: "POST"
        },
        () =>
          windowDispatchEvent("oneUseTokenError", {
            message: "Cant resolve one use token in BSIX"
          })
      )
    )
    .then(key => key.token);
}

function getEnvOnly() {
  return envGlobal;
}

function sendRecords(info) {
  if (
    typeof info !== "object" ||
    !info.name ||
    !info.tipide_dni ||
    !info.numide_dni ||
    !info.first_message ||
    !info.second_message ||
    !info.code_id ||
    !info.branch_id ||
    !info.origin
  )
    return Promise.reject(new Error("Invalid paramethers for data records"));
  return getToken().then(key => {
    return requester({
      url: "/virtualTurn",
      body: info,
      method: "POST",
      headers: {
        Authorization: key
      }
    });
  });
}

function tracking() {
  // if (typeof info !== "object" || !info.uuid || !info.accion || !info.debmedia_turn_code || !info.url_origen || !info.origen  || !info.mensajes)
  //   console.error('Invalid paramethers for data records')
  return new Promise(r=>setTimeout(() => { r() }, 1000));
  // return getToken().then(key => {
  //   return requester({
  //     url: "/tracking",
  //     body: info,
  //     method: "POST",
  //     headers: {
  //       Authorization: key
  //     }
  //   });
  // });
}

function getCustomersWithTurns(dni) {
  if (!dni) return Promise.reject(new Error("Invalid dni for request"));
  return getToken().then(key => {
    return requester({
      url: `/getCustomersWithTurns?dni=${dni}`,
      method: "GET",
      headers: {
        Authorization: key
      }
    });
  });
}

export {
  join,
  end,
  getCorrelation,
  setCorrelation,
  XMLtoPEM,
  getOneTimeToken,
  getCompany,
  getBranch,
  getEnvironments,
  getEnvOnly,
  sendFile,
  getStyles,
  getEnqueueTurn,
  getTurnInfo,
  cancelTurn,
  getToken,
  getOneUseToken,
  clearTokens,
  getQueues,
  getMenus,
  windowDispatchEvent,
  sendRecords,
  tracking,
  getCustomersWithTurns,
  requester
};
