var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "contain" },
        [
          _c(
            "h2",
            {
              staticClass: "TitleSection ml-2 pt-1",
              attrs: { id: "headerCalendarTitle" }
            },
            [_vm._v("Adjunta documentos")]
          ),
          _vm.isntIntersection
            ? _c("div", { staticClass: "headerFixed" }, [
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles py-3 px-4",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        _vm.documents.length == 0
                          ? _vm.backView()
                          : _vm.$bvModal.show("ConfirmDelete")
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#ffff",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c(
                  "h2",
                  { staticClass: "TitleSection text-title color-white pt-2" },
                  [_vm._v("Adjunta documentos")]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "botton-actions ml-4 pl-2 pt-1" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "button-without-styles",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      _vm.documents.length == 0
                        ? _vm.backView()
                        : _vm.$bvModal.show("ConfirmDelete")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "19",
                        viewbox: "0 0 20 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                          stroke: "#0058A2",
                          "stroke-width": "3.12307",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ]),
          _c("div", [
            !_vm.documents.length
              ? _c(
                  "p",
                  {
                    staticClass:
                      "text-start font-16 mr-5 color-descartDocumentBack DescDocs mt-3"
                  },
                  [_vm._v("Por favor adjunta los siguientes documentos:")]
                )
              : _vm._e(),
            !_vm.documents.length
              ? _c("ol", { staticClass: "olContainer" }, [
                  _c("li", { staticClass: "TextSection text-start listItem" }, [
                    _vm._v(
                      "Foto de orden médica una cara y/o foto de frente y respaldo de solicitud de servicios médicos"
                    )
                  ]),
                  _c("li", { staticClass: "TextSection text-start listItem" }, [
                    _vm._v(
                      "Resultado de exámenes relacionados con la solicitud (en caso de tenerlos)"
                    )
                  ]),
                  _c("li", { staticClass: "TextSection text-start listItem" }, [
                    _vm._v(
                      "Copia de la historia clínica (en caso de tenerla a la mano)"
                    )
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "ordenMedica" }, [
              _c(
                "h3",
                {
                  staticClass: "mb-2 mt-4 d-flex",
                  style: _vm.statusOrdenStyles
                },
                [
                  _vm.documents.length
                    ? _c(
                        "span",
                        {
                          staticClass: "mr-2 d-flex",
                          staticStyle: {
                            width: "25px",
                            height: "25px",
                            "border-radius": "50%",
                            "background-color": "rgb(104 169 95)",
                            color: "white"
                          }
                        },
                        [_c("Check")],
                        1
                      )
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(_vm.statusOrden))])
                ]
              ),
              _c("p", { staticClass: "TextSection text-center" }, [
                _vm._v(_vm._s(_vm.statusOrdenDescription))
              ])
            ]),
            _c("div", { staticClass: "addDocument" }, [
              !_vm.documents.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "addDocumentContainer smallContainer mx-0 text-center",
                      on: { click: _vm.simulateImagen }
                    },
                    [
                      _c("div", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "56",
                              height: "56",
                              viewbox: "0 0 56 56",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M28.1138 5L28.1138 51",
                                stroke: "#8f9caf",
                                "stroke-width": "10",
                                "stroke-linecap": "round"
                              }
                            }),
                            _c("path", {
                              attrs: {
                                d: "M5 28H51",
                                stroke: "#8f9caf",
                                "stroke-width": "10",
                                "stroke-linecap": "round"
                              }
                            })
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "m-0 mt-3",
                            staticStyle: {
                              "font-size": "20px",
                              "line-height": "20px"
                            }
                          },
                          [_vm._v("Adjuntar")]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "m-0",
                            staticStyle: { "font-size": "20px" }
                          },
                          [_vm._v("Documento")]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "glider-contain documents-zone" },
                [
                  _vm.documents.length
                    ? _c(
                        "vue-slick-carousel",
                        {
                          attrs: {
                            slidesToScroll: 2,
                            slidesToShow: 2,
                            list: _vm.convertDataMapped
                          }
                        },
                        [
                          _vm._l(_vm.convertDataMapped, function(snap, key2) {
                            return !snap.isContainerToAdd
                              ? _c(
                                  "div",
                                  {
                                    key: "col_" + key2,
                                    staticClass: "slick-item",
                                    class:
                                      _vm.convertDataMapped.length === 1 &&
                                      "centeredUniqElement",
                                    attrs: { id: key2 + 1 }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "icon fa fa-times-circle",
                                      on: {
                                        click: function($event) {
                                          return _vm.removeScan(key2)
                                        }
                                      }
                                    }),
                                    snap.isImg
                                      ? _c("img", {
                                          staticClass: "snapshot",
                                          attrs: { src: snap.file },
                                          on: {
                                            click: function($event) {
                                              return _vm.showImageInModal(snap)
                                            }
                                          }
                                        })
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "snapshot",
                                            on: {
                                              click: function($event) {
                                                return _vm.showImageInModal(
                                                  snap
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("pdf", {
                                              attrs: { src: snap.file }
                                            })
                                          ],
                                          1
                                        ),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "TextSection text-truncate w-100 descP mt-1 text-start"
                                      },
                                      [_vm._v(_vm._s(snap.info.name))]
                                    )
                                  ]
                                )
                              : _vm._e()
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "slick-item addDocumentContainer position-relative",
                              attrs: { id: _vm.convertDataMapped.length },
                              on: { click: _vm.simulateImagen }
                            },
                            [
                              _vm.convertDataMapped[0].isImg
                                ? _c("img", {
                                    staticClass: "snapshotHidden",
                                    attrs: {
                                      src: _vm.convertDataMapped[0].file
                                    }
                                  })
                                : _vm._e(),
                              _c("div", { staticClass: "position-absolute" }, [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "56",
                                      height: "56",
                                      viewbox: "0 0 56 56",
                                      fill: "none",
                                      xmlns: "http://www.w3.org/2000/svg"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M28.1138 5L28.1138 51",
                                        stroke: "#8f9caf",
                                        "stroke-width": "10",
                                        "stroke-linecap": "round"
                                      }
                                    }),
                                    _c("path", {
                                      attrs: {
                                        d: "M5 28H51",
                                        stroke: "#8f9caf",
                                        "stroke-width": "10",
                                        "stroke-linecap": "round"
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "m-0 mt-3",
                                    staticStyle: {
                                      "font-size": "20px",
                                      "line-height": "20px"
                                    }
                                  },
                                  [_vm._v("Adjuntar")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass: "m-0",
                                    staticStyle: { "font-size": "20px" }
                                  },
                                  [_vm._v("Documento")]
                                )
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.documents.length
                    ? _c("div", [_vm._m(0), _vm._m(1), _vm._m(2)])
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm.documents.length
            ? _c("div", { staticClass: "botton-actions-footer" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "text-center w-100 py-2 buttonTitle position-relative",
                        attrs: { variant: "bluecolmedica" },
                        on: {
                          click: function($event) {
                            return _vm.goToView("ConfirmUpload")
                          }
                        }
                      },
                      [_vm._v("Enviar solicitud")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ]
            },
            [
              _c("input", {
                ref: "image",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: "image/*" },
                on: { change: _vm.fileHandlerImage }
              }),
              _c("input", {
                ref: "file",
                staticStyle: { display: "none" },
                attrs: { type: "file", accept: "*/*" },
                on: { change: _vm.fileHandlerGeneral }
              })
            ]
          ),
          _c(
            "div",
            { staticClass: "ModalContent", attrs: { id: "AddDocument" } },
            [
              _c("div", { staticClass: "position-relative w-auto h-100" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "position-relative pt-4 d-flex justify-content-between pb-2"
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "button-without-styles ml-4",
                        on: {
                          click: function() {
                            return _vm.descartDocumentBack(true)
                          }
                        }
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "19",
                              viewbox: "0 0 20 19",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                d:
                                  "M9.28739 16.5742L2.00024 9.28706L9.28739 1.99991M3.01234 9.28706L17.7891 9.28707",
                                stroke: "#0058A2",
                                "stroke-width": "3.12307",
                                "stroke-linecap": "round",
                                "stroke-linejoin": "round"
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c("span")
                  ]
                ),
                _c("div", { staticClass: "d-block text-center Background" }, [
                  _vm.holdIsImagen
                    ? _c(
                        "figure",
                        {
                          staticClass:
                            "m-0 containerImage mx-auto block pt-5 px-3"
                        },
                        [
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.cropper,
                                expression: "cropper"
                              }
                            ],
                            ref: "fileImage",
                            staticClass:
                              "object-fit-cover w-100 h-100 p-16 imageModal",
                            attrs: {
                              src: _vm.urlData,
                              alt: "imageUser",
                              id: "generalImage"
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  !_vm.holdIsImagen
                    ? _c("div", { staticClass: "pt-4 mb-4 TextSection" }, [
                        _vm._v("Sin vista previa del documento")
                      ])
                    : _vm._e(),
                  !_vm.holdIsImagen
                    ? _c(
                        "div",
                        [
                          !_vm.modeCropper
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    variant: "bluecolmedica",
                                    size: "sm"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addDocument()
                                    }
                                  }
                                },
                                [_vm._v("Usar imagen")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.holdIsImagen
                    ? _c("div", { staticClass: "zone-button" }, [
                        _c("div", { staticClass: "mx-5" }, [
                          _vm.compressImagen == false
                            ? _c(
                                "div",
                                [
                                  !_vm.modeCropper
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-2 d-flex justify-content-around w-100 mt-3 mb-1"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "circleCard mr-3 d-flex text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function() {
                                                      return _vm.onRotation(-90)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "16",
                                                        height: "20",
                                                        viewbox: "0 0 16 20",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M2.09 12.5C2.568 15.333 5.033 17.5 8 17.5C11.308 17.5 14 14.808 14 11.5C14 8.192 11.308 5.5 8 5.5H5.828L7.914 7.586L6.5 9L2 4.5L6.5 0L7.914 1.414L5.828 3.5H8C10.1217 3.5 12.1566 4.34285 13.6569 5.84315C15.1571 7.34344 16 9.37827 16 11.5C16 13.6217 15.1571 15.6566 13.6569 17.1569C12.1566 18.6571 10.1217 19.5 8 19.5C3.921 19.5 0.562 16.445 0.0690002 12.5H2.09Z",
                                                          fill: "#F4F4F4"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "textCard mt-1"
                                                },
                                                [_vm._v("Izquierda")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "circleCard ml-2 d-flex text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function() {
                                                      return _vm.onRotation(90)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "16",
                                                        height: "20",
                                                        viewbox: "0 0 16 20",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M13.91 12.5C13.432 15.333 10.967 17.5 8 17.5C4.692 17.5 2 14.808 2 11.5C2 8.192 4.692 5.5 8 5.5H10.172L8.086 7.586L9.5 9L14 4.5L9.5 0L8.086 1.414L10.172 3.5H8C5.87827 3.5 3.84344 4.34285 2.34315 5.84315C0.842855 7.34344 0 9.37827 0 11.5C0 13.6217 0.842855 15.6566 2.34315 17.1569C3.84344 18.6571 5.87827 19.5 8 19.5C12.079 19.5 15.438 16.445 15.931 12.5H13.91Z",
                                                          fill: "#F4F4F4"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "textCard mt-1"
                                                },
                                                [_vm._v("Derecha")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "circleCard ml-4 d-flex text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.activateCropper()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "22",
                                                        height: "23",
                                                        viewbox: "0 0 22 23",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M18.5833 3.375H19.5833C19.5833 2.82272 19.1356 2.375 18.5833 2.375V3.375ZM0 4.375H18.5833V2.375H0V4.375ZM17.5833 3.375V22.5H19.5833V3.375H17.5833Z",
                                                          fill: "#F4F4F4"
                                                        }
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M3.71484 18.2461L20.9597 1.00123",
                                                          stroke: "#F4F4F4",
                                                          "stroke-width": "2",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round"
                                                        }
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M3.41667 18.543H2.41667C2.41667 19.0953 2.86438 19.543 3.41667 19.543V18.543ZM22 17.543L3.41667 17.543V19.543L22 19.543V17.543ZM4.41667 18.543L4.41667 0H2.41667L2.41667 18.543H4.41667Z",
                                                          fill: "#F4F4F4"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "textCard mt-1"
                                                },
                                                [_vm._v("Recortar")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.modeCropper
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mb-4",
                                          attrs: { variant: "bluecolmedica" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addDocument()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "font-md" },
                                            [_vm._v("Usar imagen")]
                                          )
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "p-2 d-flex justify-content-around w-100 mt-3 mb-1"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "circleCard mr-3 d-flex text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onCancel()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      staticClass:
                                                        "svgCloseIcon",
                                                      attrs: {
                                                        width: "20",
                                                        height: "21",
                                                        viewbox: "0 0 20 21",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M2.49023 17.7656L17.2565 2.99936",
                                                          stroke: "#F4F4F4",
                                                          "stroke-width":
                                                            "4.55622",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round"
                                                        }
                                                      }),
                                                      _c("path", {
                                                        attrs: {
                                                          d:
                                                            "M2.49023 3.23438L17.2565 18.0006",
                                                          stroke: "#F4F4F4",
                                                          "stroke-width":
                                                            "4.55622",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "textCard mt-1"
                                                },
                                                [_vm._v("Cancelar")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "circleCard ml-2 d-flex text-center"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onAccept()
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "22",
                                                        height: "16",
                                                        viewbox: "0 0 22 16",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg"
                                                      }
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M2 8L8 14L20 2",
                                                          stroke: "#f4f4f4",
                                                          "stroke-width":
                                                            "3.12",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "textCard mt-1"
                                                },
                                                [_vm._v("Aceptar")]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "ShowImage",
                footerClass: "p-2 border-top-0",
                headerClass: "p-2 border-bottom-0",
                centered: "",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c("div", { staticClass: "position-relative w-auto h-auto" }, [
                _c("div", { staticClass: "d-block text-center" }, [
                  _vm.holdDocument
                    ? _c("img", {
                        staticClass: "img-fluid max-image-hold",
                        attrs: { src: _vm.holdDocument }
                      })
                    : _c("div", [_vm._v("Sin vista previa del documento")])
                ]),
                _c(
                  "div",
                  { staticClass: "position-relative mt-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-sizing-12",
                        attrs: { variant: "bluecolmedica", size: "sm" },
                        on: { click: _vm.hiddenImageInModal }
                      },
                      [_vm._v("close")]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "ConfirmDelete",
                footerClass: "p-2 border-top-0",
                headerClass: "p-2 border-bottom-0",
                centered: "",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c("div", { staticClass: "position-relative w-auto h-auto" }, [
                _c("div", { staticClass: "d-block text-center" }, [
                  _c("div", { staticClass: "text-sizing-108" }, [
                    _vm._v(
                      "Al ir Atrás, perderas las fotos cargadas. Estas seguro?"
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "position-relative mt-3" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "d-block mx-auto my-1 text-sizing-12",
                        attrs: { variant: "bluecolmedica", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.backView()
                          }
                        }
                      },
                      [_vm._v("Si, ir Atrás")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "d-block mx-auto my-1 text-sizing-12",
                        attrs: { variant: "bluecolmedica", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.$bvModal.hide("ConfirmDelete")
                          }
                        }
                      },
                      [_vm._v("No cancelar")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "invalid-mime",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          }
        },
        [
          _c("h6", [
            _vm._v(
              "Puedes cargar documentos de maximo 10Mb de peso, y con formato: pdf, png, jpg, jpeg y tiff"
            )
          ]),
          _c("h6", { staticClass: "text-error" }, [
            _vm._v(
              "Error: " +
                _vm._s(
                  _vm.errorModalType
                    ? "Su documento no tiene el formato pdf, png, jpg, jpeg o tiff"
                    : "Su documento pesa más de 10MB"
                )
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons mt-4 mimeErrorCloser" },
            [
              _c(
                "b-button",
                { staticClass: "ml-2", on: { click: _vm.toogleInvalidModal } },
                [_vm._v("Cerrar")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "invalid-size",
            footerClass: "p-2 border-top-0",
            headerClass: "p-2 border-bottom-0",
            centered: "",
            "hide-footer": "",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          }
        },
        [
          _c("h6", [
            _vm._v(
              "No puedes cargar este archivo. El tamaño máximo del archivo es de 10 MB."
            )
          ]),
          _c(
            "div",
            { staticClass: "buttons mt-4 sizeErrorCloser" },
            [
              _c(
                "b-button",
                { staticClass: "ml-2", on: { click: _vm.toogleInvalidModal } },
                [_vm._v("Cerrar")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "red" }, [_c("b", [_vm._v("¡")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "blue" }, [
      _c("b", [_vm._v("Tus documentos se han adjuntado con éxito")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "red" }, [_c("b", [_vm._v("!")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }