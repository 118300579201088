var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Turn" }, [
    _vm._m(0),
    _c("div", { staticClass: "ContainerData pt-3 px-2" }, [
      _c("h3", { staticClass: "yourTurnLabelO text-center" }, [
        _vm._v(_vm._s(_vm.selectedArea ? _vm.selectedArea.label : ""))
      ]),
      _c("ul", { staticClass: "containerCards" }, [
        _c("figure", { staticClass: "containerImg" }, [
          _c("div", { staticClass: "position-absolute assignedTurn" }, [
            _c("p", { staticClass: "mb-0" }, [_vm._v("Te hemos dado un")]),
            _vm.priority
              ? _c("h5", [_vm._v("Turno preferencial")])
              : _c("h5", [_vm._v("Turno general")])
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/mobileFSFB/doctor.png"),
              alt: "doctor"
            }
          })
        ])
      ]),
      _vm.stage === -1
        ? _c("div", { staticStyle: { "text-align": "center" } }, [
            _vm.errorCounter < 3
              ? _c(
                  "div",
                  [_c("Loading"), _c("h3", [_vm._v("Conectando...")])],
                  1
                )
              : _c(
                  "span",
                  { staticStyle: { width: "100%", "text-align": "center" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-info",
                        staticStyle: {
                          "font-style": "normal",
                          "font-weight": "600",
                          "font-size": "25.9817px",
                          "line-height": "34px",
                          "text-align": "center",
                          color: "#f9f9f9"
                        },
                        on: { click: _vm.startTurn }
                      },
                      [_vm._v("Reintentar")]
                    )
                  ]
                ),
            _vm.errorCounter >= 3
              ? _c(
                  "h5",
                  {
                    staticClass: "mt-3",
                    staticStyle: {
                      "font-style": "normal",
                      "font-weight": "600",
                      "font-size": "25.9817px",
                      "line-height": "34px",
                      "text-align": "center",
                      color: "#d3455b"
                    }
                  },
                  [
                    _vm._v(
                      "Error de conexión con servidor. Presiona el botón para reintentar.."
                    )
                  ]
                )
              : _vm._e(),
            _vm.errorCounter >= 3
              ? _c(
                  "span",
                  { staticStyle: { width: "100%", "text-align": "center" } },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-info",
                        staticStyle: {
                          "font-style": "normal",
                          "font-weight": "400",
                          "font-size": "17.9817px",
                          "line-height": "15px",
                          "text-align": "center",
                          color: "rgb(249 249 249)",
                          opacity: "0.8"
                        },
                        on: { click: _vm.onGoToCancelTurn }
                      },
                      [_vm._v("Cancelar")]
                    )
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      !_vm.turn && _vm.stage === 0
        ? _c(
            "div",
            {
              staticClass: "whiteCard mx-auto",
              staticStyle: { "text-align": "center" }
            },
            [
              _c("img", {
                staticClass: "size_icon mx-auto",
                staticStyle: { width: "70%" },
                attrs: { src: _vm.Conected }
              }),
              _c("h5", [
                _vm._v("Estamos solicitando tu turno. Por favor espera.")
              ])
            ]
          )
        : _vm._e(),
      _vm.turn && _vm.status == "WAITING_TO_BE_CALLED"
        ? _c("div", { staticClass: "whiteCard mx-auto" }, [
            _c("h3", { staticClass: "yourTurnLabel" }, [
              _vm._v("Tu turno asignado")
            ]),
            _c("h2", { staticClass: "yourTurnValue" }, [
              _vm._v(_vm._s(_vm.myTurn))
            ]),
            _c("h6", { staticClass: "subTitle subTitleBlue" }, [
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.service))
              ])
            ]),
            _c("p", { staticClass: "yourEstimatedLabel" }, [
              _vm._v("Tiempo estimado de espera")
            ]),
            _c(
              "div",
              {
                staticClass:
                  "yourEstimatedValue mx-auto d-flex align-items-center"
              },
              [
                _c("p", { staticClass: "mb-1 pt-3 mt-1" }, [
                  _vm._v(_vm._s(_vm.calculatedMinutes(_vm.turn.jsonDetails)))
                ]),
                _c("p", { staticClass: "m-0 pt-3" }, [
                  _c("small", [
                    _vm._v(
                      "Minuto" +
                        _vm._s(
                          _vm.calculatedMinutes(_vm.turn.jsonDetails) === 1
                            ? ""
                            : "s"
                        )
                    )
                  ])
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm.turn && _vm.status == "ANNOUNCED"
        ? _c(
            "div",
            {
              staticClass: "whiteCard mx-auto",
              staticStyle: { height: "auto", "min-height": "272px" }
            },
            [
              _c("h3", { staticClass: "yourTurnLabel" }, [_vm._v("Tu turno")]),
              _c("h2", { staticClass: "yourTurnValue" }, [
                _vm._v(_vm._s(_vm.myTurn))
              ]),
              _c("h3", { staticClass: "animated-box yourTurnLabel" }, [
                _vm._v("ha sido llamado")
              ]),
              _vm._m(1),
              _c("div", { staticClass: "pb-3" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "yourEstimatedValue mx-auto d-flex align-items-center",
                    class: _vm.moduleCalled
                      ? _vm.moduleCalled.length >= 5 && "moduleCalledMore5"
                      : ""
                  },
                  [
                    _vm._m(2),
                    _c("p", { staticClass: "mb-1 pt-3 mt-3" }, [
                      _vm._v(_vm._s(_vm.moduleCalled))
                    ])
                  ]
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.turn && _vm.status == "WAITING_TO_BE_CALLED"
        ? _c("p", { staticClass: "yourEstimatedLabel mb-0 mt-4" }, [
            _vm._v("Mientras esperas mira nuestros")
          ])
        : _vm._e(),
      _vm.turn && _vm.status == "WAITING_TO_BE_CALLED"
        ? _c("p", { staticClass: "yourEstimatedLabel mb-4" }, [
            _vm._v("videos informativos")
          ])
        : _vm._e(),
      _vm.turn && _vm.status == "WAITING_TO_BE_CALLED"
        ? _c(
            "button",
            {
              staticClass:
                "outlinedBthal buttonBthal mx-auto d-block mb-2 text-center",
              on: {
                click: function() {
                  return _vm.goToView({ view: "TikTokVideos" })
                }
              }
            },
            [_c("span", { staticClass: "d-block" }, [_vm._v("Ver videos")])]
          )
        : _vm._e(),
      _c("div", { staticClass: "pb-5 pt-3" }, [
        _vm.turn && _vm.status == "WAITING_TO_BE_CALLED"
          ? _c(
              "a",
              {
                staticClass: "mt-2 mx-auto d-block text-center linkStyles",
                on: { click: _vm.onGoToCancelTurn }
              },
              [_vm._v("Cancelar turno")]
            )
          : _vm._e(),
        _vm.turn && _vm.status != "WAITING_TO_BE_CALLED"
          ? _c(
              "a",
              {
                staticClass: "mt-5 pb-3 mx-auto d-block text-center linkStyles",
                attrs: { variant: "whitecolmedica" }
              },
              [_vm._v("Ya puedes cerrar esta ventana")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ContainerHeader pb-5" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-between" },
        [
          _c("img", {
            staticClass: "backButton icn_backicn_back opacity-0 mr-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          }),
          _c("h3", { staticClass: "fontTitleHeader m-0" }, [
            _vm._v("Turno asignado")
          ]),
          _c("img", {
            staticClass: "icn_backicn_back opacity-0 mr-3 backButton",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "yourEstimatedLabel" }, [
      _c("small", [_vm._v("Acércate al módulo")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "m-0 pt-1" }, [
      _c("small", [_vm._v("Módulo")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }