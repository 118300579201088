var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Header"),
      _c("div", { staticClass: "Page" }, [
        _c("div", { staticClass: "container-logo MeetingPage" }, [
          _c("div", { staticClass: "p-3" }, [
            _vm.message
              ? _c(
                  "h5",
                  {
                    staticClass: "buttonText",
                    staticStyle: { "text-align": "center", color: "firebrick" }
                  },
                  [
                    _vm._v(
                      "No tienes habilitado el uso de la cámara y del video. Habilítalo y vuelve a intentarlo."
                    )
                  ]
                )
              : _vm._e(),
            _vm.messageTurn
              ? _c("div", [
                  _c(
                    "span",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-center AuthsBtn mt-4 mb-5",
                          staticStyle: { width: "fit-content" },
                          attrs: {
                            variant: "bluecolmedica",
                            block: "",
                            size: "lg",
                            type: "button"
                          },
                          on: { click: _vm.startTurn }
                        },
                        [
                          _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                            _vm._v("Reintentar")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "h5",
                    {
                      staticClass: "mt-3 buttonText",
                      staticStyle: {
                        "text-align": "center",
                        color: "firebrick"
                      }
                    },
                    [
                      _vm._v(
                        "No fue posible establecer un turno. Por favor reinténtelo o comuníquese con el soporte técnico."
                      )
                    ]
                  )
                ])
              : _vm._e(),
            !_vm.message && !_vm.messageTurn
              ? _c(
                  "div",
                  {
                    staticClass:
                      "position-relative d-inline text-center display-dk"
                  },
                  [
                    _c("img", {
                      staticClass: "size_icon mx-auto",
                      attrs: { src: _vm.Conected }
                    }),
                    _c(
                      "h5",
                      {
                        staticClass: "buttonText",
                        staticStyle: { "font-size": "24px" }
                      },
                      [
                        _vm._v(
                          "Estamos solicitando tu turno. Por favor espera."
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }