var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: _vm.fv_atril_video ? "lds-dual-ring-fv" : "lds-dual-ring"
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }