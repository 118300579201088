import { getEnvOnly } from "../../helpers/Meet";

export default {
  view: "TurnChecker",
  HorarioOutContinue: false,
  // autenticacion
  charging: false,
  servicioSelected: null,
  validationErrors: [],
  client: null,
  service: "",
  errorValidationTemporizer: null,
  explanation: true,
  isCorrect: false,
  showModalError: false,
  stopTimer: false,
  servicesAvailable: [
    {
      label: "Exámenes diagnósticos (Laboratorios o Imágenes Diagnosticas)",
      code_name: "examenes_diagnosticos",
      code: 1
    },
    {
      label: "Procedimientos quirúrgicos",
      code_name: "procedimientos_quirurgicos",
      code: 2
    },
    {
      label: "Otros servicios",
      code_name: "otros_servicios",
      code: 3
    }
  ],
  tipide: [
    { value: "AS", text: "Adulto Sin Identificación" },
    { value: "CC", text: "Cédula de Ciudadanía" },
    { value: "CD", text: "Carné Diplomático" },
    { value: "CE", text: "Cedula de Extranjería" },
    { value: "CN", text: "Certificado de Nacido Vivo" },
    { value: "CO", text: "Código" },
    { value: "MS", text: "Menor Sin Identificación" },
    { value: "NI", text: "N.I.T." },
    { value: "PA", text: "Pasaporte" },
    { value: "PE", text: "Permanencia Especial" },
    { value: "PT", text: "Permiso Protección Temporal" },
    { value: "RC", text: "Registro Civil" },
    { value: "RU", text: "Reg. Único de Contribuyentes." },
    { value: "SC", text: "Salvoconducto De Permanencia" },
    { value: "TE", text: "Tarjeta de Extranjería" },
    { value: "TI", text: "Tarjeta Identidad" }
  ],

  // documents
  files: [],
  colmedicaRequest: null,

  // B64 - enviado a bsix
  b64final: {
    ti: "",
    nu: "",
    ma: "",
    na: "",
    ap: "",
    tr: getEnvOnly().VUE_APP_DEBMEDIA_DEFAULT_TRAMITE,
    so: "",
    fu: "ad",
    em: "",
    ce: "",
    to: "",
    su: getEnvOnly().VUE_APP_DEBMEDIA_DEFAULT_BRANCH
  },
  turn_from_code: {},
  test_users: [
    {
      NumeroIdentificacion: 1033724553,
      Celular: "3017076719",
      Correo: "oscar.jimenez@brilliant360.net",
      Nombres: "Oscar",
      PrimerApellido: "Jimenez",
      Resultado: 1,
      SegundoApellido: "DEMO",
      PrioridadMarcaValor: 1
    },
    {
      NumeroIdentificacion: 1000778041,
      Celular: "3154494547",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Derek",
      PrimerApellido: "Paul",
      Resultado: 1,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1073519274,
      Celular: "3164960387",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Maria Paz",
      PrimerApellido: "Espejo",
      Resultado: 1,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1019140052,
      Celular: "3227466696",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Marcela",
      PrimerApellido: "Riascos",
      Resultado: 1,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 53038862,
      Celular: "3182404149",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Paola",
      PrimerApellido: "Torres",
      Resultado: 1,
      SegundoApellido: "DEMO"
    }     
  ]
};
