var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "MainView box_size atrilColmedica",
      class: { atril: _vm.isAtril }
    },
    [
      _c("Header", { class: { atrilHeader: _vm.isAtril } }),
      [
        _vm.isAtril
          ? _c(
              "div",
              {
                staticStyle: {
                  position: "fixed",
                  right: "15px",
                  bottom: "85px"
                }
              },
              [
                _c("small", { on: { click: _vm.specialAction } }, [
                  _vm._v("versión " + _vm._s(_vm.version))
                ]),
                _vm.customArea
                  ? _c(
                      "h6",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "font-size": "0.7rem"
                        }
                      },
                      [_vm._v("Area " + _vm._s(_vm.customArea))]
                    )
                  : _vm._e()
              ]
            )
          : _c(
              "div",
              { staticStyle: { position: "fixed", right: "10px", top: "5px" } },
              [
                _c(
                  "h6",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "font-size": "0.6rem",
                      "margin-bottom": "0"
                    }
                  },
                  [_vm._v("V. " + _vm._s(_vm.version))]
                ),
                _vm.customArea
                  ? _c(
                      "h6",
                      {
                        staticStyle: {
                          "text-align": "right",
                          "font-size": "0.6rem"
                        }
                      },
                      [_vm._v("A. " + _vm._s(_vm.customArea))]
                    )
                  : _vm._e()
              ]
            )
      ],
      _vm.isAtril &&
      _vm.view !== "QR" &&
      _vm.view !== "OutOperations" &&
      _vm.view !== ""
        ? _c("Progress")
        : _vm._e(),
      _vm.view == "Home"
        ? _c("CurrentUser")
        : _vm.view == "QR"
        ? _c("QRView")
        : _vm.view == "OutOperations"
        ? _c("OutOperations", {
            style: { marginTop: _vm.isAtril ? "10vh" : "" },
            attrs: { withOutClose: _vm.isAtril, withOutHeader: true }
          })
        : _vm.view == "evaluationPriority"
        ? _c("EvaluationPriority")
        : _vm.view == "NoDate"
        ? _c("NoDate")
        : _vm.view == "Quote"
        ? _c("Quote")
        : _vm.view == "ErrorAutenticate"
        ? _c("ErrorAutenticate")
        : _vm.view == "ErrorAutenticateVal"
        ? _c("ErrorAutenticateVal")
        : _vm.view == "Normativity" || _vm.view == "normativityBack"
        ? _c("Normativity")
        : _vm.view == "Help"
        ? _c("Help")
        : _vm.view == "Pay"
        ? _c("Pay", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex",
                        staticStyle: {
                          "align-items": "center",
                          "justify-content": "center"
                        }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-4",
                            style: _vm.colorComputed,
                            attrs: { variant: "bluecolmedica" },
                            on: { click: _vm.toHome }
                          },
                          [_c("span", [_vm._v("Volver al inicio")])]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        : _vm.view == "Turn"
        ? _c("Turn", {
            attrs: {
              tramiteName: _vm.tramiteName,
              userInfo: _vm.turnObject,
              branchId: _vm.branch,
              queueId: _vm.tramite,
              source: _vm.source,
              onlyGenerate: _vm.isAtril,
              withAnalitycExtraFields: true,
              waitVideoSource: _vm.waitVideoSource,
              waitVideoPoster: _vm.waitVideoPoster,
              hideVideo: _vm.hideVideo
            },
            on: {
              generatedTurn: _vm.printTicket,
              endTurn: _vm.endTurn,
              errorRequestTurn: _vm.toHome,
              goBack: _vm.goBack
            },
            scopedSlots: _vm._u(
              [
                _vm.isAtril
                  ? {
                      key: "waitingActions",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              style: _vm.isAtril
                                ? "font-size: 1.5rem;" + " " + _vm.colorComputed
                                : _vm.colorComputed,
                              attrs: { variant: "bluecolmedica" },
                              on: { click: _vm.toHome }
                            },
                            [_c("span", [_vm._v("Volver al inicio")])]
                          )
                        ]
                      },
                      proxy: true
                    }
                  : null,
                {
                  key: "waiting",
                  fn: function(ref) {
                    var turn = ref.turn
                    return [
                      _vm.currentValidation.length
                        ? _vm._l(_vm.currentValidation, function(valid, i) {
                            return _c(
                              "h4",
                              { key: i, staticClass: "mt-4 text-middle" },
                              [_vm._v(_vm._s(valid.DescripcionPaso))]
                            )
                          })
                        : _vm._e(),
                      !_vm.currentValidation.length
                        ? _c("h4", { staticClass: "mt-2 text-middle" }, [
                            !_vm.siteIsOffice
                              ? _c("div", [
                                  _c("p", { staticClass: "m-0" }, [
                                    _vm._v("Para"),
                                    _c(
                                      "strong",
                                      {
                                        staticClass: "ml-2",
                                        style: _vm.colorStyle
                                      },
                                      [_vm._v(_vm._s(turn.tramiteName) + ",")]
                                    )
                                  ]),
                                  _c("p", { staticClass: "m-0" }, [
                                    _c("strong", {
                                      staticClass: "assignedLabel"
                                    }),
                                    _vm._v("tu turno asignado es")
                                  ])
                                ])
                              : _c("p", { staticClass: "m-0 assignedLabel" }, [
                                  _vm._v("Tu turno asignado es")
                                ])
                          ])
                        : _vm._e(),
                      _vm.currentValidation.length
                        ? _c(
                            "h4",
                            { staticClass: "mt-4 text-middle assignedLabel" },
                            [_vm._v("tu turno asignado es")]
                          )
                        : _vm._e(),
                      _c("h3", { staticClass: "text-center mt-0 valueLabel" }, [
                        _vm._v(_vm._s(turn.myTurn))
                      ]),
                      _c("h5", { staticClass: "mt-3 text-middle inAtt" }, [
                        _vm._v("En atención")
                      ]),
                      _c(
                        "h3",
                        {
                          staticClass:
                            "text-center mt-0 valueLabel littleValLabel"
                        },
                        [_vm._v("B31")]
                      ),
                      _c("p", { staticClass: "m-0" }, [
                        _c("small", { staticClass: "estimadoP" }, [
                          _vm._v("Tiempo estimado de espera")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "circleEstimado mx-auto mt-2 mb-4" },
                        [
                          _c("h2", [
                            _vm._v(_vm._s(_vm.calculatedMinutes(turn)))
                          ]),
                          _c("h4", [_vm._v("Minutos")])
                        ]
                      ),
                      _c("p", { staticClass: "impresoTicket mb-0" }, [
                        _vm._v("Toma el ticket impreso")
                      ]),
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "24",
                            height: "11",
                            viewbox: "0 0 24 11",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M12 11L0.74167 0.5L23.2583 0.500002L12 11Z",
                              fill: "#283645"
                            }
                          })
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _c("div", { staticClass: "pending" }, [
            _vm._v("Estamos verificando la sucursal.")
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }