var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CancelTurn" },
    [
      _c("div", { staticClass: "banderaSvgMobile" }),
      _c("Modal", {
        attrs: { header: true, goBack: _vm.goBack },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-start"
                  },
                  [
                    _c("img", {
                      staticClass: "image-confirm_data pl-3",
                      attrs: {
                        src: require("@/assets/mobileFSFB/info_data.svg"),
                        alt: "confirm_data"
                      }
                    }),
                    _c("h2", { staticClass: "text-confirm_data ml-3" }, [
                      _c("strong", [_vm._v("¿Estás seguro de cancelar?")])
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between pt-3 pb-4" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "buttonBack px-4 py-2",
                        on: { click: _vm.goBack }
                      },
                      [_vm._v("Regresar")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "buttonConfirm px-4 py-2",
                        on: { click: _vm.onContinue }
                      },
                      [
                        !_vm.prelaod
                          ? _c("span", [_vm._v("Confirmar")])
                          : _c("span", [_c("MiniSpinner")], 1)
                      ]
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }