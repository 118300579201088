var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Normativity Container",
      style: _vm.isAtril
        ? "margin: auto; width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 96.38px);"
        : "width: 100%; font-size: 1.3rem;",
      on: { click: _vm.actionActive }
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c(
          "article",
          {
            staticClass: "Article",
            style: !_vm.isAtril
              ? "color: #545454;"
              : "color: #545454; margin-top: 40px; width: 400px;"
          },
          [
            _c("div", { staticClass: "card-box-white" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "21",
                    height: "21",
                    viewbox: "0 0 21 21",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 1.49637e-06 10.5 9.8941e-07C4.70102 4.82446e-07 3.33225e-06 4.70101 2.82529e-06 10.5C0.00615352 16.2964 4.70356 20.9938 10.5 21Z",
                      fill: "#3290E0"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d:
                        "M10.4993 16.3906C11.2513 16.3906 11.8203 15.9982 11.8203 15.3642L11.8203 9.54988C11.8203 8.9153 11.2514 8.52416 10.4993 8.52416C9.76557 8.52416 9.17819 8.93167 9.17819 9.54988L9.17819 15.3642C9.17826 15.9831 9.76556 16.3906 10.4993 16.3906ZM10.4993 7.21426C11.2218 7.21426 11.8098 6.62622 11.8098 5.90311C11.8098 5.18124 11.2218 4.5932 10.4993 4.5932C9.77672 4.5932 9.18935 5.18124 9.18935 5.90311C9.18942 6.62629 9.77672 7.21426 10.4993 7.21426Z",
                      fill: "#F4F4F4"
                    }
                  })
                ]
              ),
              _c(
                "p",
                { staticClass: "text-center text-success-local titleCard" },
                [_vm._v("Si eres una persona:")]
              ),
              _c("ul", { staticClass: "listUnderlined m-4 mt-0 mx-auto" }, [
                _c("li", [
                  _vm._v("Mayor de"),
                  _c("strong", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.env.VUE_APP_OLD_AGE || 60) + " años")
                  ])
                ]),
                _vm._m(0),
                _vm._m(1)
              ]),
              _c(
                "p",
                { staticClass: "mb-2 mt-2 text-center mr-2 ml-2 derecho" },
                [_vm._v("Tienes derecho a:")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-bottom": "20px",
                    "margin-top": "1.3rem"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "py-2 buttonBlueContinue button-without-styles mx-auto d-block",
                      attrs: { size: "sm", type: "button" },
                      on: {
                        click: function() {
                          return _vm.turnCall(true)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "my-1 d-block" }, [
                        _vm._v("Turno preferencial")
                      ])
                    ]
                  )
                ]
              )
            ]),
            _vm._m(2),
            _c(
              "button",
              {
                staticClass: "text-center buttonOutlined",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return _vm.turnCall(false)
                  }
                }
              },
              [_c("span", [_vm._v("Continuar sin turno preferencial")])]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  style: _vm.isAtril
                    ? "font-size: 1.5rem !important; " + _vm.colorComputed
                    : _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.goBack }
                },
                [
                  _c("Back", { attrs: { size: "24px" } }),
                  _c(
                    "span",
                    {
                      staticClass: "text-sizing-12",
                      style: _vm.isAtril ? "font-size: 1.5rem !important;" : ""
                    },
                    [_vm._v("Atrás")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("En estado de"),
      _c("strong", { staticClass: "ml-1" }, [_vm._v("embarazo")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v("En condición de"),
      _c("strong", { staticClass: "ml-1" }, [_vm._v("discapacidad")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticStyle: {
          "line-height": "1",
          "margin-top": "0.1rem",
          "text-align": "center",
          margin: "1rem auto"
        }
      },
      [
        _c("small", { staticClass: "ruleSmall mx-auto mt-5 mb-4" }, [
          _vm._v(
            "Entre todos debemos proteger, promover, respetar y defender los derechos de esta población (Circular única 049 de la Supersalud)"
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }