import axios from "axios";
import moment from "moment";
import { getEnvOnly } from "./Meet";
var CryptoJS = require("crypto-js");
let passphrase = "bsiTokenGenerator";
import _omit from "lodash/omit";

async function fetchApi(routes, config = {}) {
    // eslint-disable-next-line
    const url = config.external_url || getEnvOnly()?.VUE_APP_SERVER; // default url api gateway
    config.headers = {
      ...(config?.headers || {
        // eslint-disable-next-line
        "x-api-key": getEnvOnly()?.VUE_APP_API_KEY_DEV,
        "Content-Type": "application/json",
      }),
    };
    if (!config.headers.Authorization && (window.token || localStorage.getItem("token")))
      config.headers.Authorization =
        window.token || localStorage.getItem("token");
    const responseJSON = await fetch(`${url}${routes}`, config),
      response = await responseJSON.json();
  
    if (responseJSON.status >= 400)
      throw (responseJSON.status == 400 && !response.message) ||
        responseJSON.status == 401
        ? responseJSON.status
        : response.message;
    return response;
}

async function requester(ctx) {
    const { headers = {}, url, body } = ctx;
    if (!url) return Promise.reject(new Error("URL is required"));
    return await fetch(`${getEnvOnly()?.VUE_APP_SERVER}${url}`, {
        ..._omit(ctx, ["headers", "url", "body"]),
        body: body ? JSON.stringify(body) : undefined,
        headers: {
        ...{
            "x-api-key": getEnvOnly()?.VUE_APP_API_KEY_DEV,
            "Content-Type": "application/json"
        },
        ...headers
        },
        mode: "cors"
    })
    .then(response => {
    if (
        typeof response.status === "number" &&
        response.status >= 200 &&
        response.status < 400
    )
        return response;

    if(url == '/meet/meet-coorelation' && response.status == 410) return response;
    
    throw new Error(
        `Invalid request status ${response.status}. ${JSON.stringify(
        response.json()
        )}`
    );
    })
    .then(response => response.json());
}

function recursiveRequest(config, errorEventFunction) {
    let countError = 0;
    return new Promise(resolve => {
      executor();
      // eslint-disable-next-line no-unused-vars
      async function executor() {
        let time = Date.now();
        try {
          const response = await requester(config);
          resolve(response);
        } catch (error) {
          console.error("Error in recursiveRequest", error);
          countError++;
          console.log(countError)
          let newTime = Date.now() - time;
          if (typeof errorEventFunction === "function")
            errorEventFunction(newTime);
          if (newTime < 5000) setTimeout(() =>{
              if(countError>=3) resolve(false);
              else executor();
          }, 5000 - newTime);
          else executor();
        }
      }
    });
}

function windowDispatchEvent(name, detail) {
    let event = new CustomEvent(name, { detail });
    window.dispatchEvent(event);
}

function getToken(manualKey, keysource, body = {}) {

    return recursiveRequest(
      {
        url: "key/token",
        body: {
          token: getEnvOnly()?.VUE_APP_BSI_KEY,
          authentication_engine: keysource || (manualKey ? "bsix" : "bsi"),
          passphrase,
          ...body
        },
        method: "POST"
      },
      () =>
        windowDispatchEvent("tokenError", {
          message: "Cant resolve token in BSIX"
        })
    )
      
}

function externalRequest(params){
    return new Promise(resolve=>{
        return getToken().then(key => {
    
            return fetchApi(`mirror_request`, {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                Authorization: key.token,
                "x-api-key": getEnvOnly()?.VUE_APP_API_KEY_DEV
                }
            })
            .then(result => {
                resolve(result);
            } )
            .catch(error=> {
                console.log(error)
                resolve(error);
            } );
        });
    })
    
}

function payLogs(params, resolve){
    
    return getToken().then(key => {

        return fetchApi(`pays/logs`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
            Authorization: key.token,
            "x-api-key": getEnvOnly()?.VUE_APP_API_KEY_DEV
            }
        })
        .then(result => {
            resolve(result);
        } )
        .catch(error=> {
            console.log(error)
            payLogs(params, resolve);
        } );
    });
        
}

function getLlavePublica(TipoIdentificacion, NumeroIdentificacion) {

    var privateKey = getEnvOnly()?.VUE_APP_FSFB_KEY;
    var timestamp = Math.round(new Date().getTime() / 1000);
    const convertDocuments = {
        "CC": "4",
        "TI": "3",
        "PS": "P",
        "CE": "5",
        "RC": "2",
        "SI": "A",
        "CD": "D",
        "MI": "M",
        "NI": "6",
        "NU": "N",
        "SC": "S",
        "SN": "A"
    };
    // Document Types
    //CC => 4
    //TI => 3
    //PS => P
    //CE => 5
    //RC => 2
    //SI => A
    //CD => D
    //MI => M
    //NI => 6
    //NU => N
    //SC => S
    //SN => A
    var documentType = convertDocuments[TipoIdentificacion];
    var documentNumber = NumeroIdentificacion;
    /* eslint-disable */
    var md5 = CryptoJS.MD5(documentType + "~" + documentNumber + "~" + timestamp + "~" + privateKey);
    var payload = CryptoJS.SHA1(md5 + "");
    
    /* eslint-enable */
    return {
        documentType: documentType,
        documentNumber: documentNumber,
        timestamp: timestamp,
        payload: payload
    }
    
}


async function autorizarFSFB({
  // LF Tested OK 21-01-20
  TipoIdentificacion,
  NumeroIdentificacion
}) {

    return new Promise(resolve =>{ 
        const enc = getLlavePublica(TipoIdentificacion, NumeroIdentificacion);
        const server = getEnvOnly()?.VUE_APP_FSFB_SERVER;
        const key = getEnvOnly()?.VUE_APP_FSFB_KEY

        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': key
        }
        
        var raw = {
            data:{
                "tdoc": enc.documentType,
                "doc": enc.documentNumber
            },
            token: key,
            url: server,
            authType: true,
            contentType: 'application/json',
            stringify: true,
            authHeader: 'x-api-key'
        };

        // Direct request to Fundación
        axios.post(server, raw.data, {
            headers: headers
        })
        .then(data => data.data)
        .then(data => data.data)
        .then(data => data.response)
        .then((user) => {
            resolve(user ? user[0] ? user[0] : {} : {} );
        })
        .catch(async (error) => {
            console.error("Invalid site auth", error);
            console.log("Invalid Authorization in Huella FSFB");
            // request external (ERROR DE CORS)
            let user = await externalRequest(raw);
            user = user?.data?.response || [];
            resolve(user ? user[0] ? user[0] : {} : {});
        })
    })

}

async function citasFSFB( user, resolve ) {
    
    /* eslint-disable */

    const server = getEnvOnly()?.VUE_APP_FSFB_SERVER_APPOINTMENTS;
    const raw = {
        "start_date":  moment().format("YYYY-MM-DD"),
        "end_date": moment().format("YYYY-MM-DD"),
        "doc": user.doc,
        "tdoc": user.tdoc.code
    };
    let externalData = {
        data:raw,
        url:server,
        contentType: 'application/json',
        token: 'fsfb',
        stringify: true,
        authHeader: 'x-api-key',
    };

    if(resolve){

        let appointments = await externalRequest(externalData);
        if(appointments && appointments.data && appointments.data.response){
            resolve(appointments.data.response);
        } else citasFSFB(user, resolve);

    } else {
        return new Promise(async resolve =>{

            let appointments = await externalRequest(externalData);
            if(appointments && appointments.data && appointments.data.response){
                resolve(appointments.data.response);
            }  else citasFSFB(user, resolve);

        });
    }
}

async function fetchCupsCode(speciality){
    /* eslint-disable */
    return new Promise(async resolve =>{
        const server = getEnvOnly()?.VUE_APP_FSFB_SERVER_KIOSKOS_DISTRIBUTION;
        let externalData = {
            data: {},
            method: 'GET',
            url:server + speciality,
            contentType: 'application/json',
            token: 'fsfb',
            stringify: true,
            authHeader: 'x-api-key'
        };
        let appointments = await externalRequest(externalData);
        if(appointments && appointments.data && appointments.data.response){
            resolve(appointments.data.response);
        } else resolve([]);

    });
}

async function notificationsFSFB(postData) {
    return new Promise(resolve =>{ 
        
        const server = getEnvOnly()?.VUE_APP_FSFB_NOTIFICATION_URL  + '/backend-app/send-notification';

        const headers = {
            'Content-Type': 'application/json'
        }
        
        var raw = {
            data: postData,
            token: 'fsfb',
            url: server,
            authType: true,
            contentType: 'application/json',
            stringify: true
        };

        // Direct request to Fundación
        axios.post(server, postData, {
            headers: headers
        })
        .then((response) => {
            resolve(response);
        })
        .catch(async (error) => {
            console.error("Invalid site auth", error);
            console.log("Invalid Notification FSFB");
            // request external (ERROR DE CORS)
            let user = await externalRequest(raw);
            resolve(user);
        })
    })
}

let countSync = 0;
async function syncPaysWithFSFB(postData, promise) {
    countSync++;
    console.log("counter: " + countSync)
    if(countSync>=3 && promise) {
        countSync = 0;
        return promise(false);
    } else if(!promise) return;
    
    const server = getEnvOnly()?.VUE_APP_FSFB_NOTIFICATION_URL  + '/payment/register-core';

    const key = getEnvOnly()?.VUE_APP_FSFB_KEY
    // const headers = {
    //     'Content-Type': 'application/json',
    //     'x-api-key': key
    // }

    let externalData = {
        data: postData,
        method: 'POST',
        url:server,
        contentType: 'application/json',
        token: key,
        stringify: true,
        authHeader: 'x-api-key',
        authType: true,
    };

    let appointments = await externalRequest(externalData);
    if(appointments && appointments.data && appointments.data.response){
        promise(appointments.data.response);
    } else {
        console.error("Error sending sync payment:", appointments);
        // retry
        syncPaysWithFSFB(postData, promise);
    };
    
    // // Direct request to Fundación
    // axios.post(server, postData, {
    //     headers: headers
    // })
    // .then((response) => {
    //     promise(response);
    // })
    // .catch(async (error) => {
    //     console.error("Error sending sync payment:", error);
    //     // retry
    //     syncPaysWithFSFB(postData, promise);
    // })
}

let tokenPayments;

async function fetchToken(){
    return new Promise(async r=>{
        const server = getEnvOnly()?.VUE_APP_FSFB_PAYS;
        let res = await externalRequest({
            url: server,
            data: '{}',
            method: 'GET',
            contentType: 'application/json',
            token: 'fsfb-pays',
            stringify: true,
            authHeader: 'x-api-key'
        });
        tokenPayments = res.token
        return r(res);
    })

}

async function pay(d){

    const server = getEnvOnly()?.VUE_APP_FSFB_PAYS;
    let res = await fetchToken();
    console.log("pay")
    console.log(res)

    let res_p = await externalRequest({
        url: `${server}/api/DatosCompra?Cod_unico=${res.code}&id_transaccion=${d.id_transaction}&Data=${d.data}&token=${tokenPayments}&sobreescribir=S`,
        data: '{}',
        method: 'GET',
        contentType: 'application/json',
        token: 'fsfb-pays-r',
        stringify: true,
        authHeader: 'x-api-key'
    });
    
    // send
    return {...res, pay_r: res_p, id_transaction: d.id_transaction};
}

async function checkPay(data){
    const server = getEnvOnly()?.VUE_APP_FSFB_PAYS;
    let res_p = await externalRequest({
        url: `${server}/api/Respuesta?Cod_unico=${data?.code}&id_transaccion=${data?.id_transaction}&token=${tokenPayments}&parte=1`,
        data: '{}',
        method: 'GET',
        contentType: 'application/json',
        token: 'fsfb-pays-r',
        stringify: true,
        authHeader: 'x-api-key'
    });
    
    // send
    return res_p;
}

async function cancelPay(data){
    const server = getEnvOnly()?.VUE_APP_FSFB_PAYS;
    let res_p = await externalRequest({
        url: `${server}/api/BorrarTransaccion?Cod_unico=${data.code}&id_transaccion=${data.id_transaction}&token=${data.token}`,
        data: '{}',
        method: 'PUT',
        contentType: 'application/json',
        token: 'fsfb-pays-r',
        stringify: true,
        authHeader: 'x-api-key'
    });
    
    // send
    return res_p;
}

export { autorizarFSFB, citasFSFB, fetchCupsCode, notificationsFSFB, pay, checkPay, cancelPay, syncPaysWithFSFB, payLogs, fetchToken };
