var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content", staticStyle: { height: "87%" } },
    [
      _c("Header"),
      _c("div", { staticClass: "contain", staticStyle: { height: "87%" } }, [
        _c("div", { staticClass: "headerMenu" }, [
          _c(
            "button",
            {
              staticClass: "button-without-styles py-3 px-4 isPopup",
              attrs: { type: "button" },
              on: { click: _vm.onGoBackSim }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "29",
                    height: "29",
                    viewbox: "0 0 29 29",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M2.41422 2.58578L26.4142 26.5858",
                      stroke: "#044A6B",
                      "stroke-width": "4",
                      "stroke-linecap": "round"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d: "M26 3L2 27",
                      stroke: "#044A6B",
                      "stroke-width": "4",
                      "stroke-linecap": "round"
                    }
                  })
                ]
              )
            ]
          )
        ]),
        _vm._m(0),
        _c("div", { staticClass: "botton-actions-footer" }, [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "w-100 py-2 text-center mx-1",
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.handleContinue }
                },
                [_c("span", { staticClass: "font-md" }, [_vm._v("Entendido")])]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-container" }, [
      _c("div", { staticClass: "text-center d-block" }, [
        _c("p", { staticClass: "TitleSection text-container-title" }, [
          _vm._v("¡Información!")
        ])
      ]),
      _c(
        "p",
        {
          staticClass: "TextSection mt-5 mb-0 mx-3 color-darkgray text-size-s"
        },
        [_vm._v("En este canal solo se autorizan servicios")]
      ),
      _c(
        "p",
        {
          staticClass: "TextSection mt-0 mb-0 mx-3 color-darkgray text-size-s"
        },
        [_vm._v("médicos cubiertos por tu plan de medicina")]
      ),
      _c(
        "p",
        {
          staticClass: "TextSection mt-0 mb-0 mx-3 color-darkgray text-size-s"
        },
        [_vm._v("prepagada, si tu solicitud es de medicamentos")]
      ),
      _c(
        "p",
        {
          staticClass: "TextSection mt-0 mb-0 mx-3 color-darkgray text-size-s"
        },
        [_vm._v("ambulatorios te invitamos a conocer el nuevo")]
      ),
      _c(
        "p",
        {
          staticClass: "TextSection mt-0 mb-0 mx-3 color-darkgray text-size-s"
        },
        [
          _vm._v("proceso a través de tu EPS"),
          _c(
            "a",
            {
              staticClass: "d-block text-center mt-4 mb-0 mx-3 color-dark",
              staticStyle: { "text-decoration": "underline", color: "#226DB1" },
              attrs: {
                target: "_blank",
                href: "https://www.aliansalud.com.co/Paginas/CVM.aspx"
              }
            },
            [_vm._v("Consulta aquí")]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }