import { getToken, getEnvOnly } from "../../helpers/Meet";
import {
  autorizar,
  consultarCliente,
  guardarSolicitud
} from "../../helpers/colmedica_huella";
import _get from "lodash/get";
// import { keyBy } from "lodash";

export default {
  goToView({ commit }, value) {
    commit("setState", { key: "view", value });
  },
  onStopTimer({ commit }, value) {
    commit("setState", { key: "stopTimer", value });
  },
  setService({ commit }, value) {
    commit("setState", { key: "service", value });
  },
  setExplanation({ commit }, value) {
    commit("setState", { key: "explanation", value });
  },
  setIsCorrect({ commit }, value) {
    commit("setState", { key: "isCorrect", value });
  },
  selecteService({ commit }, value) {
    commit("setState", { key: "servicioSelected", value });
  },
  captchaValidation({ dispatch, commit, state }) {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(getEnvOnly().VUE_APP_RECAPTCHA_CODE, {
              action: "submit_identification"
            })
            .then(token =>
              getToken(token, "g_recaptcha_v3", {
                bunitId: getEnvOnly().VUE_APP_BUNIT
              })
            )
            .then(() => res())
            .catch(err => {
              commit("setState", {
                key: "validationErrors",
                value: [...state.validationErrors, err]
              });
              dispatch("errorValidationTemporizer");
              console.error("error in recaptcha request.");
              rej(err);
            });
        });
        // throw new Error('aska');
      })
        .then(() => true)
        .catch(error => {
          console.error(error);
          dispatch("goToView", "ErrorAutenticateVal");
        });
    }
    return Promise.reject(new Error("Recaptcha is not ready"));
  },
  demoValidation({ commit, dispatch, state }, number_id) {
    commit("setState", { key: "charging", value: true });
    return dispatch("captchaValidation")
      .then(
        () =>
          new Promise(res => {
            setTimeout(res, 2000);
            // throw new Error("Blña");
          })
      )
      .then(() => {
        let client = state.test_users.find(
          u => u.NumeroIdentificacion == number_id
        );
        if (!client || (client && !client.NumeroIdentificacion)) {
          dispatch("goToView", "NewUser");
          commit("setState", {
            key: "client",
            value: {
              NumeroIdentificacion: number_id
            }
          });
          commit("setState", { key: "charging", value: false });
        } else {
          commit("setState", {
            key: "client",
            value: client
          });
          dispatch("goToView", "VerificationData");
          commit("setState", { key: "charging", value: false });
        }

      })
      .catch(err => {
        commit("setState", { key: "charging", value: false });
        console.error("Error cached in Autenticate.vue -> demoValidation", err);
        dispatch("goToView", "ErrorAutenticateVal");
      });
  },
  async authAndQueryColmedica({ commit, dispatch }, pay) {
    commit("setState", { key: "charging", value: true });
    try {
      
      const data = await dispatch("captchaValidation");
      dispatch(
        "log/put",
        {
          name: "Autoservice.actions. El captcha se validó bien."
        },
        { root: true }
      );
      if (data) {
        await dispatch("autorizacionColmedica", pay);
        dispatch(
          "log/put",
          {
            name:
              "Autoservice.actions. La verificación del usuario fue correcta."
          },
          { root: true }
        );
        dispatch("consultaClienteColmedica", pay);
      }

      dispatch("goToView", "VerificationData");
      commit("setState", { key: "charging", value: false });
      
    } catch (error) {
      dispatch(
        "log/put",
        {
          name: "Autoservice.actions. Falló la autenticación del usuario.",
          checked: false,
          message: error
        },
        { root: true }
      );
      console.error("ERROR", error);
      return;
    }
  },
  autorizacionColmedica({ commit, dispatch, state }, pay) {
    return autorizar({
      TipoIdentificacion: pay.ti,
      NumeroIdentificacion: pay.nu
    }).catch(err => {
      commit("setState", {
        key: "validationErrors",
        value: [...state.validationErrors, err]
      });
      dispatch("errorValidationTemporizer");
      console.error("error in autorizacion colmedica.");
      commit("setState", { key: "charging", value: false });
      console.error(
        "Error cached in Autenticate.vue -> authAndQueryColmedica",
        err
      );
      commit("setState", { key: "showModalError", value: true });
      throw err;
    });
  },
  consultaClienteColmedica({ commit, dispatch, state }, pay) {
    let dataClient = {
      TipoIdentificacion: pay.ti,
      NumeroIdentificacion: pay.nu
    };
    return consultarCliente(dataClient)
      .then(client =>
        commit("setState", {
          key: "client",
          value: { ...dataClient, ...client }
        })
      )
      .catch(err => {
        commit("setState", {
          key: "validationErrors",
          value: [...state.validationErrors, err]
        });
        dispatch("errorValidationTemporizer");
        console.error("error in autorizacion colmedica.");
        throw err;
      });
  },
  guardarSolicitudDemo({ commit }) {
    return new Promise(res => setTimeout(res, 2000))
      .then(() => "123456789")
      .then(value => {
        commit("setState", {
          key: "colmedicaRequest",
          value
        });
        return value;
      });
  },
  guardarSolicitudColmedica({ state, commit }) {

    console.log("state")
    console.log(state)
    let b64final = state.b64final;

    let canals = {
      "ad": 6, // 6: QR
      "web": 7, // 7: QR Of. Virtual Web
      "oa": 8, // Of. Virtual Adulto Mayor
    }
    console.log("Object.keys(canals)")
    console.log(Object.keys(canals))
    console.log(b64final.fu)
    console.log(Object.keys(canals).filter(c=> b64final.fu.includes(c)))
    console.log(Object.keys(canals).filter(c=> b64final.fu.includes(c))[0])
    console.log(canals[Object.keys(canals).filter(c=> b64final.fu.includes(c))[0]])
    
    let toSend = {
      ParametroSolicitudCanal: {
        CanalIngreso: canals[Object.keys(canals).filter(c=> b64final.fu.includes(c))[0]] || canals['ad'], 
        DescripcionCanal: "Descripcion Canal",

        Identificacion: `${_get(state.b64final, "ti") || ""}${_get(
          state.b64final,
          "nu"
        ) || ""}`,

        NombreUsuario: `${_get(state.client, "Nombres") || ""} ${_get(
          state.client,
          "PrimerApellido"
        ) || ""} ${_get(state.client, "SegundoApellido") || ""}`.trim(),

        CorreoElectronico: _get(state.client, "Correo") || "",
        TelefonoCelular: _get(state.client, "Celular") || "",
        TipoAutorizacionId: _get(state.servicioSelected, "code")
      },
      ArchivoSolicitud: state.files.map(file => ({
        Archivo: _get(file, "info.b64"),
        NombreArchivo: _get(file, "info.name")
      }))
    };
    return guardarSolicitud(toSend).then(data => {
      let value = _get(data, "RespuestaSolicitud.IdSolicitud");
      commit("setState", {
        key: "colmedicaRequest",
        value
      });
      return value;
    });
  },
  errorValidationTemporizer({ commit, state }) {
    if (state.errorValidationTemporizer !== null)
      clearTimeout(state.errorValidationTemporizer);
    if (!state.validationErrors.length) return;
    let timer = setTimeout(() => {
      commit("setState", { key: "validationErrors", value: [] });
      commit("setState", { key: "errorValidationTemporizer", value: null });
    }, 5000);
    commit("setState", { key: "errorValidationTemporizer", value: timer });
  },

  updateB64Final({ commit, state }, value) {
    let currentState = state.b64final;
    commit("setState", {
      key: "b64final",
      value: Object.assign({}, currentState, value)
    });
  },

  // functions documents
  clearDocs({ commit }) {
    commit("setState", { key: "files", value: [] });
  },
  removeFile({ state, commit }, keyIndex) {
    let currentFiles = state.files;
    currentFiles.splice(keyIndex, 1);
    commit("setState", { key: "files", value: currentFiles });
  },
  addFile({ state, commit }, file) {
    let currentFiles = state.files;
    currentFiles.push(file);
    commit("setState", { key: "files", value: currentFiles });
  },
  setHorarioOutContinue({ commit }, value) {
    commit("setState", { key: "HorarioOutContinue", value });
  },
  setClient({ commit }, data) {
    commit("setState", {
      key: "client",
      value: data
    });
  },
  fetchTurnData({ commit }, code) {
    return new Promise(resolve => {
      getToken().then(key => {
        commit("setState", { key: "loading", value: true });

        var myHeaders = new Headers();
        myHeaders.append("authorization", key);
        myHeaders.append("x-api-key", getEnvOnly().VUE_APP_AWS_API_KEY);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        fetch(
          getEnvOnly().VUE_APP_AWS_API_URL + "/turn_info/" + code,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            let res = JSON.parse(result);
            commit("setState", { key: "turn_from_code", value: res.data });
            commit("setState", { key: "loading", value: false });
            resolve();
          })
          .catch(error => {
            commit("setState", { key: "loading", value: {} });
            resolve(error);
          });
      });
    });
  }
};
