import {
  getEnqueueTurn,
  getTurnInfo,
  getCorrelation,
  setCorrelation,
  cancelTurn,
  getEnvOnly,
  // getCustomersWithTurns
} from "../../helpers/Meet";
import axios from "axios";
import kuid from "kuid";
import _get from "lodash/get";
import LS from "../../helpers/localStorage";
import clientInfo from "../../helpers/clientInfo";
import { utf8_to_b64 } from "../../utils/decoder";
import moment from "moment";

export default {
  extrafieldsConverter(
    { rootState },
    {
      queueId,
      source,
      branchId,
      coorelation_key,
      params,
      showable = [
        {
          in: "workstation",
          format: "both"
        }
      ]
    }
  ) {
    // dni: this.user_id,
    // cuil: rootState.sessionMaster,
    // cuit: this.tipide,
    // marcacion: marcacion Colmedica,
    // cedulaIdentidad: rootState.sessionMaster,
    // email: this.e_mail,
    // firstName: this.name,
    // lastName: this.last_name,
    // phone: this.phone_number,
    let client = clientInfo();
    client.sm = rootState.sessionMaster;
    let toPack = {
      na: params?.firstName,
      la: params?.lastName,
      ma: params?.marcacion,
      ti: params?.cuit,
      nu: params?.dni,
      ce: params?.phone,
      em: params?.marcacion,
      to: undefined,
      sm: rootState.sessionMaster,
      su: branchId,
      tr: queueId,
      fu: source,
      position: rootState.position,
      client
    };
    let appointment = sessionStorage.getItem("appointment");
    let fecha_cita = sessionStorage.getItem("fecha_cita");
    toPack.staff = JSON.parse(sessionStorage.getItem("info_cita"));
    return [
      ...(params?.extraFields || []),
      {
        showable,
        Videollamada: `<a href="${
          location.origin
        }/#/turn_assigner/${coorelation_key}?${
          params ? "&pr=" + utf8_to_b64(JSON.stringify(toPack)) : ""
        }" target="_blank">Presiona aquí</a>`,
        appointment,
        fecha_cita
      }
    ];
  },

  async getTurn({ commit, dispatch, state, rootState }, infoOfTurn) {
    if (!infoOfTurn) {
      throw new Error("Invalid info to create Turn");
    }
    let {
      source,
      queueId,
      onlyGenerate,
      branchId,
      coorelation_key = kuid(),
      withVideocall,
      withAnalitycExtraFields,
      withAuthorize,
      params
    } = infoOfTurn;
    if (state.info && !onlyGenerate)
      return dispatch("getTurnStatus", _get(state.info, "code"));
    params = {
      ...params,
      cuil: rootState.sessionMaster,
      cedulaIdentidad: rootState.sessionMaster
    };
    if (withVideocall || withAnalitycExtraFields) {
      // {
      //   in: "workstation", // "workstation / ticket / player / all",
      //   format: "onlyKey"
      // }
      let showable = withAnalitycExtraFields ? [] : undefined;
      params = {
        ...params,
        extraFields: await dispatch("extrafieldsConverter", {
          source,
          queueId,
          branchId,
          coorelation_key,
          params,
          showable
        })
      };
    }

    // add authorize in FSFB
    if(withAuthorize && withAuthorize.hasAuthorize) {
      let _extraFields = params.extraFields || []
      _extraFields.push({
        "Autorización": withAuthorize.authorize ? "Sí" : "No"
      })
      params.extraFields = _extraFields;
    }


    // Feth new turn
    let turnInfo = await (getEnvOnly().VUE_APP_DEBQ_DIRECT == "true"
      ? axios.post(`/queue/${queueId}/branch/${branchId}/enqueue`, params)
      : getEnqueueTurn(queueId, branchId, params)
    ).then(response =>
      getEnvOnly().VUE_APP_DEBQ_DIRECT == "true" ? response.data : response
    );
    if (turnInfo.error) throw new Error("Request error");
    turnInfo.status = "WAITING_TO_BE_CALLED";
    commit("setState", { key: "info", value: turnInfo });
    if (!onlyGenerate) {
      commit("setState", {
        key: "epoch",
        value: moment.utc().unix()
      });
      commit("setState", { key: "info", value: turnInfo });
      LS.setItem("turnInfo", turnInfo);
      if (withVideocall && turnInfo?.code)
        setCorrelation({ kuidId: coorelation_key, turnId: turnInfo.code });
      dispatch("getTurnStatus", _get(turnInfo, "code"));
    }

    // remove sessionStorage
    sessionStorage.removeItem("appointment");
    sessionStorage.removeItem("fecha_cita");
    sessionStorage.removeItem("info_cita");

    return turnInfo;
      
  },

  async getTurnRequest(__, code) {
    return (await axios.get(`/turn/code/${code}`)).data;
  },

  async getTurnStatus({ commit, state, dispatch }, code) {
    let codeToSend = code || _get(state.info, "code");
    if (codeToSend) {
      let response = _get(state.info, "code")
        ? JSON.parse(JSON.stringify(state.info))
        : null;
      try {
        let prevResponse = response;
        response = await getTurnInfo(codeToSend);
        commit("setState", {
          key: "infoErrorCount",
          value: 0
        });
        if (response.error) throw new Error("Request error");
        if (_get(response, "code") && _get(response, "jsonDetails")) {
          commit("setState", { key: "info", value: response });
        } else {
          let errorResp = JSON.stringify(JSON.parse(response));
          console.error("Corrupted response", errorResp);
          window.RB.error("Corrupted response in getTurnStatus", errorResp);
          response = prevResponse;
        }
      } catch (error) {
        console.error("Error in query from DebMedia", error);
        commit("setState", {
          key: "infoErrorCount",
          value: state.infoErrorCount + 1
        });
        if (state.infoErrorCount % 5 === 0)
          window.RB.error("Many request has failed in getTurnStatus", error);
      }
      clearTimeout(state.timer);
      commit("setState", { key: "timer", value: null });
      let timer = setTimeout(() => dispatch("getTurnStatus", codeToSend), 2000);
      commit("setState", { key: "timer", value: timer });
      return response;
    } else {
      console.error(
        "Polling is forced to stop in Turn component. Invalid Turn Code."
      );
      window.RB.error(
        "Polling is forced to stop in Turn component. Invalid Turn Code.",
        JSON.stringify({ code, turn: _get(state.info, "code") })
      );
      dispatch("cancelTurnAction");
      return;
    }
  },

  getTurnCoorelation(ctx, code) {
    console.log("GET TURN COORELATION FROM TURN")
    if (!code)
      return Promise.reject(
        new Error("Invalid Coorelation Code in Turn component.")
      );
    return getCorrelation(code).then(coorData => _get(coorData, "code_title"));
  },

  async cancelTurnAction({ state, commit }, onlyClean) {
    let results;
    if (!onlyClean) {
      try {
        if (!state.info?.code)
          results = { message: "Turn was canceled before" };
        else if (state.info?.status === "WAITING_TO_BE_CALLED")
          results =
            getEnvOnly().VUE_APP_DEBQ_DIRECT == "true"
              ? await axios
                  .post(`/turn/${state.info.code}/cancel`)
                  .then(response => response.data)
              : await cancelTurn(state.info.code);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    LS.removeItem("turnInfo");
    commit("setState", { key: "info", value: null });
    return results;
  },
  setTurnUUID({ commit }, code=null) {
    commit("setState", {
      key: "turnUUID",
      value: Math.floor(Date.now() / 1000) + (code || kuid())
    });
  }
};
