var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Home" },
    [
      _c("Header"),
      _c("div", { staticClass: "Page" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.explanation,
                expression: "explanation"
              }
            ],
            staticClass: "HomePage"
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-between align-items-center"
              },
              [
                _c("img", {
                  staticStyle: { width: "28px", opacity: "0" },
                  attrs: { src: require("@/assets/kiosco46/arrow.svg") }
                }),
                _c("TimerHome", {
                  attrs: {
                    containerImageDocs: _vm.containerImageDocs,
                    timer: _vm.timer,
                    showProgressBar: _vm.showProgressBar
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 contain color-colmedica-gray mt-0" },
              [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "pt-5" },
                  _vm._l(_vm.services, function(service) {
                    return _c(
                      "b-button",
                      {
                        key: service.code_name,
                        staticClass:
                          "my-4 align-self-center mx-auto home-service-button",
                        attrs: {
                          variant: "bluecolmedica",
                          size: "sm",
                          block: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.setService(service)
                          }
                        }
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "text-sizing-lg AuthMeds mb-0" },
                          [_vm._v(_vm._s(service.label))]
                        )
                      ]
                    )
                  }),
                  1
                )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pb-4 pt-4" }, [
      _c("h3", { staticClass: "Subtitle Auth" }, [
        _vm._v("Autorizaciones médicas")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }