var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Agendar" }, [
    _c("figure", { staticClass: "container-doc" }),
    _vm.showModal ? _c("div", { staticClass: "overlayau" }) : _vm._e(),
    _vm.showModal
      ? _c("div", { staticClass: "modalau" }, [
          _c(
            "button",
            {
              staticClass: "close-btn",
              on: {
                click: function($event) {
                  _vm.showModal = false
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "70",
                    height: "70",
                    viewbox: "0 0 70 70",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "1",
                      y: "1",
                      width: "68",
                      height: "68",
                      rx: "34",
                      fill: "white",
                      stroke: "#F2F2F2"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d:
                        "M22.2346 22.2425C23.3498 21.1242 25.1592 21.1228 26.2761 22.2393L34.9993 30.9595L43.7222 22.2395C44.8391 21.1229 46.6486 21.1244 47.7637 22.2427C48.8789 23.361 48.8775 25.1726 47.7605 26.2892L39.044 35.0028L47.7605 43.7165C48.8775 44.833 48.8789 46.6447 47.7637 47.763C46.6486 48.8813 44.8391 48.8827 43.7222 47.7662L34.9993 39.0462L26.2761 47.7664C25.1592 48.8829 23.3498 48.8815 22.2346 47.7632C21.1194 46.6449 21.1209 44.8332 22.2378 43.7167L30.9545 35.0028L22.2378 26.289C21.1209 25.1725 21.1194 23.3608 22.2346 22.2425Z",
                      fill: "#DEDEDE"
                    }
                  })
                ]
              )
            ]
          ),
          _c("h2", { staticClass: "blue-title" }, [_vm._v("Confirma tu")]),
          _c("h2", { staticClass: "blue-title" }, [
            _vm._v("número de celular")
          ]),
          _c("p", { staticClass: "mt-5 text-desc" }, [
            _vm._v(
              "Indica el número de teléfono para la confirmación de la cita."
            )
          ]),
          _vm._m(0),
          _c("div", { staticClass: "input-text" }, [
            _c("p", { staticClass: "label-text" }, [
              _vm._v("Número de teléfono*")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.models.NumeroIdentificacion,
                  expression: "models.NumeroIdentificacion"
                }
              ],
              staticClass: "Input-text",
              staticStyle: {
                "margin-bottom": "15px !important",
                width: "100%"
              },
              attrs: {
                type: "text",
                min: "0",
                placeholder: "Ingresa tu número",
                required: "",
                autocomplete: "off",
                id: "cedula_number"
              },
              domProps: { value: _vm.models.NumeroIdentificacion },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.models,
                    "NumeroIdentificacion",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c(
            "button",
            {
              staticClass: "text-center buttonService mt-3",
              on: { click: _vm.onShowModal }
            },
            [_c("span", [_vm._v("Confirmar el número teléfonico")])]
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "card-content" }, [
      _c("h2", { staticClass: "blue-title" }, [
        _vm._v("Selecciona el tipo e ingresa")
      ]),
      _c("h2", { staticClass: "blue-title" }, [
        _vm._v("el número de documento")
      ]),
      _c("div", { staticClass: "inputs" }, [
        _c("div", { staticClass: "select-input" }, [
          _c("p", { staticClass: "label-text" }, [_vm._v("Tipo de documento")]),
          _c(
            "div",
            { staticClass: "form-select-container select--Input-text mb-2" },
            [
              _c("b-form-select", {
                staticClass: "Input-text",
                attrs: {
                  options: _vm.options,
                  size: "sm",
                  autocomplete: "off",
                  required: "",
                  id: "formSelect"
                },
                model: {
                  value: _vm.models.TipoIdentificacion,
                  callback: function($$v) {
                    _vm.$set(_vm.models, "TipoIdentificacion", $$v)
                  },
                  expression: "models.TipoIdentificacion"
                }
              }),
              _c(
                "svg",
                {
                  staticClass: "mr-3",
                  attrs: {
                    width: "30",
                    height: "29",
                    viewbox: "0 0 30 29",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  },
                  on: { click: _vm.onFocusSelect }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M15.0001 1.16699C17.6371 1.16699 20.215 1.92946 22.4077 3.35791C24.6003 4.78637 26.3093 6.81667 27.3184 9.19211C28.3276 11.5675 28.5916 14.1814 28.0772 16.7031C27.5627 19.2249 26.2928 21.5413 24.4281 23.3594C22.5634 25.1774 20.1877 26.4156 17.6013 26.9172C15.0149 27.4188 12.334 27.1613 9.89762 26.1774C7.46128 25.1935 5.3789 23.5272 3.91382 21.3894C2.44873 19.2516 1.66675 16.7382 1.66675 14.167C1.66675 10.7192 3.0715 7.41259 5.57198 4.97462C8.07246 2.53665 11.4638 1.16699 15.0001 1.16699Z",
                      stroke: "#788591",
                      "stroke-width": "2.025",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d: "M15.0002 16.7656L18.5624 13.2925",
                      stroke: "#788591",
                      "stroke-width": "2.025",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  }),
                  _c("path", {
                    attrs: {
                      d: "M11.4377 13.2925L14.9999 16.7656",
                      stroke: "#788591",
                      "stroke-width": "2.025",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              ),
              _c("div", { staticClass: "svgBackground" })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "input-text" }, [
          _c("p", { staticClass: "label-text" }, [
            _vm._v("Número de documento")
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.models.NumeroIdentificacion,
                expression: "models.NumeroIdentificacion"
              }
            ],
            staticClass: "Input-text",
            staticStyle: { "margin-bottom": "15px !important", width: "100%" },
            attrs: {
              type: "text",
              min: "0",
              placeholder: "Ej: 105456783",
              required: "",
              autocomplete: "off",
              id: "cedula_number"
            },
            domProps: { value: _vm.models.NumeroIdentificacion },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(
                  _vm.models,
                  "NumeroIdentificacion",
                  $event.target.value
                )
              }
            }
          })
        ])
      ]),
      _c(
        "button",
        {
          staticClass: "text-center buttonService mt-3",
          staticStyle: { "max-width": "504px" },
          on: { click: _vm.onClickQuery }
        },
        [_c("span", [_vm._v("Consultar mis datos")])]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-desc" }, [_c("p", [_vm._v("sdds")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }