var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [_c("Header"), _vm._m(0)], 1)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100 text-center mt-4" }, [
      _c("div", { staticClass: "col-12 contain color-colmedica-gray" }, [
        _c(
          "p",
          { staticStyle: { "font-size": "1.3rem", "text-align": "center" } },
          [_vm._v("Estamos verificando interacciones anteriores...")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }