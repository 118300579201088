var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Procedure" }, [
    _c("div", { staticClass: "tapProcedure" }),
    _c("div", { staticClass: "position-relative" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-between backButton",
          on: { click: _vm.BackCalculed }
        },
        [
          _c("img", {
            staticClass: "icn_backicn_back ml-3",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      ),
      _c(
        "div",
        { staticClass: "w-100 d-flex justify-content-center columnClass" },
        [
          _c("img", {
            staticClass: "logoAuth mx-auto",
            attrs: {
              src: require("@/assets/mobileFSFB/logoFSFB.png"),
              alt: "logoFSFB"
            }
          }),
          _c("h3", { staticClass: "yourTurnLabelProcedure" }, [
            _vm._v(_vm._s(_vm.selectedArea ? _vm.selectedArea.label : ""))
          ])
        ]
      ),
      _vm.parentList.length > 0
        ? _c("div", {
            staticClass: "parentList",
            domProps: { innerHTML: _vm._s(_vm.pageSubTramiteLabel) }
          })
        : _c("div", {
            staticClass: "childList px-3",
            domProps: { innerHTML: _vm._s(_vm.pageTramiteLabel) }
          }),
      !_vm.pageTramiteLabel && !_vm.pageSubTramiteLabel
        ? _c("h2", { staticClass: "titleFSFBM pl-3 pt-4" }, [
            _vm._v("Cuéntanos, ¿qué necesitas?")
          ])
        : _vm._e(),
      !_vm.pageTramiteLabel && !_vm.pageSubTramiteLabel
        ? _c("p", { staticClass: "desc2 px-3" }, [
            _vm._v("Selecciona un tipo de trámite"),
            _c("span", { staticClass: "ml-0 d-block" }, [
              _vm._v("para continuar")
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "pt-3 buttons-dk text-start mx-auto text-center d-flex flex-wrap justify-content-center"
        },
        [
          _vm._l(_vm.actionToShow, function(act) {
            return _c("div", { staticClass: "d-inline-block mx-2" }, [
              !!!act.hidden
                ? _c(
                    "button",
                    {
                      key: act.actionCode,
                      staticClass:
                        "arrowButton p-1 w-100 my-2 text-center button-without-styles",
                      attrs: { disabled: !!act.disabled, block: "" },
                      on: {
                        click: function() {
                          return _vm.onSelectedThisProcedure(act)
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: act.button_icon,
                          alt: act.label,
                          width: "47",
                          height: "49"
                        }
                      }),
                      _c("span", {
                        staticClass:
                          "my-2 w-100 text-center d-block fontButton",
                        domProps: { innerHTML: _vm._s(act.label) }
                      })
                    ]
                  )
                : _vm._e()
            ])
          }),
          _c("div", { staticStyle: { width: "160px !important" } })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }