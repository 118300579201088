var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Quote",
      style: _vm.isAtril
        ? "height: calc(100vh - 0px); min-height: 768px; width: 100%;"
        : "width: 100%;",
      on: { click: _vm.validationAction }
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              style: _vm.isAtril
                ? "position: relative; height: 66px; margin-top: 23px; margin-bottom: 23px;"
                : "position: relative; height: 48px; margin-top: 51px;"
            },
            [
              _c(
                "span",
                {
                  staticClass: "text-center mt-2 h dk-pos-dk",
                  class: _vm.isAtril ? "h1 mt-4" : "",
                  style:
                    _vm.isAtril &&
                    "font-weight: bold !important; font-size: 2.8rem; top: 2%;"
                },
                [
                  _vm._v(
                    _vm._s(_vm.siteIsOffice ? "Oficina" : "Centro Médico") +
                      " " +
                      _vm._s(_vm.siteName)
                  )
                ]
              )
            ]
          ),
          _vm.citasFueraCM.length < 1
            ? _c(
                "p",
                {
                  staticClass: "text-center mt-4",
                  style: _vm.isAtril && "font-size: 2rem; margin: 0 !important;"
                },
                [_vm._v("¿Vienes para esta cita?")]
              )
            : _vm._e(),
          _vm.citasFueraCM.length
            ? _c(
                "p",
                {
                  staticClass: "text-center mt-4",
                  style: _vm.isAtril
                    ? "margin-bottom: 5rem; margin-top: 1rem;"
                    : "margin-top: 0.5rem;"
                },
                [
                  _c(
                    "small",
                    {
                      staticClass: "text-center mt-1 dk-link",
                      style:
                        _vm.isAtril && "font-size: 2rem; font-weight: bold;",
                      on: {
                        click: function($event) {
                          _vm.showModalOthers = true
                        }
                      }
                    },
                    [_vm._v("Ver citas programadas en otros Centros Médicos:")]
                  )
                ]
              )
            : _vm._e(),
          _vm.citasEnCM && _vm.citasEnCM.length
            ? _c("div", { class: _vm.isAtril ? "cards-dk" : "cards" }, [
                _c(
                  "div",
                  {
                    staticClass: "big_box mt-4",
                    style: _vm.isAtril
                      ? "position: relative; min-height: 160px; min-width: 600px;"
                      : ""
                  },
                  [
                    _c("div", { staticClass: "dk-overlay" }, [
                      _c(
                        "p",
                        {
                          style: _vm.isAtril
                            ? "font-weight: bold !important; font-size: 2rem;"
                            : "font-size: 1.3rem; font-weight: bold;"
                        },
                        [
                          _vm._v(
                            "Especialidad: " +
                              _vm._s(_vm.citasEnCM[0].Especialidad)
                          )
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "m-0",
                          style: _vm.isAtril
                            ? "font-weight: bold !important; font-size: 2rem;"
                            : "font-size: 1.3rem; font-weight: bold;"
                        },
                        [_vm._v("Fecha: " + _vm._s(_vm.citaHora))]
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "d-block text-center d-flex mt-2",
              staticStyle: {
                "flex-direction": "column",
                margin: "auto",
                "max-width": "370px"
              }
            },
            [
              _vm.citasFueraCM.length < 1
                ? _c(
                    "b-button",
                    {
                      staticClass: "text-center ml-2 mb-2",
                      style: _vm.isAtril
                        ? "font-size: 1.7rem !important; " +
                          _vm.colorComputed +
                          " display: block;"
                        : _vm.colorComputed + " display: block;",
                      attrs: {
                        disabled: !!_vm.charging,
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: {
                        click: function($event) {
                          return _vm.selectCita(_vm.citasEnCM[0])
                        }
                      }
                    },
                    [
                      !!!_vm.charging
                        ? _c(
                            "span",
                            {
                              style: _vm.isAtril
                                ? "font-size: 1.7rem !important;"
                                : "font-size: 1.3rem !important;"
                            },
                            [_vm._v("Si, continuar con esta cita")]
                          )
                        : _c("span", [_c("MiniSpinner")], 1)
                    ]
                  )
                : _vm._e(),
              _c(
                "b-button",
                {
                  staticClass: "text-center ml-2",
                  style: _vm.isAtril
                    ? "font-size: 1.7rem !important; " + _vm.colorComputed
                    : _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToView({ view: "NoDate" })
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      style: _vm.isAtril
                        ? "font-size: 1.7rem !important;"
                        : "font-size: 1.3rem !important;"
                    },
                    [_vm._v("No, continuar con otro servicio")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "text-center",
              staticStyle: { "font-style": "italic", "margin-top": "2rem" }
            },
            [_vm._v(_vm._s(_vm.computedText))]
          ),
          _c(
            "b-modal",
            {
              attrs: { "hide-footer": "" },
              model: {
                value: _vm.showModalOthers,
                callback: function($$v) {
                  _vm.showModalOthers = $$v
                },
                expression: "showModalOthers"
              }
            },
            [
              _c(
                "h6",
                {
                  staticClass: "text-center",
                  staticStyle: { color: "gray" },
                  style: _vm.isAtril && "font-size: 1.4rem;"
                },
                [_vm._v("Estas citas son de otros Centros Médicos")]
              ),
              _c(
                "div",
                {
                  staticClass: "cards",
                  staticStyle: { "padding-bottom": "100px" }
                },
                _vm._l(_vm.citasFueraCM, function(cita) {
                  return _c(
                    "div",
                    { key: cita.idCita, staticClass: "big_box mb-1" },
                    [
                      _c("div", { staticClass: "dk-overlay" }, [
                        _c(
                          "h5",
                          {
                            style: _vm.isAtril
                              ? "font-size: 1.6rem; font-weight: bold !important; color: #545454;"
                              : "font-size: 1.3rem; font-weight: bold;"
                          },
                          [_vm._v("Cita " + _vm._s(cita.idCita) + ".")]
                        ),
                        _c(
                          "h6",
                          {
                            style: _vm.isAtril
                              ? "font-size: 1.6rem; font-weight: bold !important; color: #545454;"
                              : "font-size: 1.3rem; font-weight: bold;"
                          },
                          [
                            _vm._v(
                              "Nombre del centro médico: " +
                                _vm._s(cita.NombreCentroMedico)
                            )
                          ]
                        ),
                        _c(
                          "p",
                          {
                            style: _vm.isAtril
                              ? "font-size: 1.6rem; font-weight: bold !important; color: #545454;"
                              : "font-size: 1.3rem; font-weight: bold;"
                          },
                          [_vm._v("Especialidad: " + _vm._s(cita.Especialidad))]
                        ),
                        _c(
                          "p",
                          {
                            staticClass: "m-0",
                            style: _vm.isAtril
                              ? "font-size: 1.6rem; font-weight: bold !important; color: #545454;"
                              : "font-size: 1.3rem; font-weight: bold;"
                          },
                          [_vm._v("Fecha: " + _vm._s(cita.Fecha))]
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "buttons", style: _vm.colorSecondaryComputed },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-content-between" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-center",
                      style: _vm.colorComputed,
                      attrs: {
                        variant: "bluecolmedica",
                        size: "sm",
                        type: "button"
                      },
                      on: { click: _vm.goBack }
                    },
                    [
                      _c("Back", { attrs: { size: "24px" } }),
                      _c("span", { staticClass: "text-sizing-12" }, [
                        _vm._v("Atrás")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }