var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "NoDate Container",
      style: _vm.isAtril
        ? "color: #545454; width: 100%; height: calc(100vh - 112.06px); min-height: 600px;"
        : "margin: auto; width: 100%; font-size: 1.3rem;"
    },
    [
      _c(
        "div",
        {
          staticClass: "Content mt-0 box pb-0",
          style:
            _vm.parentList.length < 1 && _vm.padding
              ? "padding:" + _vm.padding + "px"
              : ""
        },
        [
          _vm.parentList.length > 0
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.pageSubTramiteLabel) }
              })
            : _c("div", {
                staticClass: "innerHTML",
                domProps: { innerHTML: _vm._s(_vm.pageTramiteLabel) }
              }),
          !_vm.pageTramiteLabel && !_vm.pageSubTramiteLabel
            ? _c(
                "h3",
                { staticClass: "fonterH3 fonterH3NoDate mb-1 mx-auto" },
                [_vm._v("Cuéntanos, ¿qué necesitas?")]
              )
            : _vm._e(),
          !_vm.pageTramiteLabel && !_vm.pageSubTramiteLabel
            ? _c(
                "p",
                {
                  staticClass: "fonterNoDate textHero m-0 mx-auto d-block mb-3"
                },
                [_vm._v("Selecciona la opción que deseas consultar")]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "pt-1 buttons-dk d-flex text-start justify-content-center mx-auto",
              style: _vm.isAtril ? "" : "margin: 30px auto;"
            },
            [
              _vm._l(_vm.actionToShow, function(act) {
                return [
                  !!!act.hidden
                    ? _c(
                        "button",
                        {
                          key: act.actionCode,
                          staticClass:
                            "arrowButton p-4 w-100 my-3 text-center button-without-styles mx-3 d-flex",
                          class: _vm.selectedThing === act && "selectedThing",
                          style: act.disabled ? "opacity:0.7" : "",
                          attrs: { disabled: !!act.disabled, block: "" },
                          on: {
                            click: function() {
                              return _vm.onSelectedCheckbox(act)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: act.button_icon, alt: act.label }
                          }),
                          _c("span", {
                            staticClass:
                              "my-4 w-100 text-center d-block fontButton",
                            domProps: { innerHTML: _vm._s(act.label) }
                          }),
                          !act.disabled
                            ? _c(
                                "div",
                                { staticClass: "position-relative container" },
                                [
                                  _c("input", {
                                    staticClass: "checkBoxArrow",
                                    attrs: {
                                      type: "checkbox",
                                      id: act.actionCode,
                                      disabled: ""
                                    }
                                  }),
                                  _c("div", { staticClass: "checkmark" })
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          ),
          _c("div", { staticClass: "pb-2" }, [
            _c(
              "button",
              {
                staticClass: "text-center buttonService containerForm mt-3",
                class: !_vm.selectedThing ? "disabled" : "active",
                attrs: { disabled: !_vm.selectedThing },
                on: {
                  click: function($event) {
                    return _vm.calculateAction(_vm.selectedThing)
                  }
                }
              },
              [_vm._v("Continuar")]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }