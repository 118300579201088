/* eslint-disable no-unused-vars */
import {
  getToken,
  getQueues,
  getStyles,
  getEnvOnly,
  getMenus,
  sendRecords,
  tracking
} from "../../helpers/Meet";
import {
  validarPago
} from "../../helpers/colmedica_fila_virtual";
import { autorizarFSFB, citasFSFB, fetchCupsCode, notificationsFSFB, pay, checkPay, cancelPay, syncPaysWithFSFB, payLogs, fetchToken } from "../../helpers/santafe_fila_firtual";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import LS from "../../helpers/localStorage";
import { sendMessage, checkMessage } from "../../helpers/pays_messages";
import moment from "moment";

/**
 * Data for demo porpouses
 */
import CentroDemo from "./CentroDemo";
import OficinaDemo from "./OficinaDemo";
import CitasDemo from "./CitasDemo";
import ValPagoDemo from "./ValidarpagocitaDemo";

export default {
  changeAuth({ commit }, value) {
    commit("setState", { key: "showAuth", value });
  },

  setCustomArea({ commit }, value) {
    commit("setState", { key: "customArea", value });
  },

  setSede({ commit }, value) {
    commit("setState", { key: "sedeCode", value });
  },

  setUserActionDate({ commit },value){
    commit("setState", { key: "userActionDate", value });
  },

  async getSites({ commit }) {
    const sites = await getQueues();
    if (sites && sites.length)
      commit("setState", { key: "sites", value: sites });
  },

  async getStyles({ commit }) {
    try {
      const styles = await getStyles();
      commit("setState", {
        key: "primaryColorBlue",
        value: styles?.bsix_client?.companyColor
      });
      commit("setState", {
        key: "secondColorBlue",
        value: styles?.bsix_client?.secondaryCompanyColor
      });
      commit("setState", {
        key: "threeColorBlueColor",
        value: styles?.bsix_client?.contrastTextColor
      });
    } catch (error) {
      console.error("Cant get Styles", error);
    }
  },

  async getMenus({ commit }, { code, area }) {
    let { menu, actions, customMenu } = await getMenus(code);
    commit("setState", { key: "menu", value: customMenu[area] || menu });
    commit("setState", { key: "actions", value: actions });
    commit("setState", { key: "basemenu", value: customMenu[area] || menu });
  },

  setMenus({ commit }, val) {
    commit("setState", { key: "menu", value: val });
  },

  setAuthorize({ commit }, val) {
    commit("setState", { key: "authorize", value: val });
  },

  restartTimerModal({ commit }, fn) {
    commit("setState", { key: "restartTimerModalState", value: fn });
  },

  goToView({ commit, state }, { view }) {
    // if (state.isAtril) {
    //   dispatch("setTimer", true, { root: true });
    // }
    commit("setState", { key: "view", value: view });
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.push(view);
    commit("setState", { key: "history", value: newArray });
  },

  setExplanation({ commit }, value) {
    commit("setState", { key: "explanation", value });
  },

  selecteService({ commit }, value) {
    commit("setState", { key: "servicioSelected", value });
  },

  goBack({ commit, state }) {
    let newArray = JSON.parse(JSON.stringify(state.history));
    newArray.pop();
    commit("setState", { key: "history", value: newArray });
    commit("setState", {
      key: "view",
      value: state.history[state.history.length - 1]
    });
  },

  async setCitaCM({ commit, dispatch, state }, appointment) {
    
    // Lf Tested 21-01-21
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "currentAppointment", value: appointment });
    commit("setState", { key: "currentValidation", value: [] });

    try {

      let currentSite = state.sites.find(s=>s.code == state.sedeCode);

      // Check Payments enabled
      let paymentsEnabled = currentSite.customAreas[state.customArea];

      console.log("PAYMENTS ENABLED: " + paymentsEnabled.enablePayments)

      // Check if appointment is pending for pay
      if(
        paymentsEnabled.enablePayments &&
        appointment.authorized_appoinment == 'SI' && 
        appointment.appoinment_paid == 'NO' && 
        appointment.cost_appoinment > 0 && (
        appointment.appoinment_url_paid || (!appointment.appoinment_url_paid && !paymentsEnabled.hiddenButtonPaymentAgent) || 
        (!appointment.appoinment_url_paid && paymentsEnabled.showButtonPaymentDataphone) )
      ){
      // if(appointment.appoinment_paid == 'NO' && (appointment.appoinment_url_paid || appointment.cost_appoinment > 0)){
        commit("setState", { key: "charging", value: false });
        return dispatch("goToView", { view: "Payment" });  
      }
      
      
      let conditions = currentSite.conditions[state.customArea];

      // check conditions
      let code = conditions.find(c=>{
        if(c.appoinment_paid == appointment.appoinment_paid && 
          c.authorized_appoinment == appointment.authorized_appoinment) 
          return c
      })

      // let menu =
      //   state.menu[0] && state.menu[0].children // TODO: Only 2 levels. If require multilevel, fix it with a recursive function
      //     ? state.menu[0].children
      //     : state.menu;
      // menu = menu.filter(m => m.service_id == value.service.code);

      // serch actions
      commit("setState", {
        key: "selectedAccion",
        value: code
      });

      // Check if priority
      let queue = null;
      try {
        queue = (
          state.actions?.[state.customArea] ||
          state.actions?.default ||
          []
        ).find(br => br.actionCode === state.selectedAccion?.actionCode);
      } catch (error) { console.log("Error: Check customArea " + error )}
      
      commit("setState", { key: "charging", value: false });
      if ( queue && (queue.priority_tramite_id != queue.tramite_id) ) return dispatch("goToView", { view: "Normativity" });  
      else return dispatch("turnCall", false);
    } catch (error) {
      console.error("In setCitaCM action", error);
    }
    commit("setState", {
      key: "selectedAccion",
      value:
        state.menu[0] && state.menu[0].children // TODO: Only 2 levels. If require multilevel, fix it with a recursive function
          ? state.menu[0].children[0]
          : state.menu[0]
    });
    
  },

  setAction({ commit, dispatch }, value) {
    commit("setState", { key: "selectedAccion", value });
    if (value?.evaluationComponent)
      return dispatch("goToView", { view: value.evaluationComponent });
    if (value?.ignorePriority) {
      commit("setState", { key: "priority", value: false });
      dispatch("goToView", { view: "Turn" });
    } 
    else {
      if (value.userAge && value.userAge >= 60) {
        commit("setState", { key: "priority", value: true });
        dispatch("goToView", { view: "Turn" });
      } else dispatch("goToView", { view: "Normativity" });
    } 
  },

  confirmedAction({ state, commit, dispatch }) {
    // /  state.selectedaction tiene ignorePriority?
    if (state.selectedAccion?.ignorePriority)
      dispatch("goToView", { view: "Turn" });
    else dispatch("goToView", { view: "Normativity" });
  },

  turnCall({ commit, dispatch }, value) {
    commit("setState", { key: "priority", value });
    dispatch("goToView", { view: "Turn" });
  },

  async getCitaValidation(ctx, { idCita }) {
    let toReturn = [];
    if (getEnvOnly().VUE_APP_DEMO_MODE === "true") {
      if (idCita === 123456) return ValPagoDemo.good;
      else ValPagoDemo.bad;
    } else {
      try {
        toReturn = await validarPago({ idCita });
      } catch (error) {
        console.error("In getCitaValidation", error);
      }
    }
    return toReturn;
  },

  captchaValidation({ dispatch, commit, state }) {
    if (window.grecaptcha && window.grecaptcha.ready) {
      return new Promise((res, rej) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(getEnvOnly().VUE_APP_RECAPTCHA_CODE, {
              action: "submit_identification"
            })
            .then(token =>
              getToken(token, "g_recaptcha_v3", {
                bunitId: getEnvOnly().VUE_APP_BUNIT
              })
            )
            .then(() => res())
            .catch(err => {
              commit("setState", {
                key: "validationErrors",
                value: [...state.validationErrors, err]
              });
              dispatch("errorValidationTemporizer");
              console.error("error in recaptcha request.");
              rej(err);
            });
        }); //
        // throw new Error('X ERROR');
      }).catch(err => {
        commit("setState", { key: "charging", value: false });
        console.error("Error cached in Autenticate.vue -> demoValidation", err);
        dispatch("goToView", { view: "ErrorAutenticate" });
      });
    }
    return Promise.reject(new Error("Recaptcha is not ready"));
  },

  async authenticate({ dispatch, commit, state }, toSend) {
    commit("setState", { key: "charging", value: true });
    commit("setState", { key: "error", value: null });
    let nextComponent = {
      view: "ErrorAutenticate"
    };
    try {
      if (!state.isAtril) await dispatch("captchaValidation");
      try {
        await dispatch(
          getEnvOnly().VUE_APP_DEMO_MODE === "true"
            ? "demoValidation"
            : "authAndQuery",
          toSend
        );

        // check area
        let currentSite = state.sites.find(s=>s.code == state.sedeCode);
        let currentArea = currentSite.customAreas[state.customArea];
        if(currentArea.authorize) {
          nextComponent.view = state.citas && state.citas.length ? "Quote" : "Authorization";
        } else {
          nextComponent.view = state.citas && state.citas.length ? "Quote" : "ExistentUser";
        }
      } catch (error) {

        if(error.message == "Connection Error") {
          commit("setState", { key: "charging", value: false });
          return commit("setState", {
            key: "error",
            value: {
              zone: "authorization",
              message: "Error de conexión con servidor. Por favor, intenta nuevamente."
            }
          });
        }
        
        console.error("Error in authorization", error);
        commit("setState", {
          key: "error",
          value: {
            zone: "authorization",
            message: "El usuario no se encuentra registrado en el sistema"
          }
        });
        dispatch("errorValidationTemporizer");
        nextComponent.view = "Help";
      }
    } catch (error) {
      console.error("Error in Captcha", error);
      commit("setState", {
        key: "error",
        value: { zone: "recaptcha", message: "La validación del captcha falló" }
      });
      dispatch("errorValidationTemporizer");
    }
    dispatch("goToView", nextComponent);
    commit("setState", { key: "charging", value: false });
  },

  async demoValidation({ commit, state }, toSend) {
    let userfinded = state.userDemo.find(
      usr => usr.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    await new Promise(res => setTimeout(res, 2000));
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
    if (!userfinded) throw new Error("Invalid demo User");
    let originFinded = (state.currentSite?.type === "cm"
      ? CentroDemo
      : OficinaDemo
    ).find(
      cm =>
        cm.TipoIdentificacion == toSend?.TipoIdentificacion &&
        cm.NumeroIdentificacion == toSend?.NumeroIdentificacion
    );
    if (!originFinded) throw new Error("User not have meetings");
    toSend = { ...toSend, ...originFinded };
    if (
      state.currentSite?.type === "cm" &&
      toSend.NumeroIdentificacion === "789"
    )
      commit("setState", { key: "citas", value: CitasDemo });
    else commit("setState", { key: "citas", value: [] });
    commit("setState", { key: "currentUser", value: toSend });
    LS.setItem("currentUser", toSend);
  },

  async authAndQuery({ state, commit }, pay) {
    try {
      // Check Company

      // Santafe
      if (getEnvOnly().VUE_APP_COMPANY == "fsfb") {
        commit("setState", { key: "charging", value: true });
        const userResponse = await autorizarFSFB({
          TipoIdentificacion: pay.TipoIdentificacion,
          NumeroIdentificacion: pay.NumeroIdentificacion
        });
        
        if(userResponse.message && userResponse.message.includes("Failed to fetch")){
          throw new Error("Connection Error");
        }

        let citasList = [];
        if (userResponse && userResponse.id_patient) {
          // Check appointments
          citasList = await citasFSFB(userResponse);

          // check cups_code
          let cups_code = await fetchCupsCode(state.customArea.toUpperCase());
          cups_code = cups_code.map(c=>c.cups_code.S)
          citasList = citasList.filter( c=> cups_code.includes(c.attention.code) );

          /**
           * ESTO ES PARA PROBAR
           */
          // citasList = citasList.map( c=> {
          //   if(c.appoinment_paid == 'NO' && (!c.cost_appoinment || (c.cost_appoinment && c.cost_appoinment< 1) ) ){
          //   // if((!c.cost_appoinment || (c.cost_appoinment && c.cost_appoinment< 1) ) ){
          //     c.cost_appoinment = 9500 
          //   }
          //   c.cost_appoinment = 9500 
          //   c.appoinment_url_paid = 'www.test.com';
          //   c.appoinment_paid = 'NO';
          //   return c
          // } );
          /**
           * ---------
           */
          citasList = citasList.sort((itemA, itemB) => {
            const itemBHCS = moment().hour(itemB.hour_appoinment.split(":")[0]);
            const itemAHCS = moment().hour(itemA.hour_appoinment.split(":")[0]);
      
            if (moment().diff(itemAHCS.hour()) - moment().diff(itemBHCS.hour()) > 0) {
              return -1;
            }
            if (
              moment().diff(itemAHCS.hour()) - moment().diff(itemBHCS.hour()) <=
              0
            ) {
              return 1;
            }
            return 0;
          })

        } else {
          throw new Error("Invalid Authorization in Fundación Santafe");
        }

        // if(!userResponse || !userResponse.codigo){
        //   throw new Error("Invalid Authorization in Fundación Santafe");
        // }
        commit("setState", { key: "citas", value: citasList });
        commit("setState", {
          key: "currentUser",
          value: { ...pay, ...userResponse }
        });

        commit("setState", { key: "charging", value: false });
      
      } else {
        commit("setState", { key: "currentUser", value: pay });
        throw "No company defined";
      }
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }
  },

  handleSendRecords({ commit }, info) {
    return sendRecords(info)
      .then(response => {
        // commit("saveRecord", response);
        commit("setState", { key: "saveRecord", value: response });
        return response;
      })
      .catch(error => {
        // commit("errorRecord", error);
        commit("setState", { key: "errorRecord", value: error });
        return {
          response: false,
          message: error
        };
      });
  },

  handleTracking({ commit }, info) {
    return tracking(info)
      // .then(response => {
      //   commit("setState", { key: "trackingRecord", value: response });
      //   return response;
      // })
      // .catch(error => {
      //   commit("setState", { key: "errorTrackingRecord", value: error });
      //   return {
      //     response: false,
      //     message: error
      //   };
      // });
  },

  errorValidationTemporizer({ commit, state }) {
    if (state.errorValidationTemporizer !== null)
      clearTimeout(state.errorValidationTemporizer);
    if (state.error) return;
    let timer = setTimeout(() => {
      commit("setState", { key: "error", value: null });
      commit("setState", { key: "errorValidationTemporizer", value: null });
    }, 5000);
    commit("setState", { key: "errorValidationTemporizer", value: timer });
  },

  cleanCurrentUser({ commit }) {
    commit("setState", { key: "currentUser", value: null });
    LS.removeItem("currentUser");
  },

  clearError({ commit }) {
    commit("setState", { key: "error", value: null });
  },

  async mobileNotification({ state, commit }, pay) {

    try {
      
      // check notification
      let notification = await notificationsFSFB({
        "tdoc": pay.type == 1 ? state.convertDocumentsFromString[state.currentUser.TipoIdentificacion] : null,
        "doc": pay.type == 1 ? state.currentUser.NumeroIdentificacion : null,
        "title": pay.title,
        "body": pay.body,
        "type": pay.type,
        "module": state.customArea //"derivaciones"
      });
      return notification.response;
      
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }

  },

  async sendMessageForPay({ commit }, pay) {
    try {

      commit("setState", { key: "qrLoading", value: true });

      /* eslint-disable */
      return new Promise(async resolve=>{

        pay.promise = resolve;

        // check notification
        let notification = await sendMessage(pay);

        commit("setState", { key: "qrLoading", value: false });
        return notification;
      })
      
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }
  },

  async checkMessageStatus({ commit }, pay) {
    
    try {
      
      // check message
      let message = await checkMessage(pay.id_cita_pago);

      commit("setState", { key: "qrLoading", value: false });
      return message;
      
    } catch (error) {
      commit("setState", { key: "currentUser", value: pay });
      throw error;
    }
  },

  // Send pay to CrediBanco
  async sendCrediPay({ commit, state }, id_transaction){

    try {
      
      let terminal = state.currentSite.customAreas[state.customArea].terminal;

      // search terminal
      state.site
      let data  = 
      `${state.currentAppointment.cost_appoinment},`+
      '0,'+
      '0,'+
      '0,'+
      `${state.currentAppointment.cost_appoinment},`+
      `${id_transaction},`+
      `${state.customArea},`+
      '0,'+
      `${state.currentAppointment.cost_appoinment},`+
      'C,'+
      '0,'+
      '0,'+
      `${terminal},`+
      '0,'+
      state.customArea;
      
      // check res
      let res = await pay({
        data,
        id_transaction
      });

      commit("setState", { key: "dataPayment", value: data });

      // set data
      return res;
      
    } catch (error) {
      commit("setState", { key: "currentErrorPay", value: error });
      throw error;
    }
  },

  clearMessagePays({commit}){
    commit("setState", { key: "respPayments", value: "" });
  },

  async checkCrediPay({ commit }, res){
    
    try {
      
      // check res
      let resp = await checkPay(res);
      commit("setState", { key: "respPayments", value: resp });

      // set data
      return resp;
      
    } catch (error) {
      commit("setState", { key: "currentErrorPay", value: error });
      throw error;
    }

  },

  async cancelPayment({ commit }, res){
    
    try {
      
      // check res
      let resp = await cancelPay(res);

      // set data
      return resp;
      
    } catch (error) {
      commit("setState", { key: "currentErrorPay", value: error });
      throw error;
    }

  },

  // Sync pays
  async syncPaymentsFSFB({ commit }, res){
    
    try {
      
      // check res
      let resp = await new Promise(r => {
        syncPaysWithFSFB(res, r);
      });

      // set data
      return resp;
      
    } catch (error) {
      commit("setState", { key: "currentErrorPay", value: error });
      throw error;
    }
  },

  async saveLogsPayments({commit}, data){
    
    try {
      
      // check res
      let resp = await new Promise(r=>{
        payLogs(data, r);
      }) 

      // set data
      return resp;
      
    } catch (error) {
      commit("setState", { key: "currentErrorPay", value: error });
      throw error;
    }
  },

  refreshTokenPays({commit}){
    let res = fetchToken();
    commit("setState", { key: "tokenPayments", value: res.token });
  },

  clear({commit}){
    commit("setState", { key: "dataPayment", value: "" });
    commit("setState", { key: "respPayments", value: "" });
    commit("setState", { key: "currentErrorPay", value: null });
  },

  cleanMenus({ commit, state }) {
    let base = _cloneDeep(state.basemenu);
    commit("setState", { key: "menu", value: base });
  },
};
