var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Content" },
    [
      _c("Header"),
      _vm.isntIntersection
        ? _c("div", { staticClass: "headerFixed" }, [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.explanation,
                    expression: "!explanation"
                  }
                ],
                staticClass: "button-without-styles py-3 px-4",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.setExplanation(true)
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "19",
                      viewbox: "0 0 20 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                        stroke: "#ffff",
                        "stroke-width": "3.12307",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "botton-actions ml-4 pl-2" }, [
        _c("div", { staticClass: "d-flex justify-content-start" }, [
          _c(
            "button",
            {
              staticClass: "button-without-styles",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.setExplanation(true)
                }
              }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "19",
                    viewbox: "0 0 20 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                      stroke: "#0058A2",
                      "stroke-width": "3.12307",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "contain color-colmedica-gray" }, [
        _c("h2", { staticClass: "TitleSection text-center m-0" }, [
          _vm._v("Selecciona el")
        ]),
        _c("h2", { staticClass: "TitleSection text-center mb-4" }, [
          _vm._v("servicio que requieres autorizar:")
        ]),
        _c(
          "div",
          _vm._l(_vm.services, function(service) {
            return _c(
              "button",
              {
                key: service.code_name,
                staticClass: "w-100 button-without-styles my-2 mt-3",
                on: {
                  click: function($event) {
                    return _vm.setService(service)
                  }
                }
              },
              [
                _c(
                  "stack-item",
                  { staticClass: "btn-block align-self-center mx-auto" },
                  [_vm._v(_vm._s(service.label))]
                )
              ],
              1
            )
          }),
          0
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }