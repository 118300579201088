var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Payment" },
    [
      _vm.preload ? _c("LoadingFSFB") : _vm._e(),
      _c("div", { staticClass: "ContainerHeader pb-5" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-between" },
          [
            !_vm.fromQR
              ? _c("img", {
                  staticClass: "icn_backicn_back ml-3 backButton",
                  attrs: {
                    src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
                    alt: "icn_backicn_back"
                  },
                  on: { click: _vm.goBack }
                })
              : _c("div", { staticClass: "icn_backicn_back ml-3" }),
            _vm.statusPayment == "success"
              ? _c("h3", { staticClass: "fontTitleHeader m-0" }, [
                  _vm._v("Pago exitoso")
                ])
              : _vm.statusPayment == "error"
              ? _c("h3", { staticClass: "fontTitleHeader m-0" }, [
                  _vm._v("Pago no exitoso")
                ])
              : _vm.statusPayment == "outTime"
              ? _c("h3", { staticClass: "fontTitleHeader m-0" }, [
                  _vm._v("Fuera de horario")
                ])
              : _c("h3", { staticClass: "fontTitleHeader m-0" }, [
                  _vm._v("Pendiente de pago")
                ]),
            _c("img", {
              staticClass: "icn_backicn_back opacity-0 mr-3",
              attrs: {
                src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
                alt: "icn_backicn_back"
              }
            })
          ]
        )
      ]),
      _c("div", { staticClass: "ContainerData px-2" }, [
        _c("div", { staticClass: "card mt-4" }, [
          _vm.appointment && _vm.appointment.service
            ? _c("h6", { staticClass: "subTitle text-start pb-3 m-0" }, [
                _c("strong", [_vm._v(_vm._s(_vm.appointment.service.detail))])
              ])
            : _vm._e(),
          _c("p", { staticClass: "mb-2" }, [_vm._v(_vm._s(_vm.doctorName))]),
          _c(
            "div",
            {
              staticClass:
                "dateCard d-flex align-items-center justify-content-between p-3"
            },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "25",
                      viewbox: "0 0 24 25",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M9.46056 5.62695V7.31721",
                        stroke: "#5AC3F0",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d: "M14.8297 5.62695V7.31721",
                        stroke: "#5AC3F0",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M7.92462 6.33936H16.3411C16.7418 6.33936 17.1261 6.50672 17.4094 6.80462C17.6927 7.10251 17.8519 7.50655 17.8519 7.92784V16.4499C17.8519 16.8724 17.6923 17.2775 17.4081 17.5763C17.124 17.875 16.7387 18.0428 16.3369 18.0428H7.92041C7.51973 18.0428 7.13546 17.8755 6.85214 17.5776C6.56882 17.2797 6.40965 16.8756 6.40965 16.4543V7.92784C6.40965 7.71887 6.44887 7.51195 6.52506 7.31893C6.60124 7.12592 6.7129 6.95061 6.85363 6.80305C6.99436 6.65549 7.16141 6.53857 7.34519 6.459C7.52897 6.37944 7.72587 6.33878 7.92462 6.33936V6.33936Z",
                        stroke: "#5AC3F0",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d: "M6.41446 9.32617H17.8777",
                        stroke: "#5AC3F0",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                ),
                _c("p", { staticClass: "mb-0 parragraphCapitalize" }, [
                  _vm._v(
                    _vm._s(_vm.citaFecha(_vm.appointment.date_appoinment)) + " "
                  )
                ])
              ]),
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "24",
                      height: "25",
                      viewbox: "0 0 24 25",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M10.9219 18.043C14.0735 18.043 16.6283 15.3567 16.6283 12.043C16.6283 8.72926 14.0735 6.04297 10.9219 6.04297C7.77031 6.04297 5.21545 8.72926 5.21545 12.043C5.21545 15.3567 7.77031 18.043 10.9219 18.043Z",
                        stroke: "#89D4F4",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d: "M10.9219 12.0431V8.79297",
                        stroke: "#89D4F4",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d: "M10.9219 12.043H12.871",
                        stroke: "#89D4F4",
                        "stroke-width": "1.5",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                ),
                _c("p", { staticClass: "mb-0" }, [
                  _vm._v(
                    _vm._s(
                      _vm.citaHora(
                        _vm.appointment.date_appoinment +
                          " " +
                          _vm.appointment.hour_appoinment
                      )
                    )
                  )
                ])
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "dateCard d-flex align-items-center p-3 mt-3" },
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v("№ de confirmación: " + _vm._s(_vm.appointment.pac_num))
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "card card-2 mb-5" }, [
          _vm.statusPayment
            ? _c("div", [
                _vm.statusPayment === "success"
                  ? _c("h3", [_vm._v("Pago exitoso!")])
                  : _vm._e(),
                _vm.statusPayment === "error"
                  ? _c("h3", [_vm._v("El pago no fue exitoso...")])
                  : _vm._e(),
                _vm.statusPayment === "error"
                  ? _c(
                      "button",
                      {
                        staticClass: "buttonForm d-block w-100 mt-4",
                        on: {
                          click: function($event) {
                            return _vm.onSelectedThisProcedure(_vm.appointment)
                          }
                        }
                      },
                      [_c("span", [_vm._v("Pagar con asesor")])]
                    )
                  : _vm._e(),
                _vm.statusPayment === "outTime"
                  ? _c("h3", [_vm._v("La cita esta fuera del horario")])
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "buttonForm d-block w-100 mt-4",
                    on: { click: _vm.checkTurn }
                  },
                  [
                    _vm.loading && _vm.loading === "checkTurn"
                      ? _c("span", [_c("MiniSpinner")], 1)
                      : _c("span", [_vm._v("Continuar")])
                  ]
                )
              ])
            : _c("div", [
                _c("h3", [
                  _vm._v("Selecciona la forma en que deseas pagar tu cita:")
                ]),
                _vm.urlExist
                  ? _c(
                      "button",
                      {
                        staticClass: "buttonForm d-block w-100 mt-4",
                        on: { click: _vm.openAlert }
                      },
                      [
                        _vm.loading && _vm.loading === "externalPay"
                          ? _c("span", [_c("MiniSpinner")], 1)
                          : _c("span", [_vm._v("Pagar Online")])
                      ]
                    )
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "buttonForm d-block w-100 mt-4",
                    on: {
                      click: function($event) {
                        return _vm.onSelectedThisProcedure(_vm.appointment)
                      }
                    }
                  },
                  [_c("span", [_vm._v("Pagar con asesor")])]
                )
              ])
        ])
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            id: "pays-modal-mobileFSFB",
            "hide-footer": "",
            "hide-header": "",
            size: "s",
            centered: "",
            "no-close-on-backdrop": ""
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "dateCard d-flex align-items-center justify-content-between p-3",
              staticStyle: { "text-align": "center" }
            },
            [
              _c("strong", { staticClass: "mt-0 mb-0 fonterCurrentUser" }, [
                _vm._v(
                  "Al finalizar tu proceso de pago, recuerda descargar tu comprobante y regresar al comercio"
                )
              ])
            ]
          ),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "buttonForm d-block w-100 mt-2",
                staticStyle: { background: "#aaaaaa" },
                on: { click: _vm.cancelPayAction }
              },
              [_c("span", [_vm._v("Cancelar")])]
            ),
            _c(
              "button",
              {
                staticClass: "buttonForm d-block w-100 mt-2",
                on: {
                  click: function($event) {
                    return _vm.externalPay(_vm.appointment)
                  }
                }
              },
              [_c("span", [_vm._v("Continuar con Pago Online")])]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }