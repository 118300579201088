var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Timer" }, [
    _c(
      "div",
      {
        staticClass: "containerTimer",
        staticStyle: { "--duration": "15", "--size": "80" },
        on: { click: _vm.goToHome }
      },
      [
        _c("div", { staticClass: "circle_mask" }),
        _vm.showProgressBar
          ? _c("div", { staticClass: "timer_container" }, [
              _c("div", { ref: "timer", staticClass: "timer" }, [
                _c("div", { staticClass: "mask" })
              ])
            ])
          : _vm._e(),
        _c(
          "div",
          { ref: "circle", staticClass: "circle" },
          [_c("home", { attrs: { size: "40px" } })],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }