/* eslint-disable no-unused-vars */
import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import { store } from "./store";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import ToggleButton from "vue-js-toggle-button";
import Rollbar from "rollbar";
import LS from "./helpers/localStorage";
import kuid from "kuid";
import VueProgress from "./externals/progress";
import { getEnvironments } from "./helpers/Meet";
import moment from "moment";
import VueGlide from "vue-glide-js";
import VCalendar from "v-calendar";
import CircularCountDownTimer from "vue-circular-count-down-timer";

// Directives
import openLogDirective from "./directives/openLog";

// css
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-glide-js/dist/vue-glide.css";

window.lastUpdate = moment().format();

if (!LS.getItem("person")) {
  window.person = {
    id: kuid()
  };
  LS.setItem("person", window.person);
} else {
  window.person = LS.getItem("person");
}

// Service worker disabler
if ("serviceWorker" in navigator)
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister();
    }
  });

window.setPerson = function({ username }) {
  window.person = { ...window.person, username };
  LS.setItem("person", window.person);
};

import "./assets/style.scss";

// default base URL from Axios

Vue.config.productionTip = false;
Vue.use(CircularCountDownTimer);
Vue.use(BootstrapVue);
Vue.use(ToggleButton);
Vue.use(VueProgress);
Vue.use(VueGlide);
Vue.use(VCalendar);

getEnvironments().then(env => {
  axios.defaults.baseURL = env.VUE_APP_DEBMEDIA_SERVER;
  axios.defaults.timeout = 60000;
  window.RB = new Rollbar({
    accessToken: "71e1dcf747d3476c99f70a63d0bb106d",
    autoInstrument: true,
    maxTelemetryEvents: 50,
    captureIp: true,
    retryInterval: 5000,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment: env.VUE_APP_ENV,
      client: {
        javascript: {
          code_version: env.PACKAGE_VERSION
        }
      },
      server: {
        host: location.host
      },
      fingerprint: env.PACKAGE_VERSION,
      bsix_version: env.PACKAGE_VERSION,
      bsix_server: env.VUE_APP_AWS_API_URL,
      person: window.person
    }
  });

  axios.interceptors.request.use(
    function(config) {
      config.metadata = { startTime: new Date() };
      return config;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    x => {
      console.log(
        `Execution time for: ${x.config.url} - ${new Date().getTime() -
          x.config.metadata.startTime} ms. Code: ${x.status}`
      );
      return x;
    },
    // Handle 4xx & 5xx responses
    x => {
      console.error(
        `Execution time for: ${x.config.url} - ${new Date().getTime() -
          x.config.metadata.startTime} ms. Code: ${x.status}`
      );
      throw x;
    }
  );

  Vue.prototype.$rollbar = window.RB;

  Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };

  Vue.directive("openlog", openLogDirective);

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
