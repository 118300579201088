import { getToken, requester } from "./Meet";

function sendMessage(info){
    return getToken().then(key => {
        return requester({
          url: "/saveMessage",
          body: info,
          method: "POST",
          headers: {
            Authorization: key
          }
        });
    }).then( data => {
        info.promise(data)
    }).catch(err=>{
        console.error(err)
        sendMessage(info);
    });;
}

function checkMessage(info){
    return getToken().then(key => {
        return requester({
          url: "/getMessages?id_cita_pago="+info,
          method: "GET",
          headers: {
            Authorization: key
          }
        });
    });
}

export {
    sendMessage,
    checkMessage
};