var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Normativity Container",
      style: _vm.isAtril
        ? "margin: auto; width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 70px);"
        : "width: 100%; font-size: 1.3rem;",
      on: { click: _vm.actionActive }
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c(
          "div",
          {
            style: _vm.isAtril
              ? "position: relative; margin: auto; margin-top: 72px; margin-bottom: 33px; height: 37px; max-width: 701px;"
              : "position: relative; height: 48px; margin-top: 20px;"
          },
          [
            _c(
              "span",
              {
                staticClass: "text-center mt-2 h dk-pos-dk",
                class: _vm.isAtril ? "h1 isAtril" : "",
                style:
                  _vm.isAtril &&
                  "font-weight: bold !important; font-size: 2.8rem;"
              },
              [
                _vm._v(
                  _vm._s(_vm.siteIsOffice ? "Oficina" : "Centro Médico") +
                    " " +
                    _vm._s(_vm.siteName)
                )
              ]
            )
          ]
        ),
        _c(
          "article",
          {
            staticClass: "Article",
            style: !_vm.isAtril
              ? "color: #545454; margin-top: 40px;"
              : "color: #545454; margin-top: 40px; max-width: 460px;"
          },
          [
            _c("p", { staticClass: "text-center mb-2 mt-2 mr-2 ml-2" }, [
              _vm._v("Si eres una persona:")
            ]),
            _vm._m(0),
            _c("p", { staticClass: "mb-2 mt-2 text-left mr-2 ml-2" }, [
              _vm._v("tienes derecho a:")
            ]),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-bottom": "20px",
                  "margin-top": "1.3rem"
                }
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "text-center",
                    style: _vm.colorComputed,
                    attrs: {
                      variant: "bluecolmedica",
                      size: "sm",
                      type: "button",
                      block: ""
                    },
                    on: { click: _vm.confirmedAction }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-sizing-12",
                        style: _vm.isAtril
                          ? "font-size: 1.5rem !important;"
                          : ""
                      },
                      [_vm._v("Tomar turno preferencial")]
                    )
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "text-center",
                    style: "margin-top: 20px; " + _vm.colorComputed,
                    attrs: {
                      variant: "bluecolmedica",
                      size: "sm",
                      type: "button",
                      block: ""
                    },
                    on: { click: _vm.goBack }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "text-sizing-12",
                        style: _vm.isAtril
                          ? "font-size: 1.5rem !important;"
                          : ""
                      },
                      [_vm._v("Volver al menú principal")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "p",
              {
                staticClass: "mr-2 ml-2",
                staticStyle: {
                  "line-height": "1",
                  "font-style": "italic",
                  "margin-top": "0.1rem",
                  "text-align": "center"
                }
              },
              [
                _c(
                  "small",
                  {
                    style: _vm.isAtril
                      ? "font-size: 73%; max-width: 345px; text-align: center; display: block; margin: 0.5rem auto; margin-bottom: 2rem;"
                      : ""
                  },
                  [
                    _vm._v(
                      "Entre todos debemos proteger, promover, respetar y defender los derechos de esta población (Circular única 049 de la supersalud)"
                    )
                  ]
                )
              ]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  style: _vm.isAtril
                    ? "font-size: 1.5rem !important; " + _vm.colorComputed
                    : _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.goBack }
                },
                [
                  _c("Back", { attrs: { size: "24px" } }),
                  _c(
                    "span",
                    {
                      staticClass: "text-sizing-12",
                      style: _vm.isAtril ? "font-size: 1.5rem !important;" : ""
                    },
                    [_vm._v("Atrás")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "mr-2 ml-2",
        staticStyle: {
          "list-style": "none",
          "text-align": "start",
          margin: "0"
        }
      },
      [
        _c("li", [_vm._v("- Mayor de 62 años")]),
        _c("li", [_vm._v("- En estado de embarazo")]),
        _c("li", [_vm._v("- En condición de discapacidad")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }