// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/older_adults/oldPeople2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Atention[data-v-db5d68ac] {\n  display: block;\n  height: calc(100vh - 70.98px);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n}\n.ImageContainerTap[data-v-db5d68ac] {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  background-color: rgba(255, 255, 255, 0.8);\n}\n.Atention__Container[data-v-db5d68ac] {\n  max-width: 1080px;\n  width: 100%;\n  margin: auto;\n}\n.Atention__MainText[data-v-db5d68ac] {\n  font-weight: 400;\n  font-size: 30px;\n  line-height: 32px;\n  text-align: center;\n  padding-bottom: 25px;\n  padding-top: 25px;\n}\n.TextAtention[data-v-db5d68ac] {\n  color: var(--color-primary);\n}\n.ContainerCards[data-v-db5d68ac] {\n  height: 264px;\n  z-index: 0;\n}\n.Card[data-v-db5d68ac] {\n  max-width: 252px;\n  width: 100%;\n  height: 264px;\n  z-index: 2;\n  position: relative;\n  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);\n  border-radius: 24px;\n  background-color: #00aeee;\n  color: white;\n}\n.max-button[data-v-db5d68ac] {\n  max-width: 252px;\n}\n.Card__Text[data-v-db5d68ac] {\n  font-weight: 700;\n  font-size: 25px;\n  color: white;\n}\n.active-link[data-v-db5d68ac] {\n  font-size: 18px;\n  line-height: 17px;\n  cursor: pointer;\n  text-decoration: none;\n  color: var(--color-dark);\n}\n.tap[data-v-db5d68ac] {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  z-index: 1;\n}\n@media screen and (max-width: 780px) {\n.Atention[data-v-db5d68ac] {\n    background-position: center;\n}\n}\n.blue[data-v-db5d68ac] {\n  color: #05a9dd;\n}\n.red[data-v-db5d68ac] {\n  color: #ff0304;\n}", ""]);
// Exports
module.exports = exports;
