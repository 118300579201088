// Libraries
import _get from "lodash/get";
import { getEnvironments } from "../helpers/Meet";
import { store } from "../store";

// Routes
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    redirect: "/"
  },
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home"),
    beforeEnter(to, from, next) {
      if (_get(to, "query.pr")) next({ name: "Decoder", query: to.query });
      else next({ name: "SelfService" });
    }
  },
  {
    path: "/simulator",
    name: "Simulator",
    component: () => import("../views/simulator.vue")
  },
  {
    path: "/recorder",
    name: "Recorder",
    component: () => import("../views/Recorder.vue")
  },
  {
    path: "/turn_assigner/:turnId?",
    name: "TurnAssigner",
    component: () => import("../views/TurnAssigner.vue")
  },
  {
    path: "/meeting/:meetId?",
    name: "Meeting",
    component: () => import("../views/MeetingWrapper.vue")
  },
  {
    path: "/meeting_direct/:meetId?",
    name: "MeetingDirect",
    component: () => import("../views/MeetingDirect.vue")
  },
  {
    path: "/agent",
    component: () => import("../views/Agent.vue"),
    children: [
      {
        path: "",
        name: "Agent",
        component: () => import("../views/AgentBase.vue")
      },
      {
        path: ":meetId",
        name: "MeetingAgent",
        component: () => import("../views/MeetingWrapper.vue")
      }
    ]
  },
  {
    path: "/silver",
    component: () => import("../views/Agent.vue"),
    children: [
      {
        path: "",
        name: "AgentSilver",
        component: () => import("../views/silver/AgentSilver")
      }
    ]
  },
  {
    path: "/out_of_service",
    name: "OutService",
    component: () => import("../views/OutOperations")
  },
  {
    path: "/before-turn",
    name: "BeforeTurn",
    component: () => import("../views/BeforeTurn.vue"),
    props: true
  },
  {
    path: "/turno",
    name: "Turno",
    component: () => import("../views/Turno.vue"),
    props: true
  },
  {
    path: "/canceled",
    name: "Canceled",
    component: () => import("../views/Canceled.vue")
  },
  {
    path: "/finished",
    name: "Finished",
    component: () => import("../views/Finished.vue")
  },
  {
    path: "/finished_fv_atril",
    name: "Finished46",
    component: () => import("../views/fv_atril_video/Finished.vue")
  },
  {
    path: "/canceled_agent",
    name: "CanceledAgent",
    component: () => import("../views/Canceled_agent.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/ProfilePM.vue")
  },
  {
    path: "/profile_dev",
    name: "Profile_dev",
    component: () => import("../views/Profile.vue")
  },
  {
    path: "/dc",
    name: "Decoder",
    component: () => import("../views/Decoder.vue")
  },
  {
    path: "/dcoa",
    name: "DecoderOlderAdults",
    component: () => import("../views/older_adult/Decoder.vue")
  },
  {
    path: "/profile_oa",
    name: "ProfileOlderAdults",
    component: () => import("../views/older_adult/Profile.vue")
  },
  {
    path: "/before-turn_oa",
    name: "BeforeTurnOlderAdults",
    component: () => import("../views/older_adult/BeforeTurn.vue"),
    props: true
  },
  {
    path: "/turno_oa",
    name: "TurnoOlderAdults",
    component: () => import("../views/older_adult/Turno.vue"),
    props: true
  },
  {
    path: "/dcfv_atril_video",
    name: "DecoderKiosco46",
    component: () => import("../views/fv_atril_video/Decoder.vue")
  },
  {
    path: "/profile_fv_atril_video",
    name: "ProfileKiosco46",
    component: () => import("../views/fv_atril_video/Profile.vue")
  },
  {
    path: "/before-turn_fv_atril_video",
    name: "BeforeTurnKiosco46",
    component: () => import("../views/fv_atril_video/BeforeTurn.vue"),
    props: true
  },
  {
    path: "/turno_fv_atril_video",
    name: "TurnoKiosco46",
    component: () => import("../views/fv_atril_video/Turno.vue"),
    props: true
  },
  {
    path: "/ad",
    component: () => import("../views/components/TypicalRoot"),
    children: [
      {
        path: "",
        name: "SelfService",
        component: () => import("../views/autoservice/root")
      }
    ]
  },
  {
    path: "/oa",
    component: () => import("../views/older_adult/root")
  },
  {
    path: "/fv_atril_video",
    component: () => import("../views/fv_atril_video/root")
  },
  {
    path: "/fv/:code?",
    name: "Virtualrow",
    component: () => import("../views/virtualrow/root")
  },
  {
    path: "/mobile_fsfb/:code?",
    name: "MobileFSFB",
    component: () => import("../views/mobileFSFB/root")
  },
  {
    path: "/fv_atril/:code?",
    name: "VirtualrowAtril",
    component: () => import("../views/virtualrow/root")
  },
  {
    path: "/fv_atril_fsfb/:code?",
    name: "VirtualrowAtrilFSFB",
    component: () => import("../views/virtualrowFSFB/root")
  },
  {
    path: "/fv_atril_fsfb_au/",
    name: "VirtualrowAtrilFSFBAU",
    component: () => import("../views/virtualrowFSFBau/root")
  },
  {
    path: "/fv_print",
    name: "TicketPrint",
    component: () => import("../views/virtualrow/TicketPrint")
  },
  {
    path: "/fv_print_pay",
    name: "InfoPrint",
    component: () => import("../views/virtualrow/InfoPrint")
  },
  {
    path: "/print_invoice",
    name: "PrintInvoice",
    component: () => import("../views/virtualrowFSFB/PrintInvoice")
  },
  {
    path: "/environment_error",
    name: "EnvironmentError",
    component: () => import("../views/EnvironmentError")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async (to, from, next) => {
  try {
    await getEnvironments();
    store.dispatch("log/put", {
      name: "Router. Variables de entorno obtenidas"
    });
    next();
  } catch (error) {
    store.dispatch("log/put", {
      name: "Router. Error bloqueante. No se obtienen las variables de entorno",
      checked: false,
      message: error
    });
    console.error("Error with environment variables", error);
    next("/environment_error");
  }
});

export default router;
