var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "position-relative m-0 container-client-data" },
    [
      _c("div", { staticClass: "col-12 m-0 p-1" }, [
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Conexión a la videoconferencia:"),
          _vm.clientConnected
            ? _c("span", { staticClass: "ml-2 isCircle led-green" })
            : _c("span", { staticClass: "ml-2 isCircle led-red" })
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Agente: "),
          _vm.personalVideo
            ? _c("span", { staticClass: "ml-2 isCircle led-green" })
            : _c("span", { staticClass: "ml-2 isCircle led-red" })
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Cliente conectado:"),
          _vm.isMeetingWithTwo
            ? _c("span", { staticClass: "ml-2 isCircle led-green" })
            : _c("span", { staticClass: "ml-2 isCircle led-red" })
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Cliente con video:"),
          _vm.externalVideos
            ? _c("span", { staticClass: "ml-2 isCircle led-green" })
            : _c("span", { staticClass: "ml-2 isCircle led-red" })
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Compartir pantalla:"),
          _vm.haveSharedVideo
            ? _c("span", { staticClass: "ml-2 isCircle led-green" })
            : _c("span", { staticClass: "ml-2 isCircle led-red" })
        ])
      ]),
      _c("div", { staticClass: "col-12 m-0 p-1" }, [
        _c("b", { staticClass: "my-1 d-block text-center" }, [
          _vm._v("Datos de cliente")
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Sistema: " + _vm._s(_vm.system))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Navegador: " + _vm._s(_vm.navegador))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Dispositivo: " + _vm._s(_vm.platform))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Entorno BSIX: " + _vm._s(_vm.entorno))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Nombres: " + _vm._s(_vm.name))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Apellidos: " + _vm._s(_vm.last_name))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Tipide: " + _vm._s(_vm.tipide))
        ]),
        _c(
          "p",
          { staticClass: "containerInfo" },
          [
            _vm._v("Numide:"),
            _c(
              "span",
              {
                staticClass: "ml-1",
                staticStyle: { display: "inline-block" },
                attrs: { id: "numide" }
              },
              [_vm._v(_vm._s(_vm.numide))]
            ),
            _c(
              "b-button",
              {
                staticClass: "btn-sm p-0 ml-1",
                staticStyle: { display: "inline-block" },
                attrs: { variant: "outline" },
                on: {
                  click: function() {
                    return _vm.copyElement("numide")
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { width: "20px", "object-fit": "cover" },
                  attrs: {
                    src: require("../../../assets/unnamed.png"),
                    alt: "Copiar"
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm.showMessage
          ? _c(
              "p",
              {
                staticClass: "text-success m-0 p-0",
                staticStyle: { "font-style": "italic" }
              },
              [_vm._v(_vm._s(_vm.showMessage))]
            )
          : _vm._e(),
        _c(
          "p",
          { staticClass: "containerInfo" },
          [
            _vm._v("Numero de solicitud:"),
            _c(
              "span",
              {
                staticClass: "ml-1",
                staticStyle: { display: "inline-block" },
                attrs: { id: "SOM" }
              },
              [_vm._v(_vm._s(_vm.SOM || _vm.default_response))]
            ),
            _c(
              "b-button",
              {
                staticClass: "btn-sm p-0 ml-1",
                staticStyle: { display: "inline-block" },
                attrs: { variant: "outline" },
                on: {
                  click: function() {
                    return _vm.copyElement("SOM")
                  }
                }
              },
              [
                _c("img", {
                  staticStyle: { width: "20px", "object-fit": "cover" },
                  attrs: {
                    src: require("../../../assets/unnamed.png"),
                    alt: "Copiar"
                  }
                })
              ]
            )
          ],
          1
        ),
        _vm.showMessageSOM
          ? _c(
              "p",
              {
                staticClass: "text-success m-0 p-0",
                staticStyle: { "font-style": "italic" }
              },
              [_vm._v(_vm._s(_vm.showMessageSOM))]
            )
          : _vm._e(),
        _vm.phoneNumber
          ? _c(
              "p",
              { staticClass: "containerInfo" },
              [
                _vm._v("Número de celular:"),
                _c(
                  "span",
                  {
                    staticClass: "ml-1",
                    staticStyle: { display: "inline-block" },
                    attrs: { id: "phone" }
                  },
                  [_vm._v(_vm._s(_vm.phoneNumber))]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "btn-sm p-0 ml-1",
                    staticStyle: { display: "inline-block" },
                    attrs: { variant: "outline" },
                    on: {
                      click: function() {
                        return _vm.copyElement("phone")
                      }
                    }
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "20px", "object-fit": "cover" },
                      attrs: {
                        src: require("../../../assets/unnamed.png"),
                        alt: "Copiar"
                      }
                    })
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.showMessageOther
          ? _c(
              "p",
              {
                staticClass: "text-success m-0 p-0",
                staticStyle: { "font-style": "italic" }
              },
              [_vm._v(_vm._s(_vm.showMessageOther))]
            )
          : _vm._e(),
        _vm.turnCreation
          ? _c("p", { staticClass: "containerInfo" }, [
              _vm._v("Hora turno emitido: " + _vm._s(_vm.turnCreation))
            ])
          : _vm._e(),
        _vm.turnTimeElapsed
          ? _c("p", { staticClass: "containerInfo" }, [
              _vm._v(
                "Tiempo de espera cliente: " + _vm._s(_vm.turnTimeElapsedText)
              )
            ])
          : _vm._e(),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Fuente: " + _vm._s(_vm.font))
        ]),
        _c("p", { staticClass: "containerInfo" }, [
          _vm._v("Servicio: " + _vm._s(_vm.service))
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }