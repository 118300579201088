var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "CurrentUser" }, [
    _vm._m(0),
    _c("div", { staticClass: "ContainerData pt-3 px-2" }, [
      _c("h6", { staticClass: "subTitle" }, [_vm._v("Usuarios registrados")]),
      _c(
        "ul",
        { staticClass: "containerCards" },
        _vm._l(_vm.users, function(user) {
          return _c(
            "li",
            { key: user.id, staticClass: "liCard mx-auto px-3 pt-3 pb-4 my-3" },
            [
              _c("p", { staticClass: "mb-0 fontLiCard" }, [
                _c("strong", [_vm._v("Nombre:")]),
                _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(user.name))])
              ]),
              _c("p", { staticClass: "mb-0 fontLiCard" }, [
                _c("strong", [_vm._v("Número de documento:")]),
                _c("span", { staticClass: "ml-1" }, [
                  _vm._v(_vm._s(user.docNumber))
                ])
              ]),
              _c(
                "div",
                { staticClass: "w-100 d-flex justify-content-end mt-3" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "buttonConfirm buttonSelect px-4 py-2",
                      attrs: { disabled: _vm.charging },
                      on: { click: _vm.onContinue }
                    },
                    [
                      !_vm.charging
                        ? _c("span", [_vm._v("Seleccionar")])
                        : _c("span", [_c("MiniSpinner")], 1)
                    ]
                  )
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ContainerHeader pb-5" }, [
      _c(
        "div",
        { staticClass: "d-flex align-items-center justify-content-between" },
        [
          _c("img", {
            staticClass: "icn_backicn_back ml-3 backButton",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          }),
          _c("h3", { staticClass: "fontTitleHeader m-0" }, [
            _vm._v("Elegir usuario")
          ]),
          _c("img", {
            staticClass: "icn_backicn_back opacity-0 mr-3 backButton",
            attrs: {
              src: require("@/assets/mobileFSFB/icn_backicn_back.png"),
              alt: "icn_backicn_back"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }