var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Calendar" },
    [
      _c("Header"),
      _vm.isntIntersection
        ? _c("header", { staticClass: "headerCalendar" }, [
            _c(
              "h2",
              {
                staticClass: "TitleSection text-title pl-2 ml-4 pt-3 pb-3",
                staticStyle: {
                  "text-align": "center",
                  "padding-right": "12px",
                  margin: "0"
                },
                attrs: { id: "headerCalendarTitle" }
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
            _c("div", { staticClass: "botton-actions ml-5" }, [
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles",
                    attrs: { type: "button" },
                    on: { click: _vm.onGoBack }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20",
                          height: "19",
                          viewbox: "0 0 20 19",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                            stroke: "#0058A2",
                            "stroke-width": "3.12307",
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ])
          ])
        : _vm._e(),
      !_vm.isntIntersection
        ? _c("div", { staticStyle: { "margin-top": "50px" } })
        : _vm._e(),
      _c(
        "h2",
        {
          staticClass: "TitleSection pb-3",
          attrs: { id: "headerCalendarTitle" }
        },
        [_vm._v(_vm._s(_vm.title))]
      ),
      _c("div", { staticClass: "botton-actions ml-5" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c(
            "button",
            {
              staticClass: "button-without-styles",
              attrs: { type: "button" },
              on: { click: _vm.onGoBack }
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "19",
                    viewbox: "0 0 20 19",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      d:
                        "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                      stroke: "#0058A2",
                      "stroke-width": "3.12307",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round"
                    }
                  })
                ]
              )
            ]
          )
        ])
      ]),
      _vm._m(0),
      _vm.servicioSelected
        ? _c("span", { staticClass: "sizeBanner" }, [
            _vm._v("Trámite:"),
            _c("strong", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.servicioSelected.label))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "max-width-100" },
        [
          _c("v-calendar", {
            ref: "calendar",
            staticClass: "CalendarComponent px-4 pt-2 mt-3",
            attrs: {
              "is-expanded": "",
              "available-dates": _vm.availablesDays,
              "disabled-dates": _vm.disableDays,
              locale: "es"
            },
            on: {
              dayclick: _vm.handleClickCalendar,
              "update:from-page": _vm.sendRange
            }
          })
        ],
        1
      ),
      _vm.transformToMoment(_vm.date) !== "Invalid date"
        ? _c(
            "p",
            {
              staticClass: "TextSection subTitle text-start mb-2 mt-3",
              staticStyle: { "text-transform": "lowercase" }
            },
            [
              _vm._v(
                "Hora disponible para: " +
                  _vm._s(_vm.transformToMoment(_vm.date))
              )
            ]
          )
        : _c(
            "p",
            { staticClass: "TextSection subTitle text-start mb-2 mt-3" },
            [_vm._v("Hora disponible")]
          ),
      _c(
        "ul",
        { staticClass: "BannerContainer" },
        _vm._l(_vm.datesByDay, function(dateByDay, dateByDayIndex) {
          return _c(
            "button",
            {
              key: dateByDayIndex,
              staticClass: "button-without-styles mt-3 mb-1 mr-2",
              on: {
                click: function(event) {
                  return _vm.handleGoToDate(event, dateByDay)
                }
              }
            },
            [
              _c("li", { staticClass: "Banner position-relative" }, [
                _c("div", { staticClass: "buttonSelectHour" }),
                _c("span", { staticClass: "p-1 px-2" }, [
                  _vm._v(_vm._s(dateByDay.hora))
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "botton-actions-footer" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center" },
          [
            _c(
              "b-button",
              {
                staticClass: "w-100 py-2 mx-1 text-center",
                attrs: {
                  variant: "bluecolmedica",
                  size: "sm",
                  type: "button",
                  disabled: !_vm.date || !_vm.hour
                },
                on: { click: _vm.goToSchedule }
              },
              [_c("span", { staticClass: "font-md" }, [_vm._v("Continuar")])]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "FontDesc mb-2" }, [
      _vm._v("Selecciona el día y la hora que "),
      _c("span", [_vm._v("prefieras. Un agente te ayudará en la ")]),
      _c("span", [_vm._v("atención de tu solicitud.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }