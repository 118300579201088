var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "modal-content",
      attrs: {
        id: "modal-content",
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": ""
      },
      model: {
        value: _vm.showMe,
        callback: function($$v) {
          _vm.showMe = $$v
        },
        expression: "showMe"
      }
    },
    [
      _c(
        "div",
        { staticClass: "p-5", attrs: { id: "modal_inside_out" } },
        [
          _vm.showTimer
            ? _c(
                "div",
                { staticClass: "timer-container", on: { click: _vm.goToHome } },
                [
                  _c("TimerComponent", {
                    attrs: {
                      timer: _vm.timer,
                      showProgressBar: _vm.showProgressBarAun
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "h3",
            { staticClass: "Title text-center pt-5" },
            [
              _c("div", { staticClass: "text-center d-block pb-5" }, [
                _c("img", {
                  staticClass: "infoIcon",
                  attrs: {
                    src: require("@/assets/kiosco46/warnIcon.svg"),
                    alt: "warnIcon"
                  }
                })
              ]),
              _vm._l(_vm.blocks_2, function(block) {
                return _c(
                  "p",
                  {
                    key: block,
                    staticClass:
                      "buttonText text-start color-colmedica-gray mb-3 pb-1"
                  },
                  [_vm._v(_vm._s(block))]
                )
              }),
              _vm.branchInfo.activeBranchMessage
                ? _c(
                    "p",
                    {
                      staticClass:
                        "buttonText text-start color-colmedica-gray mb-3 pb-1"
                    },
                    [_vm._v(_vm._s(_vm.branchInfo.activeBranchMessage))]
                  )
                : _vm._e(),
              _c(
                "p",
                {
                  staticClass:
                    "buttonText text-start color-colmedica-gray mb-3 pb-1"
                },
                [
                  _vm._v(
                    "En este momento puedes radicar tu solicitud y enviaremos respuesta a tu correo electrónico."
                  )
                ]
              ),
              _c(
                "p",
                {
                  staticClass:
                    "buttonText text-start color-colmedica-gray mb-3 pb-1"
                },
                [
                  _vm._v(
                    "Te invitamos a realizar tu solicitud a través de los diferentes canales:"
                  )
                ]
              ),
              _c("p", { staticClass: "buttonText m-0 color-colmedica-gray" }, [
                _vm._v("App")
              ]),
              _c("p", { staticClass: "buttonText m-0 color-colmedica-gray" }, [
                _vm._v("Web")
              ]),
              _c("p", { staticClass: "buttonText m-0 color-colmedica-gray" }, [
                _vm._v("WhatsApp 3175031111")
              ]),
              _c("p", { staticClass: "buttonText m-0 color-colmedica-gray" }, [
                _vm._v("Línea de asistencia")
              ])
            ],
            2
          ),
          _c(
            "b-button",
            {
              staticClass: "text-center AuthsBtn mb-4 mt-5",
              staticStyle: { width: "fit-content" },
              attrs: {
                variant: "bluecolmedica",
                block: "",
                size: "lg",
                type: "button"
              },
              on: { click: _vm.goToHome }
            },
            [
              _c("p", { staticClass: "buttonText AuthMeds mb-0" }, [
                _vm._v("Radicar solicitud")
              ])
            ]
          ),
          _c(
            "div",
            { staticClass: "pt-3" },
            [_c("BottomTimer", { attrs: { doThis: _vm.goToHome } })],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }