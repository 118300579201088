/* eslint-disable no-debugger */
import {
  getBranch,
  getCompany,
  getEnqueueTurn,
  getTurnInfo,
  cancelTurn,
  getEnvironments,
  getEnvOnly,
  getCustomersWithTurns
} from "../helpers/Meet";
import axios from "axios";
import kuid from "kuid";
import _get from "lodash/get";
import LS from "../helpers/localStorage";
import { getCorrelation } from "./../helpers/Meet";
import { b64_to_utf8, try_json } from "@/utils/decoder";
import { GTMService as GM } from "../helpers/gtm.service";
import moment from "moment";
import router from '../router'

export default {
  setViewState({ commit }, value) {
    commit("setState", { key: "pageForeground", value });
    GM.event({
      name: value ? "GTMUserInPage" : "GTMUserOutPage",
      eventCategory: "Page_Vision",
      eventAction: value ? "Enter" : "Leave"
    });
  },
  async startup({ commit, state, dispatch }, pay = {}) {
    if (state.started) return;
    commit("setEnvironments", await getEnvironments());
    let { session, forced } = pay;
    let sessionCode = session || kuid();
    if (!GM.checkSession() || forced)
      GM.setFirstElementDatalayer({
        session_id: sessionCode,
        version: state.version,
        user_type: LS.getItem("user_type") || "client",
        user_uuid: window.person.id
      });
    else GM.replaceSession(sessionCode);
    commit("setState", { key: "sessionMaster", value: sessionCode });
    commit("setStarted", true);
    dispatch("setLocalization");
  },
  async setLocalization({ commit }) {
    if (getEnvOnly().VUE_APP_GET_LOCATION !== "true") return;
    let position;
    try {
      if ("geolocation" in navigator) {
        position = await new Promise(res =>
          navigator.geolocation.getCurrentPosition(res)
        );
        commit("setPosition", {
          lat: _get(position, "coords.latitude"),
          long: _get(position, "coords.longitude")
        });
        GM.setVariable({
          customLocationGPS: `${_get(position, "coords.latitude")},${_get(
            position,
            "coords.longitude"
          )}`
        });
      }
    } catch (error) {
      console.error("Position error", error);
    }
  },
  getCompany({ commit }, companyName) {
    return getCompany(companyName).then(response => {
      commit("setState", { key: "company", value: response });
      return response;
    });
  },
  setTramite({ commit }, value) {
    commit("setState", { key: "tramite_info", value });
  },
  setTurnUUID({ commit }, code = null) {
    commit("setState", {
      key: "turnUUID",
      value: Math.floor(Date.now() / 1000) + (code || kuid())
    });
  },
  setKiosco({ commit }, value) {
    commit("setState", { key: "fromKiosco46", value });
  },
  async getTurn({ commit, dispatch, state }) {
    if (!state.tramite_info) {
      return Promise.reject(new Error("Invalid Tramite"));
    }
    let { queueId, branchId, params } = state.tramite_info;
    if (state.turn) return dispatch("getTurnInfo", _get(state.turn, "code"));

    // check previous turns
    let turns = [];
    
    let customer = await getCustomersWithTurns(params.dni)
      .then(response => response)
      .catch(error => {
        console.log(error);
        return [];
      });
      
    // if user have before turns
    if (customer.length > 0) {
      if (customer[0].turns.length > 0) {
        if (
          customer[0].turns[0].queue.id ==
          state.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
        ) {
          // autorizaciones medicas
          if (queueId == state.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE) {
            // Fetch autorizaciones medicas?
            turns =
              customer.length > 0
                ? customer[0].turns.filter(t => t.queue.id == queueId)
                : [];
          } else {
            // cancel before turn
            axios
              .post(`/turn/${customer[0].turns[0].code}/cancel`)
              .then(response => response.data);
          }
        } else {

          try {
            // check if turns from oficina virtual
            let customerTurns = customer[0].turns;
            let customerTurnsF = customerTurns.filter(t => {
              t.queue?.branch?.label.toString().toLowerCase().includes("oficina virtual")
              return t
            });
            turns = customerTurnsF || [];
          } catch (error) {
            console.log("ERROR")
            console.log(error)
            turns = [];
          }
        }
      }
    }

    if (turns.length > 0) {
      console.log(
        "El usuario ya tiene un turno asignado y pendiente de ser atendido"
      );
      let response = {
        branch: {
          id: turns[0].branchId,
          label: turns[0].queue.branch
        },
        code: turns[0].code,
        jsonDetails: {
          turn: turns[0].letter + turns[0].number,
          queue: {
            id: turns[0].queue.id,
            name: turns[0].queue.name
          }
        },
        finishTime: ""
      };
      commit("setState", {
        key: "time_received_turn",
        value: moment.utc().unix()
      });
      response.status = "WAITING_TO_BE_CALLED";
      commit("setState", { key: "turn", value: response });
      dispatch("getTurnInfo", _get(response, "code"));
      if (turns[0].extraFields) {
        let extraFields = JSON.parse(turns[0].extraFields);
        response.extraFields = extraFields.length > 0 ? extraFields[0] : {};
      }
      // set uuid to coorelation
      let UUID = response.extraFields.Videollamada.match(
        /(turn_assigner[/A-Z])\w+/g,
        "\\$&"
      );
      UUID = UUID[0]?.split("/");
      dispatch("setTurnUUID", UUID[1]);
      
    } else {

      // Check schedule
      try {
        
        const branchInfo = await getBranch(state.env.VUE_APP_COMPANY, state.branchInfo.branch_label);
        
        if (!branchInfo.activeBranch) {
          console.error("Esta fuera del horario")
          // return this.goToView("OutOperations");
          return router.push('/out_of_service');
        }
      } catch (error) {
        console.log("ERROR: ");
        console.log(error)
      }

      // Feth new turn
      let promise =
        getEnvOnly()?.VUE_APP_DEBQ_DIRECT == "true"
          ? axios.post(`/queue/${queueId}/branch/${branchId}/enqueue`, params)
          : getEnqueueTurn(queueId, branchId, params);
      return promise
        .then(response =>
          getEnvOnly()?.VUE_APP_DEBQ_DIRECT == "true" ? response.data : response
        )
        .then(response => {
          commit("setState", {
            key: "time_received_turn",
            value: moment.utc().unix()
          });
          response.status = "WAITING_TO_BE_CALLED";
          commit("setState", { key: "turn", value: response });
          return response;
        })
        .then(turnInfo => {
          dispatch("getTurnInfo", _get(turnInfo, "code"));
          if (params.extraFields) {
            turnInfo.extraFields = params.extraFields[0].Videollamada || {};
          }
          return turnInfo;
        })
        .catch(err => {
          commit("setState", { key: "catch_all", value: err });
          throw new Error(err);
        });
    }
  },
  async getTurnRequest(__, code) {
    return (await axios.get(`/turn/code/${code}`)).data;
  },
  // eslint-disable-next-line no-unused-vars
  async getTurnInfo({ commit, state, dispatch }, code) {
    let codeToSend = code || _get(state.turn, "code");
    if (codeToSend) {
      let response = _get(state.turn, "code")
        ? JSON.parse(JSON.stringify(state.turn))
        : null;
      try {
        let prevResponse = response;
        response = await getTurnInfo(codeToSend);
        commit("setState", {
          key: "error_turn_request_counter",
          value: 0
        });
        if (_get(response, "code") && _get(response, "jsonDetails")) {
          commit("setState", { key: "turn", value: response });
        } else {
          let errorResp = JSON.stringify(JSON.parse(response));
          console.error("Corrupted response", errorResp);
          window.RB.error("Corrupted response in getTurnInfo", errorResp);
          response = prevResponse;
        }
      } catch (error) {
        console.error("Error in query from DebMedia", error);
        commit("setState", { key: "catch_all", value: error });
        commit("setState", {
          key: "error_turn_request_counter",
          value: state.error_turn_request_counter + 1
        });
        if (state.error_turn_request_counter % 5 === 0)
          window.RB.error("Many request has failed in getTurnInfo", error);
      }
      clearTimeout(state.turn_timer);
      commit("setState", { key: "turn_timer", value: null });
      let timer = setTimeout(() => dispatch("getTurnInfo", codeToSend), 4000);
      commit("setState", { key: "turn_timer", value: timer });
      return response;
    } else {
      console.error("Polling is forced to stop. Invalid Turn Code.");
      window.RB.error(
        "Polling is forced to stop. Invalid Turn Code.",
        JSON.stringify({ code, turn: _get(state.turn, "code") })
      );
      dispatch("cancelTurn");
      return;
    }
  },

  getTurnCoorelation({ commit }, code) {
    console.log("GET TURN COORELATION");
    if (!code) return Promise.reject(new Error("Invalid Coorelation Code"));
    return getCorrelation(code).then(coorData => {
      commit("setCoorelation", coorData);
      return coorData;
    });
  },

  cleanTurn({ commit, state }) {
    clearTimeout(state.turn_timer);
    commit("setState", { key: "error_turn_request_counter", value: 0 });
    commit("setState", { key: "time_received_turn", value: null });
    commit("setState", { key: "sessionMaster", value: null });
    commit("setState", { key: "turn_timer", value: null });
    commit("setState", { key: "tramite_info", value: null });
    commit("setState", { key: "client_data", value: {} });
    commit("setState", { key: "turn", value: null });
    commit("setState", { key: "turnUUID", value: null });
    commit("setState", { key: "eclient_data", value: null });
    commit("setState", { key: "dclient_data", value: null });
    LS.removeItem("sessionMaster");
    LS.removeItem("rawB64");
    LS.removeItem("marcacion");
    LS.removeItem("token_col");
    LS.removeItem("sucursal");
    LS.removeItem("tramite");
    LS.removeItem("fuente");
  },

  setSessionMaster({ commit }, value) {
    commit("setState", { key: "sessionMaster", value });
  },

  cancelTurn({ dispatch, state }, options) {
    let currentTurn, code;
    try {
      currentTurn = JSON.parse(JSON.stringify(state.turn));
      code = _get(currentTurn, "code");
    } catch (error) {
      console.error("Turn was canceled before: " + error);
    }
    if (!code) return Promise.resolve({ message: "Turn was canceled before" });
    if (
      (options && options.local) ||
      (currentTurn &&
        (currentTurn.status === "REVOKED" ||
          currentTurn.status === "FINALIZED" ||
          currentTurn.status === "CANCELED_BY_USER"))
    )
      return Promise.resolve({});
    let promise =
      getEnvOnly()?.VUE_APP_DEBQ_DIRECT == "true"
        ? axios.post(`/turn/${code}/cancel`).then(response => response.data)
        : cancelTurn(code);
    // clear all
    setTimeout(() => {
      dispatch("cleanTurn");
    }, 1000);
    return promise.catch(err => console.error("Error in cancel turn", err));
  },

  async verify_client_data({ dispatch }) {
    let data = LS.getItem("data_b64") || null;
    if (data) await dispatch("load_client_data", data);
  },
  async load_client_data({ dispatch }, enclient) {
    try {
      let json_value = try_json(b64_to_utf8(enclient));
      if (_get(json_value, "client.sm"))
        await dispatch("startup", {
          session: _get(json_value, "client.sm"),
          forced: true
        });
      dispatch("feclient_data", enclient);
      dispatch("fdclient_data", json_value);
    } catch (error) {
      console.error("Error in load_client_data", error);
    }
  },
  // function encrypted client data
  feclient_data({ commit }, dt) {
    commit("setState", { key: "eclient_data", value: dt });
  },
  // function decrypted client data
  fdclient_data({ commit }, dt) {
    commit("setState", { key: "dclient_data", value: dt });
  },
  // Set info of branch
  setBranchInfo({ commit }, value) {
    commit("setState", { key: "branchInfo", value });
  },
  setTimer({ commit, state }) {
    if (state.generalTimeout !== null) clearTimeout(state.generalTimeout);
    let timeout = setTimeout(
      () =>
        commit("setState", { key: "eventTime", value: state.eventTime + 1 }),
      1000 * state.timer
    );
    commit("setState", { key: "removeTimeout", value: false });
    commit("setState", { key: "generalTimeout", value: timeout });
  },
  setGoTime({ commit }, value) {
    commit("setState", { key: "goTime", value });
  },
  setTimerModal({ commit, state }, time) {
    if (state.generalTimeoutModal !== null)
      clearTimeout(state.generalTimeoutModal);
    let timeout = setTimeout(
      () =>
        commit("setState", { key: "eventTime", value: state.eventTime + 1 }),
      1000 * time
    );
    commit("setState", { key: "removeTimeout", value: false });
    commit("setState", { key: "generalTimeoutModal", value: timeout });
  },
  setTimerAreYouThere({ commit, state }, time = 15000) {
    if (state.generalTimeoutAreYouThere !== null)
      clearTimeout(state.generalTimeoutAreYouThere);
    let timeout = setTimeout(
      () =>
        commit("setState", { key: "eventTime", value: state.eventTime + 1 }),
      1000 * time
    );
    commit("setState", { key: "removeTimeout", value: false });
    commit("setState", { key: "generalTimeoutAreYouThere", value: timeout });
  },
  setTimerCounter({ commit, state }) {
    if (state.generalTimeoutCounter !== null)
      clearTimeout(state.generalTimeoutCounter);
    let timeout = setTimeout(
      () =>
        commit("setState", { key: "eventTime", value: state.eventTime + 1 }),
      1000 * state.timerCounter
    );
    commit("setState", { key: "removeTimeout", value: false });
    commit("setState", { key: "generalTimeout", value: timeout });
  },
  setTimer3({ commit, state }) {
    if (state.generalTimeout3 !== null) clearTimeout(state.generalTimeout3);
    let timeout = setTimeout(
      () =>
        commit("setState", { key: "eventTime", value: state.eventTime + 1 }),
      1000 * state.timer3
    );
    commit("setState", { key: "removeTimeout3", value: false });
    commit("setState", { key: "generalTimeout3", value: timeout });
  },
  removeTimer({ commit, state }) {
    clearTimeout(state.generalTimeout);
    commit("setState", { key: "removeTimeout", value: true });
  },
  restart({ commit }, value) {
    commit("setState", { key: "restart", value });
  }
};
