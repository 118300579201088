var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Normativity Container",
      style: _vm.isAtril
        ? "margin: auto; width: 100%; display: block; overflow: hidden; max-width: 1254px; height: calc(100vh - 96.38px);"
        : "width: 100%; font-size: 1.3rem;",
      on: { click: _vm.actionActive }
    },
    [
      _c("div", { staticClass: "Content mx-auto d-block" }, [
        _c(
          "article",
          {
            staticClass: "Article",
            style: !_vm.isAtril
              ? "color: #545454;"
              : "color: #545454; margin-top: 40px; max-width: 584px;"
          },
          [_vm._m(0)]
        ),
        _c("div", { staticClass: "d-flex justify-content-center" }, [
          _c("div", { staticClass: "card-box-white p-4" }, [
            _vm._m(1),
            _vm._m(2),
            _c(
              "div",
              {
                staticStyle: {
                  "padding-bottom": "20px",
                  "margin-top": "1.3rem"
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "buttonOutlined text-center",
                    staticStyle: { display: "block", margin: "auto" },
                    attrs: { size: "sm", type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.turnCall(true)
                      }
                    }
                  },
                  [_c("span", [_vm._v("Tomar turno preferencial")])]
                )
              ]
            )
          ]),
          _c("div", { staticClass: "card-box-white p-4 ml-4" }, [
            _vm._m(3),
            _vm._m(4),
            _c(
              "button",
              {
                staticClass: "text-center buttonGreen mt-4",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.turnCall(false)
                  }
                }
              },
              [_c("span", [_vm._v("Continuar sin turno preferencial")])]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  style: _vm.isAtril
                    ? "font-size: 1.5rem !important; " + _vm.colorComputed
                    : _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.goBack }
                },
                [
                  _c("Back", { attrs: { size: "24px" } }),
                  _c(
                    "span",
                    {
                      staticClass: "text-sizing-12",
                      style: _vm.isAtril ? "font-size: 1.5rem !important;" : ""
                    },
                    [_vm._v("Atrás")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-dk mt-4 mb-3 pt-3 text-center" }, [
      _c(
        "h3",
        {
          staticClass: "fonterH3 mx-auto d-block fonterAtt",
          staticStyle: { width: "545px" }
        },
        [_vm._v("Prioridad en la atención")]
      ),
      _c("p", { staticClass: "textHero m-0 mx-auto d-block pb-4" }, [
        _vm._v("¿Qué tipo de atención deseas?")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "text-center text-success-local titleCard mx-auto mt-4" },
      [
        _c("strong", { staticClass: "mx-1" }, [
          _vm._v("Con turno preferencial")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "d-flex align-items-center justify-content-center height-container mb-5"
      },
      [
        _c("img", {
          staticClass: "embarazo mr-3",
          staticStyle: { "margin-top": "-1px" },
          attrs: { src: require("@/assets/embarazo.svg"), alt: "embarazo" }
        }),
        _c("img", {
          staticClass: "silla mx-4",
          staticStyle: { "margin-top": "2px" },
          attrs: { src: require("@/assets/silla.svg"), alt: "silla" }
        }),
        _c("img", {
          staticClass: "ball2",
          staticStyle: { "margin-top": "33px" },
          attrs: { src: require("@/assets/ball2.svg"), alt: "ball2" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticClass: "text-center text-success-local titleCard mx-auto mt-4" },
      [
        _c("strong", { staticClass: "mx-1" }, [
          _vm._v("Sin turno preferencial")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "desc-blue mx-auto d-flex justify-content-center align-items-center mb-5"
      },
      [
        _c("div", [
          _c("p", { staticClass: "text-desc-blue" }, [
            _vm._v("Protejamos el bienestar de todos,")
          ]),
          _c("p", { staticClass: "text-desc-blue" }, [
            _vm._v("tus acciones valen más")
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }