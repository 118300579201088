var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Quote Container",
      staticStyle: {
        height: "calc(100vh - 96.38px)",
        "min-height": "600px",
        width: "100%",
        "align-items": "flex-start"
      },
      on: { click: _vm.validationAction }
    },
    [
      _c("div", { staticClass: "Content" }, [
        _c("h3", { staticClass: "textHero w-100 mt-5 pt-3" }, [
          _vm._v(
            "!Bienvenido" +
              _vm._s(
                _vm.fullname && _vm.fullname.trim() !== "undefined"
                  ? ", " + _vm.fullname
                  : ""
              ) +
              "!"
          )
        ]),
        _c(
          "p",
          {
            staticClass: "fonterH3 m-0 mx-auto d-block",
            staticStyle: { "font-size": "40px", padding: "24px" }
          },
          [_vm._v("Selecciona la cita a la que vienes")]
        ),
        _vm.citasEnCM && _vm.citasEnCM.length
          ? _c("div", { class: _vm.isAtril ? "cards-dk" : "cards" }, [
              _c(
                "div",
                {
                  staticClass: "big_box mt-4 d-flex ml-4",
                  style: _vm.isAtril
                    ? "position: relative; min-height: 160px; min-width: 600px;"
                    : ""
                },
                _vm._l(_vm.citasEnCM, function(cita) {
                  return _c(
                    "div",
                    {
                      staticClass: "dk-overlay pb-4 mr-4",
                      class: _vm.availableTime(cita)
                        ? _vm.selectedSchedule === cita && "selectedSchedule"
                        : "disableSchedule",
                      on: {
                        click: function($event) {
                          return _vm.selectAppointment(cita)
                        }
                      }
                    },
                    [
                      _c(
                        "h5",
                        { staticClass: "titleCard pl-4 ml-2 mb-2 pt-4" },
                        [_vm._v(_vm._s(cita.service.detail))]
                      ),
                      _c("h6", { staticClass: "tramiteCard pl-4 ml-2 mb-2" }, [
                        _c("strong", { staticClass: "fontWeightCard" }, [
                          _vm._v("Trámite:")
                        ]),
                        _c("span", { staticClass: "pl-3" }, [
                          _vm._v(_vm._s(cita.attention.detail))
                        ])
                      ]),
                      _c("p", { staticClass: "confirmCard pl-4 ml-2 mb-5" }, [
                        _vm._v("№ de confirmación: " + _vm._s(cita.number_form))
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "m-0 dateCard d-flex align-items-center justify-content-between mx-auto"
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center ml-4" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "svgIconDate",
                                  attrs: {
                                    width: "46",
                                    height: "46",
                                    viewbox: "0 0 46 46",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M18.062 10.4856V13.6596",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M28.6636 10.4856V13.6596",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15.0298 11.8235H31.6474C32.4386 11.8235 33.1973 12.1378 33.7567 12.6972C34.3161 13.2566 34.6303 14.0153 34.6303 14.8064V30.8092C34.6303 31.6025 34.3152 32.3633 33.7542 32.9243C33.1933 33.4852 32.4324 33.8004 31.6391 33.8004H15.0214C14.2303 33.8004 13.4716 33.4861 12.9122 32.9267C12.3528 32.3673 12.0386 31.6086 12.0386 30.8175V14.8064C12.0386 14.414 12.116 14.0254 12.2664 13.663C12.4169 13.3005 12.6373 12.9713 12.9152 12.6942C13.193 12.4171 13.5229 12.1976 13.8857 12.0482C14.2486 11.8987 14.6373 11.8224 15.0298 11.8235V11.8235Z",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M12.0483 17.4316H34.6816",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  })
                                ]
                              ),
                              _c("p", { staticClass: "dayCard" }, [
                                _vm._v(
                                  _vm._s(_vm.citaFecha(cita.date_appoinment))
                                )
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center mr-5" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "svgIconDate",
                                  attrs: {
                                    width: "46",
                                    height: "46",
                                    viewbox: "0 0 46 46",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M22.8294 33.8006C29.0519 33.8006 34.0963 28.7563 34.0963 22.5337C34.0963 16.3112 29.0519 11.2668 22.8294 11.2668C16.6069 11.2668 11.5625 16.3112 11.5625 22.5337C11.5625 28.7563 16.6069 33.8006 22.8294 33.8006Z",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M22.8291 22.5339V16.4307",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M22.8291 22.5337H26.6774",
                                      stroke: "#89D4F4",
                                      "stroke-width": "2.02572",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round"
                                    }
                                  })
                                ]
                              ),
                              _c("p", { staticClass: "dayCard" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.citaHora(
                                      cita.date_appoinment +
                                        " " +
                                        cita.hour_appoinment
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          : _vm._e(),
        _c(
          "svg",
          {
            staticClass: "svgIconHelpInfo",
            attrs: {
              width: "35",
              height: "36",
              viewbox: "0 0 35 36",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M17.5 2C8.66345 2 1.5 9.16344 1.5 18C1.5 26.8366 8.66344 34 17.5 34C26.3366 34 33.5 26.8366 33.5 18C33.5 9.16344 26.3366 2 17.5 2Z",
                stroke: "#4B5CB7",
                "stroke-width": "2.5"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M17.4995 26L17.4995 16.4L17.4995 26ZM17.4995 10.8L17.4995 10L17.4995 10.8Z",
                fill: "white"
              }
            }),
            _c("path", {
              attrs: {
                d:
                  "M17.4995 10.8L17.4995 10M17.4995 26L17.4995 16.4L17.4995 26Z",
                stroke: "#4B5CB7",
                "stroke-width": "2.5",
                "stroke-linecap": "round"
              }
            })
          ]
        ),
        _c("p", { staticClass: "fontBlue firstFont" }, [
          _vm._v("Recuerda tomar tu turno antes de")
        ]),
        _c("p", { staticClass: "fontBlue" }, [_vm._v("la hora de la cita")]),
        _c(
          "div",
          {
            staticClass:
              "d-block text-center d-flex mt-4 pt-3 align-items-center",
            staticStyle: {
              "flex-direction": "column",
              margin: "auto",
              "max-width": "370px"
            }
          },
          [
            !_vm.citasEnCM ||
            (_vm.citasEnCM && _vm.citasEnCM.length < 1) ||
            !_vm.citasFueraCM ||
              (_vm.citasFueraCM && _vm.citasFueraCM.length < 1)
              ? _c(
                  "button",
                  {
                    staticClass: "ml-2 mb-3 text-center buttonService",
                    attrs: {
                      type: "button",
                      disabled: !Object.keys(_vm.selectedSchedule).length
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectCita(_vm.selectedSchedule)
                      }
                    }
                  },
                  [_c("span", [_vm._v("Continuar con cita elegida")])]
                )
              : _c(
                  "button",
                  {
                    staticClass: "ml-2 mb-3 text-center buttonService",
                    attrs: {
                      type: "button",
                      disabled: !Object.keys(_vm.selectedSchedule).length
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectCita(_vm.selectedSchedule)
                      }
                    }
                  },
                  [_c("span", [_vm._v("Continuar con cita elegida")])]
                ),
            _c(
              "button",
              {
                staticClass:
                  "ml-2 text-center outlineService button-without-styles",
                attrs: { type: "button" },
                on: { click: _vm.noSelect }
              },
              [_c("span", [_vm._v("Ver otros servicios")])]
            )
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "buttons Footer", style: _vm.colorSecondaryComputed },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  style: _vm.colorComputed,
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: { click: _vm.goBack }
                },
                [
                  _c("Back", { attrs: { size: "24px" } }),
                  _c("span", { staticClass: "text-sizing-12" }, [
                    _vm._v("Atrás")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }