import { join, end, sendFile } from "../../helpers/Meet";
import kuid from "kuid";
import _get from "lodash/get";
import _omit from "lodash/omit";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
  DefaultModality,
  BackgroundBlurVideoFrameProcessor,
  DefaultVideoTransformDevice,
  BackgroundReplacementVideoFrameProcessor
} from "amazon-chime-sdk-js";
import { GTMService as GM } from "../../helpers/gtm.service";

let meetingSession;
let meetTiming = 0;

export default {
  setMeetType({ commit }, status) {
    commit("setState", { key: "isAgent", value: !!status });
  },
  async startMeet({ commit, dispatch, rootState }, pay) {
    commit("setState", { key: "connecting", value: true });
    let newerror;
    try {
      // const title = _get(pay, "meetId") || `meet_${kuid()}`;
      const title = _get(pay, "turnCode");
      const name = _get(pay, "username") || `user_${kuid()}`;
      const turn_code = _get(pay, "turnCode");
      const client_info = _omit(rootState.client_data, [
        "token",
        "extraFields"
      ]);
      const results = await join({
        title,
        name,
        turn_code,
        session: rootState.sessionMaster,
        assistant: {
          fullname: name,
          session: rootState.sessionMaster,
          ...client_info,
          version: rootState.version,
          turn_code: _get(rootState.turn, "code"),
          turn_id: _get(rootState.turn, "id"),
          turn_strings: _get(rootState.turn, "jsonDetails.turn"),
          branch_id: _get(rootState.turn, "branch.id"),
          branch_name: _get(rootState.turn, "branch.name"),
          queue_id: _get(rootState.turn, "jsonDetails.queue.id")
        },
        video_public_path: rootState.client_data.extraFields
          ? rootState.client_data.extraFields[0].Videollamada
          : null
      });
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Se realizó la solicitud de videollamada con el servidor."
        },
        { root: true }
      );
      commit("setMeeting", results.JoinInfo);
      if (results.JoinInfo) dispatch("startMeeting", pay);
      else throw new Error("Meeting was rejected");
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error restrictivo. No fue posible establecer la videollamada.",
          checked: false,
          message: error
        },
        { root: true }
      );
      newerror = error;
    }
    commit("setState", { key: "connecting", value: false });
    if (newerror) throw newerror;
  },

  async endMeet({ commit }) {
    if (!meetingSession) return;
    // console.log("ending meet with meeting:", meetingSession);
    commit("setState", { key: "connecting", value: true });
    try {
      meetingSession.audioVideo.realtimeUnsubscribeFromReceiveDataMessage(
        "chat"
      );
      meetingSession.audioVideo.stop();
      // meetingSession.deviceController.destroy();
      // FIXME: Require observer to this action https://github.com/aws/amazon-chime-sdk-js#stopping-a-session
    } catch (error) {
      console.error("Canceling meet", error);
    }
  },

  async ConfirmedendMeeting({ commit, state }) {
    meetingSession = null;
    try {
      await end({
        title: _get(state.meeting, "Meeting.Meeting.ExternalMeetingId"),
        meetingId: _get(state.meeting, "Meeting.Meeting.MeetingId")
      });
      commit("setMeeting", null);
    } catch (error) {
      if (window.RB) window.RB.warning("Meeting end", error);
    }
    commit("setState", { key: "attendees", value: [] });
    commit("setState", { key: "videosIds", value: [] });
    commit("setState", { key: "connecting", value: false });
    commit("setState", { key: "messages", value: [] });
    commit("setState", { key: "audioInputDevices", value: [] });
    commit("setState", { key: "audioOutputDevices", value: [] });
    commit("setState", { key: "videoInputDevices", value: [] });
    commit("setState", { key: "videoDevice", value: null });
    commit("setState", { key: "speakerDevice", value: null });
    commit("setState", { key: "audioDevice", value: null });
    commit("setState", { key: "audioEnabled", value: true });
    commit("setState", { key: "camEnabled", value: false });
    commit("setState", { key: "speakerEnabled", value: true });
  },

  setAtendeeName({ commit }, value) {
    if (typeof value === "string") commit("setState", { key: "myname", value });
  },

  /** chat methods */
  sendMessage({ dispatch, state }, { message, local }) {
    if (!message || !meetingSession) return;
    if (!local)
      meetingSession.audioVideo.realtimeSendDataMessage("chat", message);
    let dataMessage = {
      timestampMs: Date.now(),
      senderAttendeeId: meetingSession.configuration.credentials.attendeeId,
      senderExternalUserId: state.myname,
      text: () => message
    };
    dispatch("chatMessageHandler", dataMessage);
  },

  chatMessageHandler({ commit }, dataMessage) {
    if (!meetingSession) return;
    let info = {
      timestamp: Math.floor(dataMessage.timestampMs),
      attendeeName: dataMessage.senderExternalUserId,
      isSelf:
        dataMessage.senderAttendeeId ===
        meetingSession.configuration.credentials.attendeeId,
      text: dataMessage.text()
    };
    commit("addMessage", info);
  },

  async sendFile(ctx, payload) {
    const results = await sendFile(payload);
    return results;
  },

  /** Methods of control Meet */

  toogleAudioEnabled({ state, commit }) {
    if (
      !meetingSession ||
      !Array.isArray(state.audioInputDevices) ||
      !state.audioInputDevices.length
    )
      return;
    commit("setState", { key: "audioEnabled", value: !state.audioEnabled });
    if (state.audioEnabled)
      meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    else meetingSession.audioVideo.realtimeMuteLocalAudio();
  },

  toogleSpeakerEnabled({ state, commit, dispatch }) {
    if (
      !meetingSession ||
      !Array.isArray(state.audioOutputDevices) ||
      !state.audioOutputDevices.length
    )
      return;
    commit("setState", { key: "speakerEnabled", value: !state.speakerEnabled });
    if (state.speakerEnabled) dispatch("bindAudio");
    else meetingSession.audioVideo.unbindAudioElement();
  },

  async changeSpeakerDevice({ state, dispatch }, audio) {
    if (
      state.speakerDevice &&
      audio &&
      state.speakerDevice.deviceId &&
      audio.deviceId &&
      state.speakerDevice.deviceId !== audio.deviceId
    ) {
      await dispatch("selectSpeakerDevice", audio);
    }
  },

  async changeVideoDevice({ state, dispatch }, video) {
    if (!meetingSession) return;
    if (
      state.videoDevice &&
      video &&
      state.videoDevice.deviceId &&
      video.deviceId &&
      state.videoDevice.deviceId !== video.deviceId
    ) {
      if (state.camEnabled) meetingSession.audioVideo.stopLocalVideoTile();
      await dispatch("selectVideoDevice", video);
      if (state.camEnabled) meetingSession.audioVideo.startLocalVideoTile();
    }
  },

  async changeAudioDevice({ state, dispatch }, audio) {
    if (
      state.audioDevice &&
      audio &&
      state.audioDevice.deviceId &&
      audio.deviceId &&
      state.audioDevice.deviceId !== audio.deviceId
    ) {
      await dispatch("selectAudioDevice", audio);
    }
  },

  async startCamera({ dispatch, state }) {
    await dispatch("checkHabilitiesVideo");
    await dispatch("firstVideo");
    if (!state.camEnabled) await dispatch("toogleCamEnabled");
  },

  async toogleCamEnabled({ commit, state, dispatch }) {
    if (
      !meetingSession ||
      !Array.isArray(state.videoInputDevices) ||
      !state.videoInputDevices.length
    )
      return;
    commit("setState", { key: "camEnabled", value: !state.camEnabled });
    if (state.camEnabled) {
      await dispatch("selectVideoDevice", state.videoDevice);
      meetingSession.audioVideo.startLocalVideoTile(); // TODO: Es impartante, es la que arranca el video
    } else {
      meetingSession.audioVideo.stopLocalVideoTile();
    }
  },

  /**
   * Group of first start of meeting. First meet creation
   */
  async startMeeting({ commit, dispatch, state }, pay) {
    GM.event({
      name: "GTMVideoStartBefore",
      eventCategory: "Video_Connection",
      eventAction: "Before_Start"
    });
    meetTiming = Date.now();
    const logger = new ConsoleLogger("MyLogger", LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);

    // You need responses from server-side Chime API. See below for details.
    const meetingResponse = state.meeting.Meeting.Meeting;
    const attendeeResponse = state.meeting.Attendee.Attendee;
    const configuration = new MeetingSessionConfiguration(
      meetingResponse,
      attendeeResponse
    );

    // In the usage examples below, you will use this meetingSession object.
    meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    );

    // blur video
    if(BackgroundBlurVideoFrameProcessor && BackgroundReplacementVideoFrameProcessor){
      commit("setState", { key: "deviceController", value: deviceController });
      commit("setState", { key: "loggerMeeting", value: logger });
  
      const promises = Promise.all([
        BackgroundBlurVideoFrameProcessor.isSupported(),
        BackgroundReplacementVideoFrameProcessor.isSupported()
      ]);
  
      promises.catch(error => {
        console.error("BLUR or BG VIDEO FILTER IS NOT SUPPORTED", error);
      });
    }

    return (await dispatch("checkHabilities", pay)) && // TODO: Separate it for best performance?
      (await dispatch("selectFirstMedia")) &&
      (await dispatch("observersChangeOfMedia")) &&
      (await dispatch("observersGlobal")) &&
      (await dispatch("attendeesObservable"))
      ? meetingSession.audioVideo.start()
      : null;
  },

  async startBlurVideo({ dispatch, commit, state }) {
    if (state.isAgent) {
      const blurProcessor = await BackgroundBlurVideoFrameProcessor.create();
      commit("setState", {
        key: "processors",
        value: [...state.processors, blurProcessor]
      });

      dispatch("setterController", true, "blur");
    }
  },

  async startBgVideo({ dispatch, commit, state }, imageBlob) {
    if (state.isAgent) {
      await dispatch("removeVideoFilter", "background replacement");
      const bgProcessor = await BackgroundReplacementVideoFrameProcessor.create(
        null,
        { filterCPUUtilization: 100, imageBlob }
      );
      console.warn([...state.processors, bgProcessor]);
      commit("setState", {
        key: "processors",
        value: [...state.processors, bgProcessor]
      });

      dispatch("setterController", true, "bg");
    }
  },

  async removeVideoFilter(
    { dispatch, commit, state },
    type = "background blur"
  ) {
    if (state.isAgent) {
      const newProcessors = state.processors.filter(
        item => item.filterType !== type
      );
      commit("setState", {
        key: "processors",
        value: newProcessors
      });

      dispatch("setterController", false, "blur");
    }
  },

  async setterController({ dispatch, commit, state }, value = true, type = "blur") {
    const transformDevice = new DefaultVideoTransformDevice(
      state.loggerMeeting,
      state.videoDevice,
      state.processors
    );

    if (type === "blur") {
      commit("setState", { key: "isBlur", value });
    } else {
      commit("setState", { key: "isWithBg", value });
    }

    if(state.deviceController) await state.deviceController.chooseVideoInputDevice(transformDevice);
    else setTimeout(() => {
      dispatch("setterController", value, type);
    }, 2000);
  },

  attendeesObservable({ commit, state }) {
    if (!meetingSession) return;
    meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId, present) => {
        let index = state.attendees.findIndex(elm => elm === presentAttendeeId);
        if (present) {
          if (index === -1)
            commit("setState", {
              key: "attendees",
              value: [...state.attendees, presentAttendeeId]
            });
        } else {
          if (index > -1)
            commit("setState", {
              key: "attendees",
              value: state.attendees.filter(elm => elm !== presentAttendeeId)
            });
        }
        if (state.attendees.length > 1)
          GM.event({
            name: "GTMVideoMeetAssistants",
            eventCategory: "Meeting",
            eventAction: "Assistants_Arrived"
          });
      }
    );
    return true;
  },

  async checkHabilities({ commit, state, dispatch }) {
    if (!meetingSession) return;
    let audioInputDevices = [];
    try {
      audioInputDevices =
        (await meetingSession.audioVideo.listAudioInputDevices()) || [];
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error no restrictivo. No se puede conectar el micrófono.",
          checked: false,
          message: error
        },
        { root: true }
      );
      if (window.RB)
        window.RB.warning("Error when obtain Audio input devices", error);
    }
    commit("setState", { key: "audioInputDevices", value: audioInputDevices });
    let audioOutputDevices = [];
    try {
      audioOutputDevices =
        (await meetingSession.audioVideo.listAudioOutputDevices()) || [];
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error no restrictivo. No se puede conectar el parlante.",
          checked: false,
          message: error
        },
        { root: true }
      );
      if (window.RB)
        window.RB.warning("Error when obtain Audio output devices", error);
    }
    commit("setState", {
      key: "audioOutputDevices",
      value: audioOutputDevices
    });
    if (state.isAgent) await dispatch("checkHabilitiesVideo");
    return true;
  },

  async selectFirstMedia({ state, dispatch }) {
    if (!meetingSession) return;
    if (state.isAgent) {
      await dispatch("firstVideo");
      if (!state.camEnabled) await dispatch("toogleCamEnabled");
    }
    if (state.audioInputDevices && state.audioInputDevices.length)
      await dispatch("selectAudioDevice", state.audioInputDevices[0]);
    if (state.audioOutputDevices && state.audioOutputDevices.length)
      await dispatch("selectSpeakerDevice", state.audioOutputDevices[0]);
    return true;
  },

  async checkHabilitiesVideo({ commit, dispatch }) {
    meetingSession.audioVideo.chooseVideoInputQuality(320, 180, 15, 150); // Specific modification with Marcelo
    let videoInputDevices = [];
    try {
      videoInputDevices =
        (await meetingSession.audioVideo.listVideoInputDevices()) || [];
      console.log(" meetingSession.audioVideo.listVideoInputDevices");
      console.log(videoInputDevices);

      try {
        let devicesWin = [];
        navigator.mediaDevices.enumerateDevices().then(function(devices) {
          for (var i = 0; i < devices.length; i++) {
            var device = devices[i];
            if (device.kind === "videoinput") {
              devicesWin.push(device);
            }
          }
        });
        console.log("devicesWin");
        console.log(devicesWin);
      } catch (e) {
        console.log(e);
      }
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error no restrictivo. No se puede conectar la cámara.",
          checked: false,
          message: error
        },
        { root: true }
      );
      if (window.RB)
        window.RB.warning("Error when obtain Video devices", error);
    }
    commit("setState", { key: "videoInputDevices", value: videoInputDevices });
  },

  async firstVideo({ state, dispatch }) {
    console.log("firstVideo");
    console.log(state);

    if (state.videoInputDevices && state.videoInputDevices.length) {
      let videos = state.videoInputDevices;
      if (state.configCameras) {
        // check config cameras
        let kioscos = state.configCameras.kioscos;
        let params = state.configCameras.params;
        if (kioscos && kioscos[params.branchid]) {
          videos = videos.filter(
            v =>
              !v.label
                .toLowerCase()
                .includes(
                  kioscos[params.branchid].cameras?.documents?.toLowerCase()
                )
          ); //ignore camera scan
          videos = videos.filter(v =>
            v.label
              .toLowerCase()
              .includes(
                kioscos[params.branchid].cameras?.lateral?.toLowerCase()
              )
          ); // set default as main camera
        } else {
          videos = videos.filter(v => !v.label.includes("Q800L")); //ignore camera scan
          videos = videos.filter(v => v.label.includes("camera b")); // if exist name camera b is default
        }
      }
      console.log("videos:");
      console.log(videos);
      const result = await dispatch(
        "selectVideoDevice",
        videos.length > 0 ? videos[0] : state.videoInputDevices[0]
      );
      if (result) meetingSession.audioVideo.startLocalVideoTile();
    }
  },

  async selectVideoDevice({ commit, dispatch }, video) {
    if (!video || !video.deviceId || !meetingSession) return false;
    commit("setState", { key: "videoDevice", value: video });
    try {
      await meetingSession.audioVideo.chooseVideoInputDevice(video.deviceId);
      // await meetingSession.audioVideo.startVideoInput(video.deviceId);
      return true;
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error no restrictivo. No se puede seleccionar una cámara.",
          checked: false,
          message: error
        },
        { root: true }
      );
      if (window.RB)
        window.RB.warning("Error in selection of video source", error);
    }
    return false;
  },

  async selectSpeakerDevice({ commit, dispatch }, audio) {
    if (!audio || !audio.deviceId || !meetingSession) return false;
    commit("setState", { key: "speakerDevice", value: audio });
    try {
      await meetingSession.audioVideo.chooseAudioOutputDevice(audio.deviceId);
      return true;
    } catch (error) {
      dispatch(
        "log/put",
        {
          name:
            "Meeting.actions. Error no restrictivo. No se puede seleccionar un parlante.",
          checked: false,
          message: error
        },
        { root: true }
      );
      if (window.RB)
        window.RB.warning("Error in selection of audio output source", error);
    }
    return false;
  },

  async selectAudioDevice({ commit }, audio) {
    if (!audio || !audio.deviceId || !meetingSession) return false;
    commit("setState", { key: "audioDevice", value: audio });
    try {
      await meetingSession.audioVideo.chooseAudioInputDevice(audio.deviceId);
      // await meetingSession.audioVideo.startAudioInput(audio.deviceId);
      return true;
    } catch (error) {
      if (window.RB)
        window.RB.warning("Error in selection of audio input source", error);
    }
    return false;
  },

  healthChange({ commit, state }, { bitrateKbps, packetsPerSecond }) {
    if (bitrateKbps && bitrateKbps !== state.bitrateKbps)
      commit("setState", { key: "bitrateKbps", value: bitrateKbps });
    if (packetsPerSecond && packetsPerSecond !== state.packetsPerSecond)
      commit("setState", { key: "packetsPerSecond", value: packetsPerSecond });
  },

  bandwidthChange({ commit, state }, { nackCountPerSecond }) {
    if (nackCountPerSecond && nackCountPerSecond !== state.nackCountPerSecond)
      commit("setState", {
        key: "nackCountPerSecond",
        value: nackCountPerSecond
      });
  },

  videoStopSuggestion({ commit, state, dispatch }, status) {
    if (!state.videoStopSuggestion && status && state.camEnabled) {
      commit("setState", {
        key: "stopSuggestionFromActiveCamera",
        value: true
      });
      // dispatch("toogleCamEnabled");
    }
    if (
      state.videoStopSuggestion &&
      !status &&
      state.stopSuggestionFromActiveCamera
    ) {
      // dispatch("toogleCamEnabled");
    }
    commit("setState", { key: "videoStopSuggestion", value: status });
    dispatch("commonNotification", "VideostopSuggestion change detected");
  },

  commonNotification({ state }, message) {
    try {
      if (window.RB)
        window.RB.info(message, {
          bitrateKbps: state.bitrateKbps,
          connectionIsGood: state.connectionIsGood,
          estimatedBandwidth: state.estimatedBandwidth,
          requiredBandwidth: state.requiredBandwidth,
          videoStopSuggestion: state.videoStopSuggestion
        });
    } catch (error) {
      console.error("Rollbar not exist", error);
    }
  },

  connectionStatus({ commit, dispatch }, status) {
    commit("setState", { key: "connectionIsGood", value: status });
    dispatch("commonNotification", "Change health of connection");
  },

  bandwidthRequirements(
    { commit, state },
    { estimatedBandwidth, requiredBandwidth }
  ) {
    if (estimatedBandwidth && estimatedBandwidth !== state.estimatedBandwidth)
      commit("setState", {
        key: "estimatedBandwidth",
        value: estimatedBandwidth
      });
    if (requiredBandwidth && requiredBandwidth !== state.requiredBandwidth)
      commit("setState", {
        key: "requiredBandwidth",
        value: requiredBandwidth
      });
  },

  async observersChangeOfMedia() {
    if (!meetingSession) return;
    const observer = {
      audioInputsChanged: freshAudioInputDeviceList => {
        // An array of MediaDeviceInfo objects
        // TODO: What happend when change devices? It could not to be tested
        if (window.RB)
          window.RB.info("Audio inputs updated", {
            list: JSON.stringify(freshAudioInputDeviceList)
          });
        freshAudioInputDeviceList.forEach(mediaDeviceInfo => {
          console.log(
            `Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`
          );
        });
      },
      audioOutputsChanged: freshAudioOutputDeviceList => {
        if (window.RB)
          window.RB.info("Audio outputs updated", {
            list: JSON.stringify(freshAudioOutputDeviceList)
          });
      },
      videoInputsChanged: freshVideoInputDeviceList => {
        if (window.RB)
          window.RB.info("Video inputs updated", {
            list: JSON.stringify(freshVideoInputDeviceList)
          });
      }
    };

    meetingSession.audioVideo.addDeviceChangeObserver(observer);
    return true;
  },

  async observersGlobal({ dispatch, state, commit }) {
    if (!meetingSession) return;
    meetingSession.audioVideo.addObserver({
      connectionDidSuggestStopVideo: () =>
        dispatch("videoStopSuggestion", true),
      connectionDidBecomeGood: () => {
        dispatch("connectionStatus", true);
        dispatch("videoStopSuggestion", false);
      },
      estimatedDownlinkBandwidthLessThanRequired: (
        estimatedBandwidth,
        requiredBandwidth
      ) =>
        dispatch("bandwidthRequirements", {
          estimatedBandwidth,
          requiredBandwidth
        }),
      connectionDidBecomePoor: () => dispatch("connectionStatus", false),
      videoSendHealthDidChange: (bitrateKbps, packetsPerSecond) =>
        dispatch("healthChange", { bitrateKbps, packetsPerSecond }),
      videoSendBandwidthDidChange: (
        newBandwidthKbps,
        oldBandwidthKbps,
        nackCountPerSecond
      ) =>
        dispatch("bandwidthChange", {
          newBandwidthKbps,
          oldBandwidthKbps,
          nackCountPerSecond
        }),
      audioVideoDidStart: () => {
        GM.event({
          name: "GTMVideoStartOk",
          eventCategory: "Video_Connection",
          eventAction: "Started"
        });
        GM.event({
          name: "GTMVideoStartTimeOk",
          eventCategory: "Video_Connection",
          eventAction: "Started",
          eventValue: Date.now() - meetTiming
        });
        dispatch("bindAudio");
        if (!state.isAgent) commit("toogleCamModal");
        else meetingSession.audioVideo.startLocalVideoTile();
      },
      // audioVideoDidStop: () => dispatch("ConfirmedendMeeting"),
      videoTileDidUpdate: tileState => {
        // User updates
        if (!tileState.tileId) return;

        if (meetingSession) {
          // Ignore a tile without attendee ID and videos.
          const yourAttendeeId =
            meetingSession.configuration.credentials.attendeeId;

          // tileState.boundAttendeeId is formatted as "attendee-id#content".
          const boundAttendeeId = tileState.boundAttendeeId;

          if (tileState.isContent) {
            // Get the attendee ID from "attendee-id#content".
            const baseAttendeeId = new DefaultModality(boundAttendeeId).base();
            if (baseAttendeeId === yourAttendeeId) {
              tileState.isMineContent = true;
            }
          }
          const index = state.videosIds.findIndex(
            vid => vid.tileId === tileState.tileId
          );
          if (index > -1)
            commit("setState", {
              key: "videosIds",
              value: state.videosIds.map((vid, i) => {
                if (i === index) return tileState;
                return vid;
              })
            });
          else {
            commit("setState", {
              key: "videosIds",
              value: [...state.videosIds, tileState]
            });
            setTimeout(() => {
              // Add video tag correspondence
              const videoElement = document.getElementById(
                `videouser_${tileState.tileId}`
              );
              if (!meetingSession) return;
              if (!videoElement)
                throw new Error(
                  "Not exist video element!. Brilliant seek this problem." +
                    JSON.stringify(tileState) +
                    JSON.stringify(state.videosIds)
                );
              else
                meetingSession.audioVideo.bindVideoElement(
                  tileState.tileId,
                  videoElement
                );
            }, 500);
          }
        }
      },
      videoTileWasRemoved: tileId => {
        const videoIndex = state.videosIds.findIndex(
          elm => elm.tileId === tileId
        );
        if (videoIndex > -1)
          commit("setState", {
            key: "videosIds",
            value: state.videosIds.filter((vid, i) => i !== videoIndex)
          });
      }
    });
    /**Chat observer */
    meetingSession.audioVideo.realtimeSubscribeToReceiveDataMessage(
      "chat",
      dataMessage => dispatch("chatMessageHandler", dataMessage)
    );
    return true;
  },

  async toogleSharingEnabled({ commit, state }) {
    if (!meetingSession) return;
    commit("setState", { key: "sharingEnabled", value: !state.sharingEnabled });
    if (state.sharingEnabled) {
      await meetingSession.audioVideo.startContentShareFromScreenCapture();
    } else {
      await meetingSession.audioVideo.stopContentShare();
    }
  },

  async bindAudio({ state }) {
    if (!meetingSession) return;
    meetingSession.audioVideo.bindAudioElement(
      document.getElementById(state.audioId)
    );
  },

  setConfigCameras({ commit }, value) {
    commit("setState", { key: "configCameras", value: value });
  }

  /**End of group of basic meeting */
};
