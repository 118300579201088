var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Authorization Container",
      style: _vm.isAtril
        ? "color: #545454;"
        : "color: #545454; font-size: 1.3rem;"
    },
    [
      _c("div", { staticClass: "banderaSvg" }),
      _c("div", { staticClass: "Content" }, [
        _c("div", { staticClass: "CardWhite mx-auto" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "mb-1 mt-0 px-5 container-texts" },
            [
              _c(
                "p",
                {
                  staticClass: "fonterNoDate textHero m-0 mx-auto d-block mb-4"
                },
                [
                  _vm._v(
                    "En cumplimiento de la Ley 1581 de 2012 y demás normas concordantes, la FSFB como institución que almacena y recolecta datos personales para realizar los trámites administrativos relacionados con su proceso de atención, enviarle información relacionada con la evaluación de satisfacción, así como campañas comerciales, requiere obtener su autorización para que de manera libre, previa, expresa, voluntaria, y debidamente informada, le permita a la Institución recolectar, recaudar, almacenar, usar, circular, suprimir, procesar, compilar, intercambiar, actualizar y disponer de los datos como nombre, teléfono, dirección física y electrónica, entre otros que serán suministrados y que serán incorporados en distintas bases o bancos de datos."
                  )
                ]
              ),
              _c(
                "P",
                {
                  staticClass: "fonterNoDate textHero m-0 mx-auto d-block mb-4"
                },
                [
                  _vm._v(
                    "De igual forma por ser el titular de los datos personales usted podrá conocer, actualizar y rectificar sus datos personales, solicitar prueba de la autorización otorgada a la Fundación Santa Fe de Bogotá, revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. Para dudas e inquietudes relacionadas con el tratamiento de datos personales puede escribirnos al correo electrónico datospersonales@fsfb.org.co. o consultar nuestra política de tratamiento de datos personales dispuesta en la página web"
                  ),
                  _c("strong", { staticClass: "linking ml-1" }, [
                    _vm._v("www.fundacionsantafedebogota.com.")
                  ])
                ]
              ),
              _c(
                "p",
                {
                  staticClass:
                    "fonterNoDate textHero m-0 mx-auto d-block mb-3 small-txt pb-2"
                },
                [
                  _vm._v(
                    "Lo anterior hace exclusiva referencia a lo señalado arriba y no se relaciona con la información consignada en las historias clínicas, debido a que los datos y la información allí consignada conservan su carácter reservado, confidencial y totalmente privado (Resolución 1995 de 1999)."
                  )
                ]
              ),
              _c(
                "p",
                {
                  staticClass:
                    "fonterNoDate textHero m-0 mx-auto d-block mb-0 subtitle"
                },
                [
                  _vm._v(
                    "Por lo tanto, ¿Autorizo a la Fundación Santa Fe de Bogotá?"
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "mt-0 d-flex gap-3 justify-content-center" }, [
          _c(
            "button",
            {
              staticClass:
                "arrowButton p-4 w-100 my-3 text-center button-without-styles mx-3 d-flex",
              class: _vm.selectedThing === "Si" && "selectedThing",
              attrs: { block: "" },
              on: {
                click: function() {
                  return _vm.onSelectedCheckbox("Si", 1)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/mobileFSFB/si-verde.svg"),
                  alt: "Si"
                }
              }),
              _c(
                "span",
                { staticClass: "my-4 w-100 text-center d-block fontButton" },
                [_vm._v("Si")]
              ),
              _vm._m(1)
            ]
          ),
          _c(
            "button",
            {
              staticClass:
                "arrowButton p-4 w-100 my-3 text-center button-without-styles mx-3 d-flex",
              class: _vm.selectedThing === "No" && "selectedThing",
              attrs: { block: "" },
              on: {
                click: function() {
                  return _vm.onSelectedCheckbox("No", 2)
                }
              }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/mobileFSFB/error.png"),
                  alt: "No"
                }
              }),
              _c(
                "span",
                { staticClass: "my-4 w-100 text-center d-block fontButton" },
                [_vm._v("No")]
              ),
              _vm._m(2)
            ]
          )
        ]),
        _c("div", { staticClass: "pb-2" }, [
          _c(
            "button",
            {
              staticClass: "text-center buttonService containerForm mt-3",
              class: !_vm.selectedThing ? "disabled" : "active",
              attrs: { disabled: !_vm.selectedThing },
              on: {
                click: function($event) {
                  return _vm.calculateAction(_vm.selectedThing)
                }
              }
            },
            [_vm._v("Continuar")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "py-4" }, [
      _c("h3", { staticClass: "fonterH3 fonterH3NoDate mb-1 mx-auto" }, [
        _vm._v("Autorización de datos personales")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative container" }, [
      _c("input", {
        staticClass: "checkBoxArrow",
        attrs: { type: "checkbox", id: "1", disabled: "" }
      }),
      _c("div", { staticClass: "checkmark" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "position-relative container" }, [
      _c("input", {
        staticClass: "checkBoxArrow",
        attrs: { type: "checkbox", id: "2", disabled: "" }
      }),
      _c("div", { staticClass: "checkmark" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }