var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dk" }, [
    _c("div", { staticClass: "contain" }, [
      _c("div", { staticClass: "box-error" }, [
        _c(
          "div",
          { staticClass: "pt-5 m-5" },
          [_c("AlertOutline", { staticClass: "alert", attrs: { size: 50 } })],
          1
        ),
        _c(
          "p",
          { staticClass: "text-center mb-3", staticStyle: { color: "gray" } },
          [
            _vm._v(
              "Nuestro sistema de seguridad ha detectado fallas de seguridad en este navegador."
            )
          ]
        ),
        _c(
          "p",
          { staticClass: "text-center mb-3", staticStyle: { color: "gray" } },
          [_vm._v("Porfavor intenta nuevamente desde un navegador distinto")]
        ),
        _c(
          "p",
          {
            staticClass: "text-center mt-2 pt-5",
            staticStyle: { color: "gray" }
          },
          [_vm._v("Cerrando ventana...")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }