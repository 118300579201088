export default [
  {
    NombreCentroMedico: "CMC DEMo",
    Especialidad: "Odontología",
    Fecha: "2021-01-15 10:00 am",
    idCita: 123456,
    EnCentroMedico: true
  },
  {
    NombreCentroMedico: "CMC DEMo",
    Especialidad: "Odontología",
    Fecha: "2021-01-15 2:00 pm",
    idCita: 123457,
    EnCentroMedico: true
  },
  {
    NombreCentroMedico: "CMC BOCAGRANDE",
    Especialidad: "Odontología",
    Fecha: "2021-01-16 10:00 am",
    idCita: 123458,
    EnCentroMedico: false
  },
  {
    NombreCentroMedico: "CMC BOCAGRANDE",
    Especialidad: "Odontología",
    Fecha: "2021-01-17 10:00 am",
    idCita: 123459,
    EnCentroMedico: false
  },
  {
    NombreCentroMedico: "CMC BOCAGRANDE",
    Especialidad: "Odontología",
    Fecha: "2021-01-18 10:00 am",
    idCita: 123460,
    EnCentroMedico: false
  }
];
