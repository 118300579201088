var moment = require("moment");
import { getToken, getEnvOnly } from "../../helpers/Meet";

export default {
    fetchAppointments({ commit }, params) {
      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        commit("setState", { key: "appointments", value: [] });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch( `${params.url}api/v2/appointments${params.queryData}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let appointments = JSON.parse(result)
          appointments = appointments.map(a=> {
            try {
              a.reason = JSON.parse(a.reason);
            } catch (error) {
              a.reason = {
                formulas: a.reason,
                point: null
              };
            }
            
            return a;
          } )
          appointments = appointments.filter(a=>moment(a.startAt)>moment())
          commit("setState", { key: "appointments", value: appointments });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    resetEnvironment({ commit }) {
      commit("setState", { key: "appointments", value: [] });
    },
    fetchAvailableMonth({ commit }, params) {
      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch( `${params.url}api/v1/web/appointment/availabledays${params.queryData}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          commit("setState", { key: "availables", value: JSON.parse(result) });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    fetchAvailableDays({ commit }, params){
      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch( `${params.url}api/v2/schedules/${params.scheduleId}/branch/${params.branchId}/availability?strDate=${params.startAt}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          commit("setState", { key: "availables", value: JSON.parse(result) });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    fetchAvailablebyMonths({ commit }, params){
      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        // https://appointments.sales.debmedia.com/api/v1/web/appointment/availabledays?scheduleId=10296&branchId=20104&strDate=2021-11-01T00:02:00-0500
        fetch( `${params.url}api/v1/web/appointment/availabledays?scheduleId=${params.scheduleId}&branchId=${params.branchId}&strDate=${params.startAt}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          result = JSON.parse(result || '{}');
          result.slots = result.slots.map(r=> {
            return {
              ...r,
              zoneOwn: moment(r.startDate).utc().format("YYYY-MM-DD h:mm:ss")
            }
          })
          console.log("result")
          console.log(result)
          commit("setState", { key: "availablesDaysMonth", value: result });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    createAppointment({ commit }, params){

      if (!params.data || typeof params.data !== "object" || !params.data.branch || !params.data.schedule || !params.data.startAt || !params.data.user || !params.data.reason){
        console.log(params)
        return Promise.reject(
          new Error("Invalid paramethers for appointments creation")
        );
      }

      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });

        console.log(params)
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "branch": {
            "id": params.data.branch
          },
          "schedule": {
            "id": params.data.schedule
          },
          "startAt": params.data.startAt,
          "customer": params.data.user,
          "reason": params.data.reason
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        };
        
        fetch( `${params.url}api/v2/appointments${params.queryData}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          commit("setState", { key: "responseAppointment", value: JSON.parse(result) });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    updateAppointment({ commit }, params){

      if (typeof params.data !== "object" || !params.data?.code){
        console.log(params)
        return Promise.reject(
          new Error("Invalid paramethers for appointments updating")
        );
      }

      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "branch": {
            "id": params.data.branch
          },
          "status":params.data.status || 'CANCELED',
          "schedule": {
            "id": params.data.schedule
          },
          "startAt": params.data.startAt,
          "endAt": params.data.startAt,
        });
        
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
        };
        
        fetch( `${params.url}api/v2/appointments/${params.data?.code}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          commit("setState", { key: "responseAppointment", value: JSON.parse(result) });
          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },
    fetchSchedule({ commit }, params){

      if (typeof params !== "object"){
        console.log(params)
        return Promise.reject(
          new Error("Invalid paramethers for fetch schedule")
        );
      }

      return new Promise((resolve) => {

        commit("setState", { key: "loading", value: true });
        
        var myHeaders = new Headers();
        myHeaders.append("x-api-token", String(params.headers.token_appointments) );
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders
        };
        
        fetch( `${params.url}api/v2/schedules${params.queryData}`, requestOptions)
        .then(response => response.text())
        .then(result => {
          if(result){
            let schedules = JSON.parse(result)
            let schedule = schedules.length ? schedules[0] : {};
            commit("setState", { key: "schedule", value: schedule });
          }

          commit("setState", { key: "loading", value: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          commit("setState", { key: "error", value: error });
          commit("setState", { key: "loading", value: false });
        });
      });
    },

    setDay({ commit }, params){
      console.log(params)
      commit("setState", { key: "selectedDay", value: params });
    },

    setAppointment({commit}, params){
      commit("setState", {key: "appointment", value: params});
    },
    
    async sendNotification({ commit }, params){
      commit("setState", { key: "loading", value: false });
      console.log(params)

      return new Promise(resolve => {
        
        getToken().then(key => {
          var myHeaders = new Headers();
          myHeaders.append("authorization", key);
          myHeaders.append("x-api-key", getEnvOnly().VUE_APP_AWS_API_KEY);
  
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              schedule_code: params.code,
              number: params.Celular,
              schedule_date: params.selectedDay
            }),
            redirect: "follow"
          };
  
          fetch(
            getEnvOnly().VUE_APP_AWS_API_URL + "/send_notification",
            requestOptions
          )
            .then(response => response.text())
            .then(result => {
              let res = JSON.parse(result);
              console.log(res)
              resolve();
            })
            .catch(error => {
              resolve(error);
            });
        });
      });
    }
};
