var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box_size" },
    [
      _vm._l(_vm.lines, function(line, i) {
        return _c(
          "h4",
          {
            key: "line_" + i,
            staticClass: "center-text mt-2",
            staticStyle: { "font-size": "1.1rem" }
          },
          [_vm._v(_vm._s(line))]
        )
      }),
      _c(
        "h5",
        {
          staticClass: "center-text mt-4",
          staticStyle: { "font-size": "1rem" }
        },
        [_vm._v(_vm._s(_vm.date))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }