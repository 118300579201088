var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CancelSchedule" },
    [
      _c("Header"),
      _c("div", { staticStyle: { "margin-top": "50px" } }),
      _vm.isntIntersection && !_vm.modeCanceled
        ? _c("div", { staticClass: "headerFixed" }, [
            _c(
              "button",
              {
                staticClass: "button-without-styles py-3 px-4",
                attrs: { type: "button" },
                on: {
                  click: function() {
                    return _vm.goToView("ToSchedule")
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "19",
                      viewbox: "0 0 20 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                        stroke: "#ffff",
                        "stroke-width": "3.12307",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            ),
            _c(
              "h2",
              { staticClass: "TitleSection text-title color-white pt-2" },
              [_vm._v("Cancelación de tu videollamada")]
            )
          ])
        : _vm._e(),
      !_vm.modeCanceled
        ? _c(
            "h2",
            {
              staticClass: "TitleSection pt-3 pb-3",
              attrs: { id: "headerCalendarTitle" }
            },
            [_vm._v("Cancelación de tu videollamada")]
          )
        : _vm._e(),
      !_vm.modeCanceled
        ? _c("div", { staticClass: "botton-actions ml-5" }, [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c(
                "button",
                {
                  staticClass: "button-without-styles",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      return _vm.goToView("ToSchedule")
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "20",
                        height: "19",
                        viewbox: "0 0 20 19",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                          stroke: "#0058A2",
                          "stroke-width": "3.12307",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ])
        : _c("h2", { staticClass: "TitleSection pt-4 mb-4 pb-1" }, [
            _vm._v("Tu videollamada ha sido cancelada")
          ]),
      _c("div", { staticClass: "form-data mb-3 mt-3 py-4 mx-2 px-4" }, [
        _c("p", { staticClass: "font-16 mb-0" }, [
          _vm._v("Nombre:"),
          _c("strong", { staticClass: "ml-1" }, [
            _vm._v(_vm._s(_vm.appointment.customer.firstName) + " ")
          ])
        ]),
        _c("p", { staticClass: "font-16 mb-0" }, [
          _vm._v("Fecha y hora:"),
          _c("strong", { staticClass: "ml-1" }, [
            _vm._v(_vm._s(_vm.appointmentDate))
          ])
        ]),
        _vm.appointment.reason.servicioSelected &&
        typeof _vm.appointment.reason.servicioSelected == "object" &&
        Object.keys(_vm.appointment.reason.servicioSelected).length
          ? _c("p", { staticClass: "font-16 mb-0" }, [
              _vm._v("Trámite:"),
              _c("strong", { staticClass: "ml-1" }, [
                _vm._v(_vm._s(_vm.appointment.reason.servicioSelected))
              ])
            ])
          : _vm._e(),
        _c("p", { staticClass: "font-16 mb-0" }, [
          _vm._v("Modalidad de atención:"),
          _c("strong", { staticClass: "ml-1" }, [
            _vm._v(_vm._s(_vm.appointment.reason.modalidad))
          ])
        ]),
        _c(
          "a",
          { staticClass: "mx-2 LinkColored mt-4 pb-1 text-center d-block" },
          [
            _vm._v(
              "Te enviaremos la confirmación de cancelacion a tu correo electrónico"
            ),
            _c(
              "p",
              {
                staticClass: "mb-0 ml-1 d-flex flex-wrap justify-content-center"
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.email1))]),
                _c("span", [_vm._v(_vm._s(_vm.email2))])
              ]
            ),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v("y a tu número celular")
            ]),
            _c("span", { staticClass: "ml-1" }, [_vm._v(_vm._s(_vm.phone))])
          ]
        )
      ]),
      _c("div", { staticClass: "botton-actions-footer" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center" },
          [
            !_vm.modeCanceled
              ? _c(
                  "b-button",
                  {
                    staticClass: "text-center w-100 py-2 mx-1",
                    attrs: {
                      variant: "bluecolmedica",
                      size: "sm",
                      type: "button"
                    },
                    on: { click: _vm.handleCancel }
                  },
                  [
                    !_vm.loading
                      ? _c("span", { staticClass: "font-md" }, [
                          _vm._v("Cancelar videollamada")
                        ])
                      : _c(
                          "div",
                          { staticClass: "py-1" },
                          [_c("MiniSpinner")],
                          1
                        )
                  ]
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-2 d-block text-center w-100 py-2 mx-1",
                        attrs: { variant: "bluecolmedica" },
                        on: {
                          click: function() {
                            return _vm.goToView("ToSchedule")
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "TextSection",
                            staticStyle: { color: "white" }
                          },
                          [
                            _c("span", { staticClass: "font-md" }, [
                              _vm._v("Ver videollamadas programadas")
                            ])
                          ]
                        )
                      ]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "text-center w-100 py-2 mx-1",
                        attrs: {
                          variant: "whitecolmedica",
                          size: "sm",
                          type: "button"
                        },
                        on: {
                          click: function() {
                            return _vm.goToView("Home")
                          }
                        }
                      },
                      [
                        _c("span", { staticClass: "font-md" }, [
                          _vm._v("Ya puedes cerrar esta ventana")
                        ])
                      ]
                    )
                  ],
                  1
                )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }