var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Modal w-100" }, [
    _c(
      "div",
      { staticClass: "Modal-content mx-4 px-3 py-2" },
      [
        _vm.header
          ? _c(
              "header",
              {
                staticClass:
                  "Modal-header d-flex align-items-center justify-content-end mb-4"
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "button-without-styles",
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.goBack }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "17",
                          height: "16",
                          viewbox: "0 0 17 16",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d:
                              "M16.48 16L10.624 7.808L16.224 0H11.488L8.256 4.512L5.024 0H0.256L5.856 7.84L0 16H4.768L8.256 11.136L11.712 16H16.48Z",
                            fill: "#788591"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._t("body")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }