var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "position-relative w-100 h-100",
      staticStyle: { "max-width": "100%" }
    },
    [
      _c("Header"),
      _c(
        "div",
        { staticClass: "contain" },
        [
          _c(
            "div",
            { staticClass: "box-error" },
            [
              _c("AlertCircle", {
                staticClass: "icon-alert",
                attrs: { size: "32px" }
              }),
              _c("span", { staticClass: "d-block mt-2" }, [
                _vm._v("Los datos que ingresaste no")
              ]),
              _c("span", { staticClass: "d-block mb-2" }, [
                _vm._v("concuerdan con un plan activo")
              ]),
              _c("b-form-input", {
                staticClass: "my-1 form-control-sm form-disable-white",
                attrs: {
                  type: "text",
                  placeholder: _vm.tipideText,
                  disabled: true
                }
              }),
              _c("b-form-input", {
                staticClass: "my-1 form-control-sm form-disable-white",
                attrs: { type: "text", placeholder: _vm.numide, disabled: true }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3 text-center" },
            [
              _c(
                "b-button",
                {
                  staticClass: "text-center",
                  attrs: {
                    variant: "bluecolmedica",
                    size: "sm",
                    type: "button"
                  },
                  on: {
                    click: function($event) {
                      return _vm.goToView("Autenticate")
                    }
                  }
                },
                [_vm._v("Ingresar datos nuevamente")]
              )
            ],
            1
          ),
          _c(
            "a",
            {
              staticClass: "d-block mt-5 text-center",
              on: {
                click: function($event) {
                  return _vm.$bvModal.show("CallTo")
                }
              }
            },
            [_vm._v("Quiero afiliarme a Colmédica")]
          ),
          _c(
            "b-modal",
            {
              attrs: {
                id: "CallTo",
                footerClass: "p-1 border-top-0",
                bodyClass: "p-2",
                headerClass: "p-1 border-bottom-0",
                centered: "",
                "hide-footer": "",
                "hide-header": ""
              }
            },
            [
              _c(
                "div",
                { staticClass: "position-relative w-auto h-auto py-5" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block text-center text-sizing-09 mb-3" },
                    [
                      _vm._v(
                        "Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:"
                      )
                    ]
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "d-block text-center text-sizing-2 mt-2",
                      attrs: { href: "tel:3013944550" }
                    },
                    [
                      _c("PhoneInTalk", { attrs: { size: "35px" } }),
                      _c("span", [_vm._v("3013944550")])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-block text-center text-sizing-1 mt-3" },
                    [
                      _c("div", [_vm._v("horario de atención")]),
                      _c("div", [_vm._v("lunes a viernes de 7:00AM a 7:00PM")]),
                      _c("div", [_vm._v("sábado de 9:00AM a 1:00PM")])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-block text-center text-sizing-09 mt-3" },
                    [_vm._v("o ingresa a ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-block text-center text-sizing-09" },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "link-colmedica",
                          attrs: {
                            href: "https://www.colmedica.com/planes",
                            target: "_blank"
                          }
                        },
                        [_vm._v("www.colmedica.com/planes")]
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-block text-center text-sizing-09" },
                    [_vm._v("y solicita una llamada para obtener una asesoría")]
                  )
                ]
              )
            ]
          ),
          _c("div", { staticClass: "botton-actions" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "text-center",
                    attrs: {
                      variant: "bluecolmedica",
                      size: "sm",
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.goToView("Autenticate")
                      }
                    }
                  },
                  [
                    _c("Back", { attrs: { size: "24px" } }),
                    _c("span", { staticClass: "text-sizing-12" }, [
                      _vm._v("Atrás")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }