var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Modal" }, [
    _c("div", { staticClass: "Overlay" }),
    _c("div", { staticClass: "ModalContent", style: _vm.maxWidth }, [
      !_vm.hideHeader
        ? _c(
            "header",
            {
              staticClass:
                "headerModal d-flex align-items-center justify-content-center"
            },
            [_vm._t("header")],
            2
          )
        : _vm._e(),
      _c(
        "main",
        {
          staticClass: "mainModal p-4",
          class: _vm.hideHeader && "pt-5 heightToFrame"
        },
        [_vm._t("main")],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }