var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Home Container2 align-items-flex-start",
      staticStyle: { color: "#545454", margin: "auto", width: "100%" }
    },
    [
      false
        ? _c("div", { staticClass: "Content" }, [
            _c("div", { staticClass: "banderaSvg" }),
            _c("article", { staticClass: "w-100" }, [
              _c("div", { staticClass: "d-flex containerBoxes w-100 h-100" }, [
                _c("div", { staticClass: "box" }, [
                  _c("h4", { staticClass: "textHeroH4 w-100 mt-5 mb-2 pb-3" }, [
                    _vm._v("Fundación Santa Fe de Bogotá")
                  ]),
                  _c("div", { staticClass: "whiteCard mx-auto" }, [
                    _c(
                      "h3",
                      { staticClass: "fonterH3 pt-0 mt-2 text-center" },
                      [_vm._v("¡Hola, elige tu idioma!")]
                    ),
                    _c(
                      "p",
                      {
                        staticClass: "text-center textHero w-100 mt-1 mb-4 pb-3"
                      },
                      [_vm._v("Hi, Select your language")]
                    ),
                    _c("div", { staticClass: "px-5 pt-5" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "button-without-styles d-flex buttonContinue p-2 w-100 mx-auto mb-4 align-items-center justify-content-between",
                          on: { click: _vm.goCurrent }
                        },
                        [
                          _c("img", {
                            staticClass: "img-ESP",
                            attrs: {
                              src: require("@/assets/ESP.png"),
                              alt: "ESP"
                            }
                          }),
                          _c("span", [_vm._v("Español")]),
                          _c("span", { staticClass: "opacity-0" }, [
                            _vm._v("Esp")
                          ])
                        ]
                      ),
                      _c(
                        "button",
                        {
                          staticClass:
                            "button-without-styles d-flex buttonContinue p-2 w-100 mx-auto align-items-center justify-content-between",
                          on: { click: _vm.goCurrent }
                        },
                        [
                          _c("img", {
                            staticClass: "img-USA",
                            attrs: {
                              src: require("@/assets/USA.png"),
                              alt: "USA"
                            }
                          }),
                          _c("span", [_vm._v("English")]),
                          _c("span", { staticClass: "opacity-0" }, [
                            _vm._v("Esp")
                          ])
                        ]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "box box2 mx-0 w-100" }, [
                  _vm.areaCustom.backgrounds
                    ? _c("img", {
                        staticClass: "containerImg-img w-100",
                        attrs: {
                          src: _vm.areaCustom.backgrounds.background1,
                          alt: "saludImportantImage"
                        }
                      })
                    : _vm._e(),
                  _c("img", {
                    staticClass: "logoFSFBTransparent",
                    attrs: {
                      src: require("@/assets/logoFSFBTransparent.png"),
                      alt: "logoFSFBTransparent"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "box-tape text-center d-flex pl-5" },
                    [
                      _c("div", { staticClass: "boxText text-start d-block" }, [
                        _c("div", { staticClass: "ml-4 pl-3 boxBorderer" }, [
                          _vm._m(0),
                          _c(
                            "h4",
                            {
                              staticClass:
                                "welcomeText welcomeTextSecondary mb-2"
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  "(Zona " + _vm._s(_vm.areaCustom.label) + ")"
                                )
                              ])
                            ]
                          )
                        ]),
                        _vm.areaCustom && _vm.areaCustom.labelEnglish
                          ? _c(
                              "p",
                              { staticClass: "descText mb-0 pl-5 ml-2 mt-4" },
                              [_vm._v("Welcome to")]
                            )
                          : _vm._e(),
                        _vm.areaCustom && _vm.areaCustom.labelEnglish
                          ? _c(
                              "p",
                              { staticClass: "descText mb-4 pl-5 ml-2" },
                              [_vm._v(_vm._s(_vm.areaCustom.labelEnglish))]
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "Footer" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticClass: "welcomeText mb-0" }, [
      _c("strong", [_vm._v("Bienvenido a Fundación Santa Fé de Bogotá")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }