var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Content" }, [
    _c(
      "div",
      { staticClass: "Afiliate" },
      [
        _c("Header"),
        _c("div", { staticClass: "botton-actions" }, [
          _c("div", { staticClass: "d-flex justify-content-between pl-2" }, [
            _c(
              "button",
              {
                staticClass: "button-without-styles py-3 px-4",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.goToView("Autenticate")
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "19",
                      viewbox: "0 0 20 19",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269",
                        stroke: "#0058A2",
                        "stroke-width": "3.12307",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round"
                      }
                    })
                  ]
                )
              ]
            )
          ])
        ]),
        _c("h2", { staticClass: "TitleSection pt-5" }, [
          _vm._v("Afiliate a Colmédica")
        ]),
        _c("p", { staticClass: "TextSection mb-0 mt-3" }, [
          _vm._v(
            "Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:"
          )
        ]),
        _c("p", { staticClass: "TextSection" }, [_vm._v("301 394 45 50")]),
        _c("p", { staticClass: "TextSection mb-0" }, [
          _vm._v("El horario de atención para afiliaciones a Colmédica es:")
        ]),
        _c("p", { staticClass: "TextSection mb-0" }, [
          _vm._v("Lunes a viernes: 7:00 a.m. a 7:00 p.m")
        ]),
        _c("p", { staticClass: "TextSection" }, [
          _vm._v("Sábados: 9:00 a.m. a 1:00 p.m.")
        ]),
        _c("p", { staticClass: "TextSection mb-0" }, [
          _vm._v("O ingresa a nuestro sitio web")
        ]),
        _vm._m(0),
        _c("p", { staticClass: "TextSection mb-0 px-3" }, [
          _vm._v("y solicita una llamada para obtener una asesoría.")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center mb-0" }, [
      _c(
        "a",
        {
          staticClass: "Link",
          attrs: { href: "https://www.colmedica.com/planes", target: "_blank" }
        },
        [_vm._v("https:// www.colmedica.com/planes")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }