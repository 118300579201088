import { recorder } from "../../helpers/recorder";

export default {
  startCheck({ commit, state }) {
    if (state.timer !== null) return;
    let timer = setInterval(async () => {
      let status = await recorder.checkRecorder();
      commit("setState", { key: "status", value: status });
    }, 2000);
    commit("setState", { key: "timer", value: timer });
  },

  sendTurn(ctx, turn_id) {
    return recorder.setTurn(turn_id);
  },

  stopCheck({ commit, state }) {
    if (state.timer === null) return;
    clearInterval(state.timer);
    commit("setState", { key: "timer", value: null });
    commit("setState", { key: "status", value: null });
  }
};
