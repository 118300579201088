var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Meeting", {
    attrs: { meetId: _vm.$route.params.meetId, username: _vm.username },
    on: {
      "~endMeet": function($event) {
        return _vm.endMeet.apply(null, arguments)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }