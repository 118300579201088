var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "Progress d-flex justify-content-center align-items-center"
      },
      [
        _c("div", { staticClass: "container p-5" }, [
          _c(
            "div",
            {
              staticClass: "progressCounter",
              staticStyle: { cursor: "pointer" },
              attrs: { "data-percentage": _vm.percent },
              on: { click: _vm.toHome }
            },
            [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "progressCounter-value" }, [
                _c("div", { staticClass: "mt--1" }, [
                  _vm._v(_vm._s(_vm.seconds))
                ])
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progressCounter-left" }, [
      _c("span", { staticClass: "progressCounter-bar" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "progressCounter-right" }, [
      _c("span", { staticClass: "progressCounter-bar" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }