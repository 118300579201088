var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "videocall" },
    [
      _vm._l(_vm.vidsWithSelector, function(video, i) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.haveSharedVideo || video.isContent,
                expression: "!haveSharedVideo || video.isContent"
              }
            ],
            staticClass: "videoContainer",
            class: {
              firstVideo:
                _vm.isfirstVideo(_vm.vidsWithSelector, video) === "first",
              secondVideo:
                _vm.isfirstVideo(_vm.vidsWithSelector, video) === "second",
              minivideoSharedLeft:
                _vm.isShared(_vm.vidsWithSelector, video) === "left",
              minivideoSharedRight:
                _vm.isShared(_vm.vidsWithSelector, video) === "right",
              minicontentShared:
                _vm.isShared(_vm.vidsWithSelector, video) === "shared",
              minicontentHidden:
                _vm.isShared(_vm.vidsWithSelector, video) === "hide"
            }
          },
          [
            _c("video", { key: video.selector, attrs: { id: video.selector } }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: video.localTile && video.active,
                  expression: "video.localTile && video.active"
                }
              ],
              staticClass: "camChange",
              attrs: { src: _vm.camChange },
              on: {
                click: function($event) {
                  return _vm.$emit("CamChange")
                }
              }
            })
          ]
        )
      }),
      _c("audio", {
        staticStyle: { display: "none" },
        attrs: { id: _vm.audioIdFinal }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }