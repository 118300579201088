import { getEnvOnly } from "../../helpers/Meet";

const isModeTutorial = localStorage.getItem("isModeTutorial");

export default {
  view: "Home",
  isModeTutorial: isModeTutorial ? JSON.parse(isModeTutorial) : true,
  history: [],
  HorarioOutContinue: false,
  // autenticacion
  charging: false,
  servicioSelected: null,
  validationErrors: [],
  client: null,
  isOlderAdult: false,
  service: "",
  errorValidationTemporizer: null,
  explanation: true,
  showModalError: false,
  showUserInactive: false,
  showUserOlder: false,
  env: {},
  servicesAvailable: [
    {
      label: "Exámenes diagnósticos",
      code_name: "examenes_diagnosticos",
      code: 1
    },
    {
      label: "Procedimientos quirúrgicos",
      code_name: "procedimientos_quirurgicos",
      code: 2
    },
    {
      label: "Otros servicios",
      code_name: "otros_servicios",
      code: 3
    }
  ],
  tipide: [
    { value: "CC", text: "Cédula de Ciudadanía" },
    { value: "CE", text: "Cedula de Extranjería" },
    { value: "PA", text: "Pasaporte" },
    { value: "CD", text: "Carné Diplomático" },
    { value: "PE", text: "Permanencia Especial" },
    { value: "PT", text: "Permiso Protección Temporal" },
    { value: "SC", text: "Salvoconducto de Permanencia" },
    { value: "TE", text: "Tarjeta de Extranjería" },
    { value: "AS", text: "Adulto Sin Identificación" }
  ],

  // documents
  files: [],
  colmedicaRequest: null,

  // B64 - enviado a bsix
  b64final: {
    ti: "",
    nu: "",
    ma: "",
    na: "",
    ap: "",
    tr: getEnvOnly().VUE_APP_DEBMEDIA_DEFAULT_TRAMITE,
    so: "",
    fu: "oa",
    em: "",
    ce: "",
    to: "",
    su: getEnvOnly().VUE_APP_DEBMEDIA_DEFAULT_BRANCH
  },
  turn_from_code: {},
  test_users: [
    {
      NumeroIdentificacion: 1033724553,
      Celular: "3017076719",
      Correo: "oscar.jimenez@brilliant360.net",
      Nombres: "Oscar",
      PrimerApellido: "Jimenez",
      Resultado: 1,
      Edad: 31,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1000778041,
      Celular: "3154494547",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Derek",
      PrimerApellido: "Paul",
      Resultado: 1,
      Edad: 26,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1073519274,
      Celular: "3164960387",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Maria Paz",
      PrimerApellido: "Espejo",
      Resultado: 1,
      Edad: 28,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 1019140052,
      Celular: "3227466696",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Marcela",
      PrimerApellido: "Riascos",
      Resultado: 1,
      Edad: 38,
      SegundoApellido: "DEMO"
    },
    {
      NumeroIdentificacion: 53038862,
      Celular: "3182404149",
      Correo: "derek.paul@brilliant360.net",
      Nombres: "Paola",
      PrimerApellido: "Torres",
      Resultado: 1,
      Edad: 29,
      SegundoApellido: "DEMO"
    }     
  ],
  phone_in: ""
};
