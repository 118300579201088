var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-11 header" }, [
        _c("div", { staticStyle: { margin: "0px auto", width: "50%" } }, [
          _c("img", {
            directives: [{ name: "openlog", rawName: "v-openlog" }],
            staticClass: "client_logo",
            attrs: { src: _vm.Logo }
          })
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "main_box" },
      [
        _c("h3", { staticClass: "text-center" }, [
          _vm._v("Version " + _vm._s(_vm.version))
        ]),
        _c(
          "b-form",
          {
            staticClass: "form-horizontal",
            on: {
              submit: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.saveUserData.apply(null, arguments)
              }
            }
          },
          [
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Nombre" },
              model: {
                value: _vm.name,
                callback: function($$v) {
                  _vm.name = $$v
                },
                expression: "name"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Apellido" },
              model: {
                value: _vm.last_name,
                callback: function($$v) {
                  _vm.last_name = $$v
                },
                expression: "last_name"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Cédula" },
              model: {
                value: _vm.user_id,
                callback: function($$v) {
                  _vm.user_id = $$v
                },
                expression: "user_id"
              }
            }),
            _c("b-form-input", {
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Celular" },
              model: {
                value: _vm.phone_number,
                callback: function($$v) {
                  _vm.phone_number = $$v
                },
                expression: "phone_number"
              }
            }),
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              _vm._l(_vm.queues, function(q) {
                return _c(
                  "b-button",
                  {
                    key: q.id,
                    staticClass: "my-2 btn-block align-self-center mx-auto",
                    attrs: { type: "submit", variant: "outline-bluecolmedica" },
                    on: {
                      click: function($event) {
                        _vm.queue_dt = q
                      }
                    }
                  },
                  [_vm._v(_vm._s(q.name) + " - " + _vm._s(q.branch.label))]
                )
              }),
              1
            ),
            _vm.errorMessage
              ? _c("h5", { staticClass: "text-center" }, [
                  _vm._v(
                    "Se ha presentado un error al crear el usuario con la información existente."
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-block",
            on: { click: _vm.cleanCache }
          },
          [_vm._v("Limpiar todo el cache.")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }