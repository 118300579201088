export default {
  showModal: false,
  /** Example
   * {
      name: "Debugger Animals",
      checked: false, // true is green check, false is red cross
      message: "'t' is Not Defined"
    }
   */
  list: []
};
