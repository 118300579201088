var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Help Container",
      style: _vm.isAtril
        ? "color: #545454;"
        : "color: #545454; font-size: 1.3rem;"
    },
    [
      _c("div", { staticClass: "banderaSvg" }),
      _c("div", { staticClass: "Content" }, [
        _c("div", { staticClass: "CardWhite mx-auto" }, [
          _vm._m(0),
          _c("div", { staticClass: "mb-2 pb-3 mt-0 px-5" }, [
            _c("p", { staticClass: "mb-0 text-start" }, [
              _c("strong", { staticClass: "boldLabel" }, [
                _vm._v("Documento:")
              ]),
              _c("span", { staticClass: "ml-3 textFonter" }, [
                _vm._v(_vm._s(_vm.optionSelected.text || "No registra"))
              ])
            ]),
            _c("p", { staticClass: "text-start fonterNumberContainer" }, [
              _c("strong", { staticClass: "boldLabel boldNumber" }, [
                _vm._v("Numero:")
              ]),
              _c("span", { staticClass: "pl-3 textFonter mb-3" }, [
                _vm._v(
                  _vm._s(
                    _vm.currentUser ? _vm.currentUser.NumeroIdentificacion : ""
                  )
                )
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "mt-2" }, [
          _c(
            "button",
            {
              staticClass: "text-center buttonService mx-auto d-block",
              on: { click: _vm.goUserView }
            },
            [_c("span", [_vm._v("Continuar")])]
          ),
          _c(
            "button",
            {
              staticClass:
                "text-center outlinedServiceButton mr-4 mt-0 button-without-styles",
              on: { click: _vm.onGoToCurrentUser }
            },
            [_c("span", [_vm._v("Reingresar datos")])]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pb-0 pt-0" }, [
      _c(
        "div",
        {
          staticClass:
            "box-dk mt-0 mb-0 px-5 d-flex justify-content-start pb-1 container-header"
        },
        [
          _c("img", {
            staticClass: "infoSvgIcon mb-5 mt-0",
            attrs: { src: require("@/assets/cancel.png"), alt: "infoSvgIcon" }
          }),
          _c("h4", { staticClass: "textHero mb-0 ml-4 text-start" }, [
            _vm._v("Bienvenido, eres un usuario nuevo")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }