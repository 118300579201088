var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ModalLogs" },
    [
      _c(
        "b-modal",
        {
          staticClass: "modal-content-dk",
          attrs: {
            "hide-footer": "",
            "no-close-on-backdrop": "",
            centered: ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function(ref) {
                var close = ref.close
                return [
                  _c("h4", [_vm._v("Registro de eventos")]),
                  _c("div", { staticClass: "DropDown__Header__title" }, [
                    _c("i", {
                      staticClass: "fas fa-times",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function($event) {
                          return _vm.showModal(false)
                        }
                      }
                    })
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            _vm._l(_vm.logListMap, function(log, index) {
              return _c("div", { key: index, staticClass: "DropDown" }, [
                _c(
                  "div",
                  {
                    staticClass: "DropDown__Header",
                    on: {
                      click: function() {
                        return _vm.toggleDropDown(index)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "DropDown__Header__title" }, [
                      _c("i", {
                        class: log.checked
                          ? "fas fa-check CHECK"
                          : "fas fa-times TIMES"
                      }),
                      _c("h6", [_vm._v(_vm._s(log.name))])
                    ]),
                    _c("div", { staticClass: "DropDown__Header__icon" }, [
                      _c("i", {
                        ref: "iconItem__" + index,
                        refInFor: true,
                        staticClass: "fas fa-sort-down icon"
                      })
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "logItem__" + index,
                    refInFor: true,
                    staticClass: "DropDown__Body",
                    staticStyle: { display: "none" }
                  },
                  [_c("p", [_vm._v(_vm._s(log.message))])]
                )
              ])
            }),
            0
          ),
          _c("hr"),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "DropDown" }, [
              _c(
                "div",
                {
                  staticClass: "DropDown__Header",
                  on: {
                    click: function() {
                      return _vm.toggleDropDownMore()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "DropDown__Header__title" }, [
                    _c("h6", [_vm._v("Datos Vídeo Llamada")])
                  ]),
                  _c("div", { staticClass: "DropDown__Header__icon" }, [
                    _c("i", {
                      ref: "iconItem__more",
                      staticClass: "fas fa-sort-down icon"
                    })
                  ])
                ]
              ),
              _c(
                "div",
                {
                  ref: "logItem__more",
                  staticClass: "DropDown__Body",
                  staticStyle: { display: "none" }
                },
                [
                  _c("p", [
                    _c("b", [_vm._v("meeting: ")]),
                    _c("pre", [_vm._v(_vm._s(_vm.meeting))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("connecting: ")]),
                    _vm._v(_vm._s(_vm.connecting))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("audioId: ")]),
                    _vm._v(_vm._s(_vm.audioId))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("audioInputDevices: ")]),
                    _c("pre", [_vm._v(_vm._s(_vm.audioInputDevices))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("audioOutputDevices: ")]),
                    _c("pre", [_vm._v(_vm._s(_vm.audioOutputDevices))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("videoInputDevices: ")]),
                    _c("pre", [_vm._v(_vm._s(_vm.videoInputDevices))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("attendees: ")]),
                    _vm._v(_vm._s(_vm.attendees))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("videosIds: ")]),
                    _c("pre", [_vm._v(_vm._s(_vm.videosIds))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("myname: ")]),
                    _vm._v(_vm._s(_vm.myname))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("videoDevice: ")]),
                    _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.videoDevice)))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("speakerDevice: ")]),
                    _c("pre", [
                      _vm._v(_vm._s(JSON.stringify(_vm.speakerDevice)))
                    ])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("audioDevice: ")]),
                    _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.audioDevice)))])
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("audioEnabled: ")]),
                    _vm._v(_vm._s(_vm.audioEnabled))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("camEnabled: ")]),
                    _vm._v(_vm._s(_vm.camEnabled))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("speakerEnabled: ")]),
                    _vm._v(_vm._s(_vm.speakerEnabled))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("bitrateKbps: ")]),
                    _vm._v(_vm._s(_vm.bitrateKbps))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("stopSuggestionFromActiveCamera: ")]),
                    _vm._v(_vm._s(_vm.stopSuggestionFromActiveCamera))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("packetsPerSecond: ")]),
                    _vm._v(_vm._s(_vm.packetsPerSecond))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("nackCountPerSecond: ")]),
                    _vm._v(_vm._s(_vm.nackCountPerSecond))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("videoStopSuggestion: ")]),
                    _vm._v(_vm._s(_vm.videoStopSuggestion))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("connectionIsGood: ")]),
                    _vm._v(_vm._s(_vm.connectionIsGood))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("estimatedBandwidth: ")]),
                    _vm._v(_vm._s(_vm.estimatedBandwidth))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("requiredBandwidth: ")]),
                    _vm._v(_vm._s(_vm.requiredBandwidth))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("m_allow_video: ")]),
                    _vm._v(_vm._s(_vm.m_allow_video))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("sharingEnabled: ")]),
                    _vm._v(_vm._s(_vm.sharingEnabled))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("meetingSession: ")]),
                    _vm._v(_vm._s(_vm.meetingSession))
                  ]),
                  _c("p", [
                    _c("b", [_vm._v("isAgent: ")]),
                    _vm._v(_vm._s(_vm.isAgent))
                  ])
                ]
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }